import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { Flex, NewTag, tokens, Typography, Space, NewTagColor } from '@unitoio/mosaic';

import { getProviderById } from 'reducers';
import { useGetTestModeTerm } from '~/containers/FlowBuilder/hooks/useGetTestModeTerm';
import { LinkContainerIcons } from '~/components/LinkItem/LinkContainerIcons';
import { ProviderTerms } from '~/components/ProviderTerms/ProviderTerms';

export const LinkRadioLabel = ({ link }) => {
  const providerIdA = link.getIn(['A', 'providerIdentity', 'providerId']);
  const providerIdB = link.getIn(['B', 'providerIdentity', 'providerId']);
  const containerA = link.getIn(['A', 'container']);
  const containerB = link.getIn(['B', 'container']);
  const readOnlyA = link.getIn(['syncSettings', 'A', 'readOnly']);
  const readOnlyB = link.getIn(['syncSettings', 'B', 'readOnly']);
  const name = link.get('name');
  const isSyncOnlyNewTasks = !!link.getIn(['syncSettings', 'earliestCreatedAt']);

  const testModeTerm = useGetTestModeTerm(link.get('_id'));

  const providerA = useSelector((state) => getProviderById(state, { providerId: providerIdA }));
  const providerB = useSelector((state) => getProviderById(state, { providerId: providerIdB }));

  const getTaskTerm = () => (
    <ProviderTerms
      providerIdA={providerA.get('_id')}
      providerIdB={providerB.get('_id')}
      termKey="tasks"
      plurality="plural"
    />
  );

  return (
    <Flex align="center">
      <LinkContainerIcons
        containerA={containerA}
        containerB={containerB}
        providerA={providerA}
        providerB={providerB}
        readOnlyA={readOnlyA}
        readOnlyB={readOnlyB}
      />
      <Space align="center" direction="horizontal" style={{ marginLeft: tokens.spacing.s4 }}>
        <Typography>{name}</Typography>
      </Space>
      {isSyncOnlyNewTasks && (
        <Space style={{ marginLeft: tokens.spacing.s4 }}>
          <NewTag color={NewTagColor.INFO}>
            Only {testModeTerm} {getTaskTerm()}
          </NewTag>
        </Space>
      )}
    </Flex>
  );
};

LinkRadioLabel.propTypes = {
  link: PropTypes.instanceOf(Map).isRequired,
};
