import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Bold, Box, Col, Collapsible, Grid, Link, ProviderIcon, Row, Typography, tokens } from '@unitoio/mosaic';

import { getActivityStatusOption } from '~/utils/getActivityStatusOption';
import { capitalize } from '~/utils/capitalize';

import { errorCategoryTemplates } from './errorCategoryTemplates';

const renderWithHyperlink = (templateLine) => {
  // Seperates the string in potentially 4 groups:
  // 1 - Starting text  2 - Label for link, wrapped in squared brackets
  // 3 - Link, wrapped in paranthesis  4 - Ending text
  // Working example: This is my string with a [link](https://cats.com) and the rest of the string.
  // Faulty example: This is my string with a (link) [https://cats.com] and the rest of the string.
  const fullLinkRegex = /(.*?)\[(.*?)\]\((.*?)\)(.*)/;
  const match = templateLine.match(fullLinkRegex);

  if (match) {
    const [, textBefore, label, link, textAfter] = match;
    return (
      <>
        {textBefore}
        <Link href={link} isExternalLink>
          {label}
        </Link>
        {textAfter}
      </>
    );
  }

  return templateLine;
};

const renderDefaultErrorTemplate = (isRetryable = false) => (
  <Box flexDirection={Box.flexDirection.COLUMN} m={[tokens.spacing.s4]}>
    An error has occurred that prevents us from processing this request.
    <Box m={[tokens.spacing.s5, 0, 0, 0]}>
      <Bold>Solutions</Bold>
      <Box m={[tokens.spacing.s4, 0, tokens.spacing.s3, 0]}>
        <ul>
          <li>
            {isRetryable
              ? 'Unito will retry your request automatically. You can find a list of'
              : 'Resolve any warnings you may have in your flow and try again. You can find a list of'}
            <Link isExternalLink href="https://guide.unito.io/common-error-messages">
              common errors here
            </Link>
            . If the error persists, contact our support team.
          </li>
        </ul>
      </Box>
    </Box>
  </Box>
);

export const FailedActivityItem = ({ activityLog, handleOnToggle, getStatusIcon }) => {
  const { publisherError, status } = activityLog;
  const activityStatusOption = getActivityStatusOption(status, publisherError);

  // Example of PublisherError
  // {
  //   retryable: true;
  //   identifier: 'sync-worker PermissionDeniedError';
  //   category: RateLimitError;
  //   subCategory: ProjectNotFound;
  //   name: PermissionDeniedError
  // }
  const errorCategory =
    publisherError?.name || publisherError?.identifier?.split(' ').pop() || publisherError?.category;
  const template = errorCategoryTemplates[errorCategory];

  return (
    <Box
      borderSize={1}
      borderColor={publisherError?.retryable ? undefined : tokens.colors.strokes.message.destructive}
      borderRadius={tokens.spacing.s3}
      p={[tokens.spacing.s3]}
      m={[0, 0, tokens.spacing.s4, 0]}
    >
      <Collapsible
        onToggle={handleOnToggle}
        defaultIsOpen={false}
        header={
          <Grid>
            <Row>
              <Col xs={3}>
                <Typography>
                  {getStatusIcon(activityStatusOption)} {capitalize(activityStatusOption)}
                </Typography>
              </Col>
              <Col xs={9}>
                <Typography>{moment(activityLog.changeTime).format('D MMM | h:mm:ss A')}</Typography>
              </Col>
            </Row>
          </Grid>
        }
      >
        {template?.label && template?.solutions ? (
          <Box flexDirection={Box.flexDirection.COLUMN} m={[tokens.spacing.s4]}>
            {renderWithHyperlink(template.label)}
            <Box m={[tokens.spacing.s5, 0, 0, 0]}>
              <Bold>{template.solutions.title}</Bold>
              <Box m={[tokens.spacing.s4, 0, tokens.spacing.s3, 0]}>
                <ul>
                  {template.solutions.context.map((step, stepIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${errorCategory}_context_${stepIndex}`}>{renderWithHyperlink(step)}</li>
                  ))}
                </ul>
              </Box>
            </Box>
          </Box>
        ) : (
          renderDefaultErrorTemplate(publisherError?.retryable)
        )}
        {!!activityLog.fields.length && (
          <Box flexDirection={Box.flexDirection.COLUMN} m={[tokens.spacing.s4, 0, 0, tokens.spacing.s4]}>
            <Box m={[0, 0, tokens.spacing.s5, 0]}>
              <Bold>Updates detected that may not have synced</Bold>
            </Box>
            {activityLog.fields.map((field) => (
              <Box
                flexDirection={Box.flexDirection.ROW}
                m={[0, 0, tokens.spacing.s4, 0]}
                p={[0, tokens.spacing.s3, 0, tokens.spacing.s4]}
              >
                <Box m={[0, tokens.spacing.s2, 0, 0]}>
                  <ProviderIcon name={activityLog.sourceConnectorName} size="default" />
                </Box>
                <Box
                  p={[tokens.spacing.s2, tokens.spacing.s3, 0, tokens.spacing.s3]}
                  borderRadius={tokens.spacing.s3}
                  backgroundColor={tokens.colors.background.neutral.grey}
                >
                  <Typography variant={Typography.variants.BODY2}>{capitalize(field)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Collapsible>
    </Box>
  );
};

FailedActivityItem.propTypes = {
  activityLog: PropTypes.shape({
    type: PropTypes.string.isRequired,
    changeTime: PropTypes.number.isRequired,
    status: PropTypes.bool.isRequired,
    fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    sourceConnectorName: PropTypes.string.isRequired,
    targetConnectorName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    link: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    publisherError: PropTypes.shape({
      identifier: PropTypes.string,
      retryable: PropTypes.bool,
      category: PropTypes.string,
      subCategory: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  handleOnToggle: PropTypes.func.isRequired,
  getStatusIcon: PropTypes.func.isRequired,
};
