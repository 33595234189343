import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Checkbox } from '~/components/Checkbox/Checkbox';

const CheckboxWrapper = styled.div`
  float: left;
  position: relative;
  display: block;
  width: 24px;
  margin-right: 8px;
  margin-left: 0px;
  height: 24px;
`;

const StyledLabel = styled.label`
  vertical-align: middle;
`;

export class CheckboxText extends Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onChange = (e) => {
    if (e.target.nodeName !== 'A') {
      const { onChange, checked } = this.props;
      onChange(!checked);
    }
  };

  render() {
    const { children, id, onChange, ...rest } = this.props;

    return (
      <div
        className="checkbox-text"
        onClick={this.onChange}
        onKeyPress={this.onChange}
        tabIndex="0"
        role="checkbox"
        aria-checked={rest.checked}
      >
        <CheckboxWrapper id={id}>
          <Checkbox {...rest} />
        </CheckboxWrapper>
        <StyledLabel htmlFor={id}>{children}</StyledLabel>
      </div>
    );
  }
}
