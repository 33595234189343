import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TrackingFunnelContext } from '~/contexts';

/**
 * HoC wrapper for tracking funnels which is context hierarchy aware
 * */
export function TrackingFunnel({ children, contextName, sharedProperties = {} }) {
  // Fetch context data from current parent context
  const { parents: ancestors, sharedProperties: ancestorsSharedProperties } = useContext(TrackingFunnelContext);

  const trackingValue = useMemo(() => {
    const parents = [contextName, ...(ancestors || [])];

    return {
      name: contextName,
      parents,
      sharedProperties: {
        ...ancestorsSharedProperties,
        ...sharedProperties,
      },
    };
  }, [contextName, ancestors, ancestorsSharedProperties, sharedProperties]);

  // pass down parents to keep track of funnel hierarchy
  return <TrackingFunnelContext.Provider value={trackingValue}>{children}</TrackingFunnelContext.Provider>;
}

TrackingFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  contextName: PropTypes.string.isRequired,
  sharedProperties: PropTypes.shape({}),
};
