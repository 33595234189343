import React from 'react';
import PropTypes from 'prop-types';

export function TableBody({ children, className }) {
  return <tbody className={className}>{children}</tbody>;
}

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
