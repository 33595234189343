import { OrderedMap } from 'immutable';

/**
 * Merges containersMapB into containersMapA.
 * Both maps should be immutable maps created by "containerGenerator".
 * @param {Map} containersMapA
 * @param {Map} containersMapB
 */

export function mergeContainers(containersMapA, containersMapB) {
  const updatedContainersList = containersMapB.toList().reduce((containersList, container) => {
    if (containersMapA.has(container.get('id'))) {
      return containersList;
    }

    if (!containersMapA.has(container.get('parentId'))) {
      return containersList.push(container);
    }

    // By this point the container belongs to a workspace that already is in the containers list, but the container is not.
    // Thus it makes sense to put it under said workspace. We include it at the top of the workspace instead of the botton to save
    // ourselves the trouble of having to search for the last container that belongs to the corresponding workspace.
    const containerWorkspaceIndex = containersList.findIndex(
      (oldContainer) => oldContainer.get('id') === container.get('parentId'),
    );
    return containersList.insert(containerWorkspaceIndex + 1, container);
  }, containersMapA.toList());

  return updatedContainersList.reduce(
    (containersMap, container) => containersMap.set(container.get('id'), container),
    OrderedMap(),
  );
}
