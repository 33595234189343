import * as providerTypes from '~/consts/providers';

// TODO migrate this function to a more generic one
export const getAreModernRulesEnabled = (side) =>
  [
    providerTypes.INTEGRATION_NAME.GA4,
    providerTypes.INTEGRATION_NAME.FB_ADS,
    providerTypes.INTEGRATION_NAME.FB_INSIGHTS,
    providerTypes.INTEGRATION_NAME.GADS,
  ].some((allowed) => side.providerName?.includes(allowed));
