import { color } from 'theme';

export const GET_CONTAINERS = 'GET_CONTAINERS';
export const GET_CONTAINERS_REQUEST = `${GET_CONTAINERS}_REQUEST`;
export const GET_CONTAINERS_SUCCESS = `${GET_CONTAINERS}_SUCCESS`;
export const GET_CONTAINERS_FAILURE = `${GET_CONTAINERS}_FAILURE`;

export const GET_CONTAINER_REQUEST = 'GET_CONTAINER_REQUEST';
export const GET_CONTAINER_SUCCESS = 'GET_CONTAINER_SUCCESS';
export const GET_CONTAINER_FAILURE = 'GET_CONTAINER_FAILURE';
export const GET_WORKSPACES_REQUEST = 'GET_WORKSPACES_REQUEST';
export const GET_WORKSPACES_SUCCESS = 'GET_WORKSPACES_SUCCESS';
export const GET_WORKSPACES_FAILURE = 'GET_WORKSPACES_FAILURE';
export const CREATE_CONTAINER_REQUEST = 'CREATE_CONTAINER_REQUEST';
export const CREATE_CONTAINER_SUCCESS = 'CREATE_CONTAINER_SUCCESS';
export const CREATE_CONTAINER_FAILURE = 'CREATE_CONTAINER_FAILURE';

export const GET_CONTAINER_PLUGINS_REQUEST = 'GET_CONTAINER_PLUGINS_REQUEST';
export const GET_CONTAINER_PLUGINS_SUCCESS = 'GET_CONTAINER_PLUGINS_SUCCESS';
export const GET_CONTAINER_PLUGINS_FAILURE = 'GET_CONTAINER_PLUGINS_FAILURE';

export const GET_ACTIVE_CONTAINERS_REQUEST = 'GET_ACTIVE_CONTAINERS_REQUEST';
export const GET_ACTIVE_CONTAINERS_SUCCESS = 'GET_ACTIVE_CONTAINERS_SUCCESS';
export const GET_ACTIVE_CONTAINERS_FAILURE = 'GET_ACTIVE_CONTAINERS_FAILURE';

export const POST_ABORT_SCAN_REQUEST = 'POST_ABORT_SCAN_REQUEST';
export const POST_ABORT_SCAN_SUCCESS = 'POST_ABORT_SCAN_SUCCESS';
export const POST_ABORT_SCAN_FAILURE = 'POST_ABORT_SCAN_FAILURE';

export const STATE = {
  INACCESSIBLE: 'inaccessible',
  ACTIVE: 'active',
};

export const CONTAINER_STATUSES = {
  DEFAULT: 'default',
  DEGRADED: 'degraded',
  WARNING: 'warning',
  NEW: 'new',
  LOADING: 'loading',
  PLACEHOLDER: 'placeholder',
};

export const CONTAINER_STATUSES_VALUES = {
  [CONTAINER_STATUSES.DEGRADED]: {
    color: color.content.message.destructive,
    backgroundColor: color.brand.errorLight,
    iconName: 'times-circle',
    getTooltip: (containerTerm = 'project') => `This ${containerTerm} has probably been deleted.
    If this is not the case, try refreshing this page or contact our support team if this error persists.`,
  },
  [CONTAINER_STATUSES.WARNING]: {
    color: color.content.message.warning,
    backgroundColor: color.brand.warningLight,
    iconName: 'exclamation-triangle',
    getTooltip: () => 'Some settings need to be reviewed',
  },
  [CONTAINER_STATUSES.PLACEHOLDER]: {
    backgroundColor: color.background.message.info,
    color: color.content.message.info,
  },
};

export const TRACKING = {
  NAMES: {
    ACTION: 'ACTION',
    BLOCKED: 'BLOCKED',
  },
  ACTION_NAMES: {
    SEARCHING: 'searching a project',
    SEARCHING_BY_URL: 'searched project using url',
    SELECTED_PROJECT: 'selected a project',
    OPEN_DROPDOWN: 'opened dropdown to show projects to select',
  },
  ERROR_REASONS: {
    NO_PROJECTS: 'no projects found',
  },
};

export const DEBOUNCE_TIMEOUT = 750;

export const REQUIREMENT_CONTEXT_ADDON = 'addon';
export const REQUIREMENT_CONTEXT_ERROR = 'error';
