import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { tokens, Box, Button, Typography, TypographyVariants } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import {
  getOrganizationById,
  isEnterpriseAccount,
  isOnFreeTrial,
  isOrganizationAccountCanceled,
  isOrganizationAccountSuspended,
  isOrganizationTrialExpired,
  isOrganizationAccountPaused,
  isOrganizationAccountScheduledToPause,
  isVipAccount,
  isOrganizationResold,
} from 'reducers';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';

import { CancelOrPauseModal } from '../CancelAccountButton/CancelOrPauseModal';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const ButtonBox = styled(Box)`
  width: ${({ isEnterpriseOrVip }) => (isEnterpriseOrVip ? '15%' : '35%')};
`;

export const CancelOrPauseBlock = () => {
  const { organizationId } = useParams();
  const [isModalOpen, setIsOpenModal] = useState(false);
  const trackEvent = useTrackEvent();
  const organization = useSelector((state) => getOrganizationById(state, organizationId));
  const isCanceled = useSelector((state) => isOrganizationAccountCanceled(state, organizationId));
  const isChurnedOrExpired = useSelector((state) => isOrganizationAccountSuspended(state, organizationId));
  const isEnterprise = useSelector((state) => isEnterpriseAccount(state, organizationId));
  const isVip = useSelector((state) => isVipAccount(state, organizationId));
  const isTrialExpired = useSelector((state) => isOrganizationTrialExpired(state, organizationId));
  const isTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const isResold = useSelector((state) => isOrganizationResold(state, organizationId));
  const willPause = useSelector((state) => isOrganizationAccountScheduledToPause(state, organizationId));
  const isPaused = useSelector((state) => isOrganizationAccountPaused(state, organizationId));

  const handleClickOnCancellation = () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: trackingTypes.BILLING_EVENTS.ACTIONS.CLICK_CANCEL_SUBSCRIPTION,
    });
    setIsOpenModal(true);
  };

  if (isResold || isTrialing || isTrialExpired || isCanceled || isChurnedOrExpired || willPause || isPaused) {
    return null;
  }

  return (
    <Box
      flexDirection={Box.flexDirection.ROW}
      borderRadius={tokens.spacing.s4}
      borderSize={1}
      borderColor={tokens.colors.content.destructive.default}
      fullWidth
      p={[tokens.spacing.s5]}
    >
      <CancelOrPauseModal isOpen={isModalOpen} onClose={() => setIsOpenModal(false)} organization={organization} />

      <Box fullWidth>
        <Typography variant={TypographyVariants.BODY1}>
          <Bold>Pause or cancel your subscription</Bold>
        </Typography>
        <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n40}>
          You will continue to have access to your account until your billing period ends
        </Typography>
      </Box>

      <ButtonBox alignItems={Box.alignItems.CENTER} isEnterpriseOrVip={isEnterprise || isVip}>
        {isEnterprise || isVip ? (
          <ChatBubble type="primary" danger>
            Contact us
          </ChatBubble>
        ) : (
          <Button size={Button.sizes.SM} onClick={handleClickOnCancellation} variant="destructive">
            Pause/cancel subscription
          </Button>
        )}
      </ButtonBox>
    </Box>
  );
};
