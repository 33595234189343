import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Form, Field, reduxForm } from 'redux-form';
import { List } from 'immutable';

import { Button, tokens } from '@unitoio/mosaic';

import * as appActions from '~/actions/app';
import { getFieldValue, getScriptOutput } from 'reducers';
import { fontFamily } from 'theme';
import { Button as ButtonLink } from '~/components/Button/Button';
import { SelectInput } from '~/components/SelectInput/SelectInput';
import { Title } from '~/components/Title/Title';
import { ScriptArgsList } from './ScriptArgsList';
import { CheckboxField } from '../CheckboxField/CheckboxField';

const USSRWrapper = styled.div`
  display: flex;
  width: 800px;
  min-height: 350px;
  flex-direction: column;
  margin: 1em;
  align-content: center;
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexContainerRow = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
`;
const OutputContainer = styled.pre`
  max-height: 15.5em;
  font-family: ${fontFamily.monospace};
  font-size: ${tokens.fontSize.f8};
  overflow-y: scroll;
`;

const FORM_NAME = 'unitoPrompt';

const onSubmit = (values, dispatch, props) => {
  const { runScript, scripts } = props;
  const chosenScript = values.chosenScript.value;
  const scriptDefinition = scripts.find((script) => script.get('id') === chosenScript);

  const kwargs = {};
  scriptDefinition.get('kwargs').forEach((kwarg) => {
    kwargs[kwarg.get('name')] = values[kwarg.get('name')];
  });

  return runScript(chosenScript, kwargs, values.wetrun);
};

const validate = (values, props) => {
  if (!values.chosenScript) {
    // early return, if no chosen script, no need to look for required kwargs
    return { chosenScript: 'Choose a script to run' };
  }

  const script = props.scripts.find((scr) => scr.get('id') === values.chosenScript.value);
  const scriptDefinitionKwargs = script.get('kwargs');
  const requiredKwargs = scriptDefinitionKwargs.filter((kwarg) => kwarg.get('required'));
  if (requiredKwargs.some((kwarg) => !values[kwarg.get('name')])) {
    return { chosenScript: 'missing required args' };
  }

  return {};
};

const UnitoPromptComponent = reduxForm({
  form: FORM_NAME,
  onSubmit,
  validate,
})(
  class UnitoPromptInner extends Component {
    static propTypes = {
      chosenScript: PropTypes.shape({
        value: PropTypes.string,
      }),
      destroy: PropTypes.func.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      scripts: PropTypes.instanceOf(List).isRequired,
      onClose: PropTypes.func.isRequired,
      clearScriptOutput: PropTypes.func.isRequired,
      scriptOutput: PropTypes.string,
      valid: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
    };

    static defaultProps = {
      chosenScript: null,
      scriptOutput: null,
    };

    componentWillUnmount() {
      const { clearScriptOutput } = this.props;
      clearScriptOutput();
    }

    getScriptOptions = () => {
      const { scripts } = this.props;
      const scriptOptions = scripts
        .map((script) => ({ label: script.get('id'), value: script.get('id') }))
        .sort((a, b) => a.label.localeCompare(b.label));
      return scriptOptions.toArray();
    };

    getScriptDefinition = () => {
      const { chosenScript, scripts } = this.props;
      if (!chosenScript) {
        return null;
      }
      return scripts.find((script) => script.get('id') === chosenScript.value);
    };

    onClose = () => {
      const { destroy, onClose } = this.props;
      destroy();
      onClose();
    };

    render() {
      const { handleSubmit, scriptOutput, submitting, valid } = this.props;

      const scriptDefinition = this.getScriptDefinition();

      return (
        <USSRWrapper>
          <Title>Unito Simple Script Runner (USSR)</Title>
          <Form onSubmit={handleSubmit}>
            <FlexContainerColumn>
              <Field
                name="chosenScript"
                component={SelectInput}
                props={{
                  options: this.getScriptOptions(),
                  label: 'Select the script to run:',
                }}
              />
              {scriptDefinition && (
                <div>
                  <Title type="h3">{scriptDefinition.get('description')}</Title>
                  <p style={{ wordWrap: 'break-word' }}>{scriptDefinition.get('helpText')}</p>
                  <ScriptArgsList scriptDefinition={scriptDefinition} />
                  <Field name="wetrun" component={CheckboxField} props={{ id: 'wetrun', label: 'wetrun' }} />
                  <p style={{ marginTop: '0.5em' }}>* Required field(s)</p>
                </div>
              )}
              {scriptOutput && (
                <div>
                  <Title type="h3">Script Output:</Title>
                  <OutputContainer>{scriptOutput}</OutputContainer>
                </div>
              )}
              <FlexContainerRow>
                <ButtonLink btnStyle="link" onClick={this.onClose}>
                  Done being a strong independent CS <span role="img">😎</span>
                </ButtonLink>
                <Button type="submit" variant="destructive" disabled={!valid || submitting}>
                  Run
                </Button>
              </FlexContainerRow>
            </FlexContainerColumn>
          </Form>
        </USSRWrapper>
      );
    }
  },
);

const mapStateToProps = (state) => ({
  scriptOutput: getScriptOutput(state),
  chosenScript: getFieldValue(state, 'chosenScript', FORM_NAME),
  initialValues: { wetrun: false },
});

const mapDispatchToProps = (dispatch) => ({
  runScript: (scriptId, kwargs, wetrun) => dispatch(appActions.runScript(scriptId, kwargs, wetrun)),
  clearScriptOutput: () => dispatch(appActions.clearScriptOutput()),
});

export const UnitoPrompt = connect(mapStateToProps, mapDispatchToProps)(UnitoPromptComponent);
