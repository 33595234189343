import styled from 'styled-components';

import { color, borderRadius } from 'theme';

export const IconBadge = styled.div`
  display: flex;
  border: 1px solid ${color.dark.whisper};
  border-radius: ${borderRadius.small};
  background-color: white;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
`;
