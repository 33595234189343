import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import { ProviderIdentityItem } from '~/components/ProviderIdentityItem/ProviderIdentityItem';

import './ProviderIdentityList.scss';

export function ProviderIdentityList({ providerIdentityList, onClickProviderIdentity }) {
  return (
    <ul className="provider-identity-list" data-testid="provider-identity-list">
      {providerIdentityList
        .valueSeq()
        .map((providerIdentity, idx) => (
          <li
            data-test={`provider-identity__icon-${providerIdentity.get('providerName')}-${idx}`}
            className="provider-identity-list__item"
            key={providerIdentity.get('_id')}
          >
            <ProviderIdentityItem
              providerIdentity={providerIdentity}
              onClick={onClickProviderIdentity}
              displayType="avatar-only"
            />
          </li>
        ))
        .toArray()}
    </ul>
  );
}

ProviderIdentityList.propTypes = {
  providerIdentityList: PropTypes.instanceOf(Map).isRequired,
  onClickProviderIdentity: PropTypes.func.isRequired,
};
