import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';

import { useQueryParams } from '~/hooks/useQueryParams';

import { AuthJiraEmailVisibility } from './AuthJiraEmailVisibility';
import { AuthJiraAppLinkStep1 } from './oauth/AuthJiraAppLinkStep1';
import { AuthJiraAppLinkStep2 } from './oauth/AuthJiraAppLinkStep2';
import { AuthJiraAppLinkStep3 } from './oauth/AuthJiraAppLinkStep3';
import { AuthJiraOAuth2Step1 } from './oauth2/AuthJiraOAuth2Step1';
import { AuthJiraOAuth2Step2 } from './oauth2/AuthJiraOAuth2Step2';
import { AuthJiraOAuth2Step3 } from './oauth2/AuthJiraOAuth2Step3';
import { AuthJiraOAuth2Step4 } from './oauth2/AuthJiraOAuth2Step4';
import { AuthJiraApiTokenStep1 } from './apiKey/AuthJiraApiTokenStep1';
import { AuthJiraApiTokenStep2 } from './apiKey/AuthJiraApiTokenStep2';
import { AuthJiraBasicAuthentication } from './basic/AuthJiraBasicAuthentication';

export function AuthJiraInstructions({
  authorizationType,
  children: instructionFields,
  error,
  match,
  onCancel,
  onSubmit,
  trackEvent,
  isEmbed = false,
}) {
  const [newUI, setUI] = useState(true);
  const { windowOpenerId } = useQueryParams();
  const switchUI = () => setUI(!newUI);

  return (
    <Switch>
      {match.params.providerName === 'jiracloud' ? (
        <Route
          exact
          path={[match.path, `${match.path}/`, `${match.path}/email`]}
          render={(routeProps) => (
            <AuthJiraEmailVisibility
              {...routeProps}
              authorizationType={authorizationType}
              error={error}
              onCancel={onCancel}
              onSubmit={onSubmit}
              trackEvent={trackEvent}
              isEmbed={isEmbed}
            />
          )}
        />
      ) : (
        <Redirect
          exact
          from={match.path}
          to={`${match.path}/${authorizationType}/step1?windowOpenerId=${windowOpenerId}`}
        />
      )}

      {/* OAuth */}
      <Route
        exact
        path={`${match.path}/oauth1/step1`}
        render={(routeProps) => (
          <AuthJiraAppLinkStep1
            {...routeProps}
            newUI={newUI}
            onCancel={onCancel}
            switchUI={switchUI}
            trackEvent={trackEvent}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/oauth1/step2`}
        render={(routeProps) => (
          <AuthJiraAppLinkStep2
            {...routeProps}
            newUI={newUI}
            onCancel={onCancel}
            switchUI={switchUI}
            trackEvent={trackEvent}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/oauth1/step3`}
        render={(routeProps) => (
          <AuthJiraAppLinkStep3
            {...routeProps}
            newUI={newUI}
            onCancel={onCancel}
            onSubmit={onSubmit}
            switchUI={switchUI}
            trackEvent={trackEvent}
          >
            {instructionFields}
          </AuthJiraAppLinkStep3>
        )}
      />

      {/* OAuth2 */}
      <Route
        exact
        path={`${match.path}/oauth2/step1`}
        render={(routeProps) => <AuthJiraOAuth2Step1 {...routeProps} onCancel={onCancel} trackEvent={trackEvent} />}
      />
      <Route
        exact
        path={`${match.path}/oauth2/step2`}
        render={(routeProps) => <AuthJiraOAuth2Step2 {...routeProps} onCancel={onCancel} trackEvent={trackEvent} />}
      />
      <Route
        exact
        path={`${match.path}/oauth2/step3`}
        render={(routeProps) => (
          <AuthJiraOAuth2Step3
            {...routeProps}
            onCancel={onCancel}
            trackEvent={trackEvent}
            providerName={match.params.providerName}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/oauth2/step4`}
        render={(routeProps) => (
          <AuthJiraOAuth2Step4 {...routeProps} onCancel={onCancel} onSubmit={onSubmit} trackEvent={trackEvent}>
            {instructionFields}
          </AuthJiraOAuth2Step4>
        )}
      />

      {/* Username API Token */}
      <Route
        exact
        path={`${match.path}/apiKey/step1`}
        render={(routeProps) => (
          <AuthJiraApiTokenStep1
            {...routeProps}
            providerName={match.params.providerName}
            onCancel={onCancel}
            trackEvent={trackEvent}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/apiKey/step2`}
        render={(routeProps) => (
          <AuthJiraApiTokenStep2 {...routeProps} onCancel={onCancel} onSubmit={onSubmit} trackEvent={trackEvent}>
            {instructionFields}
          </AuthJiraApiTokenStep2>
        )}
      />

      {/* Basic authentication (Password) */}
      <Route
        exact
        path={`${match.path}/basic/step1`}
        render={(routeProps) => (
          <AuthJiraBasicAuthentication {...routeProps} onCancel={onCancel} onSubmit={onSubmit} trackEvent={trackEvent}>
            {instructionFields}
          </AuthJiraBasicAuthentication>
        )}
      />
    </Switch>
  );
}

AuthJiraInstructions.propTypes = {
  authorizationType: PropTypes.string.isRequired,
  children: PropTypes.node,
  error: PropTypes.string,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      providerName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  isEmbed: PropTypes.bool,
};
