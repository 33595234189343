import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Box, Icon, tokens } from '@unitoio/mosaic';

import { Button } from '~/components/Button/Button';

const alignmentToFlex = {
  right: 'flex-end',
  center: 'center',
  left: 'flex-start',
};

const levelToIconName = {
  info: 'info-circle',
  success: 'check',
  warning: 'exclamation-triangle',
  error: 'times-circle',
  syncing: 'refresh',
};

const levelToIconColor = {
  info: tokens.colors.content.secondary.default,
  success: tokens.colors.content.positive.default,
  warning: tokens.colors.content.warning.default,
  error: tokens.colors.content.destructive.default,
};

const levelToBackgroundColor = {
  info: tokens.colors.background.message.info,
  success: tokens.colors.background.message.positive,
  warning: tokens.colors.background.message.warning,
  error: tokens.colors.background.message.destructive,
};

const AlertWrapper = styled.div`
  background-color: ${(props) => levelToBackgroundColor[props.$level]};
  border-radius: ${tokens.spacing.s2};
  border: 1px solid ${tokens.colors.content.neutral.n10};
  display: flex;
  align-items: baseline;
  justify-content: ${(props) => alignmentToFlex[props.$alignment]};
  padding: ${tokens.spacing.s4};
`;

export function Alert({ alignment = 'left', children, content, level = 'info', showIcon = true }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <AlertWrapper $alignment={alignment} $level={level}>
        {showIcon && (
          <Box m={[tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0, tokens.spacing.s0]}>
            <Icon
              name={levelToIconName[level]}
              color={levelToIconColor[level]}
              kind={Icon.KINDS.SOLID}
              title="show content"
            />
          </Box>
        )}
        <div>{children}</div>
        {content && (
          <Button btnStyle="subtleLink" transparent size="xs" onClick={() => setIsOpen(!isOpen)}>
            <Icon
              name="chevron-down"
              kind={Icon.KINDS.SOLID}
              rotate={isOpen ? 180 : 0}
              title={isOpen ? 'hide content' : 'show content'}
            />
          </Button>
        )}
      </AlertWrapper>
      {isOpen && content}
    </>
  );
}

Alert.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  showIcon: PropTypes.bool,
};
