import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import * as appTypes from '~/consts/app';
import * as featureTypes from '~/consts/features';
import * as linkTypes from '~/consts/link';
import { getFeatureFlagValue } from 'reducers';
import * as linkActions from '~/actions/links';

export const DEFAULT_PAGE_SIZE_PAGINATED = 20;
// value to send to the backend to indicate that we do not
// wish to paginate
export const DEFAULT_PAGE_SIZE_NON_PAGINATED = 0;

// when pagination is not enabled, the backend will return this total value
export const NO_PAGINATION_TOTAL = -1;

export function useGetLinks() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const hasPaginatedFlowList = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.PAGINATED_FLOW_LIST),
  );
  const isTaskSyncRoute = pathname.includes(appTypes.PRODUCT_NAMES_PARAM.TASK_SYNC);

  const defaultKindsBasedOnRoute = useMemo(
    () =>
      isTaskSyncRoute
        ? [linkTypes.KIND.TASK_SYNC]
        : [linkTypes.KIND.MIRROR_SYNC, linkTypes.KIND.MULTI_SYNC, linkTypes.KIND.REPORT_SYNC],
    [isTaskSyncRoute],
  );

  const defaultPageSize =
    // temp fix for TaskSyncList, UI pagination is not available yet, make sure to default to 0 pageSize
    hasPaginatedFlowList && !isTaskSyncRoute ? DEFAULT_PAGE_SIZE_PAGINATED : DEFAULT_PAGE_SIZE_NON_PAGINATED;

  const [searchString, setSearchString] = useState('');
  const [siteAdminSearchString, setSiteAdminSearchString] = useState('');
  const [kinds, setKindsInner] = useState(defaultKindsBasedOnRoute);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(NO_PAGINATION_TOTAL);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  // whenever a regular search is done, reset the page
  const setSearch = (searchValue) => {
    setSearchString(searchValue);
    setPage(0);
  };

  // whenever a siteadmin search is done, also reset the page
  const setSiteAdminSearch = (searchValue) => {
    setSiteAdminSearchString(searchValue);
    setPage(0);
  };

  const setKinds = (newKinds = []) => {
    const areSameLength = newKinds.length === kinds.length;
    // don't trigger a needless useEffect if newKinds are set to the same values
    if (!areSameLength || !newKinds.every((kind) => kinds.includes(kind))) {
      setKindsInner(newKinds);
      setPage(0);
      // temp fix for TaskSyncList, UI pagination is not available yet
      if (newKinds.includes(linkTypes.KIND.TASK_SYNC)) {
        setPageSize(0);
      } else {
        setPageSize(defaultPageSize);
      }
    }
  };

  const reset = useCallback(() => {
    setSiteAdminSearchString('');
    setSearchString('');
    setKindsInner(defaultKindsBasedOnRoute);
    setPage(0);
  }, [defaultKindsBasedOnRoute]);

  useEffect(() => {
    const fetchLinks = async () => {
      if (siteAdminSearchString) {
        try {
          const {
            pagination: { total: apiResponseTotal },
          } = await dispatch(
            linkActions.searchLinks({
              page,
              pageSize,
              searchString,
              siteAdminSearchString,
              kinds,
            }),
          );
          setTotal(apiResponseTotal);
        } catch (_absorbedError) {
          setTotal(NO_PAGINATION_TOTAL);
        }
      } else {
        try {
          const {
            pagination: { total: apiResponseTotal },
          } = await dispatch(linkActions.getLinks({ page, pageSize, searchString, kinds }));
          setTotal(apiResponseTotal);
        } catch (_absorbedError) {
          setTotal(NO_PAGINATION_TOTAL);
        }
      }
    };
    fetchLinks();
  }, [dispatch, kinds, page, pageSize, searchString, siteAdminSearchString]);

  return { page, setPage, pageSize, setPageSize, setSearch, setSiteAdminSearch, setKinds, total, reset };
}
