import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { color } from 'theme';

const padding = {
  none: '0px 0px',
  xs: '4px 12px',
  sm: '8px 16px',
  md: '16px 20px',
  lg: '24px 32px',
};

const TableCellWrapper = styled.td`
  text-align: ${(props) => props.align};
  padding: ${(props) => padding[props.$paddingSize]};
  border-width: 1px 0;
  border-style: solid;
  border-color: ${color.brand.containerGray};

  &:first-child {
    border-left-width: 1px;
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-right-width: 1px;
    border-radius: 0 4px 4px 0;
  }
`;

export const TableCell = ({ align = 'left', cellSize, children, paddingSize = 'md' }) => (
  <TableCellWrapper align={align} colSpan={cellSize} $paddingSize={paddingSize}>
    {children}
  </TableCellWrapper>
);

TableCell.propTypes = {
  align: PropTypes.string,
  cellSize: PropTypes.number,
  children: PropTypes.node,
  paddingSize: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg']),
};
