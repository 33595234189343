import PropTypes from 'prop-types';
import React from 'react';

import * as trackingTypes from '~/consts/tracking';
import { Href } from '~/components/Href/Href';
import { Box } from '~/components/Box/Box';
import { UnitoLogo } from '~/components/UnitoLogo/UnitoLogo';

export const EmbedFooter = ({ trackDashboardAction }) => (
  <Box className="text-center" m={[2.5, 'auto', 0]}>
    <Box m={[0, 0, 0.5, 0]}>Made with love by</Box>
    <Href
      href={process.env.REACT_APP_PUBLIC_URL}
      onClick={() => trackDashboardAction(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.LOGO)}
    >
      <UnitoLogo width="150" />
    </Href>
  </Box>
);

EmbedFooter.propTypes = {
  trackDashboardAction: PropTypes.func.isRequired,
};
