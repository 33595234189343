import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { Icon } from '@unitoio/mosaic';

import { UnderlinedNavItem } from './UnderlinedNavItem';
import './NavTabItem.scss';

export class NavTabItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    iconName: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    tabStyle: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    iconName: undefined,
    isActive: false,
    isDisabled: false,
    name: undefined,
    onClick: undefined,
    tabIndex: undefined,
    tabStyle: undefined,
  };

  onClick = (event) => {
    const { onClick, tabIndex } = this.props;
    event.preventDefault();
    onClick && onClick(tabIndex);
  };

  render() {
    const { children, iconName, isActive, isDisabled, name, onClick, tabIndex, tabStyle } = this.props;
    const classNames = classnames('nav-tab-item', {
      active: isActive,
      disabled: isDisabled,
      'nav-tab-item--no-click': !onClick,
    });

    /* eslint-disable jsx-a11y/anchor-is-valid */
    if (tabStyle === 'underline') {
      return (
        <UnderlinedNavItem $isActive={isActive}>
          {children || (
            <a
              role="button"
              tabIndex={0}
              onClick={this.onClick}
              onKeyPress={this.onClick}
              data-test={`nav__tab--${tabIndex}`}
            >
              {name}
            </a>
          )}
        </UnderlinedNavItem>
      );
    }

    return (
      <li className={classNames}>
        {children || (
          <a
            role="button"
            tabIndex={0}
            onClick={this.onClick}
            onKeyPress={this.onClick}
            data-test={`nav__tab--${tabIndex}`}
          >
            {iconName && <Icon name={iconName} />}
            {name}
          </a>
        )}
      </li>
    );
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}
