import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { tokens } from '@unitoio/mosaic';

const StyledIframe = styled.iframe`
  border: 0px;
  border-radius: ${tokens.spacing.s4};
  min-height: 480px;
`;

export const SidePanelVideoIframe = ({ videoId, title }) => (
  <StyledIframe
    width="100%"
    height="auto"
    src={`https://www.youtube.com/embed/${videoId}?rel=0`}
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title={title}
  />
);

SidePanelVideoIframe.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
