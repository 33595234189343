import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { tokens } from '@unitoio/mosaic';
import { useTrackEvent } from '@unitoio/sherlock';

import * as trackingTypes from '~/consts/tracking';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { PageHeader } from '../../components/PageHeader';
import { RuleSidePanel } from '../SidePanels/RuleSidePanel';
import { useGetProviderNames } from '../../hooks/useGetProviderNames';

const OpenSidePanelLink = styled.span`
  color: ${tokens.colors.content.info.default};
  cursor: pointer;
`;

export const ModernRulesHeader = () => {
  const { linkId } = useParams();

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const [containerA, containerB] = useGetContainers();
  const containerIdA = containerA.get('id');
  const containerIdB = containerB.get('id');
  const trackEvent = useTrackEvent({ selected_tool_names: `${providerNameA}, ${providerNameB}` });

  return (
    <PageHeader
      title={
        <>
          Define sync conditions for{' '}
          <ProviderTermsByName
            providerNameA={providerNameA}
            providerNameB={providerNameB}
            plurality="plural"
            termKey="task"
            pcdv3
            itemTypeA={itemTypeA}
            itemTypeB={itemTypeB}
            containerIdA={containerIdA}
            containerIdB={containerIdB}
          />
        </>
      }
      subtitle={
        <>
          Add conditions to restrict which{' '}
          {
            <ProviderTermsByName
              providerNameA={providerNameA}
              providerNameB={providerNameB}
              plurality="plural"
              termKey="task"
              pcdv3
              itemTypeA={itemTypeA}
              itemTypeB={itemTypeB}
              containerIdA={containerIdA}
              containerIdB={containerIdB}
            />
          }{' '}
          Unito syncs between your tools.
          <OpenSidePanelLink
            onClick={() => {
              setIsSidePanelOpen(true);
              trackEvent(trackingTypes.ACTION, {
                action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.NEED_HELP,
                selected_tool_name: `${providerNameA},${providerNameB}`,
              });
            }}
          >
            {' '}
            Need some help?
          </OpenSidePanelLink>
          <RuleSidePanel isOpen={isSidePanelOpen} onClose={() => setIsSidePanelOpen(false)} linkId={linkId} />
        </>
      }
    />
  );
};
