import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { Button } from '~/components/Button/Button';
import { TextInput } from '~/components/TextInput/TextInput';

const InlineInputWrap = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin-left: 3px;
`;

export function InlineInputFormField({ helpText, id, name, onSubmit, placeholder, pristine, submitting }) {
  return (
    <InlineInputWrap className="inline-input">
      <Field
        name={name}
        component={TextInput}
        placeholder={placeholder}
        props={{
          id,
          helpText,
        }}
      />
      <ButtonWrapper>
        <Button
          size="md"
          className="inline-input__button"
          btnStyle="dark"
          disabled={submitting || pristine}
          pullRight
          type="submit"
          onClick={onSubmit}
        >
          Redeem
        </Button>
      </ButtonWrapper>
    </InlineInputWrap>
  );
}

InlineInputFormField.propTypes = {
  helpText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};
