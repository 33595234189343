import { List, Map, fromJS } from 'immutable';

import { normalizeEntitiesById } from '~/utils/normalizeEntitiesById';
import * as workflowTypes from '~/consts/workflows';

export const initialState = Map({
  entitiesById: Map(),
  isLoadingWorkflow: Map(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case workflowTypes.GET_WORKFLOWS_SUCCESS: {
      const workflows = fromJS(action.payload.workflows);
      const entitiesById = normalizeEntitiesById(workflows);
      return state.setIn(['entitiesById'], entitiesById);
    }

    case workflowTypes.GET_WORKFLOW_REQUEST: {
      const { workflowId } = action.meta;
      return state.setIn(['isLoadingWorkflow', workflowId], true);
    }

    case workflowTypes.PATCH_WORKFLOW_SUCCESS:
    case workflowTypes.GET_WORKFLOW_SUCCESS: {
      const {
        workflow: { diagramRepresentation, ...workflow },
      } = action.payload;
      return state
        .setIn(['isLoadingWorkflow', workflow.id], false)
        .mergeIn(['entitiesById', workflow.id], fromJS(workflow).set('diagramRepresentation', diagramRepresentation));
    }

    case workflowTypes.ADD_BLOCK_SUCCESS: {
      const { workflow, block, created } = action.payload;

      if (!created) {
        return state;
      }

      return state.updateIn(['entitiesById', workflow._id, 'blocks'], List(), (blocks) => blocks.push(fromJS(block)));
    }

    case workflowTypes.DELETE_BLOCK_SUCCESS: {
      const { workflowId, blockId } = action.meta;
      return state.updateIn(['entitiesById', workflowId, 'blocks'], List(), (blocks) =>
        blocks.filterNot((block) => block.id === blockId),
      );
    }

    case workflowTypes.GET_LINKS_BY_WORKFLOW_ID_SUCCESS: {
      const { workflowId } = action.meta;
      const { links } = action.payload;
      const linkIds = links.map((link) => link._id);
      return state.mergeIn(['entitiesById', workflowId], fromJS({ links: linkIds }));
    }

    case workflowTypes.DELETE_WORKFLOWS_SUCCESS: {
      const workflow = fromJS(action.payload.workflow);
      return state.deleteIn(['entitiesById', workflow.get('_id')]);
    }

    default:
      return state;
  }
};

export const getBlocksByWorkflowId = (state, workflowId) => state.getIn(['entitiesById', workflowId, 'blocks'], List());

export const getWorkflows = (state) => state.get('entitiesById');

export const isLoadingWorkflow = (state, workflowId) => state.getIn(['isLoadingWorkflow', workflowId]);

export const getWorkflowById = (state, workflowId) => state.getIn(['entitiesById', workflowId], Map());
