import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { tokens, Typography, ProgressBar, Box } from '@unitoio/mosaic';

import { flowBuilderTypes } from '../consts';
import { useDraftStatus } from '../hooks/useDraftStatus';
import GuideStepOneImg from '../images/guide_steps/guide_step_01.svg';
import GuideStepTwoImg from '../images/guide_steps/guide_step_02.svg';
import GuideStepThreeImg from '../images/guide_steps/guide_step_03.svg';
import GuideStepFourImg from '../images/guide_steps/guide_step_04.svg';
import GuideStepFiveImg from '../images/guide_steps/guide_step_05.svg';

const Content = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

const MainContainer = styled(Box)`
  background: ${({ $noBackground }) => ($noBackground ? undefined : tokens.colors.background.neutral.grey)};
  border-radius: ${tokens.spacing.s4};
`;

const ImageContainer = styled.div`
  text-align: center;
`;

export const guideStepsContent = {
  [flowBuilderTypes.STEPS.GUIDE]: {
    image: GuideStepOneImg,
    content: (
      <Content variant="body2">
        Hi there!
        <br />
        This step-by-step guide will take you through building your first flow. If you ever get stuck, just click on the
        chat bubble in the bottom right corner to reach our support team.
      </Content>
    ),
    percentage: 2, // arbitrary just so we see a little dot
    subtitle: '☝️ Track your progress here',
  },
  [flowBuilderTypes.STEPS.CONNECT]: {
    image: GuideStepTwoImg,
    content: (
      <Content variant="body2">
        Great!
        <br />
        Now that you've chosen your blocks of work, do you need information to go from just one tool to the other, or
        back and forth between them?
      </Content>
    ),
    percentage: 20, // arbitrary just so we see a little dot
    subtitle: '20% complete - nice work!',
  },
  [flowBuilderTypes.STEPS.FLOW_DIRECTION]: {
    image: GuideStepThreeImg,
    content: (
      <Content variant="body2">
        Got it!
        <br />
        Now you can set rules to choose which work items will sync, where they'll go, and more.
      </Content>
    ),
    percentage: 40,
    subtitle: '40% complete - nice work!',
  },
  [flowBuilderTypes.STEPS.RULES]: {
    image: GuideStepFourImg,
    content: (
      <Content variant="body2">
        Your rules are set.
        <br />
        Next, you can customize field mappings to determine how things like due dates and assignees will be synced.
        Unito maps most of these fields automatically.
      </Content>
    ),
    percentage: 60,
    subtitle: '60% complete - nice work!',
  },
  [flowBuilderTypes.STEPS.MAPPING]: {
    image: GuideStepFiveImg,
    content: (
      <Content variant="body2">
        Almost there!
        <br />
        Review your flow to make sure everything's set up right and then activate your flow. Don't forget to reach out
        if you need help.
      </Content>
    ),
    percentage: 80,
    subtitle: '80% complete - nice work!',
  },
};

function getLastStepCompleted(draftData) {
  const reversedSteps = [
    flowBuilderTypes.STEPS.MAPPING,
    flowBuilderTypes.STEPS.RULES,
    flowBuilderTypes.STEPS.FLOW_DIRECTION,
    flowBuilderTypes.STEPS.CONNECT,
  ];

  const lastStepCompleted = reversedSteps.find(
    (step) => draftData[step].status === flowBuilderTypes.STEP_STATUSES.DONE,
  );

  return lastStepCompleted || flowBuilderTypes.STEPS.GUIDE;
}

export const GuideStepTips = ({ linkId }) => {
  const draftData = useDraftStatus(linkId);
  const lastStepCompleted = getLastStepCompleted(draftData);

  const { content, percentage, subtitle, image } = guideStepsContent[lastStepCompleted];

  return (
    <Box m={[6, 0, 0]}>
      <MainContainer>
        <Box p={[tokens.spacing.s5]}>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <ImageContainer>
              <Box width="100%" as="img" m={[-6, 0, 0, 0]} alt="Welcome" src={image} />
            </ImageContainer>
          </Box>
          {content}
          {percentage && (
            <Box m={[tokens.spacing.s4, 0, tokens.spacing.s3, 0]}>
              <ProgressBar value={percentage} />
            </Box>
          )}
          {subtitle && <Content variant="body2">{subtitle}</Content>}
        </Box>
      </MainContainer>
    </Box>
  );
};

GuideStepTips.propTypes = {
  linkId: PropTypes.string.isRequired,
};
