import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, tokens } from '@unitoio/mosaic/';

import { Header, RightHeader } from '~/components/Header/Header';

export const UnauthenticatedHeaderContainer = () => (
  <Header>
    <RightHeader>
      <Box m={[0, tokens.spacing.s3, 0, 0]}>
        <Button as={Link} variant={Button.variants.OUTLINED} to="/login">
          Login
        </Button>
      </Box>
      <Button
        onClick={() => {
          window.location.href = 'https://unito.io/';
        }}
      >
        Learn more about Unito
      </Button>
    </RightHeader>
  </Header>
);
