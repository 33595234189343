import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { tokens, ProviderIcon } from '@unitoio/mosaic';

import { borderRadius } from 'theme';

const ProviderIconWrapper = styled.div`
  border: 0.125rem solid
    ${(props) => (props.$isActive ? tokens.colors.content.neutral.n40 : tokens.colors.content.neutral.n10)};
  border-radius: ${borderRadius.large};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.$size === 'sm' ? '3rem' : '4rem')};
  text-align: center;
  width: ${(props) => (props.$size === 'sm' ? '3rem' : '4rem')};

  &:hover {
    border-color: ${tokens.colors.content.neutral.n40};
    box-shadow: 0 0.25rem 0.375rem ${tokens.colors.content.neutral.n20};
  }
`;

export const ProviderIconButton = ({ onClick, providerName, size = 'default', children, disabled = false }) => (
  <ProviderIconWrapper onClick={disabled ? null : onClick} $size={size}>
    {providerName && <ProviderIcon name={providerName} size={size} />}
    {children}
  </ProviderIconWrapper>
);

ProviderIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  providerName: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default']),
  children: PropTypes.node,
  disabled: PropTypes.bool,
};
