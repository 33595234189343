import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useParams } from 'react-router-dom';

import { hasActiveLinksWithValueMapping, isProviderItemCustomFieldBased } from 'reducers';

import { flowBuilderTypes } from '../consts';

import { useGetContainerTypes } from '../hooks/useGetContainerTypes';
import { useIsOverFieldMappingsLimit } from '../hooks/useIsOverFieldMappingsLimit';
import { useIsCustomFieldLimitReached } from '../pages/Mapping/hooks/useIsCustomFieldLimitReached';
import { FieldAssociationItem } from './FieldAssociationItem';
import { useGetFirstMappingWithValueMappingIndex } from '../pages/Mapping/hooks/useGetFirstMappingWithValueMappingIndex';
import { FieldMappingGroup } from './FieldMappingGroup';

export const FieldAssociationItems = ({
  fieldAssociations,
  providerFieldsA,
  providerFieldsB,
  itemTypeA,
  itemTypeB,
  providerNameA,
  providerNameB,
  containerIdA,
  containerIdB,
  providerIdentityIdA,
  providerIdentityIdB,
  isAutomappingValuesStatus,
  isMapped,
  disabledDelete,
  isLoading,
  remove,
}) => {
  const { linkId } = useParams();
  const isFieldMappingLimitReached = useIsOverFieldMappingsLimit(linkId, false);

  const userHasActiveLinksWithValueMapping = useSelector((state) => hasActiveLinksWithValueMapping(state, true));

  const firstMappingWithFieldValuesIndex = useGetFirstMappingWithValueMappingIndex(
    fieldAssociations,
    providerFieldsA,
    providerFieldsB,
    isLoading,
  );
  const isItemACustomFieldBased = useSelector((state) =>
    isProviderItemCustomFieldBased(state, providerNameA, itemTypeA),
  );
  const isItemBCustomFieldBased = useSelector((state) =>
    isProviderItemCustomFieldBased(state, providerNameB, itemTypeB),
  );

  const isCustomFieldLimitReached = useIsCustomFieldLimitReached({
    associations: fieldAssociations,
    isItemACustomFieldBased,
    isItemBCustomFieldBased,
  });

  const [containerTypeA, containerTypeB] = useGetContainerTypes();

  return (
    <>
      {fieldAssociations
        .map((field, index) => (
          <FieldAssociationItem
            key={`${field.A.field}-${field.B.field}`}
            hasActiveLinksWithValueMapping={userHasActiveLinksWithValueMapping}
            index={index}
            isFirstFieldAssociationWithValueMapping={index === firstMappingWithFieldValuesIndex}
            fieldAssociation={field}
            fieldAssociations={fieldAssociations}
            disabledDelete={disabledDelete}
            onDelete={() => {
              remove(index);
            }}
            isCustomFieldLimitReached={isCustomFieldLimitReached}
            isFieldMappingLimitReached={isFieldMappingLimitReached}
            isEntityAlreadyMapped={isMapped}
            containerIdA={containerIdA}
            containerIdB={containerIdB}
            providerIdentityIdA={providerIdentityIdA}
            providerIdentityIdB={providerIdentityIdB}
            providerNameA={providerNameA}
            providerNameB={providerNameB}
            itemTypeA={itemTypeA}
            itemTypeB={itemTypeB}
            disabled={isAutomappingValuesStatus}
          >
            <FieldMappingGroup
              containerIdA={containerIdA}
              containerIdB={containerIdB}
              providerIdentityIdA={providerIdentityIdA}
              providerIdentityIdB={providerIdentityIdB}
              itemTypeA={itemTypeA}
              itemTypeB={itemTypeB}
              containerTypeA={containerTypeA}
              containerTypeB={containerTypeB}
              fieldIndex={index}
            />
          </FieldAssociationItem>
        ))
        .reverse()}
    </>
  );
};

FieldAssociationItems.propTypes = {
  fieldAssociations: PropTypes.arrayOf(flowBuilderTypes.fieldAssociationPropTypes).isRequired,
  disabledDelete: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAutomappingValuesStatus: PropTypes.bool.isRequired,
  isMapped: PropTypes.bool.isRequired,
  providerFieldsA: PropTypes.instanceOf(Map).isRequired,
  providerFieldsB: PropTypes.instanceOf(Map).isRequired,
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
  providerNameA: PropTypes.string.isRequired,
  providerNameB: PropTypes.string.isRequired,
  itemTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  containerIdA: PropTypes.string.isRequired,
  containerIdB: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
};
