import { Map } from 'immutable';
import { useSelector } from 'react-redux';

import * as fieldTypes from '~/consts/fields';
import { getField, getProviderByProviderIdentityId } from 'reducers';

export const BOOLEAN_OPTIONS = Map({
  false: Map({ displayName: 'Unchecked', id: 'false', type: 'boolean' }),
  true: Map({ displayName: 'Checked', id: 'true', type: 'boolean' }),
});

export const NON_BOOLEAN_OPTIONS = Map({
  false: Map({ displayName: 'Empty value', id: 'false', type: 'boolean' }),
  true: Map({ displayName: 'Any value', id: 'true', type: 'boolean' }),
});

export function useGetNonEnumCustomFieldValues({
  providerIdentityId,
  kind,
  fieldId,
  containerSide,
  containerId,
  itemType,
}) {
  const provider = useSelector((state) => getProviderByProviderIdentityId(state, providerIdentityId));
  const customField = useSelector((state) =>
    getField(state, { fieldId, kind, providerName: provider.get('name'), containerSide, containerId, itemType }),
  );

  if (kind === fieldTypes.KINDS.CUSTOM_FIELD && customField.get('type') !== fieldTypes.TYPES.ENUM) {
    // Handle BOOLEAN custom fields
    if (customField.get('type') === fieldTypes.TYPES.BOOLEAN) {
      return BOOLEAN_OPTIONS;
    }

    // Handle non-BOOLEAN custom fields
    return NON_BOOLEAN_OPTIONS;
  }

  // Return an empty Map for non-custom fields or ENUM types
  return Map();
}
