import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import { Map } from 'immutable';

import { Box, Flex, tokens, Typography } from '@unitoio/mosaic';

import * as fieldTypes from '~/consts/fields';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { FieldAssociationItem } from './FieldAssociationItem';
import { MandatoryFieldsAlertWrapper } from './mapping/alerts/MandatoryFieldsAlerts';

const Body = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
  margin-bottom: ${tokens.spacing.s4};
`;

function useAddFirstFieldAssociation(syncDirection) {
  const { fields: formFieldAssociations, append } = useFieldArray({ name: 'associations' });

  useEffect(() => {
    const areFieldAssociationsEmpty = !formFieldAssociations.length;
    if (areFieldAssociationsEmpty) {
      append({
        target: syncDirection ?? fieldTypes.TARGET.BOTH,
        A: { field: null, fieldId: null, kind: null },
        B: { field: null, fieldId: null, kind: null },
      });
    }
  }, [append, formFieldAssociations, syncDirection]);

  return formFieldAssociations;
}

export const FieldAssociationsEmptyState = ({
  containerA,
  containerB,
  isEntityAlreadyMapped,
  itemTypeA,
  itemTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
  providerNameA,
  providerNameB,
  isFieldAssociationDisabled,
  syncDirection,
  shouldShowAlerts,
}) => {
  const fieldAssociations = useAddFirstFieldAssociation(syncDirection);

  if (!fieldAssociations.length) {
    return <InlineLoading />;
  }

  return (
    <Box flexDirection="column" alignItems="center">
      <Box p={[0, 0, tokens.spacing.s2, 0]}>
        <Typography variant="h3">Start mapping your fields</Typography>
      </Box>
      <Body align="center" variant="body1">
        Map fields together to determine which information is synced, and in which direction.
      </Body>
      <Box fullWidth>
        {shouldShowAlerts && (
          <Flex vertical gap="small">
            <MandatoryFieldsAlertWrapper enableAlert={false} />{' '}
          </Flex>
        )}
        {fieldAssociations.map((association, index) => (
          <FieldAssociationItem
            key={association.id}
            containerIdA={containerA.get('id')}
            containerIdB={containerB.get('id')}
            itemTypeA={itemTypeA}
            itemTypeB={itemTypeB}
            index={index}
            // Since there's no association, the limit shouldn't have been reached anyway. Hardcoding for now
            isCustomFieldLimitReached={false}
            // Same here
            isFieldMappingLimitReached={false}
            // Since this is the empty state, the field associations don't have value mapping
            isFirstFieldAssociationWithValueMapping={false}
            isFirstFieldAssociation
            isEntityAlreadyMapped={isEntityAlreadyMapped}
            disabledDelete
            providerIdentityIdA={providerIdentityIdA}
            providerIdentityIdB={providerIdentityIdB}
            providerNameA={providerNameA}
            providerNameB={providerNameB}
            disabled={isFieldAssociationDisabled}
            fieldAssociation={association}
            fieldAssociations={fieldAssociations}
          />
        ))}
      </Box>
    </Box>
  );
};

FieldAssociationsEmptyState.propTypes = {
  containerA: PropTypes.instanceOf(Map).isRequired,
  containerB: PropTypes.instanceOf(Map).isRequired,
  isEntityAlreadyMapped: PropTypes.func.isRequired,
  itemTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
  providerNameA: PropTypes.string.isRequired,
  providerNameB: PropTypes.string.isRequired,
  isFieldAssociationDisabled: PropTypes.bool.isRequired,
  syncDirection: PropTypes.string.isRequired,
  shouldShowAlerts: PropTypes.string.isRequired,
};
