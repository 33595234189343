export const CTA_IDS = {
  FB_NEW_JIRA_EMBED_RESOURCES: 'e2ad3eb6-b9b1-40dc-88e5-88d494939705',
  FB_NEW_TRELLO_EMBED_RESOURCES: '866931cf-f123-4ce0-82b0-29b4daa10159',
  FB_NEW_WRIKE_EMBED_RESOURCES: '1a0b7f50-f592-4d8e-9f76-ff714c20992a',
  FLOW_BUILDER_GUIDE: '7e36d907-d3eb-4d48-bbf2-8452dbe57a23',
  FLOW_BUILDER_GUIDE_PREPPING_FLOW: '3b894eb7-7f1e-4246-bab6-94fc039ed1e8',
  FLOW_BUILDER_GUIDE_LAUNCH_EDIT_DUPLICATE_FLOW: '942b75a8-3d20-447d-b7c5-d7ce508ca6bc',
  FLOW_BUILDER_GUIDE_VERIFY_WORKFLOWS: '6c3b6656-0680-42a3-b756-78846af11c18',
  FLOW_BUILDER_RESOURCES: 'debb898e-c70c-4057-a23a-35c44c1506f8',
  FLOW_BUILDER_RULES_AND_MAPPINGS: 'b29508be-6e8f-4807-a04d-ff0eb3d9f2dd',
  MIRO_INSTRUCTIONS: '5d6afbe1-e9ab-42f7-865f-5008818c6552',
  MONDAY_EMBED_RESOURCES: '4e15082c-a065-426f-b0cf-2e0594597c61',
  NOTION_BOT_INSTRUCTIONS: 'b3aa6275-20dd-4410-ad71-57449e77be15',
  SLACK_BOT_INSTRUCTIONS: 'b8169600-26fe-4c83-a9a3-f3e1c027e35c',
  SSO: 'dbbf17ab-440d-41f9-a25a-03dc77f239a0',
  SUGGEST_ANYTHING: '-MB4erBaBfJJkBRIhuC5',
};
