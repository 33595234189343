import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FieldsFilters, ItemsQueryLimit, Box, LoadingPlaceholder, HorizontalSeparator, tokens } from '@unitoio/mosaic';
import { TrackingFunnel } from '@unitoio/sherlock';

import * as pcdFilterOperatorTypes from '~/consts/pcdFilterOperator';
import * as trackingTypes from '~/consts/tracking';
import { useConsoleTrackingFunnelContext } from '~/hooks/useConsoleTrackingFunnelContext';
import { getCapabilitiesForItem, getProviderByName } from 'reducers';
import { otherSide } from '~/utils/otherSide';

import { ThenStatementRuleRow } from '../ThenStatementRuleRow/ThenStatementRuleRow';
import { KeepThemInSyncRow } from '../KeepThemInSyncRow/KeepThemInSyncRow';

const StretchedBox = styled(Box)`
  width: 100%;
  min-width: 0;
`;

export function useSetStartSyncing(side) {
  const { setValue, watch } = useFormContext();
  const startSyncingPath = `rules.${side}.filters.startSyncing`;
  const startSyncingValue = watch(startSyncingPath);
  return [
    startSyncingValue,
    (value) => {
      setValue(startSyncingPath, value, { shouldDirty: true });

      const isActivePath = `rules.${side}.filters.isActive`;
      const isActive = watch(isActivePath);
      if (isActive !== false) {
        setValue(isActivePath, true);
      }
    },
  ];
}

function useItemsQueryLimit(side) {
  const { setValue, watch } = useFormContext();
  const inputName = `rules.${side}.itemsQueryLimit`;
  const itemsQueryLimitValue = watch(inputName);
  return [itemsQueryLimitValue, (value) => setValue(inputName, value, { shouldDirty: true })];
}

export const ModernIntegrationRules = ({ sides, side, isLoading, isAutoSaving }) => {
  const otherContainerSection = sides[otherSide(side)];
  const [startSyncingValue, setStartSyncing] = useSetStartSyncing(side);
  const [itemsQueryLimit, setItemsQueryLimit] = useItemsQueryLimit(side);
  const trackingSharedProperties = useConsoleTrackingFunnelContext();

  const provider = useSelector((state) => getProviderByName(state, sides[side]?.providerName));
  const providerItemsCapabilities = useSelector((state) =>
    getCapabilitiesForItem(state, provider?.get('_id'), sides[side]?.containerId, sides[side].itemType),
  );
  const itemTypeDisplayName = providerItemsCapabilities.getIn(['names', 'singular'])?.toLowerCase();
  if (isLoading) {
    return <LoadingPlaceholder borderRadius={tokens.spacing.s2} width="100%" height={tokens.spacing.s9} />;
  }

  return (
    <TrackingFunnel contextName={trackingTypes.MODULE.RULES} sharedProperties={trackingSharedProperties}>
      <StretchedBox p={[tokens.spacing.s0, tokens.spacing.s4]}>
        <ItemsQueryLimit
          prefix="AND"
          disabled={isAutoSaving}
          value={itemsQueryLimit}
          onBlur={setItemsQueryLimit}
          itemTypeDisplayName={itemTypeDisplayName}
        />
        <FieldsFilters
          firstRowPrefix="AND"
          disabled={isAutoSaving}
          credentialId={sides[side].providerIdentityId}
          containerPath={sides[side].containerPath}
          onBlur={setStartSyncing}
          value={startSyncingValue}
          itemType={sides[side].itemType}
        />
      </StretchedBox>
      <StretchedBox>
        <HorizontalSeparator />
      </StretchedBox>
      <StretchedBox>
        <ThenStatementRuleRow otherContainerSection={otherContainerSection} />
        <KeepThemInSyncRow containerType={sides[side].containerType} itemType={sides[side].itemType} />
      </StretchedBox>
    </TrackingFunnel>
  );
};

const filterValueType = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.bool.isRequired,
]);
const filterShape = PropTypes.shape({
  fieldId: PropTypes.string,
  id: PropTypes.string,
  isSetDefault: PropTypes.bool,
  kind: PropTypes.string,
  operator: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)),
  type: PropTypes.string,
  value: PropTypes.oneOfType([filterValueType, PropTypes.arrayOf(filterValueType)]),
});

const groupedFieldShape = PropTypes.shape({
  canBeAllowed: PropTypes.bool.isRequired,
  canBeDenied: PropTypes.bool.isRequired,
  existingOperators: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)).isRequired,
  nbRulesForField: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(filterValueType),
});

ModernIntegrationRules.propTypes = {
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  sides: PropTypes.shape({
    A: PropTypes.shape({
      actions: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      append: PropTypes.func.isRequired,
      canCloseTasks: PropTypes.bool.isRequired,
      containerId: PropTypes.string.isRequired,
      containerName: PropTypes.string.isRequired,
      containerUrl: PropTypes.string.isRequired,
      deepFilters: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      defaultDeepFilterItemFieldId: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(filterShape).isRequired,
      groupedActionFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      groupedDeepFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }),
      groupedFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      hasDeepFiltering: PropTypes.bool.isRequired,
      hasRequiredDate: PropTypes.bool.isRequired,
      hasSubfolders: PropTypes.bool.isRequired,
      insert: PropTypes.func.isRequired,
      move: PropTypes.func.isRequired,
      prepend: PropTypes.func.isRequired,
      providerDisplayName: PropTypes.string.isRequired,
      providerFields: PropTypes.instanceOf(Map).isRequired,
      providerIdentityId: PropTypes.string.isRequired,
      providerName: PropTypes.string.isRequired,
      remove: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      swap: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    }).isRequired,
    B: PropTypes.shape({
      actions: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      append: PropTypes.func.isRequired,
      canCloseTasks: PropTypes.bool.isRequired,
      containerId: PropTypes.string.isRequired,
      containerName: PropTypes.string.isRequired,
      containerUrl: PropTypes.string.isRequired,
      deepFilters: PropTypes.shape({
        append: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(filterShape).isRequired,
        insert: PropTypes.func.isRequired,
        move: PropTypes.func.isRequired,
        prepend: PropTypes.func.isRequired,
        remove: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        swap: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
      }).isRequired,
      defaultDeepFilterItemFieldId: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(filterShape).isRequired,
      groupedActionFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      groupedDeepFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }),
      groupedFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      hasDeepFiltering: PropTypes.bool.isRequired,
      hasRequiredDate: PropTypes.bool.isRequired,
      hasSubfolders: PropTypes.bool.isRequired,
      insert: PropTypes.func.isRequired,
      move: PropTypes.func.isRequired,
      prepend: PropTypes.func.isRequired,
      providerDisplayName: PropTypes.string.isRequired,
      providerFields: PropTypes.instanceOf(Map).isRequired,
      providerIdentityId: PropTypes.string.isRequired,
      providerName: PropTypes.string.isRequired,
      remove: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      swap: PropTypes.func.isRequired,
      update: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAutoSaving: PropTypes.bool.isRequired,
};
