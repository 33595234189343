import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@unitoio/mosaic';

export const LoginEmailPasswordButton = ({ onSubmit }) => (
  <Button variant="outlined" block onClick={() => onSubmit()}>
    Log in with email and password
  </Button>
);

LoginEmailPasswordButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
