/**
 * Return the activity status to display based on its status and whether it is retryable or not.
 * @param {*} status
 * @param {*} publisherError
 * @returns
 */

export const getActivityStatusOption = (status, publisherError) => {
  if (publisherError?.retryable === true) {
    return 'retrying';
  }
  if (publisherError !== undefined || status === true) {
    return 'failed';
  }
  if (status === false || (publisherError === undefined && status === undefined)) {
    return 'success';
  }

  return undefined;
};
