import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, fontSize } from 'theme';

const marginStyles = {
  none: '0 0',
  xs: '0.5rem 0',
  sm: '1rem 0',
  md: '1.5rem 0',
  lg: '2rem 0',
};

const SimpleDivider = styled.hr`
  color: ${color.dark.hint};
  margin: ${(props) => props.$margin};
`;

const CenteredChildrenDivider = styled.div`
  align-items: center;
  color: ${color.dark.hint};
  display: flex;
  flex-basis: 100%;
  font-size: ${fontSize.small};
  margin: ${(props) => props.$margin};

  &::before,
  &::after {
    background: ${color.dark.hint};
    content: '';
    flex-grow: 1;
    font-size: ${fontSize.small};
    height: 1px;
  }

  &::before {
    margin: 0 0.5rem 0 0;
  }

  &::after {
    margin: 0 0 0 0.5rem;
  }
`;

export const Divider = ({ children, marginSize = 'md' }) => {
  const margin = marginStyles[marginSize];

  if (children) {
    return <CenteredChildrenDivider $margin={margin}>{children}</CenteredChildrenDivider>;
  }

  return <SimpleDivider $margin={margin} />;
};

Divider.propTypes = {
  marginSize: PropTypes.oneOf(Object.keys(marginStyles)),
  children: PropTypes.node,
};
