import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';

import { FlowBuilderErrorContext } from '~/contexts';
import * as containerActions from '~/actions/containers';
import { useSetContainersErrors } from '~/containers/FlowBuilder/hooks/useSetContainersErrors';
import * as formUtils from '../../../containers/FlowBuilder/utils/form';

export function useFetchModernContainerById(side) {
  const { setLoadingState, setError, clearErrors, getValues } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);
  const dispatch = useDispatch();
  const setFormErrors = useSetContainersErrors(getValues(), setError, clearErrors);
  const [providerIdentityId, containerType, itemType, containerId, parentContainerId] = useWatch({
    name: [
      `${side}.providerIdentityId`,
      `${side}.containerType`,
      `${side}.itemType`,
      `${side}.containerId`,
      `${side}.parentContainerId`,
    ],
  });
  const errorNamespace = `${pageName}.${side}.containerId`;

  useEffect(() => {
    async function fetchContainerById() {
      setLoadingState(formUtils.loadingStates.SAVING);
      try {
        await dispatch(
          containerActions.getContainerById({
            providerIdentityId,
            containerId,
            containerType,
            itemType,
            parentContainerId,
            options: { displayError: false },
          }),
        );
        setLoadingState(formUtils.loadingStates.SAVED);
        clearErrors(errorNamespace);
      } catch (err) {
        setFormErrors({ [side]: err });
        setLoadingState(formUtils.loadingStates.ERROR);
      }
    }

    if (providerIdentityId && containerId && containerType && itemType && parentContainerId) {
      fetchContainerById();
    }
  }, [
    providerIdentityId,
    containerId,
    containerType,
    itemType,
    dispatch,
    setLoadingState,
    pageName,
    side,
    clearErrors,
    errorNamespace,
    parentContainerId,
    setFormErrors,
  ]);
}
