import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as organizationActions from '~/actions/organizations';
import { useLogger } from '~/hooks/useLogger';

export function useGetOrganizationActivities(organizationId, isDraftFlow) {
  const dispatch = useDispatch();
  const { reportWarning } = useLogger();
  const [isActivitiesLoading, setIsActivitiesLoading] = useState(true);
  const [hasFetchActivitiesFailed, setHasFetchActivitiesFailed] = useState(false);

  useEffect(() => {
    async function getOrgActivities() {
      try {
        await dispatch(organizationActions.getSyncActivity(organizationId, { displayError: false }));
      } catch (error) {
        reportWarning(error, { identifier: 'useGetOrganizationActivities getOrgActivities failedFetchOrgActivities' });
        setHasFetchActivitiesFailed(true);
      } finally {
        setIsActivitiesLoading(false);
      }
    }

    if (!isDraftFlow) {
      getOrgActivities();
    }
  }, [dispatch, isDraftFlow, organizationId, reportWarning]);

  return { isActivitiesLoading, hasFetchActivitiesFailed };
}
