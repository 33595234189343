import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { color as themeColor } from 'theme';
import { RadioButton } from '~/components/RadioButton/RadioButton';

const ALIGNMENT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

const InlineGroup = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props['$btn-alignment'] === ALIGNMENT.HORIZONTAL ? 'initial' : 'wrap')};
  flex-direction: ${(props) => (props['$btn-alignment'] === ALIGNMENT.HORIZONTAL ? 'row' : 'initial')};
`;

export class RadioButtonGroup extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    isChecked: PropTypes.bool,
    inline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    selectedColor: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    btnAlignment: PropTypes.oneOf([ALIGNMENT.VERTICAL, ALIGNMENT.HORIZONTAL]),
  };

  static defaultProps = {
    btnAlignment: ALIGNMENT.VERTICAL,
    isChecked: false,
    inline: false,
    selectedColor: themeColor.brand.primary,
    value: undefined,
    onChange: () => {},
  };

  renderOptions = () => {
    const { children, onChange, value: groupValue, name: groupName } = this.props;

    return React.Children.map(
      children,
      (option) => {
        const { color, label, selectedColor, value: radioValue, ...rest } = option.props;

        return (
          <RadioButton
            {...rest}
            color={color || selectedColor}
            isChecked={radioValue === groupValue}
            key={radioValue}
            label={label}
            name={groupName}
            onCheck={onChange}
            value={radioValue}
          />
        );
      },
      this,
    );
  };

  render() {
    const { btnAlignment, inline } = this.props;
    const Element = inline ? InlineGroup : 'div';

    return (
      <Element $btn-alignment={btnAlignment} className="radio-button-group">
        {this.renderOptions()}
      </Element>
    );
  }
}
