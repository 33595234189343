import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';
import styled from 'styled-components';

import { tokens, ProviderIcon } from '@unitoio/mosaic';

import { capitalize } from '~/utils/capitalize';
import { Href } from '~/components/Href/Href';

const ProviderIconContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
`;

const NameWrapper = styled.div`
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: middle;
`;

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
  font-weight: ${tokens.fontWeight.fw7};
`;

const NameBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: ${tokens.lineHeight.lh4};
`;

const SubtleName = styled.div`
  color: ${tokens.colors.content.neutral.n30};
  font-size: ${tokens.fontSize.f7};
`;

export class ContainerIcon extends Component {
  static propTypes = {
    container: PropTypes.instanceOf(Map),
    iconSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge']),
    isNewContainer: PropTypes.bool,
    itemFilters: PropTypes.arrayOf(PropTypes.string),
    linkStyle: PropTypes.string,
    noUrl: PropTypes.bool,
    provider: PropTypes.instanceOf(Map),
    showName: PropTypes.bool,
    terms: PropTypes.instanceOf(Map),
    tooltipPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  };

  static defaultProps = {
    container: Map(),
    iconSize: 'default',
    isNewContainer: false,
    itemFilters: [],
    linkStyle: 'subtleLink',
    noUrl: false,
    provider: Map(),
    showName: false,
    terms: Map(),
    tooltipPlacement: 'top',
  };

  static getItemFiltersText = (itemFilters) => {
    if (itemFilters.length === 0) {
      return null;
    }

    return itemFilters.reduce((acc, itemName) => {
      if (acc === '') {
        return capitalize(itemName);
      }

      return `${acc} & ${capitalize(itemName)}`;
    }, '');
  };

  hasContainerUrl = () => {
    const { isNewContainer, container, noUrl } = this.props;
    return !isNewContainer && container?.get('url') && !noUrl;
  };

  render() {
    const { container, iconSize, isNewContainer, itemFilters, linkStyle, provider, showName, terms, tooltipPlacement } =
      this.props;

    const containerName = container?.get('displayName', '');
    const containerTermSingular = terms.getIn(['container', 'singular']);
    const providerDisplayName = provider.get('displayName');
    const itemFiltersText = ContainerIcon.getItemFiltersText(itemFilters);

    const Element = this.hasContainerUrl() ? Href : 'div';
    const elemProps = this.hasContainerUrl() ? { href: container.get('url'), linkStyle } : {};

    const tooltipText = isNewContainer
      ? `A new ${providerDisplayName} ${containerTermSingular}`
      : `Open ${containerName} in a new tab`;

    return (
      <Element {...elemProps} className="container-icon">
        <ProviderIconContainer>
          <ProviderIcon
            name={provider.get('name')}
            size={iconSize}
            displayTooltip={!!(!showName && containerName && (this.hasContainerUrl() || isNewContainer))}
            tooltipPlacement={tooltipPlacement}
            tooltipText={tooltipText}
          />
        </ProviderIconContainer>
        {showName && (
          <NameWrapper>
            <NameBlock
              onMouseOver={this.handleOnMouseOver}
              onFocus={this.handleOnMouseOver}
              onMouseLeave={this.handleOnMouseLeave}
              onBlur={this.handleOnMouseLeave}
            >
              {itemFiltersText ? (
                <>
                  <Name>{itemFiltersText}</Name>
                  <SubtleName>{containerName}</SubtleName>
                </>
              ) : (
                <Name>{containerName}</Name>
              )}
            </NameBlock>
          </NameWrapper>
        )}
      </Element>
    );
  }
}
