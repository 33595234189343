import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Icon, Link, tokens, Tooltip, Typography, TypographyVariants } from '@unitoio/mosaic';

const Features = styled.ul`
  list-style: none;
  padding: 0;
`;

const StyledBox = styled(Box)`
  width: max-content;
  cursor: pointer;
`;

const getLabel = (label, disableFeaturesCTA) => {
  if (label === 'Access to 10+ integrations') {
    return (
      <>
        {label}
        {'. '}
        <Link isExternalLink href="https://unito.io/connectors/">
          See all supported integrations
        </Link>
      </>
    );
  }

  if (label.includes('-min onboarding')) {
    return (
      <>
        {label}{' '}
        {!disableFeaturesCTA && (
          <Link
            isExternalLink
            href="https://meetings.hubspot.com/unitoteam/book-a-call-with-a-workflow-expert-in-app-link"
          >
            Book a call
          </Link>
        )}
      </>
    );
  }

  return label;
};

export const PlanFeatureList = ({
  icon,
  plan,
  textColor = tokens.colors.content.neutral.n40,
  disableFeaturesCTA = false,
}) => {
  const visibleFeatures = plan
    .get('features')
    .filter((feature) => feature.getIn(['visibility', 'feature']))
    .toArray();

  return (
    <Features color={textColor}>
      {visibleFeatures.map((feature) => (
        <StyledBox as="li" key={feature.get('id')}>
          <Tooltip placement="top" content={feature.get('helpText')}>
            <Typography variant={TypographyVariants.BODY1} color={textColor}>
              <Icon name={icon} kind={Icon.KINDS.SOLID} /> &nbsp; {getLabel(feature.get('label'), disableFeaturesCTA)}
            </Typography>
          </Tooltip>
        </StyledBox>
      ))}
    </Features>
  );
};

PlanFeatureList.propTypes = {
  icon: PropTypes.string.isRequired,
  plan: PropTypes.instanceOf(Map).isRequired,
  textColor: PropTypes.string,
  disableFeaturesCTA: PropTypes.bool,
};
