import React from 'react';
import { ErrorBoundary } from '@unitoio/sherlock';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as linkTypes from '~/consts/link';

import { useLogger } from '~/hooks/useLogger';
import { getEmbedName, getLinkOrganizationId, getLinkState, getOrganizationById, isUserSiteAdmin } from 'reducers';
import { AppError } from '~/containers/AppError/AppError';

export const FlowBuilderWithErrorBoundary = ({ children, ...props }) => {
  const { reportException } = useLogger();
  const { match } = props;
  const { linkId } = match.params;
  const embedName = useSelector((state) => getEmbedName(state));
  const isEmbed = !!embedName;
  const linkState = useSelector((state) => getLinkState(state, linkId)) ?? linkTypes.LINK_STATES.DRAFT;
  const organizationId = useSelector((state) => getLinkOrganizationId(state, linkId));
  const fullLinkOrganization = useSelector((state) => getOrganizationById(state, organizationId));

  // pick the info we want to log for the organization, note if a siteadmin is opening a customer's link
  // it's possible the organization is not loaded in the redux store, in which case we will only
  // have the _id but this is fine for our purposes, the metadata will help classify errors for regular users
  // for which the organization should always be populated in the store.
  const linkOrganization = {
    _id: fullLinkOrganization.get('_id'),
    status: fullLinkOrganization.get('status'),
    planId: fullLinkOrganization.get('planId'),
  };

  const isSiteAdmin = useSelector((state) => isUserSiteAdmin(state));
  return (
    <ErrorBoundary
      FallbackComponent={AppError}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, {
          ...errMessageContext,
          linkId,
          isEmbed,
          linkState,
          linkOrganization,
          isUserSiteAdmin: isSiteAdmin,
          routePath: match.path,
          routeUrl: match.url,
          componentStack,
        })
      }
    >
      {children}
    </ErrorBoundary>
  );
};

FlowBuilderWithErrorBoundary.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ workflowId: PropTypes.string, linkId: PropTypes.string }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  children: PropTypes.node.isRequired,
};
