import { useWatch } from 'react-hook-form';

export function useIsWorkItemsPageCompleted() {
  const workItemsPageFieldNames = ['providerName', 'providerIdentityId', 'containerType', 'itemType', 'containerId'];
  const workItemsPageFields = useWatch({
    name: workItemsPageFieldNames.flatMap((field) => [`A.${field}`, `B.${field}`]),
  });

  return workItemsPageFields.every((field) => !!field);
}
