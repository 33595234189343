import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@unitoio/mosaic';

import { BlockOfWork, NoWrapHeader, TableCell } from '../components';

function Cell({ row: { original: activityLog } }) {
  // Accessing via the table instance instead of the value, because this cell requires information from 2
  // different fields and there's no other way of getting that. Accessor only gives us info abouta single field.
  const providerName = activityLog.targetConnectorName;
  const { displayName, url } = activityLog.targetContainer;

  return (
    <TableCell>
      <div className="wide">
        <BlockOfWork providerName={providerName} url={url}>
          {displayName}
        </BlockOfWork>
      </div>
    </TableCell>
  );
}

Cell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      targetConnectorName: PropTypes.string.isRequired,
      targetContainer: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const updateLocationColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="bottom" content="Name of the block of work where the item was created or updated.">
        Update location
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'targetBlockOfWorkId',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
