import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { tokens, ProviderIcon, NewTypography, Icon, Link } from '@unitoio/mosaic';

import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';

import { useGetProviderNames } from '../../hooks/useGetProviderNames';

const SideHeader = styled.div`
  display: flex;
  gap: ${tokens.spacing.s4};
  align-items: center;
  margin-left: ${tokens.spacing.s4};
  padding: ${tokens.spacing.s2} 0 ${tokens.spacing.s4} 0;
`;

export const ModernRulesSideHeader = ({ side }) => {
  const { linkId } = useParams();
  const { watch } = useFormContext();
  const syncDirection = watch('syncDirection');

  const [containerA, containerB] = useGetContainers();
  const [providerNameA, providerNameB] = useGetProviderNames(linkId);
  const containerName = side === 'A' ? containerA.get('displayName') : containerB.get('displayName');
  const containerUrl = side === 'A' ? containerA.get('url') : containerB.get('url');
  const providerName = side === 'A' ? providerNameA : providerNameB;
  const otherProviderName = side === 'A' ? providerNameB : providerNameA;
  const otherContainerName = side === 'A' ? containerB.get('displayName') : containerA.get('displayName');
  const otherContainerUrl = side === 'A' ? containerB.get('url') : containerA.get('url');

  const isDisabledSideA = syncDirection === 'A' && side === 'A';
  const isDisabledSideB = syncDirection === 'B' && side === 'B';

  return (
    <SideHeader>
      <ProviderIcon size="large" name={providerName} />{' '}
      <NewTypography.Title disabled={isDisabledSideA} level={5}>
        {containerUrl ? (
          <Link href={containerUrl} target="_blank" style={{ color: tokens.colors.content.neutral.n40 }}>
            {containerName}
          </Link>
        ) : (
          containerName
        )}
      </NewTypography.Title>
      <Icon name="long-arrow-right" />
      <ProviderIcon size="large" name={otherProviderName} />{' '}
      <NewTypography.Title disabled={isDisabledSideB} level={5}>
        {otherContainerUrl ? (
          <Link href={otherContainerUrl} target="_blank" style={{ color: tokens.colors.content.neutral.n40 }}>
            {otherContainerName}
          </Link>
        ) : (
          otherContainerName
        )}
      </NewTypography.Title>
    </SideHeader>
  );
};

ModernRulesSideHeader.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
};
