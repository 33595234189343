import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { color } from 'theme';

const SideMenu = styled.ul`
  background: ${color.light.primary};
  list-style-type: none;
  border: 1px solid ${color.dark.quiet};
  box-shadow: 0px 4px 6px ${color.dark.whisper};
  border-radius: 4px;
  padding: .75rem 0 1rem;
  margin-bottom: 1rem;

  li {
    z-index: 3;

    > a {
      border-radius: 0;
      color: ${color.dark.primary};
      padding: .5rem 1.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > a.active {
      color: ${color.brand.secondary};
      background-color: ${color.background.global.gray};

      &:hover {
        color: ${color.light.primary};
        background-color: ${color.brand.secondary};
      }
  }
`;

export function NavbarStacked({ children }) {
  return <SideMenu className="nav nav-pills nav-stacked">{children}</SideMenu>;
}

NavbarStacked.propTypes = {
  children: PropTypes.node.isRequired,
};
