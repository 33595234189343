import React from 'react';

import { tokens, Box, Alert, Typography } from '@unitoio/mosaic';

import { Href } from '~/components/Href/Href';

export const MergeDuplicatesWarning = () => (
  <Box
    borderRadius={tokens.spacing.s4}
    borderColor={tokens.colors.content.neutral.n10}
    p={[tokens.spacing.s6, tokens.spacing.s6, tokens.spacing.s5, tokens.spacing.s6]}
    m={[tokens.spacing.s6, 0, 0, 0]}
    aria-label="Manage duplicates"
  >
    <Box m={[0, 0, tokens.spacing.s3, 0]}>
      <Typography variant="h4">Manage duplicates</Typography>
    </Box>
    <p>Decide which app will overwrite the other when it’s unclear which one has the most up-to-date information.</p>
    <Alert showIcon={false} level="warning">
      Merging duplicates is not supported at the moment. This may cause near identical contacts to be created if a
      matching one already exists. If this feature would be useful in your flows,{' '}
      <Href href="https://form.typeform.com/to/Ytc6qoZR?typeform-source=unito1.typeform.com">
        we would love to hear from you
      </Href>
      .
    </Alert>
  </Box>
);
