import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTopComponent extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children || null;
  }
}

ScrollToTopComponent.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({}).isRequired,
};

export const ScrollToTop = withRouter(ScrollToTopComponent);
