import styled from 'styled-components';

import { color } from 'theme';

export const UnderlinedNav = styled.ul`
  align-items: center;
  display: flex;

  &&& {
    border-bottom: 4px solid ${(props) => (props.$hideInactiveState ? 'transparent' : color.dark.whisper)};
  }

  &.nav-sticky {
    background: white;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 3;
  }
`;
