export const API_ERROR_TYPES = {
  AUTHENTICATION: 'AUTHENTICATION',
  UNAUTHORIZED: 'UNAUTHORIZED',
  MISCONFIGURED: 'MISCONFIGURED',
  UNCATEGORIZED: 'UNCATEGORIZED',
  RATE_LIMIT: 'RATE_LIMIT',
  TIMEOUT: 'TIMEOUT',
  NOT_FOUND: 'NOT_FOUND',
  UNSUPPORTED: 'UNSUPPORTED',
  UNPROCESSABLE: 'UNPROCESSABLE',
  UNREACHABLE: 'UNREACHABLE',
  EXTERNAL_UNCATEGORIZED: 'EXTERNAL_UNCATEGORIZED',
};

export const RESOURCE_ERROR_TYPE = {
  DUPLICATION_ERROR: 'DUPLICATION_ERROR',
  PROVIDER_IDENTITIES_ERROR: 'PROVIDER_IDENTITIES_ERROR',
};
