import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, tokens } from '@unitoio/mosaic';

import { Subheading } from '~/components/Subheading/Subheading';
import { Title } from '~/components/Title/Title';

import emptyState from './empty_state.svg';
import emptyStateWithSearch from './empty_state_with_search.svg';

const EmptyStateImg = styled.img`
  margin-bottom: ${tokens.spacing.s5};
  width: 250px;
  height: auto;
`;

const ContentWrapper = styled(Box)`
  max-width: 43rem;
`;

function getEmptyStateContent(searchString) {
  if (searchString) {
    return [emptyStateWithSearch, `No results for "${searchString}"`, 'Check your search for typos and try again'];
  }

  return [
    emptyState,
    'Create your first flow!',
    'Flows connect two blocks of work and shape how information syncs between them.',
  ];
}

export const ContentEmptyState = ({ content = null, searchString = null }) => {
  const [src, title, subheading] = getEmptyStateContent(searchString);

  return (
    <ContentWrapper
      alignItems="center"
      flexDirection="column"
      flexWrap={Box.flexWrap.WRAP}
      m={[0, 'auto']}
      p={[tokens.spacing.s6, 0, tokens.spacing.s8, 0]}
    >
      <EmptyStateImg alt={title} src={src} />
      <Title type="h3">{title}</Title>
      <Subheading>{subheading}</Subheading>
      {content}
    </ContentWrapper>
  );
};

ContentEmptyState.propTypes = {
  searchString: PropTypes.string,
  content: PropTypes.node,
};
