import { Icon, NewButton } from '@unitoio/mosaic';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

export const ChatBubble = ({
  children = 'get in touch with our customer support',
  message = '',
  onClick = null,
  type = 'link',
  ...rest
}) => {
  const openChatBot = useCallback(() => {
    window.HubSpotConversations.widget.open();
  }, []);

  const openWindowMailTo = useCallback(() => {
    onClick && onClick();
    window.open(`mailto:help@unito.io?subject=Support needed&body=${message}`, '_blank');
  }, [message, onClick]);

  const handleOnClick = useCallback(() => {
    // If user is blocking, send an email to support instead
    if (!window.HubSpotConversations) {
      openWindowMailTo();
      return;
    }

    onClick && onClick();
    openChatBot();
  }, [onClick, openChatBot, openWindowMailTo]);

  return (
    <NewButton
      type={type}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      role="button"
      tabIndex="0"
      iconPosition="end"
      icon={<Icon name="external-link" size="sm" />}
      {...rest}
    >
      {children}
    </NewButton>
  );
};

ChatBubble.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['link', 'default', 'text', 'primary', 'dashed']),
};
