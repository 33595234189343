import * as providerContainerTypes from '~/consts/providerContainers';
import * as routes from '~/consts/routes';

export const createProviderContainer = (providerIdentityId, unitoUniqueId, containerType, itemType) => ({
  types: [
    providerContainerTypes.CREATE_PROVIDER_CONTAINER_REQUEST,
    providerContainerTypes.CREATE_PROVIDER_CONTAINER_SUCCESS,
    providerContainerTypes.CREATE_PROVIDER_CONTAINER_FAILURE,
  ],
  method: routes.METHODS.POST,
  url: routes.API_PATHS.CREATE_PROVIDER_CONTAINER,
  payload: {
    providerIdentityId,
    unitoUniqueId,
    containerType,
    itemType,
  },
});

export const removeProviderContainer = (providerContainerId) => ({
  type: providerContainerTypes.REMOVE_PROVIDER_CONTAINER,
  payload: { providerContainerId },
});
