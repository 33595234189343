import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Box, Link, tokens } from '@unitoio/mosaic';

import * as featureTypes from '~/consts/features';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { FeatureUsage } from './FeatureUsage';
import { FeatureLimit } from './FeatureLimit';

const PullRightBox = styled(Box)`
  margin-left: auto;
`;

const FeatureLimitSeparator = styled.span`
  margin: 0 ${tokens.spacing.s3};
`;

export function UsageCard({ featureName, featureLink, featureLinkCopy, trackingActionName }) {
  const trackEvent = useTrackEvent();

  return (
    <Box
      flexDirection={Box.flexDirection.ROW}
      p={[tokens.spacing.s6, tokens.spacing.s5]}
      borderColor={tokens.colors.content.neutral.n10}
      borderRadius={tokens.spacing.s4}
    >
      <Box flexDirection={Box.flexDirection.ROW} alignItems={Box.alignItems.BASELINE}>
        <FeatureUsage featureName={featureName} colored /> <FeatureLimitSeparator>/</FeatureLimitSeparator>{' '}
        <FeatureLimit featureName={featureName} />
      </Box>
      <PullRightBox
        p={[tokens.spacing.s2, 0]}
        onClick={() =>
          trackEvent(trackingTypes.ACTION, {
            action_name: trackingActionName,
          })
        }
      >
        <Link as={ReactRouterLink} to={featureLink}>
          {featureLinkCopy}
        </Link>
      </PullRightBox>
    </Box>
  );
}

UsageCard.propTypes = {
  featureName: PropTypes.oneOf(Object.values(featureTypes.FEATURES)).isRequired,
  featureLink: PropTypes.string.isRequired,
  featureLinkCopy: PropTypes.string.isRequired,
  trackingActionName: PropTypes.string.isRequired,
};
