import * as activityLogsTypes from '~/consts/activityLogs';
import { fromJS } from 'immutable';

export const initialState = fromJS({
  data: {
    activityLogs: [],
    activityLogsCount: 0,
  },
  isLoading: false,
  lastFetchTimestamp: null,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case activityLogsTypes.FETCH_ACTIVITY_LOGS_REQUEST:
      return state.set('isLoading', true);

    case activityLogsTypes.FETCH_ACTIVITY_LOGS_SUCCESS: {
      if (!action.meta.overwrite) {
        return state.set('isLoading', false);
      }

      let newLogs;
      let newCount;
      if (action.meta.shouldClearResults) {
        newLogs = fromJS(action.payload.activityLogs);
        newCount = action.payload.activityLogsCount;
      } else {
        const currentDocuments = state.getIn(['data', 'activityLogs']);
        const uniqueDocuments = action.payload.activityLogs.filter(
          (doc) => !currentDocuments.some((auxDoc) => auxDoc.get('id') === doc.id),
        );

        newLogs = currentDocuments.splice(0, 0, ...fromJS(uniqueDocuments)).sort((a, b) => {
          const [aChangeTime, bChangeTime] = [a.get('changeTime'), b.get('changeTime')];
          if (aChangeTime > bChangeTime) {
            return -1;
          }
          if (aChangeTime < bChangeTime) {
            return 1;
          }
          return 0;
        });
      }
      return state.merge({
        isLoading: false,
        data: fromJS({
          activityLogs: newLogs,
          activityLogsCount: newCount,
        }),
        lastFetchTimestamp: action.meta.responseTimestamp,
      });
    }

    case activityLogsTypes.FETCH_ACTIVITY_LOGS_FAILURE:
      return state.merge({
        isLoading: false,
        data: initialState.get('data'),
      });

    default:
      return state;
  }
};

export const getIsLoadingActivityLogs = (state) => state.get('isLoading');

export const getActivityLogs = (state) => state.get('data');

export const getLastActivityLogsFetchTimestamp = (state) => state.get('lastFetchTimestamp');

export const getFirstActivityLogsByItemId = (state, itemId) => {
  const activityLogs = state.getIn(['data', 'activityLogs']);

  return activityLogs.find((activityLog) => activityLog.getIn(['targetTask', 'id']) === itemId);
};
