import React from 'react';
import PropTypes from 'prop-types';

import { Box, ItemsQueryLimit, NewSelect, NewTypography, Space, tokens } from '@unitoio/mosaic';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCapabilitiesForItem, getProviderByName } from '~/reducers';
import { useGetSides } from '../pages/Rules/hooks';
import { ContainerNameWithProviderIcon } from './ContainerNameWithProviderIcon';
import { getAreModernRulesEnabled } from '../utils/getAreModernRulesEnabled';

export function useItemsQueryLimit(side) {
  const { setValue, watch } = useFormContext();
  const inputName = `rules.${side}.itemsQueryLimit`;
  const itemsQueryLimitValue = watch(inputName);
  return [itemsQueryLimitValue, (value) => setValue(inputName, value, { shouldDirty: true })];
}

export const ItemsQueryLimitOptions = () => {
  const { A, B } = useGetSides();

  const modernRulesEnabledA = getAreModernRulesEnabled(A);
  const modernRulesEnabledB = getAreModernRulesEnabled(B);

  if (!modernRulesEnabledA && !modernRulesEnabledB) {
    return null;
  }

  return (
    <Box
      borderColor={tokens.colors.content.neutral.n10}
      borderRadius={tokens.spacing.s4}
      flexDirection="column"
      m={[0, 0, tokens.spacing.s4, 0]}
      p={[tokens.spacing.s6]}
    >
      <NewTypography.Title level={3} style={{ marginTop: tokens.spacing.s1 }}>
        Item count limit
      </NewTypography.Title>
      <NewTypography.Paragraph>
        Set a maximum number of items to sync, helping Unito optimize performance and giving you full control over sync
        volume
      </NewTypography.Paragraph>

      {modernRulesEnabledA && <ItemsQueryLimitBySide side="A" />}
      {modernRulesEnabledB && <ItemsQueryLimitBySide side="B" />}
    </Box>
  );
};

const ItemsQueryLimitBySide = ({ side }) => {
  const [itemsQueryLimitValue, setItemsQueryLimitValue] = useItemsQueryLimit(side);
  const { [side]: currentSide } = useGetSides();

  const provider = useSelector((state) => getProviderByName(state, currentSide.providerName));
  const providerItemsCapabilities = useSelector((state) =>
    getCapabilitiesForItem(state, provider?.get('_id'), currentSide.containerId, currentSide.itemType),
  );
  const itemTypeDisplayName = providerItemsCapabilities.getIn(['names', 'singular'])?.toLowerCase();

  return (
    <Space>
      <NewSelect value="in" variant="readOnly" />
      <ContainerNameWithProviderIcon side={side} />
      <ItemsQueryLimit
        label="sync up to"
        value={itemsQueryLimitValue}
        onBlur={setItemsQueryLimitValue}
        itemTypeDisplayName={itemTypeDisplayName}
      />
    </Space>
  );
};

ItemsQueryLimitBySide.propTypes = {
  side: PropTypes.oneOf(['A', 'B']).isRequired,
};
