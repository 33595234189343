import React from 'react';

import { NoWrapHeader } from '../components';

export const activityTypeColumn = (title) => ({
  header: () => <NoWrapHeader>{title}</NoWrapHeader>,
  accessor: 'type',
  disableFilters: true,
  disableSortBy: true,
});
