import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Alert, AlertLevels, Bold, Box, Col, Link, Row, tokens } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';

import { useIsAlreadyActiveFlow } from '../hooks/useIsAlreadyActiveFlow';

export const AlreadyActiveFlowAlert = ({ linkId }) => {
  const { isAlreadyActiveSync, linkWithSameContainers, containerADisplayName, containerBDisplayName } =
    useIsAlreadyActiveFlow(linkId);

  if (!isAlreadyActiveSync) {
    return null;
  }

  return (
    <Row>
      <Col lg={12} md={12}>
        <Box m={[tokens.spacing.s4, 0]} as={Alert} level={AlertLevels.WARNING} showIcon={false}>
          <Bold>{containerADisplayName}</Bold> and <Bold>{containerBDisplayName}</Bold> are already connected in an
          existing flow. Only one of them can be active at a time.
          <Link
            as={ReactRouterLink}
            to={`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${linkWithSameContainers.get('_id')}`}
          >
            Edit existing flow.
          </Link>
        </Box>
      </Col>
    </Row>
  );
};

AlreadyActiveFlowAlert.propTypes = {
  linkId: PropTypes.string.isRequired,
};
