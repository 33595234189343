import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fontSize, lineHeight } from 'theme';
import * as trackingTypes from '~/consts/tracking';
import { Href } from '~/components/Href/Href';

const Content = styled.footer`
  margin-top: 5rem;

  p {
    font-size: ${fontSize.small};
    line-height: ${lineHeight.small};
  }
`;

export function Footer({ children, trackEvent }) {
  return (
    <Content className="footer">
      {children}
      <p style={{ marginTop: '1rem' }}>
        <Href
          href="https://guide.unito.io/"
          target="_blank"
          onClick={() =>
            trackEvent(trackingTypes.ACTION, {
              action_name: trackingTypes.AUTHENTICATION.ACTIONS.CLICKED_UNITO_GUIDE,
            })
          }
        >
          Unito Guide
        </Href>{' '}
        |{' '}
        <Href
          href="mailto:support@unito.io"
          onClick={() =>
            trackEvent(trackingTypes.ACTION, {
              action_name: trackingTypes.AUTHENTICATION.ACTIONS.CLICKED_GET_HELP,
            })
          }
        >
          Get Help
        </Href>
      </p>
    </Content>
  );
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
