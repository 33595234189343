import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@unitoio/mosaic';

import { BlockOfWork, NoWrapHeader, TableCell } from '../components';

function Cell({ row: { original: activityLog } }) {
  const provider = activityLog.sourceConnectorName;
  const { displayName, url } = activityLog.sourceContainer;

  return (
    <TableCell>
      <div className="wide">
        <BlockOfWork providerName={provider} url={url}>
          {displayName}
        </BlockOfWork>
      </div>
    </TableCell>
  );
}

Cell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      sourceConnectorName: PropTypes.string.isRequired,
      sourceContainer: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const originalLocationColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="left" content="Name of the block of work where the source item lives.">
        Original location
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'sourceBlockOfWorkId',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
