import { Layout, NewCol, NewRow, Space } from '@unitoio/mosaic';
import React from 'react';
import { color } from 'theme';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { TrackingFunnelContext } from '~/contexts';
import styled from 'styled-components';

import * as trackingTypes from '~/consts/tracking';
import { HeaderContainer } from '../HeaderContainer/HeaderContainer';

const { Header, Content, Footer } = Layout;

const LayoutContainer = styled(Layout)`
  background-color: ${color.content.neutral.white};
  overflow: hidden;
`;

const HeaderStyled = styled(Header)`
  padding: 0;
  height: auto;
`;

export const LayoutDashboard = ({ children }) => {
  const isWorkflowEditPage = useRouteMatch('/dashboard/workflows/edit');
  const isItemStatusPage = useRouteMatch('/items/:itemId/status');
  const isFlowBuilderPage = useRouteMatch('/dashboard/flow-builder');

  const hasItsOwnLayout = isWorkflowEditPage || isItemStatusPage || isFlowBuilderPage;

  if (hasItsOwnLayout) {
    return <>{children}</>;
  }

  return (
    <LayoutContainer>
      <Space direction="vertical" size="large">
        <HeaderStyled>
          {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
          <TrackingFunnelContext.Provider value={{ name: trackingTypes.FUNNELS.HEADER }}>
            <HeaderContainer />
          </TrackingFunnelContext.Provider>
        </HeaderStyled>
        <Content>
          <NewRow justify="center" gutter={[32, 48]}>
            <NewCol xxl={17} xl={20} lg={22} md={20} xs={20}>
              {children}
            </NewCol>
          </NewRow>
        </Content>
      </Space>
      <Footer />
    </LayoutContainer>
  );
};

LayoutDashboard.propTypes = {
  children: PropTypes.node.isRequired,
};
