import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FlowBuilderErrorContext } from '~/contexts';

export function useGetIncompleteGroupField(enableAlert = true) {
  const { setError, clearErrors } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);

  const fieldAssociations = useWatch({ name: 'associations' }) || [];
  const associationsWithMapping = fieldAssociations.filter(
    (fieldAssociation) => fieldAssociation?.A?.mapping && fieldAssociation?.B?.mapping,
  );

  const incompleteMapping = associationsWithMapping.filter((fa) => {
    const mappingA = fa.A.mapping;
    const mappingB = fa.B.mapping;

    // If both mappings are empty, then the association is not incomplete
    // e.g. List <-> List does not always require a mapping, we can support it
    // without an explicit mapping on both sides, the sync-engine will use a find by displayName
    // to map automatically the lists
    if (!mappingA?.length && !mappingB?.length) {
      return false;
    }

    // if one side has a mapping value which is not mapped on the other side (null) then the association is incomplete
    return (
      mappingA?.some((mapping) => mapping?.values?.some((mappingValues) => mappingValues.value === null)) ||
      mappingB?.some((mapping) => mapping?.values?.some((mappingValues) => mappingValues.value === null))
    );
  });

  const incompleteMappingCount = incompleteMapping.length;

  useEffect(() => {
    if (enableAlert) {
      if (incompleteMappingCount > 0) {
        setError(`${pageName}.incompleteFieldMappingGroup`, { type: 'manual' });
      } else {
        clearErrors(`${pageName}.incompleteFieldMappingGroup`);
      }
    }
  }, [setError, clearErrors, incompleteMappingCount, pageName, enableAlert]);

  return [incompleteMapping, incompleteMappingCount];
}
