import { useWatch } from 'react-hook-form';

export function useGetProviderNames() {
  const [providerNameA, providerNameB] = useWatch({ name: ['A.providerName', 'B.providerName'] });
  return [providerNameA, providerNameB];
}

export function useGetProviderNameBySide(side) {
  const [providerNameA, providerNameB] = useWatch({ name: ['A.providerName', 'B.providerName'] });
  return side === 'A' ? providerNameA : providerNameB;
}
