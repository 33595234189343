import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, tokens, Tooltip } from '@unitoio/mosaic';

import { TableCell, NoWrapHeader } from '../components';

const SyncId = styled.span`
  line-height: 1;
  display: inline-block;
  max-width: 6.25rem;
`;

function Cell({ value: syncId }) {
  return (
    <TableCell>
      <div className="no-wrap">
        <Tooltip as="div" content={syncId}>
          <SyncId className="ellipsized">{syncId}</SyncId> &nbsp;
          <Icon
            onClick={() => navigator.clipboard.writeText(syncId)}
            name="clone"
            color={tokens.colors.content.secondary.default}
            cursor="pointer"
            title="copy"
            kind={Icon.KINDS.SOLID}
          />
        </Tooltip>
      </div>
    </TableCell>
  );
}

Cell.propTypes = {
  value: PropTypes.string.isRequired,
};

function Header() {
  return (
    <NoWrapHeader>
      <Tooltip
        as="span"
        placement="bottom"
        content="A unique ID assigned to each synced items. Use it when you speak to Unito support team for advanced troubleshooting."
      >
        Activity ID
      </Tooltip>
    </NoWrapHeader>
  );
}

export const activityIdColumn = {
  header: Header,
  accessor: 'syncId',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
