import { useFieldArray, useFormContext } from 'react-hook-form';

export function useWatchFieldArray(name) {
  const { watch } = useFormContext();
  const { fields, ...fieldArray } = useFieldArray({ name });
  const watchFieldArray = watch(name);

  if (fields.length === 0 && watchFieldArray) {
    return { ...fieldArray, fields: watchFieldArray };
  }

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray?.[index],
  }));

  return { ...fieldArray, fields: controlledFields };
}
