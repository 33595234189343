import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import { FlowNodeModel } from './FlowModel';
import { FlowWidget } from './FlowWidget';

export class FlowNodeFactory extends AbstractReactFactory {
  constructor(reportWarning = () => {}) {
    super('Flow');
    this.reportWarning = reportWarning;
  }

  // eslint-disable-next-line class-methods-use-this
  generateModel = () => new FlowNodeModel();

  // the engine model we rely on here is not yet aware that it's actually an instance of
  // WorkflowModel (because the instantiation of the model has to be done after the factory
  // definitions in workflowEngine) so we must use the native methods to retrieve the layers.
  getAllDiagramNodes() {
    return this.engine
      .getModel()
      .getLayers()
      .filter((l) => l.getOptions().type === 'diagram-nodes')
      .map((layer) => layer.getModels());
  }

  // Ensures this nodes' siblings still exist within the current workflow model
  validateFlowNodeSiblings(flowNode) {
    if (!flowNode || flowNode.siblings.length !== 2) {
      return false;
    }
    const allDiagramNodes = this.getAllDiagramNodes();
    const allNodes = allDiagramNodes.length ? allDiagramNodes[0] : {};
    // check if the passed flowNode still has its siblings in the whole engine.
    return flowNode.siblings.every((siblingWorkblockNodeId) =>
      // we could technically match against Object.keys directly here but since it's more
      // an internal representation of how react-diagrams store their data, it's safer to use the accessor methods
      // for IDs.
      Object.values(allNodes).find((node) => node.getID() === siblingWorkblockNodeId),
    );
  }

  generateReactWidget(event) {
    const isValidFlowNode = this.validateFlowNodeSiblings(event.model);
    if (!isValidFlowNode) {
      const allDiagramNodes = this.getAllDiagramNodes();
      const { A, B, siblings } = event.model;
      this.reportWarning('FlowNode is invalid, siblings were not found in diagram model or incorrect sibling count.', {
        flowNode: {
          A,
          B,
          siblings,
        },
        allDiagramNodes,
      });
      return null;
    }
    return <FlowWidget engine={this.engine} node={event.model} />;
  }
}
