import React from 'react';
import PropTypes from 'prop-types';

import { NewTag, NewTagColor } from '@unitoio/mosaic';

import { NoWrapHeader } from '../components';

const activityStatusOptions = [
  { label: 'Success', value: false },
  { label: 'Failed', value: true },
];

function Cell({ value }) {
  const labelTypes = new Map([
    [false, NewTagColor.POSITIVE],
    [true, NewTagColor.ERROR],
  ]);
  const activityStatusOption = activityStatusOptions.find((statusOption) => statusOption.value === value);
  const displayValue = activityStatusOption ? activityStatusOption.label : 'Unknown';

  return <NewTag color={labelTypes.get(value) || NewTagColor.DEFAULT}>{displayValue}</NewTag>;
}

Cell.propTypes = { value: PropTypes.bool.isRequired };

export const statusColumn = {
  header: () => <NoWrapHeader>Status</NoWrapHeader>,
  accessor: 'status',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
