import { logger, addDatadogError } from '@unitoio/sherlock';

import { getClientVersion, getUserId, getSelectedOrganizationId } from 'reducers';

/**
 * Helper function to report an exception to DD via sherlock
 * @param error, a javascript error or object containing a message/stack field to be reported.
 * @param context, any additional context to be provided
 */
export const reportException = (error, context) => (dispatch, getState) => {
  const state = getState();

  const loggerContext = {
    user_id: getUserId(state) || 'unauthenticated',
    organization_id: getSelectedOrganizationId(state),
    client_version: getClientVersion(state),
    ...context,
  };

  const effectiveError = error instanceof Error ? error : new Error(JSON.stringify(error));

  addDatadogError(effectiveError, loggerContext);

  logger.error(effectiveError.message, loggerContext, effectiveError);
};

/**
 * Helper function to report an info log level to DD via sherlock
 * @param message, the message of the log.
 * @param context, any additional context to be provided.
 */
export const reportInfo = (message, context) => (dispatch, getState) => {
  const state = getState();
  logger.info(message, {
    user_id: getUserId(state) || 'unauthenticated',
    organization_id: getSelectedOrganizationId(state),
    client_version: getClientVersion(state),
    ...context,
  });
};

/**
 * Helper function to report a warn log level to DD via sherlock
 * @param message, the message of the log.
 * @param context, any additional context to be provided.
 */
export const reportWarning = (message, context) => (dispatch, getState) => {
  const state = getState();
  logger.warn(message, {
    user_id: getUserId(state) || 'unauthenticated',
    organization_id: getSelectedOrganizationId(state),
    client_version: getClientVersion(state),
    ...context,
  });
};
