import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { ProviderIcon } from '@unitoio/mosaic';

import { getProviderById } from 'reducers';

export const ProviderIconById = ({
  displayTooltip = false,
  providerId,
  size = 'default',
  tooltipPlacement = 'right',
  tooltipText,
  shape,
}) => {
  const provider = useSelector((state) => getProviderById(state, { providerId }));
  return (
    <ProviderIcon
      displayTooltip={displayTooltip}
      name={provider.get('name')}
      size={size}
      tooltipPlacement={tooltipPlacement}
      tooltipText={tooltipText}
      shape={shape}
    />
  );
};

ProviderIconById.propTypes = {
  displayTooltip: PropTypes.bool,
  providerId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'default', 'large', 'xlarge']),
  shape: PropTypes.oneOf(['circle', 'square']),
  tooltipPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  tooltipText: PropTypes.string,
};
