import React from 'react';

import { Box, NewTypography, Switch, tokens } from '@unitoio/mosaic';

import { useLocalStorage } from '~/hooks/useLocalStorage';

export const IS_SITE_ADMIN_VIEW_TOGGLED = 'isSiteAdminUiToggled';
export const SiteAdminViewSwitch = () => {
  const [isSiteAdminViewEnabled, setSiteAdminViewToggled] = useLocalStorage(IS_SITE_ADMIN_VIEW_TOGGLED, true);

  const onToggle = (newValue) => {
    setSiteAdminViewToggled(newValue);
  };

  return (
    <Box data-testid="siteAdminUiToggler" p={[tokens.spacing.s2, tokens.spacing.s5]} alignItems={Box.alignItems.CENTER}>
      <Switch defaultValue={isSiteAdminViewEnabled} onClick={onToggle} />
      <Box m={[0, 0, 0, tokens.spacing.s3]}>
        <NewTypography.Text>Admin view</NewTypography.Text>
      </Box>
    </Box>
  );
};
