import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';

import { Avatar, Icon, tokens } from '@unitoio/mosaic';

import * as providerIdentityActions from '~/actions/providerIdentity';
import { getProviderByName } from 'reducers';
import * as providerIdentityTypes from '~/consts/providerIdentity';
import './ProviderIdentityItem.scss';
import { ProviderAvatar } from '~/components/ProviderAvatar/ProviderAvatar';

const Item = styled.div`
  margin-top: 0;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const ToolIconsWrapper = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  color: ${tokens.colors.content.warning};
  margin-right: -20px;
  position: absolute;
  right: ${tokens.spacing.s4};
  top: ${tokens.spacing.s4};
`;

const displayTypes = {
  VIA_TOOL: 'via-tool',
  EMAIL: 'email',
  AVATAR_ONLY: 'avatar-only',
};

class ProviderIdentityItemComponent extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    provider: PropTypes.instanceOf(Map).isRequired,
    providerIdentity: PropTypes.instanceOf(Map).isRequired,
    displayType: PropTypes.oneOf(Object.values(displayTypes)),
    needsValidate: PropTypes.bool,
    validateProviderIdentity: PropTypes.func.isRequired,
  };

  static defaultProps = {
    displayType: displayTypes.VIA_TOOL,
    children: null,
    needsValidate: true,
    onClick: () => null,
  };

  componentDidMount() {
    const { providerIdentity, needsValidate, validateProviderIdentity } = this.props;
    const isActive = providerIdentity.get('state') === providerIdentityTypes.STATE.ACTIVE;
    const isValidated = providerIdentity.get('isValidated');

    if (needsValidate && (!isValidated || !isActive)) {
      validateProviderIdentity();
    }
  }

  handleOnClick = () => {
    const { onClick, providerIdentity } = this.props;
    onClick && onClick(providerIdentity);
  };

  getDescription = () => {
    const { provider, providerIdentity, displayType } = this.props;
    const providerName = provider.get('displayName');
    const username = providerIdentity.get('profileDisplayName');
    const defaultDescription = `${providerName} via ${username}`;

    if (displayType === displayTypes.EMAIL) {
      return providerIdentity.getIn(['profileEmails', 0]) || defaultDescription;
    }

    return `${providerName} via ${username}`;
  };

  render() {
    const { children, displayType, needsValidate, providerIdentity, onClick, provider } = this.props;

    const description = this.getDescription();
    const itemProps = {
      className: classnames('provider-identity-item', {
        media: !displayType === displayTypes.AVATAR_ONLY,
      }),
      title: description,
      onClick: onClick && this.handleOnClick,
    };

    if (displayType === displayTypes.AVATAR_ONLY) {
      return (
        <Item {...itemProps}>
          <Avatar
            image={providerIdentity.getIn(['profileAvatars', 0])}
            name={providerIdentity.get('profileDisplayName')}
            size="md"
          />
          {needsValidate &&
            (providerIdentity.get('state') === providerIdentityTypes.STATE.DISABLED ||
              providerIdentity.get('state') === providerIdentityTypes.STATE.INCAPACITATED) && (
              <StyledIcon name="exclamation-circle" kind={Icon.KINDS.SOLID} />
            )}
        </Item>
      );
    }

    return (
      <Item {...itemProps}>
        <div className="media-left media-middle">
          <ToolIconsWrapper>
            <ProviderAvatar
              providerName={provider.get('name')}
              username={providerIdentity.get('profileDisplayName')}
              image={providerIdentity.getIn(['profileAvatars', 0])}
            />
          </ToolIconsWrapper>
        </div>
        <div className="provider-identity-item__label media-body">
          <div className="provider-identity-item__description">
            {description} {children}
          </div>
        </div>
      </Item>
    );
  }
}

const mapStateToProps = (state, { providerIdentity }) => ({
  provider: getProviderByName(state, providerIdentity.get('providerName')),
});

const mapDispatchToProps = (dispatch, { providerIdentity }) => ({
  validateProviderIdentity: () => {
    dispatch(providerIdentityActions.validateProviderIdentity(providerIdentity.get('_id')));
  },
});

export const ProviderIdentityItem = connect(mapStateToProps, mapDispatchToProps)(ProviderIdentityItemComponent);
