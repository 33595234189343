import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SearchInput, tokens, Box } from '@unitoio/mosaic';

const SearchInputWrapper = styled(Box)`
  max-width: 35%;
`;

export const SyncListSearchInput = ({ searchString = '', onChange }) => (
  <SearchInputWrapper m={[tokens.spacing.s4, 0]}>
    <SearchInput
      onChange={(e) => {
        onChange(e.target?.value);
      }}
      value={searchString}
      placeholder="Search for a flow"
    />
  </SearchInputWrapper>
);

SyncListSearchInput.propTypes = {
  searchString: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
