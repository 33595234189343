import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Map, List } from 'immutable';

import { Bold, NewAlert, NewAlertLevel, NewTypography } from '@unitoio/mosaic';

import { color } from 'theme';
import * as authUtils from '~/utils/auth';
import { capitalize } from '~/utils/capitalize';
import { Href } from '~/components/Href/Href';
import { TextInput } from '~/components/TextInput/TextInput';

function getIconProps({ isTested, error }) {
  if (!isTested) {
    return undefined;
  }

  if (error) {
    return {
      name: 'exclamation-circle',
      color: color.brand.errorLight,
    };
  }

  return {
    name: 'check-circle',
    color: color.brand.success,
  };
}

const getErrorMessage = (error, provider) => {
  if (!error) {
    return null;
  }

  if (error === 'UNKNOWN') {
    return (
      <NewAlert
        level={NewAlertLevel.ERROR}
        title={`We couldn't connect with ${provider.get('displayName')}`}
        message={
          <NewTypography>
            Retry clicking <Bold>Continue</Bold>. If the issue occurs again, reach out to support@unito.io
          </NewTypography>
        }
      />
    );
  }

  return (
    <NewAlert
      level={NewAlertLevel.ERROR}
      title={`We couldn't reach your ${provider.get('displayName')}`}
      message={
        <NewTypography>
          It looks like the URL you provided is incorrect. Make sure that you entered it correctly, or find out more
          from the{' '}
          <Href href="https://guide.unito.io/troubleshooting-an-unreachable-domain-error">troubleshooting article</Href>
          in the Unito Guide.
        </NewTypography>
      }
    />
  );
};

export function AuthDomain({ provider, isTested, error }) {
  const iconProps = getIconProps({ isTested, error });
  const disabled = isTested && !error;
  const connectionParameters = authUtils.getProviderConnectionParameters(provider);
  const domainField = connectionParameters.get('fields').find((param) => param.get('fieldName') === 'domain');
  const helpText = !connectionParameters.get('allowedBaseDomain', List()).isEmpty()
    ? `${domainField.get('help')}. Allowed domains: ${connectionParameters.get('allowedBaseDomain').join(', ')}`
    : domainField.get('help');

  return (
    <div className="auth-domain">
      <Field
        name={domainField.get('fieldName')}
        component={TextInput}
        props={{
          disabled,
          iconProps,
          label: (
            <Fragment>
              {!isTested ? 'Enter your' : null} {capitalize(domainField.get('label'))} {!isTested ? 'below' : null}
            </Fragment>
          ),
          placeholder: domainField.get('placeholder'),
          helpText,
        }}
      />
      {getErrorMessage(error, provider)}
    </div>
  );
}

AuthDomain.propTypes = {
  isTested: PropTypes.bool.isRequired,
  provider: PropTypes.instanceOf(Map).isRequired,
  error: PropTypes.string,
};
