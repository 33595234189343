import { Map } from 'immutable';

export function normalizeEntitiesById(entities, removeEntityId = false) {
  let entitiesById = Map();
  entities.forEach((entity) => {
    const idKeyName = entity.has('id') ? 'id' : '_id';
    let payload = entity;
    if (removeEntityId) {
      const nonIdKeys = entity.keySeq().filter((key) => key !== idKeyName);
      payload = nonIdKeys.reduce((reducer, retainedKey) => reducer.set(retainedKey, entity.get(retainedKey)), Map());
    }
    entitiesById = entitiesById.set(entity.get(idKeyName), payload);
  });
  return entitiesById;
}
