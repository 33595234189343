import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box, tokens, Typography } from '@unitoio/mosaic';

import { getMetricsByOrganizationId } from 'reducers';

import HoursSavedImage from './images/hours_saved_calm_image.svg';

const PullRightBox = styled(Box)`
  margin-left: auto;
`;

export function HoursSavedCard() {
  // This components needs the fetching of the org metrics, through the useGetOrgMetrics hook, to be done prior
  // to loading this component. The reason being that the loading state is handle by the parents in all our use cases.
  const { organizationId } = useParams();
  const metrics = useSelector((state) => getMetricsByOrganizationId(state, organizationId));

  const hoursSaved = metrics.get('hoursSaved') || 0;

  if (hoursSaved <= 0) {
    return null;
  }

  return (
    <Box
      flexDirection={Box.flexDirection.ROW}
      p={[tokens.spacing.s3, tokens.spacing.s5]}
      borderColor={tokens.colors.content.neutral.n10}
      borderRadius={tokens.spacing.s4}
      backgroundColor={tokens.colors.background.branding.unito}
    >
      <Box flexDirection={Box.flexDirection.ROW} alignItems={Box.alignItems.BASELINE} p={[tokens.spacing.s5, 0]}>
        <Typography variant={Typography.variants.H2}>{hoursSaved}</Typography>&nbsp;
        <Typography variant={Typography.variants.BODY1}>hours saved in the past 30 days!</Typography>
      </Box>
      <PullRightBox>
        <img src={HoursSavedImage} alt="hours_saved_img" />
      </PullRightBox>
    </Box>
  );
}
