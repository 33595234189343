export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const REHYDRATE_AUTH_STATE_REQUEST = 'REHYDRATE_AUTH_STATE_REQUEST';
export const REHYDRATE_AUTH_STATE_SUCCESS = 'REHYDRATE_AUTH_STATE_SUCCESS';
export const REHYDRATE_AUTH_STATE_FAILURE = 'REHYDRATE_AUTH_STATE_FAILURE';
export const REHYDRATE_AUTH_TOKEN_SUCCESS = 'REHYDRATE_AUTH_TOKEN_SUCCESS';
export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';
export const RESET_USER_PASSWORD_CHANGE_REQUEST = 'RESET_USER_PASSWORD_CHANGE_REQUEST';
export const RESET_USER_PASSWORD_CHANGE_SUCCESS = 'RESET_USER_PASSWORD_CHANGE_SUCCESS';
export const RESET_USER_PASSWORD_CHANGE_FAILURE = 'RESET_USER_PASSWORD_CHANGE_FAILURE';
export const RESEND_CONFIRMATION_REQUEST = 'RESEND_CONFIRMATION_REQUEST';
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_FAILURE = 'RESEND_CONFIRMATION_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';
export const VALIDATE_DOMAIN_REQUEST = 'VALIDATE_DOMAIN_REQUEST';
export const VALIDATE_DOMAIN_SUCCESS = 'VALIDATE_DOMAIN_SUCCESS';
export const VALIDATE_DOMAIN_FAILURE = 'VALIDATE_DOMAIN_FAILURE';
export const TEST_CONNECTION_REQUEST = 'TEST_CONNECTION_REQUEST';
export const TEST_CONNECTION_SUCCESS = 'TEST_CONNECTION_SUCCESS';
export const TEST_CONNECTION_FAILURE = 'TEST_CONNECTION_FAILURE';
export const SET_AUTH_EMBED_DATA = 'SET_AUTH_EMBED_DATA';

export const POST_MESSAGE_TYPES = {
  PROVIDER_IDENTITY: 'PROVIDER_IDENTITY',
  ENCRYPTED_TOKEN: 'ENCRYPTED_TOKEN', // Used literally in maestro/api/resources/templates/exposeToken.pug
};

export const AUTH_ACTIONS = {
  SIGNUP: 'signup',
  LOGIN: 'login',
  CONNECT: 'connect',
  SSO: 'sso',
};

export const EMAIL_AUTH_STATES = {
  RESEND_CONFIRMATION: 'resendConfirmation',
  CONFIRM: 'confirm',
  CONFIRMED: 'confirmed',
};

export const RESET_PROCESSES = {
  RESET: 'reset',
  CHANGE_PASSWORD: 'changePassword',
};

const PROVIDERS_NAME = {
  EXCEL: 'excel',
  GOOGLE_SHEETS: 'googlesheets',
};

export const TROUBLESHOOTING_LINKS = {
  [PROVIDERS_NAME.GOOGLE_SHEETS]:
    'https://guide.unito.io/best-practices-and-troubleshooting-tips-for-unitos-google-sheets-integration',
  [PROVIDERS_NAME.EXCEL]:
    'https://guide.unito.io/en/articles/6458414-best-practices-and-troubleshooting-tips-for-unito-s-microsoft-excel-integration',
};
