export function isBrowserDoNotTrackEnabled() {
  const dntOnOldIEVersions = global.window.external?.msTrackingProtectionEnabled; // IE < 11
  if (dntOnOldIEVersions) {
    return dntOnOldIEVersions();
  }

  const dnt = global.window.doNotTrack || global.navigator.doNotTrack || global.navigator.msDoNotTrack;

  return ['1', 'yes'].includes(dnt);
}
