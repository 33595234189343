import { displayTypes } from '../components/PCDFieldsSelect';
import { getSyncDirection } from './form';

export function getDisplayTypeForRulesRowField(parentFieldId, isSetDefault, isAction) {
  if (parentFieldId) {
    return displayTypes.DEEP_FIELDS_ONLY;
  }

  if (isSetDefault && getSyncDirection() === 'both') {
    return displayTypes.REQUIRES_FIELD_VALUE;
  }

  if (isAction && isSetDefault) {
    return displayTypes.REQUIRES_FIELD_VALUE;
  }

  return displayTypes.FILTERS_ONLY;
}
