import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as providerIdentityTypes from '~/consts/providerIdentity';
import * as trackingTypes from '~/consts/tracking';
import { AuthWindowOpener } from '~/containers/authentication/AuthWindowOpener';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { NewProviderModal } from '~/components/NewProviderModal/NewProviderModal';
import { SelectInput } from '~/components/SelectInput/SelectInput';
import { ProviderIdentityItem } from '~/components/ProviderIdentityItem/ProviderIdentityItem';

import { AddProviderIdentityOption } from './AddProviderIdentityOption';
import { StateLabel } from './StateLabel';

const sortProviderIdentities = (providerIdentityA, providerIdentityB) =>
  providerIdentityA
    .get('providerName')
    .localeCompare(providerIdentityB.get('providerName'), 'en', { sensitivity: 'base' }) ||
  providerIdentityA
    .get('profileDisplayName')
    .localeCompare(providerIdentityB.get('profileDisplayName'), 'en', { sensitivity: 'base' });

export function ProviderIdentitiesSelect({
  allowedProviderIdentityIds = List(),
  disabled = false,
  isEdit = false,
  isLoading = false,
  isSideLocked = false,
  onOpenProviderIdentitiesSelect = () => null,
  onSuccessAddNewPI = () => null,
  providerId,
  providerIdentities,
  providerIdentityId,
}) {
  const [newPIModalIsOpen, setnewPIModalIsOpen] = useState(false);
  const trackEvent = useTrackEvent();
  const [isValidating, setIsValidating] = useState(false);

  function handleOnAddNewPI(addedProviderIdentity) {
    onSuccessAddNewPI && onSuccessAddNewPI();
    setnewPIModalIsOpen(false);

    const shouldChangePI = !isEdit || (isEdit && allowedProviderIdentityIds.includes(addedProviderIdentity._id));

    if (shouldChangePI) {
      providerIdentityId.input.onChange(addedProviderIdentity._id, addedProviderIdentity);
    }
  }

  function handleOnChange(selectedProviderIdentityId) {
    // When the tool and account are combined in 1 select, open the add new provider modal when clicking "connect new account"
    if (selectedProviderIdentityId === 'ADD_NEW_PROVIDER') {
      trackEvent(trackingTypes.ADD_CONNECTOR_ACTION, {
        action_name: 'clicked on add a new account',
      });
      setnewPIModalIsOpen(true);
      return;
    }

    if (providerIdentityId.input.value !== selectedProviderIdentityId) {
      providerIdentityId.input.onChange(selectedProviderIdentityId);
    }
  }

  function getOptions() {
    const providerIdentityOptions = providerIdentities
      .sort(sortProviderIdentities)
      .map((entity) => {
        const isDisabled =
          entity.get('state') === providerIdentityTypes.STATE.DISABLED ||
          entity.get('state') === providerIdentityTypes.STATE.INCAPACITATED;
        const hasNoAccess = (isEdit || isSideLocked) && !allowedProviderIdentityIds.includes(entity.get('_id'));

        return {
          value: entity.get('_id'),
          providerIdentity: entity,
          disabled: isDisabled || hasNoAccess,
        };
      })
      .toList();
    return [{ value: 'ADD_NEW_PROVIDER' }, ...providerIdentityOptions.toArray()];
  }

  function renderOption(option) {
    const { disabled: isOptionDisabled, value, providerIdentity = Map() } = option;

    if (value === 'ADD_NEW_PROVIDER') {
      return <AddProviderIdentityOption />;
    }

    return (
      <div>
        {isOptionDisabled && (
          <StateLabel
            isProviderIdentityAllowed={isEdit && allowedProviderIdentityIds.includes(value)}
            providerIdentity={providerIdentity}
          />
        )}
        <ProviderIdentityItem providerIdentity={providerIdentity} />
      </div>
    );
  }

  return (
    <>
      <NewProviderModal
        isOpen={newPIModalIsOpen}
        onRequestClose={() => setnewPIModalIsOpen(false)}
        onSuccess={(addedProviderIdentity) => handleOnAddNewPI(addedProviderIdentity)}
      />

      <AuthWindowOpener
        providerId={providerId.input.value}
        onSuccess={(addedProviderIdentity) => {
          handleOnAddNewPI(addedProviderIdentity);
          setIsValidating(false);
        }}
        onValidationStart={() => setIsValidating(true)}
      >
        {() => (
          <SelectInput
            clearable={false}
            disabled={disabled}
            input={{
              ...providerIdentityId.input,
              onChange: (selectedProviderIdentityId) => handleOnChange(selectedProviderIdentityId),
            }}
            // isLoading is not currently being used, might be deprecated
            isLoading={isLoading || isValidating}
            label="Account"
            meta={providerIdentityId.meta}
            onOpen={onOpenProviderIdentitiesSelect}
            options={getOptions()}
            optionRenderer={renderOption} // eslint-disable-line react/jsx-no-bind
            searchable={false}
            simpleValue
            valueRenderer={(option) => <ProviderIdentityItem providerIdentity={option.providerIdentity} />}
          />
        )}
      </AuthWindowOpener>
    </>
  );
}

ProviderIdentitiesSelect.propTypes = {
  allowedProviderIdentityIds: PropTypes.instanceOf(List),
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSideLocked: PropTypes.bool,
  onOpenProviderIdentitiesSelect: PropTypes.func,
  onSuccessAddNewPI: PropTypes.func,
  providerId: PropTypes.shape({
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
      onChange: PropTypes.func.isRequired,
    }),
    meta: PropTypes.shape({
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
      asyncError: PropTypes.string,
    }),
  }).isRequired,
  providerIdentities: PropTypes.instanceOf(Map).isRequired,
  providerIdentityId: PropTypes.shape({
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string,
    }),
    meta: PropTypes.shape({
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
      asyncError: PropTypes.string,
    }),
  }).isRequired,
};
