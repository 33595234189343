import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Map } from 'immutable';
import styled from 'styled-components';

import { Icon, Tooltip } from '@unitoio/mosaic';

import { color } from 'theme';
import { Button } from '~/components/Button/Button';
import { Checkbox } from '~/components/Checkbox/Checkbox';
import { ProviderIconById } from '~/components/ProviderIcon/ProviderIconById';
import { TableCell } from '~/components/Table/TableCell';
import { TableRow } from '~/components/Table/TableRow';
import { UserDisplay } from '~/components/UserDisplay/UserDisplay';

const Tool = styled.div`
  display: inline-block;
  border: 1px solid ${color.dark.whisper};
  border-radius: 50%;
  margin-left: 0.25rem;
  padding: 1px 6px;
  vertical-align: middle;
`;

const Visibility = styled.div`
  visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
`;

function getStatus(unitoIdentity) {
  if (unitoIdentity.get('isExcluded')) {
    return <Tooltip content="We will not sync any tasks where this user has collaborated.">excluded</Tooltip>;
  }

  return unitoIdentity.get('computedStatus') ?? null;
}

export const UnitoIdentityRow = ({ unitoIdentity, checked, onEdit, setChecked }) => {
  const providerIdentity = unitoIdentity.getIn(['providerIdentities', 0]);
  const [isHovered, setHovered] = useState(false);

  return (
    <TableRow
      key={unitoIdentity.get('_id')}
      isDisabled={unitoIdentity.get('isExcluded')}
      onMouseOver={() => setHovered(true)}
      onFocus={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onBlur={() => setHovered(false)}
    >
      <TableCell align="right" paddingSize="none">
        <Visibility $isVisible={isHovered || checked}>
          <Checkbox checked={checked} onChange={(value) => setChecked(!value)} />
        </Visibility>
      </TableCell>
      <TableCell>
        <UserDisplay
          avatar={providerIdentity.getIn(['profileAvatars', 0])}
          emails={
            unitoIdentity.get('providerIdentities').size === 1 ? providerIdentity.get('profileEmails') : undefined
          }
          displayName={providerIdentity.get('profileDisplayName')}
          onClick={() => onEdit(unitoIdentity.get('_id'))}
        />
      </TableCell>
      <TableCell>{getStatus(unitoIdentity)}</TableCell>
      <TableCell>
        {unitoIdentity.get('providerIdentities').map((pIdentity) => (
          <Tool key={pIdentity.get('_id')}>
            <ProviderIconById providerId={pIdentity.get('providerId')} size="small" />
          </Tool>
        ))}
      </TableCell>
      <TableCell align="right" paddingSize="none">
        <Button
          className={!isHovered ? 'invisible' : undefined}
          btnStyle="subtleLink"
          onClick={() => onEdit(unitoIdentity.get('_id'))}
          size="sm"
          name="edit"
        >
          <Icon name="pencil" kind={Icon.KINDS.SOLID} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

UnitoIdentityRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  unitoIdentity: PropTypes.instanceOf(Map).isRequired,
  onEdit: PropTypes.func.isRequired,
};
