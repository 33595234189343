/**
 * @module Select
 * A wrapper around the react-select component that works with redux-form
 * All props from react-select can be passed to this component
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import ReactSelect, { Creatable } from 'react-select';

import './Select.scss';

export class Select extends Component {
  static propTypes = {
    allowCreate: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    'data-test': PropTypes.string,
    'data-testid': PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    allowCreate: false,
    className: undefined,
    clearable: false,
    'data-test': undefined,
    'data-testid': undefined,
    name: undefined,
    onBlur: undefined,
    onChange: () => null,
    value: undefined,
  };

  handleOnBlur = () => {
    const { onBlur, value } = this.props;
    onBlur && onBlur(value);
  };

  render() {
    const {
      allowCreate,
      className,
      clearable,
      name,
      onChange,
      options,
      value,
      'data-test': dataTest,
      'data-testid': dataTestId,
      ...rest
    } = this.props;
    const selectClasses = classnames('select', className);

    if (allowCreate) {
      return (
        <div data-testid={dataTestId} data-test={dataTest}>
          <Creatable
            {...rest}
            className={selectClasses}
            closeOnSelect={!rest.multi}
            inputProps={{ name, id: name }}
            options={options}
            onBlur={this.handleOnBlur}
            onChange={onChange}
            value={value}
          />
        </div>
      );
    }

    return (
      <div data-testid={dataTestId} data-test={dataTest}>
        <ReactSelect
          {...rest}
          backspaceRemoves={rest.multi || (!rest.multi && clearable)}
          className={selectClasses}
          clearable={clearable}
          closeOnSelect={!rest.multi}
          deleteRemoves={rest.multi || (!rest.multi && clearable)}
          inputProps={{ name, id: name }}
          onBlur={this.handleOnBlur}
          onChange={(selected) => {
            onChange(selected);
            if (rest.onChanged) {
              rest.onChanged(selected);
            }
          }}
          options={options}
          value={value}
        />
      </div>
    );
  }
}
