import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

export class FeatureFlagVariant extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    if (React.isValidElement(children)) {
      return children;
    }

    return <Fragment>{children}</Fragment>;
  }
}
