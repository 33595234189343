import PropTypes from 'prop-types';
import React from 'react';

import { GoogleSheetStep } from './googlesheets/Step';
import { ExcelStep } from './excel/Step';

export const Step = ({ providerName, containerName, providerIdentityId, containerId, containerType, itemType }) => {
  switch (providerName) {
    case 'googlesheets':
      return (
        <GoogleSheetStep
          containerName={containerName}
          providerIdentityId={providerIdentityId}
          containerId={containerId}
          containerType={containerType}
          itemType={itemType}
        />
      );
    case 'excel':
      return (
        <ExcelStep
          containerName={containerName}
          providerIdentityId={providerIdentityId}
          containerId={containerId}
          containerType={containerType}
          itemType={itemType}
        />
      );
    default:
      return null;
  }
};

Step.propTypes = {
  providerName: PropTypes.string.isRequired,
  containerName: PropTypes.string.isRequired,
  providerIdentityId: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};
