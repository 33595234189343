import React from 'react';
import PropTypes from 'prop-types';

import { useQueryParams } from '~/hooks/useQueryParams';
import { Button } from '~/components/Button/Button';
import { Href } from '~/components/Href/Href';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../AuthTitleWrapper';
import { AuthOrderedList } from '../AuthOrderedList';
import { AuthImageWrapper } from '../AuthImageWrapper';
import emailVisibility from './jira-email-visibility.jpg';

export function AuthJiraEmailVisibility({
  authorizationType,
  error,
  history,
  match,
  onCancel,
  onSubmit,
  trackEvent,
  isEmbed = false,
}) {
  const { windowOpenerId } = useQueryParams();

  const handleSubmit = () => {
    trackEvent('email visibility onboarding');

    if (error) {
      onSubmit();
    }
  };

  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (Email Visibility)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          Before you connect, you’ll need to make your <strong>email address visible</strong> so that Unito can identify
          you.
        </p>
        <AuthImageWrapper>
          <img src={emailVisibility} alt="Profile and visibility" />
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            First, go to Jira’s{' '}
            <Href href="https://id.atlassian.com/manage-profile/profile-and-visibility">Profile and Visibility</Href>{' '}
            page
          </li>
          <li>
            Scroll down to the bottom to the <strong>Contact</strong> section
          </li>
          <li>
            Set the “Who can see this?” option to <strong>Anyone</strong>
          </li>
        </AuthOrderedList>
        {!isEmbed && (
          <AuthButtonsWrapper className="clearfix">
            <Button
              btnStyle="dark"
              to={!error ? `${match.url}/${authorizationType}/step1?windowOpenerId=${windowOpenerId}` : undefined}
              onClick={handleSubmit}
              type="href"
              pullRight
            >
              {error ? 'Authorize' : 'Continue'}
            </Button>
            <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
              Back
            </Button>
            <Button reverse btnStyle="dark" onClick={onCancel}>
              Cancel
            </Button>
          </AuthButtonsWrapper>
        )}
      </Section>
    </div>
  );
}

AuthJiraEmailVisibility.propTypes = {
  authorizationType: PropTypes.string.isRequired,
  error: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  isEmbed: PropTypes.bool,
};
