import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Typography, tokens } from '@unitoio/mosaic';

const Subtitle = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

export const PageHeader = ({ title, subtitle = '' }) => (
  <Box m={[tokens.spacing.s5, 0]}>
    <Typography variant="h2">{title}</Typography>
    {subtitle ? (
      <Box m={[tokens.spacing.s3, 0, 0, 0]}>
        <Subtitle variant="body1">{subtitle}</Subtitle>
      </Box>
    ) : null}
  </Box>
);

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
