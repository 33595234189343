import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box, Button, tokens, Typography } from '@unitoio/mosaic';

import * as organizationTypes from '~/consts/organizations';
import { InviteCoworkersModal } from '~/containers/InviteCoworkersModal/InviteCoworkersModal';
import { getSortedOrganizationMembersPlusInvites } from 'reducers';
import { Divider } from '~/components/Divider/Divider';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { SearchBox } from '~/components/SearchBox/SearchBox';
import { Table } from '~/components/Table/Table';
import { TableBody } from '~/components/Table/TableBody';
import { TableCellHead } from '~/components/Table/TableCellHead';
import { TableHead } from '~/components/Table/TableHead';
import { TableRow } from '~/components/Table/TableRow';
import { Can } from '~/components/Can/Can';

import { WorkspaceMemberItem } from './WorkspaceMemberItem';
import { useLoadResources } from './hooks/useLoadResources';

const P = styled(Typography)`
  flex-basis: 85%;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

function filterMembersBySearchString(members, searchString) {
  const lowerSearchString = searchString.toLowerCase().trim();
  return members.filter((member) => {
    if (member.has('user')) {
      const userEmail = member.getIn(['user', 'email'], '').toLowerCase();
      const fullName = member.getIn(['user', 'fullName'], '').toLowerCase();
      return userEmail.includes(lowerSearchString) || fullName.includes(lowerSearchString);
    }
    const inviteEmail = member.get('email').toLowerCase();
    return inviteEmail.includes(lowerSearchString);
  });
}

function isAdmin(member) {
  return member.getIn(['role', 'name']) === organizationTypes.ROLES.ADMIN;
}

const isMemberLastAdmin = (allMembers, member) => {
  const isMemberAdmin = isAdmin(member);
  const adminCount = allMembers.filter(isAdmin).size;
  return isMemberAdmin && adminCount === 1;
};

export const WorkspaceMembers = ({ organizationId }) => {
  const isLoading = useLoadResources(organizationId);
  const [searchString, setSearchString] = useState('');
  const allMembersAndInvites = useSelector((state) => getSortedOrganizationMembersPlusInvites(state, organizationId));
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);

  if (isLoading) {
    return <InlineLoading />;
  }

  const membersAndInvites = filterMembersBySearchString(allMembersAndInvites, searchString);
  return (
    <div className="workspace-members-container">
      <InviteCoworkersModal
        organizationId={organizationId}
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      />
      <Box alignItems="flex-start" justifyContent="space-between" m={[0, 0, tokens.spacing.s7]}>
        <P>
          Members can access your Unito workspace to create and edit workflows, while admins can also manage workspace
          settings.
        </P>
        <Can I="update" a="billing">
          <StyledButton size={Button.sizes.MD} onClick={() => setInviteModalOpen(true)} startIcon="plus">
            Invite members
          </StyledButton>
        </Can>
      </Box>
      <SearchBox
        onChange={(value) => setSearchString(value)}
        placeholder="Search members by name or email"
        value={searchString}
      />
      <Divider />
      {membersAndInvites.isEmpty() ? (
        <div className="text-center">
          No results
          <br />
          Sorry we couldn't find the member you were looking for.
        </div>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHead padding="0 1.5rem 0 4.5rem">Name</TableCellHead>
              <TableCellHead>Role</TableCellHead>
              <TableCellHead cellSize={2} />
            </TableRow>
          </TableHead>
          <TableBody>
            {membersAndInvites
              .map((member) => (
                <WorkspaceMemberItem
                  key={member.get('_id')}
                  isMemberAdmin={isAdmin(member)}
                  isMemberLastAdmin={isMemberLastAdmin(allMembersAndInvites, member)}
                  member={member}
                  organizationId={organizationId}
                />
              ))
              .toArray()}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

WorkspaceMembers.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
