import React from 'react';
import PropTypes from 'prop-types';

import { Box, tokens, NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

export const Level = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

export function BoxedAlert({ children, message, level = NewAlertLevel.ERROR }) {
  return (
    <Box m={[tokens.spacing.s3, 0, 0, 0]}>
      <Alert level={level} message={message ?? children} />
    </Box>
  );
}

BoxedAlert.propTypes = {
  message: PropTypes.string,
  level: PropTypes.oneOf(Object.values(NewAlertLevel)),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};
