import React from 'react';
import PropTypes from 'prop-types';

import { Box, NewButton, tokens } from '@unitoio/mosaic';

export const RestartMappingButton = ({ onRestartMapping }) => (
  <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s5, tokens.spacing.s0]}>
    <NewButton onClick={onRestartMapping}>Restart mapping</NewButton>
  </Box>
);

RestartMappingButton.propTypes = {
  onRestartMapping: PropTypes.func.isRequired,
};
