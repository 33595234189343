import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { Box, Icon, tokens } from '@unitoio/mosaic';
import * as fieldTypes from '~/consts/fields';

import { FieldValuesSelect } from '../FieldValuesSelect';
import { PrefixInputField } from './PrefixInputField';

const InputAction = styled(Box)`
  cursor: pointer;
`;

export const FieldMappingPrefixRow = ({
  field,
  index,
  keySelected = false,
  onPrefixChange,
  remove,
  side,
  fieldId,
  selectedPrefixes,
  defaultValue,
  placeholder,
}) => {
  const containerId = useWatch({ name: `${side}.containerId` });
  const providerIdentityId = useWatch({ name: `${side}.providerIdentityId` });
  const itemType = useWatch({ name: `${side}.itemType` });
  const containerType = useWatch({ name: `${side}.containerType` });
  return (
    <Box flexDirection="row" m={[tokens.spacing.s5, 0, 0, 0]}>
      <Box fullWidth>
        <FieldValuesSelect
          name={`${side}.prefixes.${index}.key`}
          kind={fieldTypes.KINDS.PCD_TYPED_FIELD}
          fieldId={fieldId}
          providerIdentityId={providerIdentityId}
          containerId={containerId}
          containerSide={side}
          value={field?.key || ''}
          placeholder={placeholder}
          onChange={(newKey) => {
            onPrefixChange(index, { value: field.value || defaultValue || '', key: newKey });
          }}
          isOptionDisabled={({ id }) => ({ disabled: selectedPrefixes.includes(id) })}
          size="md"
          itemType={itemType}
          containerType={containerType}
        />
      </Box>
      <Box fullWidth p={[0, 0, 0, tokens.spacing.s4]}>
        <PrefixInputField
          name={`${side}.prefixes.${index}.value`}
          value={field.value || defaultValue}
          onChange={(newValue) => {
            onPrefixChange(index, { value: newValue, key: field?.key || '' });
          }}
          disabled={!keySelected}
          id={`value-prefix-${index}`}
        />
      </Box>
      <Box p={[tokens.spacing.s2]}>
        <InputAction
          p={[tokens.spacing.s3]}
          flexDirection="row"
          role="button"
          onClick={() => {
            remove(index);
          }}
        >
          <Icon name="trash" kind={Icon.KINDS.SOLID} title="remove" />
        </InputAction>
      </Box>
    </Box>
  );
};
FieldMappingPrefixRow.propTypes = {
  index: PropTypes.number.isRequired,
  keySelected: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onPrefixChange: PropTypes.func.isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  field: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  remove: PropTypes.func.isRequired,
  selectedPrefixes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
