/**
 *  Creates a generator for containers tree
 *  @param {object} containerList - The array of containers
 *  @param {int} depth - The current depth of the container.
 *  @return Generator
 */

export function* containerGenerator(containerList, depth = 1, parentId = undefined) {
  for (const container of containerList) {
    yield { ...container, depth, parentId };
    if (container.children) {
      yield* containerGenerator(container.children, depth + 1, container.id);
    }
  }
}
