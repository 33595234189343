import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import { color } from 'theme';

const StyledContainerFlow = styled.div`
  height: ${(props) => props.$height}px;
  width: ${(props) => props.$width}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFlow = styled.div`
  align-items: center;
  background-color: ${(props) => linkTypes.LINK_STATUS_VALUES[props.$linkStatus].backgroundColor};
  border-radius: 50%;
  border: 2px solid ${(props) => linkTypes.LINK_STATUS_VALUES[props.$linkStatus].borderColor};
  box-sizing: border-box;
  display: flex;
  height: 3rem;
  justify-content: center;
  position: relative;
  width: 3rem;

  &:hover {
    box-shadow:
      0 5px 26px rgba(0, 0, 0, 0.12),
      0px 14px 21px rgba(0, 0, 0, 0.14);
    cursor: ${(props) => (props.$linkStatus === linkTypes.LINK_ACTIVITY_STATUS.LOADING ? 'not-allowed' : 'pointer')};
    pointer-events: ${(props) => props.$linkStatus === linkTypes.LINK_ACTIVITY_STATUS.LOADING && 'none'};
    transition: box-shadow 0.2s;
  }
`;

const TestModeDot = styled.div`
  background-color: ${color.content.message.info};
  border: 2px solid ${color.content.neutral.white};
  border-radius: 50%;
  box-sizing: border-box;
  height: 0.5rem;
  position: absolute;
  right: 0.125rem;
  top: 0.125rem;
  width: 0.5rem;
`;

export function Flow({
  isTestMode = false,
  linkStatus = linkTypes.LINK_ACTIVITY_STATUS.NEW,
  onMouseDown = () => {},
  onMouseUp = () => {},
  children,
  height,
  width,
}) {
  const { spin, iconColor, iconName } = linkTypes.LINK_STATUS_VALUES[linkStatus] || {};
  return (
    <StyledContainerFlow $height={height} $width={width}>
      <StyledFlow $linkStatus={linkStatus} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
        {isTestMode && linkStatus !== linkTypes.LINK_ACTIVITY_STATUS.NEW && <TestModeDot />}
        <Icon color={iconColor} fixedWidth name={iconName} spin={spin} />
        {children}
      </StyledFlow>
    </StyledContainerFlow>
  );
}

Flow.propTypes = {
  isTestMode: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  linkStatus: PropTypes.oneOf([...Object.values(linkTypes.LINK_ACTIVITY_STATUS), linkTypes.LINK_STATES.DRAFT]),
  children: PropTypes.node.isRequired,
};
