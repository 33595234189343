import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box } from '@unitoio/mosaic';

import * as appTypes from '~/consts/app';
import * as trackingTypes from '~/consts/tracking';
import { getOrganizationPartnerInfosAccountIds } from 'reducers';
import { Button } from '~/components/Button/Button';
import { Card } from '~/components/Card/Card';
import { Subheading } from '~/components/Subheading/Subheading';
import { Title } from '~/components/Title/Title';
import { UsefulLinks } from '~/components/UsefulLinks/UsefulLinks';
import taskSyncEmptyState from './task-sync-list-empty_state.svg';

const EmptyStateImg = styled.img`
  margin-bottom: 1.6rem;
  width: 250px;
  height: auto;
`;

export const TaskSyncEmptyState = ({ embedName, selectedOrganizationId, trackDashboardAction }) => {
  const trelloTeams = useSelector((state) =>
    getOrganizationPartnerInfosAccountIds(state, selectedOrganizationId, appTypes.EMBED.TRELLO),
  );

  const firstTrelloTeamId = trelloTeams.keySeq().first();

  return (
    <Box>
      <Card className="link-list text-center">
        <EmptyStateImg alt="" src={taskSyncEmptyState} />
        <Title type="h3">You don't have any Mirrors yet</Title>
        <Subheading>When you create your first Mirror, it will appear here.</Subheading>

        <Button
          type="href"
          btnStyle="dark"
          data-test="dashboard__btn--access-trello"
          href={`https://trello.com/w/${firstTrelloTeamId}`}
          onClick={() => trackDashboardAction(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.ACCESS_TRELLO)}
        >
          Access Trello
        </Button>
      </Card>

      <UsefulLinks
        trackEvent={trackDashboardAction}
        isEmbed={!!embedName}
        productName={appTypes.PRODUCT_NAMES.TASK_SYNC}
      />
    </Box>
  );
};

TaskSyncEmptyState.propTypes = {
  embedName: PropTypes.string.isRequired,
  selectedOrganizationId: PropTypes.string.isRequired,
  trackDashboardAction: PropTypes.func.isRequired,
};
