import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TableWrapper = styled.table`
  border-collapse: separate;
  border-spacing: 0 10px;
  line-height: 1.75;
  table-layout: ${(props) => props.$tableLayout};
  width: 100%;
`;

export const Table = ({ children, className, tableLayout = 'auto' }) => (
  <TableWrapper className={className} $tableLayout={tableLayout}>
    {children}
  </TableWrapper>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tableLayout: PropTypes.oneOf(['auto', 'fixed']),
};
