import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export function Collapse({ isCollapsed = false, children, ...rest }) {
  return <div className={classnames('collapse', 'clearfix', { in: !isCollapsed }, rest.className)}>{children}</div>;
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  isCollapsed: PropTypes.bool,
};
