import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Icon, Tooltip } from '@unitoio/mosaic';

import styled from 'styled-components';

const IconContainer = styled.span`
  font-size: 85%;
  display: inline-block;
  opacity: 0.8;
  pointer-events: auto;
  position: relative;

  &:hover {
    opacity: 1;
  }
`;

export class IconHoverTooltip extends Component {
  static propTypes = {
    icon: PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.string,
      kind: PropTypes.string,
      color: PropTypes.string,
      className: PropTypes.string,
    }),
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    children: PropTypes.node.isRequired,
    mouseEnterDelay: PropTypes.number,
    mouseLeaveDelay: PropTypes.number,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
  };

  static defaultProps = {
    icon: {},
    onBlur: () => null,
    onClick: () => null,
    onMouseOut: () => null,
    onMouseOver: () => null,
    mouseEnterDelay: 500,
    mouseLeaveDelay: 100,
    placement: 'right',
  };

  state = {
    show: false,
  };

  componentWillUnmount() {
    if (this.delayTimer) {
      window.clearTimeout(this.delayTimer);
    }
  }

  setTooltipVisible = (visibility) => {
    this.setState({
      show: visibility,
    });
  };

  handleIconMouseOver = () => {
    const { mouseEnterDelay } = this.props;
    this.delaySetTooltipVisible(true, mouseEnterDelay);
  };

  handleIconMouseOut = () => {
    const { mouseLeaveDelay } = this.props;
    this.delaySetTooltipVisible(false, mouseLeaveDelay);
  };

  handleTooltipMouseOver = () => {
    this.clearDelayTimer();
  };

  handleTooltipMouseOut = () => {
    const { mouseLeaveDelay } = this.props;
    this.delaySetTooltipVisible(false, mouseLeaveDelay);
  };

  clearDelayTimer() {
    if (this.delayTimer) {
      window.clearTimeout(this.delayTimer);
      this.delayTimer = null;
    }
  }

  delaySetTooltipVisible(visible, delay) {
    this.clearDelayTimer();
    if (delay) {
      this.delayTimer = window.setTimeout(() => {
        this.setTooltipVisible(visible);
        this.clearDelayTimer();
      }, delay);
    } else {
      this.setTooltipVisible(visible);
    }
  }

  render() {
    const { icon, placement, children, onClick } = this.props;
    const { show } = this.state;
    const onClickProps = {
      onClick,
      onKeyPress: onClick,
      role: onClick ? 'button' : undefined,
      tabIndex: onClick ? 0 : undefined,
    };

    const { className, color, name, size, kind, ...rest } = icon;

    return (
      <IconContainer className="icon-hover-tooltip" {...onClickProps}>
        <Tooltip
          placement={placement}
          content={children}
          forceHide={!show}
          onMouseOver={this.handleTooltipMouseOver}
          onFocus={this.handleTooltipMouseOver}
          onMouseOut={this.handleTooltipMouseOut}
          onBlur={this.handleTooltipMouseOut}
        >
          <Icon
            className={className}
            color={color}
            name={name || 'question-circle'}
            size={size}
            kind={kind || Icon.KINDS.SOLID}
            onMouseOver={this.handleIconMouseOver}
            onFocus={this.handleIconMouseOver}
            onMouseOut={this.handleIconMouseOut}
            onBlur={this.handleIconMouseOut}
            {...rest}
          />
        </Tooltip>
      </IconContainer>
    );
  }
}
