import React from 'react';
import PropTypes from 'prop-types';

import { Box, NewAlert, tokens } from '@unitoio/mosaic';

export const AutomappingError = ({ hasCustomFieldsError, hasFieldAssociationsError }) => {
  if (!hasCustomFieldsError && !hasFieldAssociationsError) {
    return null;
  }

  return (
    <Box m={[0, 0, tokens.spacing.s5, 0]} fullWidth>
      <NewAlert
        level="error"
        message="The mapping was interrupted, and fields couldn’t be automatically mapped. Click ‘Restart mapping’ below shorty."
        closable
      />
    </Box>
  );
};

AutomappingError.propTypes = {
  hasCustomFieldsError: PropTypes.bool.isRequired,
  hasFieldAssociationsError: PropTypes.bool.isRequired,
};
