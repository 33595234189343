import { useSelector } from 'react-redux';

import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { getProviderByName } from 'reducers';
import { useGetProviderNameBySide } from './useGetProviderNames';

export const useCanMergeSide = (side) => {
  const providerName = useGetProviderNameBySide(side);
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const primaryItemA = provider?.getIn(['capabilitiesV3', 'primaryItems', side === 'A' ? itemTypeA : itemTypeB]);
  return primaryItemA?.getIn(['item', 'canMerge'], false);
};
