import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Map } from 'immutable';
import { useRouteMatch } from 'react-router';
import { Switch } from 'react-router-dom';

import { Icon, tokens } from '@unitoio/mosaic';

import * as authActions from '~/actions/auth';
import * as inviteActions from '~/actions/invites';
import * as organizationActions from '~/actions/organizations';
import * as trackingActions from '~/actions/tracking';
import { TrackingFunnelContext } from '~/contexts';
import * as inviteTypes from '~/consts/invites';
import * as organizationTypes from '~/consts/organizations';
import * as trackingTypes from '~/consts/tracking';
import { getOrganizationRoles, getUserId } from 'reducers';
import { capitalize } from '~/utils/capitalize';
import { Dropdown, DropdownItem } from '~/components/Dropdown/Dropdown';
import { RoutedModal } from '~/components/Modal/RoutedModal';
import { TableCell } from '~/components/Table/TableCell';
import { TableRow } from '~/components/Table/TableRow';
import { UserDisplay } from '~/components/UserDisplay/UserDisplay';
import { FeatureFlag } from '~/components/FeatureFlag/FeatureFlag';
import { FeatureFlagVariant } from '~/components/FeatureFlag/FeatureFlagVariant';
import { Can } from '~/components/Can/Can';

import { WorkspaceMemberRemoveModal } from './WorkspaceMemberRemoveModal';

export const WorkspaceMemberItem = ({ organizationId, member, isMemberAdmin, isMemberLastAdmin }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [hasResentInvite, setHasResentInvite] = useState(false);
  const isCurrentUser = member.getIn(['user', '_id']) === useSelector((state) => getUserId(state));

  const resendInvite = useCallback(
    (inviteId) => {
      dispatch(inviteActions.resendInvite(inviteId));
      setHasResentInvite(true);
    },
    [dispatch, setHasResentInvite],
  );
  const cancelInvite = useCallback((inviteId) => dispatch(inviteActions.cancelInvite(inviteId)), [dispatch]);
  const roles = useSelector((state) => getOrganizationRoles(state, organizationId));

  // TODO Might be worth normalizing roles by ID
  const adminRoleId = roles
    .find((role) => role.get('name') === organizationTypes.ROLES.ADMIN, undefined, Map())
    .get('_id');
  const memberRoleId = roles
    .find((role) => role.get('name') === organizationTypes.ROLES.MEMBER, undefined, Map())
    .get('_id');

  const updateMemberRole = useCallback(
    async (roleId) => {
      const isNewRoleAdmin = adminRoleId === roleId;
      setIsLoading(true);
      await dispatch(organizationActions.updateOrganizationMemberRole(organizationId, member.get('_id'), roleId));
      if (isCurrentUser) {
        await dispatch(authActions.rehydrateAuthState(false));
      }
      setIsLoading(false);
      dispatch(
        trackingActions.trackEvent(trackingTypes.PEOPLE_EVENTS.ACTION_NAME, {
          action_name: isNewRoleAdmin
            ? trackingTypes.PEOPLE_EVENTS.ACTIONS.SWITCH_ROLE_TO_ADMIN
            : trackingTypes.PEOPLE_EVENTS.ACTIONS.SWITCH_ROLE_TO_MEMBER,
        }),
      );
    },
    [dispatch, organizationId, member, isCurrentUser, adminRoleId],
  );

  const isWorkspaceMember = !member.has('invitedBy');
  const email = isWorkspaceMember ? member.getIn(['user', 'email']) : member.get('email');

  const trackingValue = useMemo(() => ({ name: trackingTypes.FUNNELS.ORGANIZATION_REMOVE_MEMBER }), []);

  return (
    <Fragment>
      <Switch>
        <RoutedModal
          path={`${match.path}/remove-user/${member.get('_id')}`}
          size="medium"
          title="Remove user from workspace"
          render={(routeProps, closeModal) => (
            <TrackingFunnelContext.Provider value={trackingValue}>
              <WorkspaceMemberRemoveModal {...routeProps} member={member} closeModal={closeModal} />
            </TrackingFunnelContext.Provider>
          )}
        />
      </Switch>
      <TableRow dataTestId={email}>
        <TableCell paddingSize="md">
          <UserDisplay
            avatar={member.getIn(['user', 'avatarUrl'])}
            displayName={member.getIn(['user', 'fullName'])}
            emails={List([email])}
          />
        </TableCell>
        <TableCell paddingSize="md">
          {isWorkspaceMember
            ? capitalize(member.getIn(['role', 'name']), '')
            : inviteTypes.DISPLAY_TEXT_BY_STATE[member.get('state')]}
          {isCurrentUser && ' (You)'}
        </TableCell>
        <TableCell paddingSize="md" align="right">
          <Can I="update" a="billing">
            <Dropdown
              alignRight
              btnContent={
                <Icon
                  color={tokens.colors.content.secondary.default}
                  name={isLoading ? 'spinner' : 'ellipsis-v'}
                  kind={isLoading ? Icon.KINDS.SOLID : Icon.KINDS.REGULAR}
                  size="lg"
                  pulse={isLoading}
                />
              }
              disabled={isLoading}
            >
              {isWorkspaceMember ? (
                <Fragment>
                  <FeatureFlag name="PERMISSIONS">
                    <FeatureFlagVariant value={true}>
                      <DropdownItem
                        onClick={() => updateMemberRole(isMemberAdmin ? memberRoleId : adminRoleId)}
                        disabled={isMemberLastAdmin}
                      >
                        {isMemberAdmin ? 'Change to Member' : 'Promote to Admin'}
                      </DropdownItem>
                    </FeatureFlagVariant>
                  </FeatureFlag>
                  <FeatureFlag name="remove-members-feature">
                    <FeatureFlagVariant value={true}>
                      <DropdownItem
                        to={`${match.url}/remove-user/${member.get('_id')}`}
                        disabled={isMemberLastAdmin || isCurrentUser}
                      >
                        Remove from workspace
                      </DropdownItem>
                    </FeatureFlagVariant>
                  </FeatureFlag>
                </Fragment>
              ) : (
                member.get('state') === inviteTypes.STATES.PENDING && (
                  <Fragment>
                    <DropdownItem disabled={hasResentInvite} onClick={() => resendInvite(member.get('_id'))}>
                      Resend invite
                    </DropdownItem>
                    <DropdownItem onClick={() => cancelInvite(member.get('_id'))}>Cancel invite</DropdownItem>
                  </Fragment>
                )
              )}
            </Dropdown>
          </Can>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

WorkspaceMemberItem.propTypes = {
  isMemberAdmin: PropTypes.bool.isRequired,
  isMemberLastAdmin: PropTypes.bool.isRequired,
  member: PropTypes.instanceOf(Map).isRequired,
  organizationId: PropTypes.string.isRequired,
};
