import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@unitoio/mosaic';

import * as appTypes from '~/consts/app';
import { CreateSyncButton } from '~/containers/SyncsTabNav/CreateSyncButton';
import { UsefulLinks } from '~/components/UsefulLinks/UsefulLinks';
import { ContentEmptyState } from './ContentEmptyState';

export const ProjectSyncEmptyState = ({
  embedName,
  searchString = '',
  selectedOrganizationId,
  trackDashboardAction,
}) => {
  const isEmbed = !!embedName;
  return (
    <Box>
      {searchString ? (
        <ContentEmptyState searchString={searchString} />
      ) : (
        <ContentEmptyState
          content={
            <CreateSyncButton
              isEmbed={isEmbed}
              trackDashboardAction={trackDashboardAction}
              selectedOrganizationId={selectedOrganizationId}
            />
          }
        />
      )}

      {!searchString && (
        <UsefulLinks
          trackEvent={trackDashboardAction}
          isEmbed={isEmbed}
          productName={appTypes.PRODUCT_NAMES.MIRROR_SYNC}
        />
      )}
    </Box>
  );
};

ProjectSyncEmptyState.propTypes = {
  embedName: PropTypes.string.isRequired,
  selectedOrganizationId: PropTypes.string.isRequired,
  searchString: PropTypes.string,
  trackDashboardAction: PropTypes.func.isRequired,
};
