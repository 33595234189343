import PropTypes from 'prop-types';
import React from 'react';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import Color from 'color';

import { color } from 'theme';

const Anchor = styled.a`
  color: ${(props) => props['$data-color']};
  ${(props) => props.$underline && 'text-decoration: underline'};

  &:active,
  &:focus,
  &:hover {
    color: ${(props) => Color(props['$data-color']).darken(0.18).string()};
  }
`;

export function Href({
  href,
  linkStyle = 'link',
  onClick,
  target,
  to,
  borderRadius,
  underline,
  disabled = false,
  ...rest
}) {
  const className = classnames('href', rest.className, { disabled });
  const commonProps = {
    '$data-color': color.brand[linkStyle],
    $underline: underline,
    disabled,
    ...rest,
    className,
  };
  const handleOnClick = !disabled ? onClick : () => false;

  if (to && !disabled) {
    return (
      <Anchor
        as={ReactRouterLink}
        {...commonProps}
        target={target}
        to={to}
        onClick={handleOnClick}
        activeClassName="active"
      />
    );
  }

  return (
    <Anchor
      {...commonProps}
      target={target || '_blank'}
      rel="noopener noreferrer"
      href={!disabled ? href : undefined}
      onClick={handleOnClick}
    />
  );
}

Href.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string,
  linkStyle: PropTypes.oneOf([...Object.keys(color.brand), 'subtleLink']),
  onClick: PropTypes.func,
  target: PropTypes.string,
  to: PropTypes.string,
  borderRadius: PropTypes.string,
  underline: PropTypes.bool,
};
