import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useQueryParams } from '~/hooks/useQueryParams';
import * as draftActions from '~/actions/drafts';
import { SidebarIframe } from './SidebarIframe';
import { ModalIframe } from './ModalIframe';

/*
 * Parse the JWT token to extract the link id.
 *
 * FIXME:
 * This code is currently run on every render because using an effect break the tests.
 * In the tests, for an unknown reason, the component is rendered twice
 * and the effect is only ran once.
 */
function fetchLinkId(jwtToken) {
  let linkId;

  // This block handles:
  //
  // * The JWT token is not present in the query params.
  // * The JWT token is malformed.
  // * The linkId is not present in the JWT token payload.
  try {
    linkId = JSON.parse(atob(jwtToken.split('.')[1])).linkId.toString();
  } catch {
    // NOOP
  }

  return linkId;
}

export const ActivateFlow = () => {
  const { jwtToken, addonType } = useQueryParams();
  const linkId = fetchLinkId(jwtToken);
  const [isActivating, setIsActivating] = useState(!!linkId);
  const [activationFailed, setActivationFailed] = useState(!linkId);
  const dispatch = useDispatch();

  // At the moment, we don't determine client-side that the JWT token is expired to display a
  // "your token is expired, click here to manually activate your link". Instead, we always
  // call Maestro to activate the link, and if the JWT token is expired, the operation will fail.
  //
  // Calling Maestro in all scenarios is desirable because the service will tell us if we can
  // trust the JWT token.

  useEffect(() => {
    const activate = async () => {
      if (!activationFailed) {
        try {
          await dispatch(draftActions.activateOneClick(jwtToken));
        } catch {
          setActivationFailed(true);
        } finally {
          setIsActivating(false);
        }
      }
    };

    activate();
  }, [dispatch, activationFailed, jwtToken]);

  return (
    <>
      {addonType === 'sidebar' ? (
        <SidebarIframe isActivating={isActivating} activationFailed={activationFailed} linkId={linkId} />
      ) : (
        <ModalIframe isActivating={isActivating} activationFailed={activationFailed} linkId={linkId} />
      )}
    </>
  );
};
