import { useWatch } from 'react-hook-form';

import { useGetProviderExtraStep } from './useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from './useHasMissingExtraStepAction';

export function useIsWorkItemStepMissingConfig() {
  const providerIdentityIdA = useWatch({ name: 'A.providerIdentityId' });
  const providerIdentityIdB = useWatch({ name: 'B.providerIdentityId' });
  const providerNameA = useWatch({ name: 'A.providerName' });
  const providerNameB = useWatch({ name: 'B.providerName' });
  const containerIdA = useWatch({ name: 'A.containerId' });
  const containerIdB = useWatch({ name: 'B.containerId' });

  const providerAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerBExtraStep = useGetProviderExtraStep(providerNameB);

  const containerAExtraConfigDetails = useHasMissingExtraStepAction(
    providerAExtraStep,
    providerNameA,
    providerIdentityIdA,
    containerIdA,
  );
  const containerBExtraConfigDetails = useHasMissingExtraStepAction(
    providerBExtraStep,
    providerNameB,
    providerIdentityIdB,
    containerIdB,
  );

  return !!containerAExtraConfigDetails || !!containerBExtraConfigDetails;
}
