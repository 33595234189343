import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import { Icon, tokens, NewTypography, NewButton } from '@unitoio/mosaic';

import { Href } from '~/components/Href/Href';
import './Dropdown.scss';

const AlignContent = styled.span`
  display: flex;
  align-items: center;
`;

export const DropdownDivider = () => <li role="separator" className="divider" />;

export const DropdownItem = ({ children, href, onClick, to, ...rest }) => {
  const needsHref = to || href || onClick;

  if (needsHref) {
    return (
      <li className={classnames({ disabled: rest.disabled })}>
        <Href
          {...rest}
          to={to}
          href={href}
          data-test={rest['data-test']}
          onClick={!rest.disabled ? onClick : undefined}
        >
          {children}
        </Href>
      </li>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <li {...rest} className={classnames('dropdown-header', { disabled: rest.disabled })} data-test={rest['data-test']}>
      {' '}
      {children}
    </li>
  );
};

DropdownItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

const Subtitle = styled.div`
  color: ${tokens.colors.content.neutral.n30};
  font-size: ${tokens.fontSize.f7};
`;

const ChevronStyle = styled.span`
  font-size: ${tokens.fontSize.f7};
`;

const StyledIcon = styled(Icon)`
  margin-left: -${tokens.spacing.s4};
`;

export const DropdownEllipsisButton = (
  <NewButton icon={<Icon name="ellipsis-v" size="lg" kind={Icon.KINDS.SOLID} />} title="open dropdown" type="text" />
);

export class DropdownHeader extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    subtitle: PropTypes.node,
    subtitleOnMouseOver: PropTypes.node,
    title: PropTypes.node,
  };

  static defaultProps = {
    children: undefined,
    onClick: undefined,
    subtitle: undefined,
    subtitleOnMouseOver: undefined,
    title: undefined,
  };

  state = {
    isItemHovered: false,
  };

  handleMouseOver = () => this.setState({ isItemHovered: true });

  handleMouseOut = () => this.setState({ isItemHovered: false });

  render() {
    const { children, subtitle, subtitleOnMouseOver, title, onClick } = this.props;
    const { isItemHovered } = this.state;

    const MediaElement = children ? Href : 'div';
    const classNames = classnames({ 'dropdown-header': !children });
    const renderedSubtitle = subtitleOnMouseOver && isItemHovered ? subtitleOnMouseOver : subtitle;

    return (
      <li
        className={classNames}
        onBlur={this.handleMouseOut}
        onClick={onClick}
        onFocus={this.handleMouseOver}
        onKeyPress={onClick}
        onMouseOut={this.handleMouseOut}
        onKeyUp={this.handleMouseOut}
        onMouseOver={this.handleMouseOver}
        onKeyDown={this.handleMouseOver}
        role="menuitem"
      >
        <MediaElement className="media">
          <div className="media-body">
            <Subtitle>{renderedSubtitle}</Subtitle>
            <strong>{title}</strong>
          </div>
          {children && (
            <div className="media-right media-middle">
              <div className="media-object">
                <ChevronStyle>
                  <Icon name="chevron-right" kind={Icon.KINDS.SOLID} title="open" />
                </ChevronStyle>
              </div>
            </div>
          )}
        </MediaElement>
      </li>
    );
  }
}

export const Dropdown = onClickOutside(
  class Dropdown extends Component {
    static propTypes = {
      'data-test': PropTypes.string,
      alignRight: PropTypes.bool,
      btnContent: PropTypes.node,
      button: PropTypes.element,
      children: PropTypes.node.isRequired,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      isOpen: PropTypes.bool,
      onToggle: PropTypes.func,
      tag: PropTypes.string,
      trackEventOnBack: PropTypes.func,
      trackEventOnOpen: PropTypes.func,
    };

    static defaultProps = {
      'data-test': undefined,
      alignRight: false,
      btnContent: <Icon name="chevron-down" kind={Icon.KINDS.SOLID} title="open" />,
      button: undefined,
      className: undefined,
      disabled: false,
      isOpen: undefined,
      onToggle: undefined,
      tag: 'div',
      trackEventOnBack: undefined,
      trackEventOnOpen: undefined,
    };

    state = {
      activeChild: null,
      isOpen: false,
    };

    isActive = () => {
      const { isOpen } = this.props;
      const { isOpen: isOpenState } = this.state;
      if (isOpen !== undefined) {
        return isOpen;
      }

      return isOpenState;
    };

    handleClickOutside = () => {
      this.close();
    };

    open = () => {
      const { disabled } = this.props;
      if (disabled) {
        return;
      }

      this.setState({ isOpen: true });
    };

    close = () => {
      this.setState({ isOpen: false, activeChild: null });
    };

    toggle = () => {
      const { disabled, trackEventOnOpen, onToggle } = this.props;
      if (disabled) {
        return;
      }

      onToggle && onToggle();

      const isOpen = this.isActive();
      if (isOpen) {
        this.close();
        return;
      }

      if (trackEventOnOpen) {
        trackEventOnOpen();
      }

      this.open();
    };

    renderSubDropdown = (children) => {
      const { activeChild } = this.state;
      const dropdownParent = children[activeChild];
      const { trackEventOnBack, subtitle } = dropdownParent.props;

      const backButton = (
        <DropdownItem
          key={`${dropdownParent.key}-back`}
          onClick={() => {
            if (trackEventOnBack) {
              trackEventOnBack();
            }
            this.setState({ activeChild: null });
          }}
          style={{ display: 'flex', 'flex-direction': 'row', alignItems: 'baseline', flexWrap: 'nowrap' }}
        >
          <ChevronStyle>
            <StyledIcon name="chevron-left" kind={Icon.KINDS.SOLID} title="back" />
          </ChevronStyle>

          <NewTypography.Text strong style={{ fontSize: 'medium' }}>
            {subtitle}
          </NewTypography.Text>
        </DropdownItem>
      );

      const dropdown = dropdownParent.props.children;
      return [backButton, ...dropdown.props.children];
    };

    renderChildren = () => {
      const { children } = this.props;
      const { activeChild } = this.state;

      // Remove children that are not dropdown items
      const childrenArray = React.Children.toArray(children).filter(
        (child) => ![null, false, undefined].includes(child),
      );

      if (activeChild) {
        return this.renderSubDropdown(childrenArray);
      }

      return childrenArray.map((child, index) => {
        const hasDropdownChild = child.type === DropdownHeader && React.Children.count(child.props.children) === 1;

        const hasOnClick = child.props.onClick || hasDropdownChild;
        return React.cloneElement(child, {
          onClick: hasOnClick
            ? (event) => {
                child.props.onClick && child.props.onClick(event);
                if (hasDropdownChild) {
                  this.setState({ activeChild: index });
                } else {
                  this.toggle();
                }
              }
            : undefined,
        });
      });
    };

    render() {
      const { alignRight, btnContent, button, className, tag, 'data-test': dataTest } = this.props;
      const dropdownClasses = classnames('dropdown', className, {
        open: this.isActive(),
      });
      const dropdownListClasses = classnames('dropdown-menu', { 'dropdown-menu-right': alignRight });
      const buttonProps = {
        onClick: this.toggle,
        onKeyDown: this.toggle,
        type: 'menu',
      };
      const Element = tag;

      return (
        <Element data-test={dataTest} className={dropdownClasses} role="menu">
          {button ? (
            <AlignContent>{React.cloneElement(button, buttonProps)}</AlignContent>
          ) : (
            <Href {...buttonProps} tabIndex={0} title="More Options" className="dropdown__btn" data-test={dataTest}>
              <AlignContent>{btnContent}</AlignContent>
            </Href>
          )}
          <ul className={dropdownListClasses}>{this.renderChildren()}</ul>
        </Element>
      );
    }
  },
);
