import PropTypes from 'prop-types';
import React from 'react';

import { List, NewButton, NewTypography, Icon } from '@unitoio/mosaic';

import { getSortedProviderNames } from '~/utils/getSortedProviderNames';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import { AppcuesLink } from '~/components/AppcuesLink/AppcuesLink';

export const Resources = ({ title = 'Resources', items, providerNameA, providerNameB }) => {
  const trackEvent = useTrackEvent({
    action_name: 'clicked on an article',
    selected_tool_names: getSortedProviderNames(providerNameA, providerNameB),
  });

  return (
    <List
      bordered={false}
      itemLayout="vertical"
      header={
        <NewTypography.Title style={{ margin: 0 }} level={4}>
          {title}
        </NewTypography.Title>
      }
      dataSource={items}
      size="small"
      renderItem={(item) => {
        const btnProps = {
          type: 'text',
          iconPosition: 'start',
          icon: <Icon name={item.iconName} kind={item.kind || 'solid'} fixedWidth />,
          onClick: () => {
            trackEvent(trackingTypes.EVENT_NAME.ACTION, {
              article_url: item.to ?? item.appcuesId,
            });
            item.onClick?.();
          },
        };

        return (
          <List.Item style={{ border: 'none', padding: '4px 0' }} data-testid={item.to || item.appcuesId}>
            {item.appcuesId ? (
              <AppcuesLink btnProps={btnProps} ctaId={item.appcuesId} onClick={btnProps.onClick}>
                {item.text}
              </AppcuesLink>
            ) : (
              <NewButton
                aria-label={item.text}
                href={!item.onClick ? item.to : undefined}
                target="_blank"
                {...btnProps}
              >
                {item.text}
              </NewButton>
            )}
          </List.Item>
        );
      }}
    />
  );
};

Resources.propTypes = {
  title: PropTypes.string,
  providerNameA: PropTypes.string,
  providerNameB: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      iconName: PropTypes.string,
      kind: PropTypes.string,
      text: PropTypes.string.isRequired,
      to: PropTypes.string,
    }),
  ).isRequired,
};
