import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import * as formTypes from '~/consts/form';
import * as formUtils from '~/utils/forms';
import { TextInput } from '~/components/TextInput/TextInput';
import { Button } from '~/components/Button/Button';

export const ResetPasswordField = ({ input, meta, disableSubmit = false, ...rest }) => {
  const isSubmitBtnDisabled = () => {
    const isEmailInvalid = !formUtils.isEmpty(input.value) && !formUtils.validateEmailAddress(input.value);
    return disableSubmit || formUtils.isEmpty(input.value) || isEmailInvalid;
  };

  return (
    <Fragment>
      <TextInput {...rest} input={input} meta={meta} label="Email" maxLength={formUtils.FIELD_MAX_LENGTH} errorOnBlur />
      <Button block disabled={isSubmitBtnDisabled()} type="submit" size="lg">
        Reset password
      </Button>
    </Fragment>
  );
};

ResetPasswordField.propTypes = {
  input: PropTypes.shape(formTypes.fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(formTypes.fieldMetaPropTypes).isRequired,
  disableSubmit: PropTypes.bool,
};
