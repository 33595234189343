import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { createTracker } from 'redux-segment';
import Immutable from 'immutable';

import { rootReducer } from '../reducers';

import { callAPIMiddleware } from './middlewares';
import { websocketsMiddleware } from './websockets';

export function configureStore(initialState, debug = false) {
  const tracker = createTracker();
  const middleware = applyMiddleware(thunk, callAPIMiddleware, tracker, websocketsMiddleware);

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: debug,
        traceLimit: 25,
        serialize: { immutable: Immutable },
        features: debug ? undefined : { autoPause: true, latency: 500, pause: true, export: true, test: true },
      })
    : compose;

  return createStore(rootReducer, initialState, composeEnhancers(middleware));
}

export const store = configureStore(undefined, process.env.REACT_APP_DEBUG);
