import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ProviderIcon, Avatar } from '@unitoio/mosaic';

const Container = styled.div`
  position: relative;
`;

const AbsoluteAvatar = styled.div`
  position: absolute;
  bottom: 1px;
  right: 1px;
`;

export const ProviderAvatar = ({ providerName, username, image }) => {
  if (!username && !image) {
    return <ProviderIcon name={providerName} />;
  }

  return (
    <Container>
      <ProviderIcon name={providerName} />
      <AbsoluteAvatar>
        <Avatar size="sm" name={username} image={image} />
      </AbsoluteAvatar>
    </Container>
  );
};

ProviderAvatar.propTypes = {
  providerName: PropTypes.string.isRequired,
  username: PropTypes.string,
  image: PropTypes.string,
};
