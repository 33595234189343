import React from 'react';
import styled from 'styled-components';

import { LoadingSpinner, tokens } from '@unitoio/mosaic';

import ExportGif from '../img/export.gif';

const Gif = styled.img`
  max-width: 80%;
  margin-bottom: ${tokens.spacing.s4};
`;

const Loading = styled(LoadingSpinner)`
  width: ${tokens.spacing.s8};
  height: ${tokens.spacing.s8};
`;

export const LoadingPage = () => (
  <>
    <Gif src={ExportGif} alt="export-trello-to-gsheets-gif" />
    <Loading />
  </>
);
