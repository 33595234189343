import React, { useState } from 'react';
import { useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getFeatureFlagValue } from 'reducers';
import * as trackingTypes from '~/consts/tracking';
import * as featureTypes from '~/consts/features';
import { LaunchFlow } from '../../pages/LaunchFlow/LaunchFlow';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';
import { useIsWorkItemStepMissingConfig } from '../../hooks/useIsWorkItemStepMissingConfig';
import { useAreFieldAssociationsCompleted } from '../../hooks/useAreFieldAssociationsCompleted';
import { GuideStep } from './GuideStep';
import ReviewIcon from '../../images/steps_icons/review.svg';

export const useStepInfo = () => {
  const isWorkItemStepMissingConfig = useIsWorkItemStepMissingConfig();
  const areFieldAssociationsCompleted = useAreFieldAssociationsCompleted();
  const isAddOnImprovementsFeatureFlagActive = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );
  const flowBuilderStep = isAddOnImprovementsFeatureFlagActive && areFieldAssociationsCompleted ? '6' : '5';
  let isStepActive;

  if (isAddOnImprovementsFeatureFlagActive) {
    isStepActive = areFieldAssociationsCompleted && !isWorkItemStepMissingConfig;
  } else {
    isStepActive = areFieldAssociationsCompleted;
  }

  return { isStepActive, flowBuilderStep };
};

export const GuideStepReview = () => {
  const { errors } = useFormState();
  const providerIdentityAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.providerIdentityId;
  const providerIdentityBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.providerIdentityId;
  const containerAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.containerId;
  const containerBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.containerId;
  const hasContainerErrors = providerIdentityAError || providerIdentityBError || containerAError || containerBError;
  const [isLaunchOpen, setIsLaunchOpen] = useState(false);

  const { isStepActive, flowBuilderStep } = useStepInfo();

  return hasContainerErrors ? (
    <div aria-label="Flow review and launch status">
      <LaunchFlow isOpen={isLaunchOpen} onCloseModal={() => setIsLaunchOpen(false)} />
      <GuideStep status="inaccessible">
        <GuideStepTitleWithTime title="5. Launch your flow" icon={ReviewIcon} time={1} />
      </GuideStep>
    </div>
  ) : (
    <div aria-label="Flow review and launch status">
      <LaunchFlow isOpen={isLaunchOpen} onCloseModal={() => setIsLaunchOpen(false)} />
      <GuideStep
        status={isStepActive ? 'current' : 'default'}
        actionLabel={isStepActive ? 'Continue' : undefined}
        actionOnClick={() => setIsLaunchOpen(true)}
      >
        <GuideStepTitleWithTime title={`${flowBuilderStep}. Launch your flow`} icon={ReviewIcon} time={1} />
      </GuideStep>
    </div>
  );
};
