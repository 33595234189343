import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FlowBuilderErrorContext } from '~/contexts';

import { useCanMergeSide } from './useCanMergeSide';

export function useHasMissingMergeSettings(enableAlert = true) {
  const { setError, clearErrors } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);

  const canMergeA = useCanMergeSide('A');
  const canMergeB = useCanMergeSide('B');

  const isMergeContext = canMergeA && canMergeB;

  const [truthSideA, mergeFieldsA, truthSideB, mergeFieldsB] = useWatch({
    name: ['A.truthSide', 'A.merge.fields', 'B.truthSide', 'B.merge.fields'],
  });

  const mergeFieldsMissingA = !mergeFieldsA?.size;
  const mergeFieldsMissingB = !mergeFieldsB?.size;

  const isMergeFieldsMissing = mergeFieldsMissingA || mergeFieldsMissingB;
  const isTruthSideInvalid = (!truthSideA && !truthSideB) || (truthSideA && truthSideB);

  useEffect(() => {
    if (enableAlert && isMergeContext) {
      if (isMergeFieldsMissing) {
        setError(`${pageName}.hasMissingMergeSettings`, { type: 'manual' });
      } else {
        clearErrors(`${pageName}.hasMissingMergeSettings`);
      }
    }
  }, [isMergeFieldsMissing, isMergeContext, enableAlert, setError, clearErrors, pageName]);

  if (!isMergeContext) {
    return false;
  }

  return isMergeFieldsMissing || isTruthSideInvalid;
}
