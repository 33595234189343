export const GET_PROVIDER_IDENTITY_REQUEST = 'GET_PROVIDER_IDENTITY_REQUEST';
export const GET_PROVIDER_IDENTITY_SUCCESS = 'GET_PROVIDER_IDENTITY_SUCCESS';
export const GET_PROVIDER_IDENTITY_FAILURE = 'GET_PROVIDER_IDENTITY_FAILURE';
export const GET_PROVIDER_IDENTITIES_REQUEST = 'GET_PROVIDER_IDENTITIES_REQUEST';
export const GET_PROVIDER_IDENTITIES_SUCCESS = 'GET_PROVIDER_IDENTITIES_SUCCESS';
export const GET_PROVIDER_IDENTITIES_FAILURE = 'GET_PROVIDER_IDENTITIES_FAILURE';
export const VALIDATE_PROVIDER_IDENTITY_REQUEST = 'VALIDATE_PROVIDER_IDENTITY_REQUEST';
export const VALIDATE_PROVIDER_IDENTITY_SUCCESS = 'VALIDATE_PROVIDER_IDENTITY_SUCCESS';
export const VALIDATE_PROVIDER_IDENTITY_FAILURE = 'VALIDATE_PROVIDER_IDENTITY_FAILURE';
export const DISCONNECT_PROVIDER_IDENTITY_REQUEST = 'DISCONNECT_PROVIDER_IDENTITY_REQUEST';
export const DISCONNECT_PROVIDER_IDENTITY_SUCCESS = 'DISCONNECT_PROVIDER_IDENTITY_SUCCESS';
export const DISCONNECT_PROVIDER_IDENTITY_FAILURE = 'DISCONNECT_PROVIDER_IDENTITY_FAILURE';
export const CONNECT_PROVIDER_IDENTITY_REQUEST = 'CONNECT_PROVIDER_IDENTITY_REQUEST';
export const CONNECT_PROVIDER_IDENTITY_SUCCESS = 'CONNECT_PROVIDER_IDENTITY_SUCCESS';
export const CONNECT_PROVIDER_IDENTITY_FAILURE = 'CONNECT_PROVIDER_IDENTITY_FAILURE';
export const GET_ALLOWED_PROVIDER_IDENTITIES_REQUEST = 'GET_ALLOWED_PROVIDER_IDENTITIES_REQUEST';
export const GET_ALLOWED_PROVIDER_IDENTITIES_SUCCESS = 'GET_ALLOWED_PROVIDER_IDENTITIES_SUCCESS';
export const GET_ALLOWED_PROVIDER_IDENTITIES_FAILURE = 'GET_ALLOWED_PROVIDER_IDENTITIES_FAILURE';
export const GET_AVAILABLE_INSTALLATIONS_REQUEST = 'GET_AVAILABLE_INSTALLATIONS_REQUEST';
export const GET_AVAILABLE_INSTALLATIONS_SUCCESS = 'GET_AVAILABLE_INSTALLATIONS_SUCCESS';
export const GET_AVAILABLE_INSTALLATIONS_FAILURE = 'GET_AVAILABLE_INSTALLATIONS_FAILURE';

export const STATE = {
  DISABLED: 'disabled',
  INCAPACITATED: 'incapacitated',
  ACTIVE: 'active',
};
