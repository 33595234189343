import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Box, Button, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { PageHeader } from '../components/PageHeader';

import ExportGif from '../img/export.gif';

const PageContainer = styled(Box)`
  max-width: 50%;
`;

export const OneClickExplanation = ({ onComplete }) => (
  <Box justifyContent="center">
    <PageContainer>
      <PageHeader title="Export & Sync" align="left" />
      <Typography variant={TypographyVariants.BODY1} align="left">
        Export a Trello board to Google Sheets in just a few clicks and get a dynamic report that's updated
        automatically.
      </Typography>
      <Box flexDirection="column">
        <Box flexDirection="column">
          <img src={ExportGif} alt="export-trello-to-gsheets-gif" />
        </Box>
        <Box p={[tokens.spacing.s4, 0, 0, 0]} justifyContent="flex-end">
          <Box>
            <Button onClick={onComplete} m={[tokens.spacing.s3, 0, 0, 0]}>
              Get started
            </Button>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  </Box>
);

OneClickExplanation.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
