export const fontFamily = {
  primary: 'Lato',
  monospace: 'Roboto Mono',
};

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 700,
};

export const lineHeight = {
  body: '18px',
  small: '15px',
  button: {
    xs: '12px',
    sm: '16px',
    md: '18px',
    lg: '22px',
  },
  // TODO: Move to Mimics
  lh1: '4rem',
  lh2: '2.5rem',
  lh3: '2rem',
  lh4: '1.5rem',
  lh5: '1rem',
};

export const fontSize = {
  h1: '34px',
  h2: '24px',
  h3: '20px',
  h4: '16px',
  subheading: '16px',
  body: '16px',
  body2: '20px',
  small: '14px',
  xsmall: '10px',
  button: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
  },
  // TODO: Move to Mimics
  f1: '2.5rem',
  f2: '2rem',
  f3: '1.5rem',
  f4: '1.25rem',
  f5: '1.125rem',
  f6: '1rem',
  f7: '0.875rem',
  f8: '0.75rem',
};

export const color = {
  brand: {
    primary: '#2ec16b',
    secondary: '#007dd2',
    accent: '#f05a38',
    dark: '#152a4c',
    error: '#E22644',
    errorLight: '#FFEEF1',
    success: '#3ABF72',
    warning: '#FE9012',
    warningLight: '#FFF4E7',
    link: '#007dd2',
    subtleLink: '#02060f',
    containerGray: '#D0DDE2',
    // new primary palette branding
    green: '#37d97a',
    keppel: '#3AAFA9',
    blue: '#194c89',
    info: '#c4e4ff',
    infoLight: '#E8F6FB',
    successLight: '#edf9f2',
    red: '#ed3151',
    yellow: '#ffd233',
    orange: '#ff6b0f',
    purple: '#5954ba',
    // new secondary palette branding
    darkBlue: '#152A4C',
    darkGreen: '#004139',
    darkPurple: '#343078',
    darkRed: '#AA0023',
    darkYellow: '#605700',
    hintBlue: '#eef7ff',
    lightBlue: '#288ad6',
    lighterBlue: '#c4e4ff',
    lightGreen: '#a5eec2',
    lightOrange: 'rgba(255, 152, 26, 0.5)',
    lightPurple: '#9c8ffd',
    lightRed: '#f26f86',
    lightYellow: '#ffe274',
  },
  dark: {
    primary: '#02060f',
    secondary: 'rgba(0, 0, 0, .54)',
    hint: 'rgba(0, 0, 0, .38)',
    subtle: 'rgba(0, 0, 0, .24)',
    whisper: 'rgba(0, 0, 0, .12)',
    quiet: 'rgba(0, 0, 0, .03)',
    midGray: '#6D808B',
    subtleGray: '#2D404B',
    dark5: '#D7DDE8',
  },
  light: {
    primary: 'rgba(255, 255, 255, 1)',
    secondary: 'rgba(255, 255, 255, .7)',
    hint: 'rgba(255, 255, 255, .3)',
    whisper: 'rgba(255, 255, 255, .12)',
    gray: '#fafafa',
  },
  text: {
    title: '#424B55',
  },
  // TODO: Move to mimics
  background: {
    global: {
      action: '#e8f6fb',
      gray: '#f3f6f8',
    },
    message: {
      info: '#eaf2ff',
      positive: '#e7f9ef',
      warning: '#fff4e7',
      destructive: '#ffeef1',
    },
    branding: {
      unito: '#e9f7f7',
      mirro: '#efeeff',
    },
  },
  content: {
    primary: {
      default: '#187d9d',
      hover: '#004263',
      disabled: '#bde2ed',
    },
    secondary: {
      default: '#152a4c',
      hover: '#03132d',
      disabled: '#d7dde8',
    },
    neutral: {
      text: '#02060f',
      placeholder: '#677888',
      border: '#9cabb9',
      disabled: '#d7dde8',
      white: '#ffffff',
    },
    message: {
      info: '#2577f1',
      positive: '#3abf72',
      warning: '#fe9012',
      destructive: '#e22644',
    },
    branding: {
      unito: '#3aafa9',
      mirror: '#5f5bba',
    },
  },
};

export const borderRadius = {
  small: '.5rem',
  default: '2px',
  double: '4px',
  large: '1rem',
  round: '2rem',
};

export const responsive = {
  // Responsive
  screenXsMin: '480px',
  screenSmMin: '768px',
  screenMdMin: '992px',
  screenLgMin: '1200px',
  screenXsMax: '767px',
  screenSmMax: '991px',
  screenMdMax: '1199px',
};
