import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PortWidget, DiagramEngine, NodeModel } from '@projectstorm/react-diagrams';
import { useDispatch, useSelector } from 'react-redux';
import { initialize } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';

import * as draftActions from '~/actions/drafts';
import * as workflowActions from '~/actions/workflows';
import {
  getLinkById,
  getLinkByProviderContainers,
  getDuplicatableLinks,
  getProviderByName,
  getContainersByProviderIdentityId,
  isLoadingContainer,
  getProviderCapabilitiesByIdV3,
} from 'reducers';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import { Flow } from '~/components/Flow/Flow';
import * as workflowTypes from '~/consts/workflows';

import { trackableHoC } from '../../../TrackableHoC/TrackableHoC';

const degradedLinkStates = [
  linkTypes.LINK_STATES.DISABLED,
  linkTypes.LINK_STATES.DISCONNECTED,
  linkTypes.LINK_STATES.INACCESSIBLE,
];

function getLinkStatus({ link, isLoading, siblingsAreLoading, hasDegradedContainer }) {
  if (isLoading || siblingsAreLoading) {
    return linkTypes.LINK_ACTIVITY_STATUS.LOADING;
  }

  if (link.isEmpty()) {
    return undefined;
  }

  if (link.get('state') === linkTypes.LINK_STATES.DRAFT) {
    return linkTypes.LINK_STATES.DRAFT;
  }

  // display sync as degraded if one of its containers is degraded
  // the sync itself will only become degraded after it's been synced once after the container degradation
  if (hasDegradedContainer) {
    return linkTypes.LINK_ACTIVITY_STATUS.DEGRADED;
  }

  if (!link.get('isAutoSync')) {
    return linkTypes.LINK_ACTIVITY_STATUS.PAUSED;
  }

  const syncActivityKirby = link.getIn(['syncStatus', 'activity']);
  const syncHealth = link.getIn(['syncStatus', 'healthStatus'], linkTypes.LINK_ACTIVITY_STATUS.HEALTHY);
  const syncActivityStatus = degradedLinkStates.includes(link.get('state'))
    ? linkTypes.LINK_ACTIVITY_STATUS.DEGRADED
    : undefined;
  const syncStatusKey = syncActivityStatus || syncActivityKirby || syncHealth;
  return syncStatusKey;
}

function useCreateMagicLinkFromExistingLinks(existingLink, node, dispatch, engine, match, setIsLoading) {
  useEffect(() => {
    if (existingLink && !node.getLinkId() && match.params.workflowId) {
      node.setLinkId(existingLink.get('_id'));
      node.setCreating(false);
      dispatch(
        workflowActions.saveWorkflow(match.params.workflowId, {
          diagramRepresentation: engine.getModel().serialize(),
        }),
      );
    }
    setIsLoading(false);
  }, [node, dispatch, engine, existingLink, match.params.workflowId, setIsLoading]);
}

export const FlowWidgetComponent = ({ engine, node, history, match, trackEvent }) => {
  const dispatch = useDispatch();
  const isPlayground = match.url.includes('playground');
  const { workflowId } = match.params;
  const [isLoading, setIsLoading] = useState(true);
  const existingLink = useSelector((state) => getLinkByProviderContainers(state, node.getSideA(), node.getSideB()));
  const existingSimilarLinks = useSelector((state) =>
    getDuplicatableLinks(state, node.getSideA(), node.getSideB(), match.params.workflowId),
  );
  const [workblockAId, workblockBId] = node.getSiblings();
  const workblockA = engine.model.getWorkBlockNodeByNodeId(workblockAId);
  const workblockB = engine.model.getWorkBlockNodeByNodeId(workblockBId);

  const currentProviderA = useSelector((state) => getProviderByName(state, workblockA?.getToolName()));
  const currentProviderB = useSelector((state) => getProviderByName(state, workblockB?.getToolName()));

  const capabilitiesA = useSelector((state) => getProviderCapabilitiesByIdV3(state, currentProviderA.get('_id')));
  const capabilitiesB = useSelector((state) => getProviderCapabilitiesByIdV3(state, currentProviderB.get('_id')));

  const fallbackItemTypeA = capabilitiesA.getIn(['item', 'names', 'native']);
  const fallbackContainerTypeA = capabilitiesA.get('containers', Map()).first()?.getIn(['names', 'native']);

  const fallbackItemTypeB = capabilitiesB.getIn(['item', 'names', 'native']);
  const fallbackContainerTypeB = capabilitiesB.get('containers', Map()).first()?.getIn(['names', 'native']);

  // soft migration for legacy workflows which don't have the itemType/containerType stored in their blocks yet.
  // We use the default item/container types of the respective providers to populate the blocks.
  // this was easier to implement then a script to update both blocks and diagramRepresentation of all existing workflows.
  const itemTypeA = workblockA.getItemType() ?? fallbackItemTypeA;
  const containerTypeA = workblockA.getContainerType() ?? fallbackContainerTypeA;

  const itemTypeB = workblockB.getItemType() ?? fallbackItemTypeB;
  const containerTypeB = workblockB.getContainerType() ?? fallbackContainerTypeB;

  const workblockAIsLoading = useSelector((state) => isLoadingContainer(state, workblockA.containerId));
  const workblockBIsLoading = useSelector((state) => isLoadingContainer(state, workblockB.containerId));
  const siblingsAreLoading = workblockAIsLoading || workblockBIsLoading;

  const containersFromA = useSelector((state) =>
    getContainersByProviderIdentityId(state, workblockA.getProviderIdentityId()),
  );
  const containersFromB = useSelector((state) =>
    getContainersByProviderIdentityId(state, workblockB.getProviderIdentityId()),
  );
  const hasDegradedContainer =
    !containersFromA.get(workblockA.containerId) || !containersFromB.get(workblockB.containerId);

  useCreateMagicLinkFromExistingLinks(existingLink, node, dispatch, engine, match, setIsLoading);

  const editFlow = () => {
    history.push(`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${node.getLinkId()}`);
  };

  const setSyncFormValues = () => {
    dispatch(
      initialize('syncForm', {
        A: {
          providerId: currentProviderA.get('_id'),
          providerIdentityId: workblockA.getProviderIdentityId(),
          containerId: workblockA.getContainerId(),
          itemType: itemTypeA,
          containerType: containerTypeA,
          existingContainer: true,
          filters: [],
          closedTasks: false,
          disabledFields: workblockA.isPlaceholder()
            ? ['providerId']
            : ['providerId', 'providerIdentityId', 'containerId'],
        },
        B: {
          providerId: currentProviderB.get('_id'),
          providerIdentityId: workblockB.getProviderIdentityId(),
          containerId: workblockB.getContainerId(),
          itemType: itemTypeB,
          containerType: containerTypeB,
          existingContainer: true,
          filters: [],
          closedTasks: false,
          disabledFields: workblockB.isPlaceholder()
            ? ['providerId']
            : ['providerId', 'providerIdentityId', 'containerId'],
        },
      }),
    );
  };

  async function handleFlowCreation() {
    // TODO get rid of default legacy logic when we adopt flow builder as the new flow wizard :)
    setSyncFormValues();

    if (existingSimilarLinks.size) {
      trackEvent(trackingTypes.WORKFLOW_FLOW_DUPLICATE.FLOW_START, {
        selected_tool_names: `${currentProviderA.get('name')},${currentProviderB.get('name')}`,
      });
      history.push(`${match.url}/flow/${node.getID()}/link/duplicate`);
      return;
    }

    const formData = {
      A: {
        providerName: workblockA.getToolName(),
        providerContainerId: workblockA.getProviderContainerId(),
        providerIdentityId: workblockA.getProviderIdentityId(),
        containerId: workblockA.getContainerId(),
        itemType: itemTypeA,
        containerType: containerTypeA,
        existingContainer: true,
      },
      B: {
        providerName: workblockB.getToolName(),
        providerContainerId: workblockB.getProviderContainerId(),
        providerIdentityId: workblockB.getProviderIdentityId(),
        containerId: workblockB.getContainerId(),
        itemType: itemTypeB,
        containerType: containerTypeB,
        existingContainer: true,
      },
      workflowId,
    };

    const { link } = await dispatch(draftActions.createDraft(formData));

    history.push(`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${link._id}`);
  }

  const handleMouseDown = () => {
    const model = engine.getModel();
    model.setNodeLastKnownPosition(node);
    model.fireEvent({ node }, workflowTypes.DIAGRAM_EVENTS.NODE_GRABBED);
  };

  const handleMouseUp = async (link) => {
    const model = engine.getModel();
    const isEmpty = link.isEmpty();

    if (model.hasNodePositionChanged(node) || node.getHasMoved()) {
      model.fireEvent({ node }, workflowTypes.DIAGRAM_EVENTS.NODE_DROPPED);
      node.setHasMoved(false);
      return null;
    }

    if (isPlayground) {
      history.push(`${match.url}/trial`);
      return null;
    }

    if (node.getLinkId()) {
      return editFlow();
    }

    if (siblingsAreLoading && isEmpty) {
      return null;
    }

    return handleFlowCreation();
  };

  const link = useSelector((state) => getLinkById(state, node.getLinkId()));
  return (
    <Flow
      className="flow-widget"
      onMouseDown={handleMouseDown}
      onMouseUp={() => handleMouseUp(link)}
      linkStatus={getLinkStatus({ link, isLoading, siblingsAreLoading, hasDegradedContainer })}
      height={3 * workflowTypes.GRID_SIZE}
      width={3 * workflowTypes.GRID_SIZE}
    >
      <PortWidget
        style={{
          width: '1px',
          height: '1px',
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
        }}
        port={node.getPort('FlowPort')}
        engine={engine}
      />
    </Flow>
  );
};

FlowWidgetComponent.propTypes = {
  engine: PropTypes.instanceOf(DiagramEngine).isRequired,
  node: PropTypes.instanceOf(NodeModel).isRequired,
  trackEvent: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.PropTypes.shape({
      workflowId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export const FlowWidget = withRouter(trackableHoC(FlowWidgetComponent));
