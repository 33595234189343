import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { tokens, Box, Typography } from '@unitoio/mosaic';

import { PCDFieldsSelect, displayTypes } from '../../../../components/PCDFieldsSelect';
import { IncompleteItemFieldAssociationsAlert } from '../../../../components/IncompleteItemFieldAssociationsAlert';
import { RulesRow } from '../../../../components/RulesRow';

// TODO: To be moved in its own files as they are shared with other components
const StretchedBox = styled(Box)`
  width: 100%;
`;

// TODO: To be moved in its own files as they are shared with other components
const RuleTag = styled.div`
  background: ${tokens.colors.background.neutral.grey};
  border: 1px solid ${tokens.colors.background.neutral.grey};
  font-size: ${tokens.fontSize.f7};
  margin: 0 ${tokens.spacing.s2};
  border-radius: ${tokens.spacing.s3};
  padding: ${tokens.spacing.s3};
  line-height: ${tokens.lineHeight.lh5};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MissingItemFieldAssociation = ({
  itemType = '',
  containerType = '',
  deepFilterItemFieldId = '',
  otherContainerSide = '',
  side = '',
  providerIdentityId = '',
  providerName = '',
  containerId = '',
}) => {
  const { setValue } = useFormContext();

  const onChange = (newValue) => {
    setValue(
      `${otherContainerSide}.itemFieldAssociations`,
      { [deepFilterItemFieldId]: newValue },
      { shouldDirty: true },
    );
  };

  return (
    <>
      <IncompleteItemFieldAssociationsAlert />
      <RulesRow hoverable={false} itemType={itemType} containerType={containerType}>
        <StretchedBox m={[0, tokens.spacing.s2, 0, 0]} flexDirection="row" alignItems="center">
          <Box m={[0, tokens.spacing.s2, 0, 0]}>
            <Typography variant="h4">AND</Typography>
          </Box>
          <RuleTag>Assign to {deepFilterItemFieldId}</RuleTag>
          <RuleTag>based on field</RuleTag>

          <PCDFieldsSelect
            name={`${otherContainerSide}.itemFieldAssociations.${deepFilterItemFieldId}`}
            isOptionDisabledHandler={() => false}
            containerSide={side}
            itemType={itemType}
            placeholder="Select a field"
            searchPlaceholder="Search for a field"
            displayType={displayTypes.STRING_CUSTOM_FIELDS_ONLY}
            providerIdentityId={providerIdentityId}
            providerName={providerName}
            containerId={containerId}
            onChange={onChange}
          />
        </StretchedBox>
      </RulesRow>
    </>
  );
};

MissingItemFieldAssociation.propTypes = {
  itemType: PropTypes.string,
  containerType: PropTypes.string,
  deepFilterItemFieldId: PropTypes.string,
  otherContainerSide: PropTypes.string,
  side: PropTypes.string,
  providerIdentityId: PropTypes.string,
  providerName: PropTypes.string,
  containerId: PropTypes.string,
};
