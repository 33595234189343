import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * A component to render templates.
 * How it works:
 * Suppose we wanna render the following template coming from connectors:
 * const customerTemplate = `The ${person||harry||30} is in {location||h2X3HC||canada}`.
 *
 * We can create the following function to render the special fields:
 * const renderCustomerField = (templateSpecialField) => {
 *   if templateSpecialField contains person, parse the string and return <Person name="harry" age={30} />
 *   if templateSpecialField contains location, parse the string and return <Location zip="h2x3hc" country="canada" />
 * }
 *
 * Then we can render the template doing:
 * <TemplateString fieldsRenderer={renderCustomerField} template={customerTemplate} />
 *
 * TemplateString will take care of detecting the special parts (things inside ${}) in the template,
 * passing them over to fieldsRenderer, and returning the special fields rendered along with the text
 * parts.
 *
 * @param {Function} fieldsRenderer A function in the form of (template) => <React.node />. It receives a template
 *                                  coming from connectors, and transforms it into something readable.
 */
export function TemplateString({ fieldsRenderer, template }) {
  /*
   * This regex splits a string based on the presence of a component to be rendered
   *
   * Ex: The following string:
   *   'A ${field.term.singular} may have been removed from ${container.displayName}'
   *
   * will result in the array:
   *   ['A ', '${field.term.singular}', ' may have been removed from ', '${container.displayName}]
   */
  if (!template) {
    return null;
  }

  const splitRegex = /(\${.*?})/;
  const splittedStrings = template.split(splitRegex);

  const fragments = splittedStrings.map((splitString, index) => {
    const isComponentRegex = /\${(.*?)}/;
    if (!isComponentRegex.test(splitString)) {
      return splitString;
    }

    // If splitString is '${xman||wolverine}', specialField is 'xman||wolverine'.
    const [, specialField] = isComponentRegex.exec(splitString);
    const key = `template-${index}`;
    return <Fragment key={key}>{fieldsRenderer(specialField)}</Fragment>;
  });

  return <Fragment>{fragments}</Fragment>;
}

TemplateString.propTypes = {
  fieldsRenderer: PropTypes.func.isRequired,
  template: PropTypes.string.isRequired,
};
