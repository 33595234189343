import React from 'react';
import styled from 'styled-components';

import { Box, Icon, Input, tokens, Typography } from '@unitoio/mosaic';

const PreviewLabel = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

export const PrefixInputField = ({ name, value, onChange = () => null, ...rest }) => (
  <Input
    {...rest}
    defaultValue={value}
    onBlur={(e) => onChange(e.target.value)}
    description={
      <Box flexDirection="row">
        <Box p={[0, tokens.spacing.s2, 0, 0]}>
          <PreviewLabel variant="body1">
            <Icon name="eye" /> Preview:
          </PreviewLabel>
        </Box>
        <Typography variant="body1">{value}1234 - My title</Typography>
      </Box>
    }
  />
);

PrefixInputField.propTypes = {
  ...Input.propTypes,
};
