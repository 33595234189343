import { DefaultLinkModel } from '@projectstorm/react-diagrams';

export class EdgeModel extends DefaultLinkModel {
  constructor(options) {
    super({
      type: 'advanced',
      width: 2,
      ...options,
    });
  }
}
