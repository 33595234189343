import React from 'react';

import { tokens, NewAlert, NewAlertLevel, NewTypography, NewButton } from '@unitoio/mosaic';

export const ErrorBlock = () => (
  <NewAlert
    level={NewAlertLevel.WARNING}
    title="There was an error getting this item's information"
    message={
      <NewTypography color={tokens.colors.content.neutral.n30}>
        Please reload the page, check your tool's status or reach out to support@unito.io
      </NewTypography>
    }
    action={
      <NewButton type="default" onClick={() => window.location.reload()}>
        Reload page
      </NewButton>
    }
  />
);
