import { childrenOfType } from 'airbnb-prop-types';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getFeatureFlagValue } from 'reducers';

import { FeatureFlagVariant } from './FeatureFlagVariant';

// Export the unconnected component so it's testable
export class FeatureFlagComponent extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired, // eslint-disable-line
    flagValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    children: childrenOfType(FeatureFlagVariant).isRequired,
  };

  static defaultProps = {
    defaultValue: null,
    flagValue: undefined,
  };

  isFlagActive = (childValue) => {
    const { defaultValue, flagValue } = this.props;

    // Show only when the variant is exactly the same as the current flag
    return (
      childValue === flagValue ||
      // Or show for any of the variants of the current flag when the value is true
      (childValue === true && !!flagValue) ||
      // Or show the defaultValue variant if the flagValue is undefined
      (flagValue === undefined && childValue === defaultValue)
    );
  };

  render() {
    const { children } = this.props;

    const [Variant = null] =
      React.Children.map(children, (child) => {
        if (this.isFlagActive(child.props.value)) {
          return child;
        }

        return undefined;
      }) || [];

    return Variant;
  }
}

const mapStateToProps = (state, ownProps) => ({
  flagValue: getFeatureFlagValue(state, ownProps.name, ownProps.organizationId),
});

export const FeatureFlag = connect(mapStateToProps)(FeatureFlagComponent);
