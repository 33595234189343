import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import * as trackingActions from '~/actions/tracking';
import * as organizationActions from '~/actions/organizations';
import { getOrganizationSendEmailReceiptChoice } from 'reducers';
import * as trackingTypes from '~/consts/tracking';
import * as organizationTypes from '~/consts/organizations';

import { CheckboxField } from '../../../../components/CheckboxField/CheckboxField';

const SendEmailReceiptFormComponent = reduxForm({
  form: organizationTypes.EDIT_ORG_SEND_EMAIL_RECEIPT_FORM,
})(
  class SendEmailReceiptFormInner extends Component {
    static propTypes = {
      currentOrgBillingEmail: PropTypes.string,
      handleSubmit: PropTypes.func.isRequired,
      onSubmit: PropTypes.func.isRequired,
      trackEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
      currentOrgBillingEmail: '',
    };

    renderCheckboxLabel = () => {
      const { currentOrgBillingEmail } = this.props;
      const radical = 'Send me a copy of my receipt by email';
      return currentOrgBillingEmail ? radical.concat(` (${currentOrgBillingEmail})`) : radical;
    };

    handleChange = (event, newValue, previousValue, fieldName) => {
      const { onSubmit, trackEvent } = this.props;
      trackEvent(trackingTypes.BILLING_EVENTS.ACTION_NAME, {
        action_name: newValue
          ? trackingTypes.BILLING_EVENTS.ACTIONS.ON_SEND_RECEIPT
          : trackingTypes.BILLING_EVENTS.ACTIONS.OFF_SEND_RECEIPT,
      });
      onSubmit({ [fieldName]: newValue });
    };

    render() {
      const { handleSubmit } = this.props;

      return (
        <form className="send-email-receipt-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <Field
              component={CheckboxField}
              name="sendEmailReceipt"
              id="send-email-receipt"
              label={this.renderCheckboxLabel()}
              onChange={this.handleChange}
            >
              Receive an email for my receipt
            </Field>
          </div>
        </form>
      );
    }
  },
);

const mapStateToProps = (state, ownProps) => {
  const orgSendEmailReceiptChoice = getOrganizationSendEmailReceiptChoice(state, ownProps.organizationId);
  return {
    initialValues: {
      sendEmailReceipt: orgSendEmailReceiptChoice,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (formValues) => {
    dispatch(organizationActions.patchOrganization(ownProps.organizationId, formValues));
  },
  trackEvent: (...params) => dispatch(trackingActions.trackEvent(...params)),
});

export const SendEmailReceiptForm = connect(mapStateToProps, mapDispatchToProps)(SendEmailReceiptFormComponent);
