import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Typography, tokens, Alert } from '@unitoio/mosaic';

import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import { Modal } from '~/components/Modal/Modal';
import { Href } from '~/components/Href/Href';

import Illustration from '../googlesheets/illustration.svg';

const BoldTypography = styled(Typography)`
  font-weight: ${tokens.fontWeight.fw7};
`;

// TODO: insert excel article here
const articleUrl = 'https://guide.unito.io/';

export const InstructionsModal = ({ isOpen, onCancel, onConfirm, containerName, containerUrl }) => {
  const trackEvent = useTrackEvent();

  const trackOnOpenSheet = () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on sheet link in add on instructions',
      selected_tool_name: 'excel',
    });
  };

  const trackOnOpenArticle = () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on article',
      article_url: articleUrl,
      selected_tool_name: 'excel',
    });
  };

  return (
    <Modal
      size="lg"
      confirmLabel="Done"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onRequestClose={onConfirm}
      title="Install and authorize Unito’s add-in for Microsoft Excel"
      useNewButton
      displayCloseButton
    >
      <Box m={[tokens.spacing.s6, 0, tokens.spacing.s4, 0]}>
        <BoldTypography variant="body1">
          1. Open the sheet{' '}
          <Href href={containerUrl} onClick={trackOnOpenSheet}>
            {containerName}
          </Href>
        </BoldTypography>
      </Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <BoldTypography variant="body1">2. Install the Unito add-in</BoldTypography>
        <ul>
          <li>
            <Typography variant="body1">
              Open Microsoft Office Add-ins via the menu Insert &gt; Add-ins &gt; Store
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Look for <b>Unito for Excel</b> and Install the add-in
            </Typography>
          </li>
        </ul>
      </Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <BoldTypography variant="body1">3. Follow the instructions in the add-in to setup the sheet</BoldTypography>
        </Box>
        <Alert size="sm">
          It is important that the add-in remains open or minimized while you're working on this sheet in order for
          Unito to track changes.
        </Alert>
      </Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <BoldTypography variant="body1">4. Come straight back to this page and hit Done</BoldTypography>
      </Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]} />
      <Box
        p={[tokens.spacing.s4]}
        m={[tokens.spacing.s5, 0, 0, 0]}
        alignItems={Box.alignItems.CENTER}
        backgroundColor={tokens.colors.background.neutral.grey}
        borderRadius={tokens.spacing.s3}
      >
        <Box m={[0, tokens.spacing.s4, 0, 0]}>
          <img src={Illustration} alt="hey-there" />
        </Box>
        <Typography variant="body1">
          Find all the details about the add-on installation in the article{' '}
          <Href href={articleUrl} onClick={trackOnOpenArticle} target="_blank" rel="noreferrer">
            how to add Unito to your Microsoft Excel workbook.
          </Href>{' '}
        </Typography>
      </Box>
    </Modal>
  );
};
InstructionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  containerName: PropTypes.string.isRequired,
  containerUrl: PropTypes.string.isRequired,
};
