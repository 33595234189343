import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FlowBuilderErrorContext } from '~/contexts';
import { getFeatureFlagValue } from '~/reducers';
import * as featureTypes from '~/consts/features';

export function useGetIncompleteDefaultValues() {
  const hasAccessToModernRules = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_RULES_PAGE),
  );

  const { setError, clearErrors, formState } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);
  const [actionsA, actionsB] = useWatch({ name: ['A.actions', 'B.actions'] });

  // we don't want to trigger an error when the user selects a field. There should only be an error if
  // a field was chosen, but no value was associated to it
  const incompleteActionsA =
    formState?.errors?.A?.actions || actionsA.find((action) => action.fieldId && !action.value);
  const incompleteActionsB =
    formState?.errors?.B?.actions || actionsB.find((action) => action.fieldId && !action.value);

  useEffect(() => {
    if (!hasAccessToModernRules) {
      return;
    }

    const errorName = `${pageName}.incompleteFieldValuesConfigurationSide`;

    if (incompleteActionsA || incompleteActionsB) {
      setError(errorName, { type: 'manual' });
    } else {
      clearErrors(errorName);
    }
  }, [clearErrors, incompleteActionsA, incompleteActionsB, pageName, setError, hasAccessToModernRules]);

  return [incompleteActionsA, incompleteActionsB];
}
