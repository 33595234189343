import React from 'react';
import PropTypes from 'prop-types';

import { TableCell } from './TableCell';

export function NoWrapHeader({ children }) {
  return (
    <TableCell>
      <span className="no-wrap">{children}</span>
    </TableCell>
  );
}

NoWrapHeader.propTypes = {
  children: PropTypes.node.isRequired,
};
