export const GET_WORKFLOWS_REQUEST = 'GET_WORKFLOWS_REQUEST';
export const GET_WORKFLOWS_SUCCESS = 'GET_WORKFLOWS_SUCCESS';
export const GET_WORKFLOWS_FAILURE = 'GET_WORKFLOWS_FAILURE';

export const GET_WORKFLOW_REQUEST = 'GET_WORKFLOW_REQUEST';
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FAILURE = 'GET_WORKFLOW_FAILURE';

export const PATCH_WORKFLOW_REQUEST = 'PATCH_WORKFLOW_REQUEST';
export const PATCH_WORKFLOW_SUCCESS = 'PATCH_WORKFLOW_SUCCESS';
export const PATCH_WORKFLOW_FAILURE = 'PATCH_WORKFLOW_FAILURE';

export const SAVE_PLAYGROUND_WORKFLOW = 'SAVE_PLAYGROUND_WORKFLOW';

export const CREATE_WORKFLOW_REQUEST = 'CREATE_WORKFLOW_REQUEST';
export const CREATE_WORKFLOW_SUCCESS = 'CREATE_WORKFLOW_SUCCESS';
export const CREATE_WORKFLOW_FAILURE = 'CREATE_WORKFLOW_FAILURE';

export const LOCAL_STORAGE_WORKFLOW_KEY = 'LOCAL_STORAGE_WORKFLOW_KEY';

export const ADD_BLOCK_REQUEST = 'ADD_BLOCK_REQUEST';
export const ADD_BLOCK_SUCCESS = 'ADD_BLOCK_SUCCESS';
export const ADD_BLOCK_FAILURE = 'ADD_BLOCK_FAILURE';

export const DELETE_BLOCK_REQUEST = 'DELETE_BLOCK_REQUEST';
export const DELETE_BLOCK_SUCCESS = 'DELETE_BLOCK_SUCCESS';
export const DELETE_BLOCK_FAILURE = 'DELETE_BLOCK_FAILURE';

export const REMOVE_FLOW_REQUEST = 'REMOVE_FLOW_REQUEST';
export const REMOVE_FLOW_SUCCESS = 'REMOVE_FLOW_SUCCESS';
export const REMOVE_FLOW_FAILURE = 'REMOVE_FLOW_FAILURE';

export const GET_PROVIDER_CONTAINERS_BY_WORKFLOW_REQUEST = 'GET_PROVIDER_CONTAINERS_BY_WORKFLOW_REQUEST';
export const GET_PROVIDER_CONTAINERS_BY_WORKFLOW_SUCCESS = 'GET_PROVIDER_CONTAINERS_BY_WORKFLOW_SUCCESS';
export const GET_PROVIDER_CONTAINERS_BY_WORKFLOW_FAILURE = 'GET_PROVIDER_CONTAINERS_BY_WORKFLOW_FAILURE';

export const GET_LINKS_BY_WORKFLOW_ID_REQUEST = 'GET_LINKS_BY_WORKFLOW_ID_REQUEST';
export const GET_LINKS_BY_WORKFLOW_ID_SUCCESS = 'GET_LINKS_BY_WORKFLOW_ID_SUCCESS';
export const GET_LINKS_BY_WORKFLOW_ID_FAILURE = 'GET_LINKS_BY_WORKFLOW_ID_FAILURE';

export const POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_REQUEST = 'POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_REQUEST';
export const POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_SUCCESS = 'POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_SUCCESS';
export const POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_FAILURE = 'POST_GENERATE_WORKFLOW_RESOURCES_ON_DELETE_FAILURE';

export const DELETE_WORKFLOWS_REQUEST = 'DELETE_WORKFLOWS_REQUEST';
export const DELETE_WORKFLOWS_SUCCESS = 'DELETE_WORKFLOWS_SUCCESS';
export const DELETE_WORKFLOWS_FAILURE = 'DELETE_WORKFLOWS_FAILURE';

export const PORT_POSITIONS = {
  TOP: 'top',
  LEFT: 'left',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  CENTER: 'center',
};

export const GRID_SIZE = 32;
export const INITIAL_ZOOM_LEVEL = 100;
export const DIAGRAM_EVENTS = {
  NODE_GRABBED: 'nodeGrabbed',
  NODE_DROPPED: 'nodeDropped',
};

export const LAYER_TYPES = {
  LINKS: 'diagram-links',
  NODES: 'diagram-nodes',
};

export const PORT_CSS_VALUES = {
  [PORT_POSITIONS.TOP]: {
    left: '50%',
    top: 0,
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  [PORT_POSITIONS.LEFT]: {
    left: 0,
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  [PORT_POSITIONS.BOTTOM]: {
    bottom: 0,
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  [PORT_POSITIONS.RIGHT]: {
    right: 0,
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  [PORT_POSITIONS.CENTER]: {
    right: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
  },
};
