import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { hasMandatoryConditionalContainers } from 'reducers';

export function useDeepFiltering(providerId, providerFields) {
  // check fields for this item, if one field is an itemType (has its own fields) and is deepFilterable = true,
  // this means this provider's item has deep filtering capability.
  const hasDeepFiltering = providerFields.some(
    (field) => !field.getIn(['itemType', 'fields'], Map()).isEmpty() && field.get('deepFilterable', false),
  );
  const requiresDeepFiltering = useSelector((state) => hasMandatoryConditionalContainers(state, providerId));
  const firstDeepFilterItem = hasDeepFiltering
    ? providerFields
        .find((field) => !field.getIn(['itemType', 'fields'], Map()).isEmpty() && field.get('deepFilterable', false))
        .getIn(['names', 'singular'])
    : null;
  const defaultDeepFilterItemId = requiresDeepFiltering ? firstDeepFilterItem : 'all';

  return [hasDeepFiltering, defaultDeepFilterItemId];
}
