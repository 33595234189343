import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Map } from 'immutable';

import { Alert, Box, Collapsible, Typography, tokens } from '@unitoio/mosaic';

import {
  getFieldById,
  getLinkById,
  getProviderByConnectorName,
  getProviderByName,
  getProviderVisibleFields,
} from 'reducers';
import * as routes from '~/consts/routes';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';
import { Href } from '~/components/Href/Href';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { TemplateString } from '~/components/TemplateString/TemplateString';

import { useGetAnomaliesByPage, PAGES } from '../hooks/useGetAnomalies';
import { errorTemplates } from './SyncStatusForm/templates';
import { useGetContainerTypes } from '../hooks/useGetContainerTypes';

const BoldTypo = styled(Typography)`
  font-weight: ${tokens.fontWeight.fw7};
`;

const ConnectorsPageLink = () => <Href to={routes.ABSOLUTE_PATHS.PROFILE}>integrations page</Href>;
const ReachOutToSupportTeam = () => (
  <ChatBubble message="Hey Unito! It looks like my flow is not behaving as expected.">
    Please reach out to the support team
  </ChatBubble>
);
const HelpArticleUrl = ({ articleUrl }) => <Href href={articleUrl}>here</Href>;
HelpArticleUrl.propTypes = {
  articleUrl: PropTypes.string,
};

const getSideByContainerId = (currentLink, containerId) => {
  if (currentLink.getIn(['A', 'container', 'id']) === containerId) {
    return 'A';
  }
  if (currentLink.getIn(['B', 'container', 'id']) === containerId) {
    return 'B';
  }
  return null;
};

const getProviderNameBySide = (currentLink, side) => currentLink.getIn([side, 'providerName']);

const useGetProviderIdFromProviderName = (providerName) =>
  useSelector((state) => getProviderByName(state, providerName)).get('_id');

export function useGetContainerTermSingular(additionalErrorData = Map(), itemType, containerType, containerId) {
  // as sent by Kirby
  const providerName = additionalErrorData?.get('provider');
  const termFromAnomaly = additionalErrorData?.getIn(['container', 'term', 'singular']);

  if (termFromAnomaly) {
    return termFromAnomaly;
  }

  return (
    <ProviderTermsByName
      plurality="singular"
      termKey="container"
      providerNameA={providerName}
      pcdv3={itemType && containerType}
      itemTypeA={itemType}
      containerTypeA={containerType}
      containerIdA={containerId}
    />
  );
}

export function useGetFieldTermSingular(additionalErrorData = Map(), itemType, containerId) {
  // as sent by Kirby
  const providerName = additionalErrorData
    ?.get('provider', additionalErrorData?.get('connectorName', ''))
    .toLowerCase();
  const providerId = useGetProviderIdFromProviderName(providerName);
  const providerFields = useSelector((state) => getProviderVisibleFields(state, providerId, itemType, containerId));
  let fieldSingularTerm = additionalErrorData?.getIn(['field', 'term', 'singular']);
  if (fieldSingularTerm) {
    return fieldSingularTerm;
  }

  const fieldId = additionalErrorData?.getIn(['field', 'id']);
  fieldSingularTerm = providerFields.getIn([fieldId, 'names', 'singular']);

  if (!fieldSingularTerm) {
    return fieldId;
  }

  return fieldSingularTerm;
}

export function useGetTaskTerm(additionalErrorData = Map(), linkId, plurality, itemType) {
  // as sent by Kirby
  let providerName = additionalErrorData?.get('provider', null);
  let containerId;
  const side = additionalErrorData?.get('side', null);
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  if (!providerName && !!side) {
    providerName = getProviderNameBySide(currentLink, side);
    containerId = currentLink.getIn([side, 'container', 'id']);
  }
  return (
    <ProviderTermsByName
      plurality={plurality}
      termKey="tasks"
      providerNameA={providerName}
      pcdv3={!!itemType}
      itemTypeA={itemType}
      containerIdA={containerId}
    />
  );
}

export function useGetContainerDisplay(additionalErrorData = Map(), linkId) {
  let side = additionalErrorData?.get('side', null);
  const containerId = additionalErrorData?.getIn(['container', 'id'], null);
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  if (!side && !!containerId) {
    side = getSideByContainerId(currentLink, containerId);
  }
  const container = currentLink.getIn([side, 'container']);

  return container ? <Href href={container.get('url')}>{container.get('displayName')}</Href> : null;
}

export function useGetFieldDisplayName(additionalErrorData = Map()) {
  const side = additionalErrorData?.get('side', null);
  const fieldId = additionalErrorData?.getIn(['field', 'id'], null);
  const fieldType = additionalErrorData?.getIn(['field', 'type'], null);
  const field = useSelector((state) => getFieldById(state, { containerSide: side, fieldId, kind: fieldType }));
  return field.get('name');
}

export function useGetProfileDisplayName(linkId, additionalErrorData = Map()) {
  let side = additionalErrorData?.get('side');
  const containerId = additionalErrorData?.getIn(['container', 'id'], null);
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  if (!side && !!containerId) {
    side = getSideByContainerId(currentLink, containerId);
  }

  return currentLink.getIn([side, 'providerIdentity', 'profileDisplayName']);
}

export function useGetTaskDisplay(additionalErrorData = Map(), linkId, anomalyItemType) {
  const taskTerm = useGetTaskTerm(additionalErrorData, linkId, 'singular', anomalyItemType);
  const task = additionalErrorData?.get('task') ?? Map();
  if (!task.get('url')) {
    return `${taskTerm} (id: ${task.get('id')})`;
  }
  return <Href href={task.get('url')}>{taskTerm}</Href>;
}

const useGetProviderDisplayNameByConnectorName = (connectorName) =>
  useSelector((state) => getProviderByConnectorName(state, connectorName).get('displayName'));

const useGetProviderDisplayNameByName = (name) =>
  useSelector((state) => getProviderByName(state, name).get('displayName'));

export function useGetElementDisplay({ additionalErrorData = Map(), linkId, specialField, page = undefined }) {
  const side = additionalErrorData?.get('side');
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [containerTypeA, containerTypeB] = useGetContainerTypes();
  const [containerA, containerB] = useGetContainers();

  let anomalyItemType;
  let anomalyContainerType;
  let anomalyContainer;
  let anomalyContainerId;
  if (side) {
    anomalyItemType = side === 'A' ? itemTypeA : itemTypeB;
    anomalyContainerType = side === 'A' ? containerTypeA : containerTypeB;
    anomalyContainer = side === 'A' ? containerA : containerB;
    anomalyContainerId = side === 'A' ? containerA.get('id') : containerB.get('id');
  }
  // TODO anomalies/sync status will need to be overhauled, not all anomalies send side/containerId
  // it becomes very difficult to know which side/term to use without this info
  // if side is available, we can display the correct info, but if not, we will just fallback to
  // the default pcd V2 logic and assume it's about the first work item..
  const commonElements = {
    ...additionalErrorData,
    connectorsPageLink: <ConnectorsPageLink />,
    'container.term.singular': useGetContainerTermSingular(
      additionalErrorData,
      anomalyItemType,
      anomalyContainerType,
      anomalyContainerId,
    ),
    'field.id': additionalErrorData?.getIn(['field', 'id']),
    'field.displayName': additionalErrorData?.getIn(['field', 'displayName']),
    'field.term.singular': useGetFieldTermSingular(additionalErrorData, anomalyItemType, anomalyContainer),
    'task.term.plural': useGetTaskTerm(additionalErrorData, linkId, 'plural', anomalyItemType),
    'task.term.singular': useGetTaskTerm(additionalErrorData, linkId, 'singular', anomalyItemType),
    fieldName: useGetFieldDisplayName(additionalErrorData),
    contactSupportTeamButton: <ReachOutToSupportTeam />,
    container: useGetContainerDisplay(additionalErrorData, linkId),
    taskTermWithURL: useGetTaskDisplay(additionalErrorData, linkId, anomalyItemType),
    provider: useGetProviderDisplayNameByName(additionalErrorData?.get('provider')),
    pageUrl: additionalErrorData?.get('pageUrl'),
    helpArticleUrl: <HelpArticleUrl articleUrl={additionalErrorData?.get('helpArticleUrl')} />,
    movedTaskUrl: additionalErrorData?.getIn(['movedTask', 'url']),
    profileDisplayName: useGetProfileDisplayName(linkId, additionalErrorData),
    deletedFieldProvider: useGetProviderDisplayNameByConnectorName(
      additionalErrorData?.get('deletedFieldConnectorName'),
    ),
    'pageItem.singular': (page && (page === PAGES.RULES ? 'rule' : 'mapped field')) ?? null,
    'pageItem.plural': (page && (page === PAGES.RULES ? 'rules' : 'mapped fields')) ?? null,
  };

  return commonElements[specialField] ?? `[${specialField}]`;
}

function getSolutions({ anomalies, linkId, solutions = {}, anomalyId, page }) {
  const { title, context = [], alternative = [] } = solutions;
  const currentAnomaly = anomalies.get(anomalyId);
  const additionalErrorData = currentAnomaly.get('additionalErrorData') ?? Map();

  return (
    <Box m={[tokens.spacing.s4, 0, 0, 0]}>
      <BoldTypo variant="body1">
        <TemplateString
          fieldsRenderer={(specialField) => useGetElementDisplay({ linkId, additionalErrorData, specialField, page })}
          template={title}
        />
      </BoldTypo>
      <div>
        <ul>
          {context.map((step, stepIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${anomalyId}_context_${stepIndex}`}>
              <TemplateString
                fieldsRenderer={(specialField) =>
                  useGetElementDisplay({ linkId, additionalErrorData, specialField, page })
                }
                template={step}
              />
            </li>
          ))}
        </ul>
        <ul>
          {alternative.map((step, stepIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${anomalyId}_alternative_${stepIndex}`}>
              <TemplateString
                fieldsRenderer={(specialField) =>
                  useGetElementDisplay({ linkId, additionalErrorData, specialField, page })
                }
                template={step}
              />
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
}

function getAnomalyLabel({ additionalErrorData = Map(), linkId, label }) {
  return (
    <TemplateString
      fieldsRenderer={(specialField) => useGetElementDisplay({ linkId, additionalErrorData, specialField })}
      template={label}
    />
  );
}

const PageAnomaliesByLevel = ({ anomalies, side, linkId, page }) => {
  // filter anomalies to only keep unique categories to not display duplicate ones
  // if we have multiple anomalies per side, keep unique ones per side
  const uniqueAnomalies = {};
  for (const anomaly of anomalies.values()) {
    const categoryName = anomaly.get('categoryName');

    if (!categoryName) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const anomalySide = anomaly.getIn(['additionalErrorData', 'side']);

    if (side && side === anomalySide) {
      uniqueAnomalies[`${categoryName}.${side}`] = anomaly;
    } else {
      uniqueAnomalies[categoryName] = anomaly;
    }
  }
  return Object.values(uniqueAnomalies).map((anomaly) => {
    const anomalyId = anomaly.get('id');
    const key = `anomaly_${anomalyId}`;
    const template = errorTemplates[anomaly.get('categoryName')];
    const { label, solutions, level, flowBuilderSolutions } = template;
    const additionalErrorData = anomaly.get('additionalErrorData') ?? Map();
    const anomalyLabel = getAnomalyLabel({ additionalErrorData, label, linkId });
    const solutionToRender = flowBuilderSolutions ?? solutions;
    const solutionsToShow = solutionToRender
      ? getSolutions({ anomalies, linkId, solutions: solutionToRender, anomalyId, page })
      : null;
    return (
      <Box key={key} m={[tokens.spacing.s3, 0]}>
        <Alert level={level}>
          {solutionsToShow ? <Collapsible header={anomalyLabel}>{solutionsToShow}</Collapsible> : anomalyLabel}
        </Alert>
      </Box>
    );
  });
};

export function SyncStatusPageAlert({ page, side }) {
  const { linkId } = useParams();
  const [errorsByPage, warningsByPage] = useGetAnomaliesByPage(linkId, page);
  const hasSyncStatusErrors = !errorsByPage.isEmpty();
  const hasSyncStatusWarnings = !warningsByPage.isEmpty();
  return (
    (hasSyncStatusErrors || hasSyncStatusWarnings) && (
      <Box m={[tokens.spacing.s4, 0]}>
        {hasSyncStatusErrors && (
          <PageAnomaliesByLevel page={page} anomalies={errorsByPage} side={side} linkId={linkId} />
        )}
        {hasSyncStatusWarnings && (
          <PageAnomaliesByLevel page={page} anomalies={warningsByPage} side={side} linkId={linkId} />
        )}
      </Box>
    )
  );
}

SyncStatusPageAlert.propTypes = {
  side: PropTypes.string,
  page: PropTypes.oneOf(Object.values(PAGES)).isRequired,
};
