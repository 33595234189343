import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Href } from '~/components/Href/Href';
import { Section } from '~/components/Section/Section';
import { Subheading } from '~/components/Subheading/Subheading';
import { Title } from '~/components/Title/Title';

const Content = styled.div`
  background: white;
  min-height: calc(100vh - 4.5rem);
  padding: 4rem 0;
`;

const DEFAULT_TITLE = 'Doh! This page does not exist!';

export const NotFound = ({ title = DEFAULT_TITLE, goBackLink, goBackText, target = '_self' }) => (
  <Content className="container">
    <Title type="h1">{title}</Title>
    {goBackLink && goBackText && (
      <Section>
        <Subheading>
          <Href to={goBackLink} target={target}>
            {goBackText}
          </Href>
        </Subheading>
      </Section>
    )}
  </Content>
);

NotFound.propTypes = {
  title: PropTypes.string,
  goBackLink: PropTypes.string,
  goBackText: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
};
