import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Map } from 'immutable';

import { Box, tokens, Toggle } from '@unitoio/mosaic';

import * as fieldTypes from '~/consts/fields';

import { useGetContainers } from '../hooks/useGetContainers';
import { useGetProviders } from '../hooks/useGetProviders';
import { useGetProviderNames } from '../hooks/useGetProviderNames';

const CommentsLabel = styled((props) => <Box as="label" {...props} />)`
  font-size: ${tokens.fontSize.f7};
  display: inline;
  vertical-align: middle;
`;

const CommentsSection = styled(Box)`
  color: ${tokens.colors.content.neutral.n30};
  font-size: ${tokens.fontSize.f7};
`;

export const FieldMappingComments = ({ fieldA, fieldB }) => {
  const { watch, setValue } = useFormContext();
  const watchedPublicCommentsA = watch('A.includePublicComments');
  const watchedPublicCommentsB = watch('B.includePublicComments');

  const [containerA, containerB] = useGetContainers();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const [providerA, providerB] = useGetProviders(providerNameA, providerNameB);

  const onToggleComments = (e, side) => {
    const watchedCommentsSide = side === 'A' ? watchedPublicCommentsA : watchedPublicCommentsB;
    // Setting value here for UI fluidity, it looks less laggy if we change it before the update is made
    setValue(`${side}.includePublicComments`, !watchedCommentsSide, { shouldDirty: true });
  };

  return (
    <Box m={[tokens.spacing.s4, 2.5]} aria-label="Public comments">
      {fieldA.get('semantic') === fieldTypes.SEMANTIC.COMMENTS_PUBLIC && (
        <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4]} flexDirection="row" alignItems="baseline">
          <Toggle
            value={!!watchedPublicCommentsA}
            onClick={(e) => onToggleComments(e, 'A')}
            id="includePublicCommentsA"
          />

          <CommentsLabel m={[0, 0, 0, tokens.spacing.s3]} htmlFor="includePublicCommentsA">
            Include {providerA.get('displayName')} {containerA.get('displayName')} public replies as comments
          </CommentsLabel>
        </Box>
      )}
      {fieldB.get('semantic') === fieldTypes.SEMANTIC.COMMENTS_PUBLIC && (
        <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4]} flexDirection="row" alignItems="baseline">
          <Toggle
            value={!!watchedPublicCommentsB}
            onClick={(e) => onToggleComments(e, 'B')}
            id="includePublicCommentsB"
          />

          <CommentsLabel m={[0, 0, 0, tokens.spacing.s3]} htmlFor="includePublicCommentsB">
            Include {providerB.get('displayName')} {containerB.get('displayName')} public replies as comments
          </CommentsLabel>
        </Box>
      )}
      <CommentsSection>
        By default, only internal notes will sync from Zendesk. By enabling the option above, your flow will also
        include public replies when it syncs comments.
      </CommentsSection>
    </Box>
  );
};

FieldMappingComments.propTypes = {
  fieldA: PropTypes.instanceOf(Map).isRequired,
  fieldB: PropTypes.instanceOf(Map).isRequired,
};
