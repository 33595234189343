export const publicKey =
  process.env.REACT_APP_ENV === 'production'
    ? `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDKYFuFsWD7ShJeazewedP2jUZt
ueK30giaA2QSGfhkryQHdwK0YGVGCgJ42muX/Aw53wdpl7g+7Xq8aO/xV7JjO5RG
e3VSBzKUak0/PhHYrg2Cvrj3hws833f3cXSlntMnT7Y/PGfQYyOUmzNBIha1IRrQ
CHeNnq2VbC6N9SL55wIDAQAB
-----END PUBLIC KEY-----`
    : `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCmlbOhb0EFjmOh/XYXGL0d2MIu
rcywRzPNkTjpBsK6a0PPEhiwevPNoI61ZIbstrezstkA6S4PcNjpKbdlNad3JrHc
ciPccFFi4+6ISXf7wzRml7Cqebne8Ls/6EHA+usmgQdQBCmGoY959YemNICwo6Dc
HmenXy6tzy8QRrJFZwIDAQAB
-----END PUBLIC KEY-----`;
