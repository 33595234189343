import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import * as organizationActions from '~/actions/organizations';
import { getSelectedOrganizationId, getReportUrlByOrganizationId } from 'reducers';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';

const Iframe = styled.iframe`
  width: 100%;
  height: 80vh;
`;

export function LookerContainer() {
  const { reportId } = useParams();
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  const reportUrl = useSelector((state) => getReportUrlByOrganizationId(state, selectedOrganizationId, reportId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationActions.getReportUrl(selectedOrganizationId, reportId));
  }, [dispatch, reportId, selectedOrganizationId]);

  if (!reportUrl) {
    return <InlineLoading />;
  }

  return <Iframe title="Report" src={reportUrl} frameBorder="0" />;
}
