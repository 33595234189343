import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Typography, Tooltip, Icon, tokens, ProviderIcon } from '@unitoio/mosaic';

import { Href } from '~/components/Href/Href';
import { useContainerLinkTrackEvent } from '../../../../hooks/useContainerLinkTrackEvent';

const RulesProvidersBox = styled(Box)`
  width: 80%;
`;

export const SectionHeader = ({ containerName, containerUrl, providerName, otherContainerSection }) => {
  const trackEvent = useContainerLinkTrackEvent();

  return (
    <Box alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
      <RulesProvidersBox alignItems="center" flexDirection="row">
        <Tooltip content={`Open ${containerName} in a new tab`} placement="top">
          <Box onClick={() => trackEvent(providerName)} href={containerUrl} as={Href} alignItems="center">
            <Typography variant="h4">{containerName}</Typography>
            <Box m={[0, tokens.spacing.s3]}>
              <ProviderIcon name={providerName} size="large" />
            </Box>
          </Box>
        </Tooltip>
        <Icon name="long-arrow-right" />
        <Tooltip content={`Open ${otherContainerSection.containerName} in a new tab`} placement="top">
          <Box
            onClick={() => trackEvent(otherContainerSection.providerName)}
            href={otherContainerSection.containerUrl}
            as={Href}
            alignItems="center"
          >
            <Box m={[0, tokens.spacing.s3]}>
              <ProviderIcon name={otherContainerSection.providerName} size="large" />
            </Box>
            <Typography variant="h4">{otherContainerSection.containerName}</Typography>
          </Box>
        </Tooltip>
      </RulesProvidersBox>
    </Box>
  );
};

SectionHeader.propTypes = {
  containerName: PropTypes.string.isRequired,
  containerUrl: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  otherContainerSection: PropTypes.shape({
    containerName: PropTypes.string.isRequired,
    containerUrl: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
  }).isRequired,
};
