import { useCallback, useEffect, useState } from 'react';
import { Map } from 'immutable';
import { SubmissionError } from 'redux-form';

import * as providerTypes from '~/consts/providers';
import * as trackingTypes from '~/consts/tracking';
import * as authUtils from '~/utils/auth';
import { history } from '~/utils/history';
import * as authActions from '~/actions/auth';
import * as authTypes from '~/consts/auth';

export function onSubmitAuthentication(method, providers, trackEvent, reportException, reportWarning, redirect) {
  return async (formValues, dispatch) => {
    const selectedProvider = providers.get(formValues.provider, Map());
    const authenticationTool = selectedProvider.isEmpty() ? providerTypes.AUTH0 : selectedProvider.get('name');

    if (trackEvent) {
      trackEvent(authenticationTool === providerTypes.AUTH0 ? trackingTypes.ACTION : trackingTypes.SUBMIT, {
        selected_tool_name: authenticationTool,
      });
    }

    if (authUtils.providerNeedsManualConfiguration(selectedProvider)) {
      const queryParams = new URLSearchParams({
        keepInformed: formValues.keepInformed ?? '',
      });
      history.push({
        pathname: `/${method}/${selectedProvider.get('name')}`,
        search: `?${queryParams.toString()}`,
        state: redirect,
      });

      return;
    }

    try {
      const { authenticationUrl } = await dispatch(
        authActions.signin(formValues, { method, authenticationTool, redirect }),
      );
      if (
        authenticationUrl &&
        new URL(authenticationUrl).hash.split('?')[0] === `#/login/${authTypes.EMAIL_AUTH_STATES.RESEND_CONFIRMATION}`
      ) {
        trackEvent(trackingTypes.BLOCKED, {
          reason: 'email not verified',
          selected_tool_name: providerTypes.AUTH0,
        });
        reportWarning('email not verified', { identifier: 'emailNotVerified onSubmit AuthenticationContainer' });
      }

      global.window.location.assign(authenticationUrl);
    } catch (err) {
      trackEvent(trackingTypes.BLOCKED, {
        reason: err.message,
      });
      if (err.code < 500) {
        reportWarning(err.message, { identifier: 'uncaughtError onSubmit AuthenticationContainer' });
      } else {
        reportException(err, { identifier: 'uncaughtError onSubmit AuthenticationContainer' });
      }

      throw new SubmissionError({ _error: { message: err.message, name: err.name } });
    }
  };
}

export const useRedirectLoginSignup = ({
  handleSubmit,
  isAuthenticated,
  match,
  method,
  providers,
  trackEvent,
  reportException,
  reportWarning,
  redirect,
}) => {
  const canBeRedirectedToAuth0 = !!(match.url.match(/^\/login$/) || match.url.match(/^\/signup$/));

  const [isRedirecting, setIsRedirecting] = useState(false);

  const auth0RedirectOnSubmit = useCallback(
    () =>
      handleSubmit(onSubmitAuthentication(method, providers, trackEvent, reportException, reportWarning, redirect))(),
    [handleSubmit, method, providers, trackEvent, reportException, reportWarning, redirect],
  );

  useEffect(() => {
    if (!isAuthenticated && canBeRedirectedToAuth0 && !isRedirecting) {
      // Redirect user to Auth0 when it's not using our native OAuth login and signup
      auth0RedirectOnSubmit();
      setIsRedirecting(true);
    }
  }, [auth0RedirectOnSubmit, canBeRedirectedToAuth0, isAuthenticated, isRedirecting]);

  return [canBeRedirectedToAuth0, isRedirecting];
};
