import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { tokens, Icon } from '@unitoio/mosaic';

import * as trackingActions from '~/actions/tracking';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import { NavbarHeader } from '~/components/NavbarStacked/NavbarHeader';
import { NavbarItem } from '~/components/NavbarStacked/NavbarItem';
import { NavbarStacked } from '~/components/NavbarStacked/NavbarStacked';

const StyledIcon = styled(Icon)`
  margin-right: ${tokens.spacing.s3};
`;

function ProfileSettingsMenuComponent({ trackEvent }) {
  return (
    <NavbarStacked>
      <NavbarHeader>Unito Profile</NavbarHeader>
      <NavbarItem
        to={routes.ABSOLUTE_PATHS.PROFILE}
        onClick={() =>
          trackEvent(trackingTypes.SETTINGS_MENU_EVENTS.ACTION_NAME, {
            action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.CONNECTOR_SECTION,
          })
        }
      >
        <StyledIcon name="plug" kind={Icon.KINDS.SOLID} />
        Integrations
      </NavbarItem>
    </NavbarStacked>
  );
}

ProfileSettingsMenuComponent.propTypes = {
  trackEvent: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  trackEvent: (...params) => dispatch(trackingActions.trackEvent(...params)),
});

export const ProfileSettingsMenu = connect(null, mapDispatchToProps)(ProfileSettingsMenuComponent);
