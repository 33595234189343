import React from 'react';
import PropTypes from 'prop-types';

import { NewTooltip, NewTypography } from '@unitoio/mosaic';

const { Link, Text } = NewTypography;

export const ActiveContainerLabel = ({ name, url }) => {
  if (url) {
    return (
      <NewTooltip placement="top" title={`Open ${name} in a new tab`}>
        <Link href={url} target="_blank">
          <Text>{name}</Text>
        </Link>
      </NewTooltip>
    );
  }
  return <Text>{name}</Text>;
};

ActiveContainerLabel.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
