import { useFieldArray } from 'react-hook-form';
import * as fieldTypes from '~/consts/fields';

/** Checks to see if the current draft form context has 'earliestCreatedAt' field value or not.
 * In the react-hook-form form though, we translate 'earliestCreatedAt' has an individual filter for each side,
 * so here we check to see if the property is available on either side filter.
 * */
export function useHasTestModeEnabled() {
  const fieldArrayA = useFieldArray({ name: 'A.filters' });
  const fieldArrayB = useFieldArray({ name: 'B.filters' });
  return (
    fieldArrayA.fields.some(({ fieldId, value }) => fieldId === fieldTypes.EARLIEST_CREATED_AT && !!value) ||
    fieldArrayB.fields.some(({ fieldId, value }) => fieldId === fieldTypes.EARLIEST_CREATED_AT && !!value)
  );
}
