import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Box, Modal, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as appActions from '~/actions/app';

export const WarningModal = () => {
  const history = useHistory();
  const trackEvent = useTrackEvent();

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [hasConfirmedNavigation, setHasConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const dispatch = useDispatch();

  const onClose = () => {
    setShowWarningModal(false);
    setHasConfirmedNavigation(false);
  };

  useEffect(() => {
    if (hasConfirmedNavigation) {
      trackEvent(trackingTypes.EVENT_NAME.ACTION, {
        action_name: 'leaving the export & sync',
      });

      history.push(nextLocation);
    }
  }, [hasConfirmedNavigation, trackEvent, history, nextLocation]);

  const onConfirm = () => {
    dispatch(appActions.setProductNameContext(undefined));
    setShowWarningModal(false);
    setHasConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt
        when={!hasConfirmedNavigation}
        message={(location) => {
          if (
            location.pathname.includes('one-click-export') ||
            history.location.pathname.includes('/setup-completed') ||
            hasConfirmedNavigation
          ) {
            return true;
          }
          setShowWarningModal(true);
          setNextLocation(location);
          return false;
        }}
      />
      <Modal onConfirm={onConfirm} onRequestClose={onClose} onCancel={onClose} isOpen={showWarningModal}>
        <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
          <Typography variant={TypographyVariants.H3}>Leaving the Export & Sync flow?</Typography>
        </Box>
        <Box>
          <Typography>Leaving the flow before completion will result in your progress being lost.</Typography>{' '}
        </Box>
      </Modal>
    </>
  );
};
