import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Box, Button, Icon, tokens } from '@unitoio/mosaic';

import { InviteCoworkersModal } from '~/containers/InviteCoworkersModal/InviteCoworkersModal';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { useQueryParams } from '~/hooks/useQueryParams';
import { getSelectedOrganizationId } from 'reducers';
import * as trackingTypes from '~/consts/tracking';

import { RightHeaderContent } from './RightHeaderContent';

const PullRight = styled(Box)`
  float: right;
`;

const Navbar = styled(Box)`
  background: ${tokens.colors.global.primary.light};
  border-bottom: 1px solid ${tokens.colors.content.neutral.n10};
`;

function HeaderContainerItemStatus() {
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const { linkId } = useQueryParams();
  const history = useHistory();

  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));

  return (
    <>
      <InviteCoworkersModal
        organizationId={selectedOrganizationId}
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      />
      <Navbar flexDirection={Box.flexDirection.ROW} p={[tokens.spacing.s4]}>
        <Box fullWidth>
          {linkId && (
            <Box m={[tokens.spacing.s2, 0, 0, 0]}>
              <Button
                variant={Button.variants.SUBTLE}
                startIcon="chevron-left"
                iconKind={Icon.KINDS.SOLID}
                onClick={() => history.push(`/dashboard/flow-builder/edit/${linkId}/guide`)}
              >
                Back to flows
              </Button>
            </Box>
          )}
        </Box>
        <Box fullWidth>
          <PullRight>
            <RightHeaderContent />
          </PullRight>
        </Box>
      </Navbar>
    </>
  );
}

function HeaderContainerItemStatusWithTracking() {
  return (
    <TrackingFunnel contextName={trackingTypes.FUNNELS.HEADER}>
      <HeaderContainerItemStatus />
    </TrackingFunnel>
  );
}

export { HeaderContainerItemStatusWithTracking as HeaderContainerItemStatus };
