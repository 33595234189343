import PropTypes from 'prop-types';
import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';

import { SearchInput, Select } from '@unitoio/mosaic';

import * as unitoIdentityActions from '~/actions/unitoIdentities';
import { getUnitoIdentityById, getUnitoIdentitiesByOrganizationId } from 'reducers';
import { color } from 'theme';
import * as routes from '~/consts/routes';
import * as unitoIdentityTypes from '~/consts/unitoIdentities';
import emptyStateImage from '~/images/empty-forest.svg';
import { Box } from '~/components/Box/Box';
import { Button } from '~/components/Button/Button';
import { Card } from '~/components/Card/Card';
import { Checkbox } from '~/components/Checkbox/Checkbox';
import { Divider } from '~/components/Divider/Divider';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { Section } from '~/components/Section/Section';
import { Subheading } from '~/components/Subheading/Subheading';
import { Table } from '~/components/Table/Table';
import { TableBody } from '~/components/Table/TableBody';
import { TableCellHead } from '~/components/Table/TableCellHead';
import { TableRow } from '~/components/Table/TableRow';
import { Title } from '~/components/Title/Title';
import { Href } from '~/components/Href/Href';
import { TableHead } from '~/components/Table/TableHead';
import { Paginator } from '~/components/Paginator/Paginator';

import { UnitoIdentityRow } from './UnitoIdentityRow';
import { UnitoIdentityEditModal } from './UnitoIdentityEditModal';

const TableActions = styled.div`
  margin: -1rem 0 0.5rem;

  .btn {
    margin-left: .5rem
    margin-right: .5rem
  }

  small {
    color: ${color.dark.hint};
    vertical-align: middle;
  }
`;

const SearchFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchBoxContainer = styled(Box)`
  flex: 2;
`;
const StatusFilterContainer = styled(Box)`
  flex: 1;
`;

const types = {
  UNCHECK_ALL: 'UNCHECK_ALL',
  CHECK_BY_ID: 'CHECK_BY_ID',
  SET_SEARCH_STRING: 'SET_SEARCH_STRING',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
};

const ITEMS_PER_PAGE = 10;

function paginationReducer(state, action) {
  switch (action.type) {
    case types.SET_CURRENT_PAGE: {
      return { ...state, currentPage: action.page };
    }

    case types.SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.searchString,
        currentPage: 1,
      };
    }

    default: {
      return state;
    }
  }
}

function mergeUnitoIdentities(state, action) {
  switch (action.type) {
    case types.UNCHECK_ALL: {
      return Object.keys(state).reduce(
        (newState, id) => ({
          ...newState,
          [id]: false,
        }),
        {},
      );
    }

    case types.CHECK_BY_ID: {
      return {
        ...state,
        [action.id]: action.checked,
      };
    }

    default: {
      return state;
    }
  }
}

function useFetchUnitoIdentities(organizationId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchIdentities() {
      try {
        await dispatch(unitoIdentityActions.getUnitoIdentities(organizationId));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
    fetchIdentities();
  }, [organizationId, dispatch]);

  return isLoading;
}

function filterIdentitiesBySearchString(unitoIdentitiesByStatus, searchString) {
  const searchStringRegExp = new RegExp(searchString, 'i');

  function isSearchProviderIdentity(providerIdentity) {
    const userName = providerIdentity.get('profileUsername');
    const email = providerIdentity.get('profileEmails');
    const displayName = providerIdentity.get('profileDisplayName');
    return searchStringRegExp.exec(userName) || searchStringRegExp.exec(email) || searchStringRegExp.exec(displayName);
  }

  return unitoIdentitiesByStatus.filter((identity) => {
    const providerIdentities = identity.get('providerIdentities');
    return providerIdentities.some(isSearchProviderIdentity);
  });
}

const sortIdentities = (identA, identB) => {
  const statusA = identA.get('computedStatus');
  const statusB = identB.get('computedStatus');

  if (statusA === statusB) {
    return 0;
  }

  // Priority is active, inactive, excluded
  if (statusA === 'active') {
    return -1;
  }

  if (statusA === 'inactive') {
    if (statusB !== 'active') {
      return -1;
    }
  }

  return 1;
};

const statusOptions = [
  { value: null, label: 'All statuses' },
  ...Object.values(unitoIdentityTypes.STATUSES).map((value) => ({
    value,
    label: value.charAt(0).toUpperCase() + value.slice(1),
  })),
];

export const UnitoIdentities = ({ organizationId }) => {
  const isLoading = useFetchUnitoIdentities(organizationId);
  const [{ currentPage, searchString }, dispatchPagination] = useReducer(paginationReducer, {
    currentPage: 1,
    searchString: '',
  });
  const [unitoIdentityIdToEdit, setUnitoIdentityIdToEdit] = useState(null);
  const [showOnlyStatus, setShowOnlyStatus] = useState(null);
  let unitoIdentities = useSelector((state) => getUnitoIdentitiesByOrganizationId(state, organizationId));
  // Temporary solution to prevent some cases where a providerIdentity no longer exit into the DB
  unitoIdentities = unitoIdentities.filter((unitoIdentity) =>
    unitoIdentity.get('providerIdentities').some((providerIdentity) => providerIdentity !== undefined),
  );

  const filteredUnitoIdentitiesByStatus = showOnlyStatus
    ? unitoIdentities.filter((unitoIdentity) => unitoIdentity.get('computedStatus') === showOnlyStatus)
    : unitoIdentities;

  const filteredUnitoIdentities = searchString
    ? filterIdentitiesBySearchString(filteredUnitoIdentitiesByStatus, searchString)
    : filteredUnitoIdentitiesByStatus;

  // TODO only sort when needed to improve performance
  const sortedUnitoIdentities = filteredUnitoIdentities
    .sortBy((unitoIdentity) => unitoIdentity.getIn(['providerIdentities', 0, 'profileDisplayName'])?.toUpperCase())
    .sort(sortIdentities);

  const paginatedUnitoIdentities = sortedUnitoIdentities.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  );
  const [checkedState, dispatchChecked] = useReducer(mergeUnitoIdentities, {});
  const unitoIdentityToEdit = useSelector((state) => getUnitoIdentityById(state, unitoIdentityIdToEdit));
  const checkedUnitoIdentityIds = Object.keys(checkedState).filter((id) => checkedState[id]);
  const checkedCount = checkedUnitoIdentityIds.length;

  if (isLoading) {
    return <InlineLoading />;
  }

  return (
    <div className="unito-identities">
      {unitoIdentityIdToEdit && (
        <UnitoIdentityEditModal
          isOpen
          mergeWithIds={checkedUnitoIdentityIds}
          onCancel={() => setUnitoIdentityIdToEdit(null)}
          onRequestClose={() => setUnitoIdentityIdToEdit(null)}
          onConfirm={() => {
            dispatchChecked({ type: types.UNCHECK_ALL });
            setUnitoIdentityIdToEdit(null);
          }}
          unitoIdentity={unitoIdentityToEdit}
          organizationId={organizationId}
        />
      )}
      <Box $m={[0, 0, 3]}>
        An active user is anyone who, in the last 30 days, has collaborated on a work item that Unito is syncing.
      </Box>
      <SearchFilterContainer>
        <SearchBoxContainer m={[0, 0.5, 0, 0]}>
          <SearchInput
            onChange={(event) =>
              dispatchPagination({ type: types.SET_SEARCH_STRING, searchString: event.target.value })
            }
            placeholder="Search users by name or email"
            value={searchString}
          />
        </SearchBoxContainer>
        <StatusFilterContainer m={[0, 0, 0.5, 0]}>
          <Select
            value={showOnlyStatus}
            options={statusOptions}
            onChange={setShowOnlyStatus}
            size="md"
            placeholder="All statuses"
          />
        </StatusFilterContainer>
      </SearchFilterContainer>
      <Divider />
      <div className={classnames({ invisible: checkedCount < 1 })}>
        <TableActions>
          <Checkbox
            id="merge-users"
            checkType="line"
            checked
            onChange={() => dispatchChecked({ type: types.UNCHECK_ALL })}
          />
          <Button
            btnStyle="link"
            disabled={checkedCount < 2}
            noPadding
            onClick={() => {
              const unitoIdentity = unitoIdentities.find((entity) =>
                checkedUnitoIdentityIds.includes(entity.get('_id')),
              );
              setUnitoIdentityIdToEdit(unitoIdentity.get('_id'));
            }}
          >
            Merge users
          </Button>
          <small>
            {checkedCount < 2 ? 'You’ll need to select at least two users' : `(${checkedCount} users selected)`}
          </small>
        </TableActions>
      </div>

      <Section>
        {unitoIdentities.isEmpty() && (
          <Card className="text-center">
            <img alt="Moonlight" src={emptyStateImage} width="250" height="auto" />
            <Title type="h3">Looks like there's no one here yet...</Title>
            <Subheading>
              <Href to={routes.ABSOLUTE_PATHS.SYNCS}>Create a flow</Href> and users collaborating will be listed here.
            </Subheading>
          </Card>
        )}
        {!paginatedUnitoIdentities.isEmpty() && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead />
                <TableCellHead padding="0 20px 0 70px">Name</TableCellHead>
                <TableCellHead>Status</TableCellHead>
                <TableCellHead cellSize={2}>Tools</TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUnitoIdentities
                .map((unitoIdentity) => (
                  <UnitoIdentityRow
                    key={unitoIdentity.get('_id')}
                    checked={checkedState[unitoIdentity.get('_id')] || false}
                    unitoIdentity={unitoIdentity}
                    setChecked={(isChecked) =>
                      dispatchChecked({
                        type: types.CHECK_BY_ID,
                        id: unitoIdentity.get('_id'),
                        checked: isChecked,
                      })
                    }
                    onEdit={(unitoIdentityId) => {
                      dispatchChecked({ type: types.UNCHECK_ALL });
                      setUnitoIdentityIdToEdit(unitoIdentityId);
                    }}
                  />
                ))
                .toArray()}
            </TableBody>
          </Table>
        )}
        {filteredUnitoIdentities.isEmpty() && !unitoIdentities.isEmpty() && (
          <div className="text-center">
            No results
            <br />
            Sorry we couldn't find the member you were looking for.
          </div>
        )}
        <Paginator
          numPages={Math.ceil(filteredUnitoIdentities.size / ITEMS_PER_PAGE)}
          currentPage={currentPage}
          onChangePage={(page) => dispatchPagination({ type: types.SET_CURRENT_PAGE, page })}
        />
      </Section>
    </div>
  );
};

UnitoIdentities.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
