import React from 'react';

import { CopyToClipboard } from '~/components/CopyToClipboard/CopyToClipboard';
import { Href } from '~/components/Href/Href';

import { AuthImageWrapper } from '../../AuthImageWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import jiraApiToken1 from './jira-cloud-api-token-1.png';

export function JiraCloudTokenStep1() {
  return (
    <>
      <AuthImageWrapper>
        <img src={jiraApiToken1} alt="Jira API Token Step - Step 1" />
      </AuthImageWrapper>
      <AuthOrderedList>
        <li>
          First, go to Jira’s Security{' '}
          <Href href="https://id.atlassian.com/manage/api-tokens" data-testid="cloud-api-url">
            Create and manage API tokens
          </Href>{' '}
          page
        </li>
        <li>
          Click on <strong>Create API Token</strong>
        </li>
        <li>
          From the dialog that appears, enter:
          <ul>
            <li>
              Label: <CopyToClipboard>Unito Sync</CopyToClipboard>
            </li>
          </ul>
        </li>
        <li>
          Click <strong>Create</strong>
        </li>
      </AuthOrderedList>
    </>
  );
}
