import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { LoadingSpinner, NewTypography } from '@unitoio/mosaic';

import { otherSide } from '~/utils/otherSide';
import { RulesRow } from '~/containers/FlowBuilder/components/RulesRow';

import { useGetSides } from '../../Rules/hooks';
import { SetDefaultFieldValueFromFilterRows } from '../../Rules/RulesSection/SetDefaultFieldValueFromFilterRows/SetDefaultFieldValueFromFilterRows';
import { ThenStatementRuleRow } from '../../Rules/RulesSection/ThenStatementRuleRow/ThenStatementRuleRow';
import { statuses } from '../../Rules/consts';
import { isDisabledOptionHandler, useGetAddNewRule } from '../../Rules/RulesSection/ClassicIntegrationRules/utils';
import { AppendNewDefaultFieldValueFromFilter } from '../../Rules/RulesSection/AppendNewDefaultFieldValueFromFilter/AppendNewDefaultFieldValueFromFilter';

export const LegacyDefaultValues = ({ side: originalSide, loadingState }) => {
  // we flip the sides, we set default values for B on the A side in the UI
  const side = otherSide(originalSide);
  const [linkState, syncDirection] = useWatch({ name: ['state', 'syncDirection'] });

  const addNewRule = useGetAddNewRule();
  const sides = useGetSides();
  const { containerType, itemType } = sides[side];
  const currentSide = sides[side];
  const isLoading = [statuses.INITIAL, statuses.LOADING, statuses.STANDBY].includes(loadingState);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ThenStatementRuleRow prefix={<Prefix label="THEN" />} otherContainerSection={currentSide} />
      {currentSide.actions.fields.map((data, index) => (
        <RulesRow
          isAction
          prefix={<Prefix label="AND" />}
          providerName={currentSide.providerName}
          providerIdentityId={currentSide.providerIdentityId}
          containerId={currentSide.containerId}
          itemType={currentSide.itemType}
          containerType={currentSide.containerType}
          containerSide={side}
          key={data._id}
          name={`${side}.actions.${index}`}
          fieldDetails={currentSide.groupedActionFields[data.fieldId]}
          data={data}
          linkState={linkState}
        />
      ))}
      <SetDefaultFieldValueFromFilterRows
        filters={currentSide[side]}
        otherContainerSection={currentSide}
        otherItemType={itemType}
        otherContainerType={containerType}
        otherContainerSide={side}
        linkState={linkState}
        prefix={<Prefix label="AND" />}
      />
      {syncDirection !== 'both' && (
        <AppendNewDefaultFieldValueFromFilter
          prefix={<Prefix label="AND" />}
          otherContainerSide={side}
          otherContainerSection={currentSide}
          itemType={currentSide.itemType}
          containerType={currentSide.containerType}
          isDisabledOptionHandler={isDisabledOptionHandler}
          otherItemType={sides[side].itemType}
          addNewRule={addNewRule}
        />
      )}
    </>
  );
};

LegacyDefaultValues.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
  loadingState: PropTypes.oneOf(Object.values(statuses)),
};

const Prefix = ({ label }) => (
  <NewTypography.Text type="secondary" strong>
    {label}
  </NewTypography.Text>
);

Prefix.propTypes = {
  label: PropTypes.string.isRequired,
};
