import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { RulesRow } from '../../../../components/RulesRow';

const StretchedBox = styled(Box)`
  width: 100%;
  gap: ${tokens.spacing.s3};
`;

export const KeepThemInSyncRow = ({ itemType, containerType }) => (
  <RulesRow hoverable={false} itemType={itemType} containerType={containerType}>
    <StretchedBox m={[0, tokens.spacing.s2, 0, 0]} flexDirection="row" alignItems="center">
      <Box m={[0, tokens.spacing.s2, 0, 0]}>
        <Typography variant="h4">AND</Typography>
      </Box>
      <Typography variant={TypographyVariants.BODY2}>
        <Box
          backgroundColor={tokens.colors.background.neutral.grey}
          p={[tokens.spacing.s2, tokens.spacing.s3]}
          borderRadius={0.4}
        >
          keep them in sync
        </Box>
      </Typography>
    </StretchedBox>
  </RulesRow>
);

KeepThemInSyncRow.propTypes = {
  itemType: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
};
