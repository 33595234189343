import React from 'react';
import PropTypes from 'prop-types';

import { NewButton, Space } from '@unitoio/mosaic';

export const LaunchFlowButton = ({ onClose, onLaunch, isLoading, isSubmitting, isLaunchDisabled }) => (
  <Space align="center" size="small">
    <NewButton size="large" onClick={onClose} type="text">
      Cancel
    </NewButton>
    <NewButton
      size="large"
      type="primary"
      disabled={isLaunchDisabled || isLoading}
      onClick={onLaunch}
      loading={isLoading}
    >
      {isSubmitting ? 'Launching' : 'Launch'} flow
    </NewButton>
  </Space>
);

LaunchFlowButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLaunch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLaunchDisabled: PropTypes.bool.isRequired,
};
