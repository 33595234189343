import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';

import { useQueryParams } from '~/hooks/useQueryParams';

import { AuthMondayInstallationStep1 } from './oauth2/AuthMondayInstallationStep1';

export function MondayInstructions({
  authorizationType,
  children: instructionFields,
  match,
  onCancel,
  onSubmit,
  trackEvent,
}) {
  const { windowOpenerId } = useQueryParams();

  return (
    <Switch>
      <Redirect
        exact
        from={match.path}
        to={`${match.path}/${authorizationType}/step1?windowOpenerId=${windowOpenerId}`}
      />
      <Route
        exact
        path={`${match.path}/oauth2/step1`}
        render={(routeProps) => (
          <AuthMondayInstallationStep1 {...routeProps} onSubmit={onSubmit} onCancel={onCancel} trackEvent={trackEvent}>
            {instructionFields}
          </AuthMondayInstallationStep1>
        )}
      />
    </Switch>
  );
}

MondayInstructions.propTypes = {
  authorizationType: PropTypes.string.isRequired,
  children: PropTypes.node,
  error: PropTypes.string,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
