import { List } from 'immutable';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { notification } from '@unitoio/mosaic';

import * as containerActions from '~/actions/containers';
import * as websocketActions from '~/actions/websocket';
import * as workflowActions from '~/actions/workflows';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import * as websocketTypes from '~/consts/websocket';
import {
  getAllLinkBySelectedOrganizationId,
  getProvidersDefaultItemAndContainerTypes,
  getSearchableProviders,
  getWorkflowById,
} from 'reducers';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import * as workflowTypes from '~/consts/workflows';
import { isHorizontal } from './utils/isHorizontal';

import { createWorkflowEngine } from './utils/workflowEngine';
import { WorkflowContainer } from './WorkflowContainer';
import { WorkflowToolbar } from './components/WorkflowToolbar';

function useFetchWorkflowAndDeserialize(workflowId, setWorkflowEngine, trackEvent) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  // TODO investigate the best way to use the 2 variables below as dependencies without causing re-render loops.
  const searchableProviders = useSelector((state) => getSearchableProviders(state), shallowEqual);
  const providerItemContainerDefaults = useSelector(
    (state) => getProvidersDefaultItemAndContainerTypes(state),
    shallowEqual,
  );

  const { reportWarning } = useLogger();

  useEffect(() => {
    async function fetchWorkflowAndDeserializeInner() {
      try {
        const { workflow } = await dispatch(workflowActions.getWorkflow(workflowId));
        const { links } = await dispatch(workflowActions.getLinksByWorkflowId(workflowId));
        const { providerContainers } = await dispatch(workflowActions.getProviderContainersByWorkflowId(workflowId));

        const containersToFetch = workflow.blocks.reduce((acc, block) => {
          // we don't want to fetch the containers for providers with typeahead capabilities because we might time out!
          // It's gonna be the WorkBlockWidget's job to do a getContainerById
          const providerContainer = providerContainers.find((pc) => pc._id === block.providerContainer);
          if (searchableProviders.get(providerContainer.providerId)) {
            return acc;
          }

          let { containerType, itemType } = block;
          if (!containerType) {
            containerType = providerItemContainerDefaults.get(providerContainer.providerId).containerType;
          }
          if (!itemType) {
            itemType = providerItemContainerDefaults.get(providerContainer.providerId).itemType;
          }

          // we only want to issue one API call per unique triplet of containerId / PI / containerType
          const shouldAdd = !acc.some(
            (entry) =>
              entry.containerId === providerContainer.unitoUniqueId &&
              entry.providerIdentityId === block.providerIdentity &&
              entry.containerType === containerType &&
              entry.itemType === itemType,
          );

          return shouldAdd
            ? acc.push({
                containerId: providerContainer.unitoUniqueId,
                providerIdentityId: block.providerIdentity,
                containerType,
                itemType,
              })
            : acc;
        }, List());

        containersToFetch.forEach(({ providerIdentityId, containerType, itemType, containerId }) =>
          dispatch(
            containerActions.getContainerById({
              providerIdentityId,
              containerId,
              containerType,
              itemType,
              options: { displayError: false },
            }),
          ),
        );

        const engine = createWorkflowEngine(workflow.diagramRepresentation, workflow._id, trackEvent, reportWarning);
        setWorkflowEngine(engine);

        if (links.length) {
          dispatch(
            websocketActions.subscribe({
              currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_LIST,
              linkIds: links.map((link) => link._id),
            }),
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
    // TODO: optimization: do not fetch if we're coming from add (the workflow is alreayd in the store)
    fetchWorkflowAndDeserializeInner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId, dispatch, setIsLoading, setWorkflowEngine, reportWarning, trackEvent]);
  // ^^^ excluding searchableProviders from the dependency array as these are "cached" and shouldn't change anyway

  return isLoading;
}

export function hasLayoutChanged(representationInStore, actualRepresentation) {
  if (!representationInStore) {
    return true;
  }

  const layersInStore = representationInStore?.layers || [];
  const actualLayers = actualRepresentation.layers || [];

  if (layersInStore.length !== actualLayers.length) {
    return true;
  }

  const diagramLinksLayerInStore = layersInStore.find((layer) => layer.type === workflowTypes.LAYER_TYPES.LINKS);
  const actualDiagramLinksLayer = actualLayers.find((layer) => layer.type === workflowTypes.LAYER_TYPES.LINKS);

  // Only compare the number of diagram-links elements as their position depends on the diagram-nodes anyway (we'll check for that later).
  if (Object.keys(diagramLinksLayerInStore.models).length !== Object.keys(actualDiagramLinksLayer.models).length) {
    return true;
  }

  const diagramNodesLayerInStore = layersInStore.find((layer) => layer.type === workflowTypes.LAYER_TYPES.NODES);
  const actualDiagramNodesLayer = actualLayers.find((layer) => layer.type === workflowTypes.LAYER_TYPES.NODES);

  if (Object.keys(diagramNodesLayerInStore.models).length !== Object.keys(actualDiagramNodesLayer.models).length) {
    return true;
  }

  const sortedNodesInStore = Object.values(diagramNodesLayerInStore.models).sort((node1, node2) =>
    node1.id < node2.id ? -1 : 1,
  );
  const actualSortedNodes = Object.values(actualDiagramNodesLayer.models).sort((node1, node2) =>
    node1.id < node2.id ? -1 : 1,
  );

  return sortedNodesInStore.some((nodeInStore, index) => {
    const actualNode = actualSortedNodes[index];

    if (JSON.stringify(nodeInStore) === JSON.stringify(actualNode)) {
      return false;
    }

    const strippedDownNodeInStore = {
      ...nodeInStore,
      // We prevent the user from being able to drag n drop a node over another node. What we do is we simply keep the initial position of the node and
      // if we detect that the user tries to move it on top of another we manually set the node back to its original position. Doing so, we lose some
      // precision and we end up having a port model position that's diffirent by something like 0.0000002. We want to ignore those changes.
      ports: [],
      // the selected field comes from the library and sometimes the value shifts when we programatically manipulate a node. We simply ignore it as
      // it does not impact our own representation of a workflow.
      selected: undefined,
    };
    const strippedDownActualNode = {
      ...actualNode,
      ports: [],
      selected: undefined,
    };

    return JSON.stringify(strippedDownNodeInStore) !== JSON.stringify(strippedDownActualNode);
  });
}

export const AuthenticatedWorkflowDesigner = () => {
  const match = useRouteMatch();
  const trackEvent = useTrackEvent();
  const { workflowId } = match.params;
  const [workflowEngine, setWorkflowEngine] = useState(null);
  const isLoading = useFetchWorkflowAndDeserialize(workflowId, setWorkflowEngine, trackEvent);
  const dispatch = useDispatch();
  const workflow = useSelector((state) => getWorkflowById(state, workflowId));
  const links = useSelector((state) => getAllLinkBySelectedOrganizationId(state));
  const [isSavingWorkflow, setIsSavingWorkflow] = useState(false);

  const blocksQty = workflow.get('blocks', List()).size;

  useEffect(() => {
    if (blocksQty === 0) {
      trackEvent(trackingTypes.START, { workflow_id: workflowId });
    }
  }, [trackEvent, blocksQty, workflowId]);

  if (isLoading || !workflowEngine) {
    return <InlineLoading />;
  }

  const workflowModel = workflowEngine.getModel();

  function automaticallyAddExistingFlow(createdWorkBlock) {
    const workblocks = workflowModel.getWorkBlockNodes();
    const otherWorkblocks = workblocks.filter((workblock) => createdWorkBlock.getBlockId() !== workblock.getBlockId());

    // Exclude Draft links since we allow duplicate providerIdentities and containers
    // in draft links, we could end up with more than one link between work blocks..
    links
      .filter((link) => link.get('state') !== linkTypes.LINK_STATES.DRAFT)
      .forEach((link) => {
        const containerIdA = link.getIn(['A', 'container', 'id']);
        const itemTypeA = link.getIn(['A', 'itemType']);
        const containerTypeA = link.getIn(['A', 'containerType']);
        const containerIdB = link.getIn(['B', 'container', 'id']);
        const itemTypeB = link.getIn(['B', 'itemType']);
        const containerTypeB = link.getIn(['B', 'containerType']);

        otherWorkblocks.forEach((workblock) => {
          // check for pairing in A and B
          const existsInA =
            (workblock.getContainerId() === containerIdA &&
              workblock.getItemType() === itemTypeA &&
              workblock.getContainerType() === containerTypeA) ||
            (workblock.getContainerId() === containerIdB &&
              workblock.getItemType() === itemTypeB &&
              workblock.getContainerType() === containerTypeB);
          const existsInB =
            (createdWorkBlock.getContainerId() === containerIdA &&
              createdWorkBlock.getItemType() === itemTypeA &&
              createdWorkBlock.getContainerType() === containerTypeA) ||
            (createdWorkBlock.getContainerId() === containerIdB &&
              createdWorkBlock.getItemType() === itemTypeB &&
              createdWorkBlock.getContainerType() === containerTypeB);

          if (existsInA && existsInB) {
            // defines which is blockA and which is blockB in the sync
            const workblockA =
              workblock.getContainerId() === containerIdA &&
              workblock.getItemType() === itemTypeA &&
              workblock.getContainerType() === containerTypeA
                ? workblock
                : createdWorkBlock;
            const workblockB =
              workblock.getContainerId() === containerIdB &&
              workblock.getItemType() === itemTypeB &&
              workblock.getContainerType() === containerTypeB
                ? workblock
                : createdWorkBlock;

            const { x: sideAx, y: sideAy } = workblockA.getPosition();
            const { x: sideBx, y: sideBy } = workblockB.getPosition();
            let portA;
            let portB;

            // detects if the flow is going to be horizontal or vertical, and picks a port accordingly
            if (isHorizontal(sideAx, sideAy, sideBx, sideBy)) {
              portA = sideAx < sideBx ? 'right' : 'left';
              portB = sideAx < sideBx ? 'left' : 'right';
            } else {
              portA = sideAy < sideBy ? 'bottom' : 'top';
              portB = sideAy < sideBy ? 'top' : 'bottom';
            }

            workflowModel.addFlowNode({ sourcePort: workblockA.ports[portA], targetPort: workblockB.ports[portB] });
            notification.info({
              message: 'Flow automatically created',
              description:
                'This flow is already in your workspace, so all existing settings have been automatically applied.',
              placement: 'topRight',
              duration: 5,
            });
          }
        });
      });
  }

  async function saveWorkflow(id, model) {
    setIsSavingWorkflow(true);
    await dispatch(
      workflowActions.saveWorkflow(id, {
        diagramRepresentation: model.serialize(),
      }),
    );
    setIsSavingWorkflow(false);
  }

  return (
    <Fragment>
      <WorkflowContainer
        isLoading={isLoading}
        workflow={workflow}
        workflowEngine={workflowEngine}
        checkSaveRequired={hasLayoutChanged}
        onSaveWorkflow={saveWorkflow} // eslint-disable-line react/jsx-no-bind
        onUpdatedNewWorkBlock={automaticallyAddExistingFlow} // eslint-disable-line react/jsx-no-bind
      />

      <WorkflowToolbar
        workflow={workflow}
        isSavingWorkflow={isSavingWorkflow}
        zoomToFit={() => workflowEngine.zoomToFitAllNodes()}
      />
    </Fragment>
  );
};
