import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FlowBuilderErrorContext } from '~/contexts';
import { getProviderByName, hasProviderCustomFieldOfTypeSupport } from 'reducers';

export function useGetIncompleteItemFieldAssociation(setErrors = true) {
  const { setError, clearErrors, watch } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);
  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const itemFieldAssociationsA = watch('A.itemFieldAssociations') || {};
  const itemFieldAssociationsB = watch('B.itemFieldAssociations') || {};
  const syncDirection = watch('syncDirection');

  const missingItemFieldAssociationsA = Object.entries(itemFieldAssociationsA)
    .filter(([, field]) => !field)
    .map(([fieldId]) => fieldId);
  const missingItemFieldAssociationsB = Object.entries(itemFieldAssociationsB)
    .filter(([, field]) => !field)
    .map(([fieldId]) => fieldId);

  const incompleteItemFieldAssociationsA =
    !!Object.keys(itemFieldAssociationsA).length && missingItemFieldAssociationsA.length;
  const incompleteItemFieldAssociationsB =
    !!Object.keys(itemFieldAssociationsB).length && missingItemFieldAssociationsB.length;

  const providerIdA = useSelector((state) => getProviderByName(state, providerNameA).get('_id'));
  const providerIdB = useSelector((state) => getProviderByName(state, providerNameB).get('_id'));
  const providerASupportStringCustomField = useSelector((state) =>
    hasProviderCustomFieldOfTypeSupport(state, providerIdA),
  );
  const providerBSupportStringCustomField = useSelector((state) =>
    hasProviderCustomFieldOfTypeSupport(state, providerIdB),
  );

  const displayIncompleteMappingA =
    providerNameA && incompleteItemFieldAssociationsA && providerBSupportStringCustomField && syncDirection !== 'B';
  const displayIncompleteMappingB =
    providerNameA && incompleteItemFieldAssociationsB && providerASupportStringCustomField && syncDirection !== 'A';

  useEffect(() => {
    if (setErrors) {
      if (displayIncompleteMappingA) {
        setError(`${pageName}.incompleteItemFieldAssociationsA`, { type: 'manual' });
      } else if (displayIncompleteMappingB) {
        setError(`${pageName}.incompleteItemFieldAssociationsB`, { type: 'manual' });
      } else {
        clearErrors([`${pageName}.incompleteItemFieldAssociationsA`, `${pageName}.incompleteItemFieldAssociationsB`]);
      }
    }
  }, [setError, clearErrors, displayIncompleteMappingB, displayIncompleteMappingA, pageName, setErrors]);

  return [
    displayIncompleteMappingA ? missingItemFieldAssociationsA[0] : undefined,
    displayIncompleteMappingB ? missingItemFieldAssociationsB[0] : undefined,
  ];
}
