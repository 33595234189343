export const GET_ORGANIZATION_INVITES_REQUEST = 'GET_ORGANIZATION_INVITES_REQUEST';
export const GET_ORGANIZATION_INVITES_SUCCESS = 'GET_ORGANIZATION_INVITES_SUCCESS';
export const GET_ORGANIZATION_INVITES_FAILURE = 'GET_ORGANIZATION_INVITES_FAILURE';

export const GET_USER_PENDING_INVITES_REQUEST = 'GET_USER_PENDING_INVITES_REQUEST';
export const GET_USER_PENDING_INVITES_SUCCESS = 'GET_USER_PENDING_INVITES_SUCCESS';
export const GET_USER_PENDING_INVITES_FAILURE = 'GET_USER_PENDING_INVITES_FAILURE';

export const GET_INVITE_WORKSPACE_NAME_REQUEST = 'GET_INVITE_WORKSPACE_NAME_REQUEST';
export const GET_INVITE_WORKSPACE_NAME_SUCCESS = 'GET_INVITE_WORKSPACE_NAME_SUCCESS';
export const GET_INVITE_WORKSPACE_NAME_FAILURE = 'GET_INVITE_WORKSPACE_NAME_FAILURE';

export const UPDATE_INVITE_REQUEST = 'UPDATE_INVITE_REQUEST';
export const UPDATE_INVITE_FAILURE = 'UPDATE_INVITE_FAILURE';
export const UPDATE_INVITE_SUCCESS = 'UPDATE_INVITE_SUCCESS';

export const SHOW_INVITE_COWORKERS_MODAL = 'SHOW_INVITE_COWORKERS_MODAL';
export const HIDE_INVITE_COWORKERS_MODAL = 'HIDE_INVITE_COWORKERS_MODAL';

export const SEND_HUBSPOT_INVITE_REQUEST = 'SEND_HUBSPOT_INVITE_REQUEST';
export const SEND_HUBSPOT_INVITE_SUCCESS = 'SEND_HUBSPOT_INVITE_SUCCESS';
export const SEND_HUBSPOT_INVITE_FAILURE = 'SEND_HUBSPOT_INVITE_FAILURE';

export const SEND_HUBSPOT_REFERRAL_REQUEST = 'SEND_HUBSPOT_REFERRAL_REQUEST';
export const SEND_HUBSPOT_REFERRAL_SUCCESS = 'SEND_HUBSPOT_REFERRAL_SUCCESS';
export const SEND_HUBSPOT_REFERRAL_FAILURE = 'SEND_HUBSPOT_REFERRAL_FAILURE';

export const STATES = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
};

export const DISPLAY_TEXT_BY_STATE = {
  [STATES.PENDING]: 'Pending invite',
  [STATES.DECLINED]: 'Declined invite',
  [STATES.EXPIRED]: 'Expired invite',
};

export const INVITE_BY_EMAIL_FORM = 'INVITE_BY_EMAIL_FORM';
