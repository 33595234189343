import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { useTrackEvent } from '~/hooks/useTrackEvent';
import { RoutedModal } from '~/components/Modal/RoutedModal';

import { WorkBlockConfigurationForm } from '../WorkBlockConfigurationForm';

const STEP_URLS = {
  WORKBLOCK_CONFIGURATION: 'workblock',
  PLACEHOLDER_CONFIGURATION: 'placeholder',
  CONNECT_TOOL: 'connect',
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const WorkblockConfigurationModal = ({ onClose, onError, onSubmit, workflowId = null }) => {
  const matchParent = useRouteMatch();
  const matchAddBlock = useRouteMatch(`${matchParent.path}/blocks/add`, { exact: false });
  const trackEvent = useTrackEvent();

  if (!matchAddBlock) {
    return null;
  }

  const Title = (
    <Switch>
      <Route exact path={matchAddBlock.path}>
        Which tool do you want to connect?
      </Route>
      <Route exact path={`${matchAddBlock.path}/${STEP_URLS.WORKBLOCK_CONFIGURATION}`}>
        Where does the work happen?
      </Route>
      <Route exact path={`${matchAddBlock.path}/${STEP_URLS.PLACEHOLDER_CONFIGURATION}`}>
        Name your block of work
      </Route>
      <Route exact path={`${matchAddBlock.path}/${STEP_URLS.WORKBLOCK_CONFIGURATION}/${STEP_URLS.CONNECT_TOOL}`}>
        Before connecting to Unito...
      </Route>
    </Switch>
  );

  return (
    <RoutedModal
      closeOnClickOutside={false}
      onClose={onClose}
      path={matchAddBlock.path}
      size="small"
      title={Title}
      titleAlign="center"
      render={(routeProps) => (
        <Form>
          <Switch>
            <Route exact path={`${routeProps.match.path}/${STEP_URLS.WORKBLOCK_CONFIGURATION}`}>
              <WorkBlockConfigurationForm
                onError={onError}
                onSubmit={onSubmit}
                trackEvent={trackEvent}
                workflowId={workflowId}
              />
            </Route>
            <Redirect from={routeProps.match.url} to={`${routeProps.match.url}/${STEP_URLS.WORKBLOCK_CONFIGURATION}`} />
          </Switch>
        </Form>
      )}
    />
  );
};

WorkblockConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  workflowId: PropTypes.string,
};
