import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Typography, tokens } from '@unitoio/mosaic';

const ColoredText = styled.span`
  color: ${tokens.colors.content.neutral.n30};
`;

export function SecondaryInfo({ children, ...props }) {
  return (
    <Typography {...props}>
      <ColoredText>{children}</ColoredText>
    </Typography>
  );
}

SecondaryInfo.propTypes = {
  children: PropTypes.node,
};
