import PropTypes from 'prop-types';
import React from 'react';

import { Box, LoadingPlaceholder, Typography, tokens } from '@unitoio/mosaic';

import { useGetCustomFields, statuses } from '~/hooks/useGetCustomFields';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { useGetProviderIdentityIds } from '~/containers/FlowBuilder/hooks/useGetProviderIdentityIds';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';

import { ActivityItem } from './ActivityItem';
import { ErrorBlock } from './ErrorBlock';

export function ActivityBlock({ activityLogs, hasError, isLoadingActivityLogs }) {
  const [containerA, containerB] = useGetContainers();
  const [providerIdentityIdA, providerIdentityIdB] = useGetProviderIdentityIds();
  const [itemTypeA, itemTypeB] = useGetItemTypes();

  const customFieldsStatus = useGetCustomFields({
    containerIdA: containerA.get('id'),
    containerIdB: containerB.get('id'),
    providerIdentityIdA,
    providerIdentityIdB,
    itemTypeA,
    itemTypeB,
  });

  if (isLoadingActivityLogs || customFieldsStatus === statuses.LOADING) {
    return (
      <>
        {new Array(5).fill(null).map((_, index) => (
          <Box
            // Disabling this warning as the index is only use to render the placeholder.
            // No manipulation are done to the array so performance won't be impacted
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            borderRadius={tokens.spacing.s4}
            borderSize={1}
            p={[tokens.spacing.s4, tokens.spacing.s5]}
            flexDirection={Box.flexDirection.COLUMN}
            m={[0, 0, tokens.spacing.s4, 0]}
          >
            <Box m={[0, 0, 0, 0]}>
              <LoadingPlaceholder width="22" borderRadius={tokens.spacing.s4} height={tokens.spacing.s4} />
            </Box>
          </Box>
        ))}
      </>
    );
  }

  if (hasError || [statuses.ERROR_CF, statuses.ERROR_FA].includes(customFieldsStatus)) {
    return <ErrorBlock />;
  }

  return (
    <Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <Typography variant={Typography.variants.H4}>Latest activity for this item</Typography>
      </Box>
      {activityLogs.map((activityLog) => (
        <ActivityItem key={activityLog.id} activityLog={activityLog} />
      ))}
    </Box>
  );
}

ActivityBlock.propTypes = {
  activityLogs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      changeTime: PropTypes.number.isRequired,
      status: PropTypes.bool.isRequired,
      fields: PropTypes.arrayOf(PropTypes.string).isRequired,
      sourceConnectorName: PropTypes.string.isRequired,
      targetConnectorName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      link: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      publisherError: PropTypes.shape({
        retryable: PropTypes.bool,
      }),
    }),
  ).isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoadingActivityLogs: PropTypes.bool.isRequired,
};
