import { Map, List } from 'immutable';

export function providerNeedsManualConfiguration(provider) {
  const authMethods = getProviderAuthorizationMethods(provider);
  return (
    authMethods.size > 1 ||
    !getProviderConnectionParameters(provider).isEmpty() ||
    providerHasAuthenticationMethodFields(provider) ||
    providerHasSetupInstructions(provider)
  );
}

export function getProviderConnectionParameters(provider) {
  return provider.getIn(['capabilitiesV3', 'authentication', 'connectionParameters'], Map());
}

export function getProviderConnectionParameterFields(provider) {
  const connectionParameters = getProviderConnectionParameters(provider);
  return connectionParameters.get('fields', List());
}

export function providerHasAuthenticationMethodFields(provider) {
  const authorizations = provider.getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map());
  return authorizations.some((authMethod) => !authMethod.get('fields', List()).isEmpty());
}

export function providerHasSetupInstructions(provider) {
  const authorizations = provider.getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map());
  return authorizations.some((authMethod) => authMethod.get('setupInstructions', false));
}

export function getProviderAuthorizationMethodFields(provider, authorizationMethod) {
  const authorizationMethods = getProviderAuthorizationMethods(provider);
  return authorizationMethods.getIn([authorizationMethod, 'fields'], List());
}

export function authorizationMethodHasSetupInstructions(provider, authorizationMethod) {
  const authorizationMethods = getProviderAuthorizationMethods(provider);
  return authorizationMethods.getIn([authorizationMethod, 'setupInstructions'], false);
}

export function getProviderAuthorizationMethods(provider) {
  return provider.getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map());
}
