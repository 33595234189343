import React from 'react';
import styled from 'styled-components';

import * as trackingTypes from '~/consts/tracking';
import { Box, tokens } from '@unitoio/mosaic';
import { Title } from '~/components/Title/Title';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { ReferralForm } from './ReferralForm';

const Content = styled.div`
  .form-group {
    margin-bottom: ${tokens.spacing.s3};
  }
`;

const Ol = styled.ol`
  padding-left: ${tokens.spacing.s4};
`;

const Ul = styled.ul`
  list-style-type: disc;
  padding-left: ${tokens.spacing.s4};
`;

function RewardsContainer() {
  return (
    <Content className="workspace-settings">
      <div className="row">
        <Box m={[2, 0, 0, 0]} p={[0, 0, 0, 2]} className="col-sm-12">
          <Title type="h1">Refer contacts and earn rewards</Title>
          <p>
            Receive an <b>Amazon.com Gift Card</b> for each person who becomes a Unito customer!
          </p>
          <Ol>
            <li>Invite your friends, colleagues, and clients by submitting the form.</li>
            <li>We’ll send them a follow-up email mentioning you as the referrer.</li>
            <li>
              Once they become a paying customer, you’ll receive a gift card based on the plan they choose!
              <Ul>
                <li>
                  $25 for <b>Personal</b> plans
                </li>
                <li>
                  $85 for <b>Team</b> plans
                </li>
                <li>
                  $250 for <b>Company</b> plans
                </li>
              </Ul>
            </li>
          </Ol>
          <p>
            And as a bonus: your contact gets a <b>10% discount</b> on the first year of their plan.
          </p>
          <Box m={[2, 0, 0, 0]}>
            <Title type="h2">Who do you want to refer to Unito?</Title>
          </Box>
          <ReferralForm />
        </Box>
      </div>
    </Content>
  );
}

export const RewardsContainerWithTrackingFunnel = (props) => (
  <TrackingFunnel contextName={trackingTypes.SUB_PAGE.REWARDS}>
    <RewardsContainer {...props} />
  </TrackingFunnel>
);
