export const START = 'START';
export const ACTION = 'ACTION';
export const SUBMIT = 'SUBMIT';
export const BLOCKED = 'BLOCKED';
export const EVENT_NAME = {
  START,
  ACTION,
  SUBMIT,
  BLOCKED,
};

export const PAGE = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  EMBEDDED_AUTH: 'EMBEDDED_AUTH',
  FLOW_LIST: 'FLOW_LIST',
  FLOW_BUILDER: 'FLOW_BUILDER',
  WORKFLOW_LIST: 'WORKFLOW_LIST',
  MIRROR_LIST: 'MIRROR_LIST',
  WORKFLOW_PLAYGROUND: 'WORKFLOW_PLAYGROUND',
  PROFILE_SETTINGS: 'PROFILE_SETTINGS',
  WORKSPACE_SETTINGS: 'WORKSPACE_SETTINGS',
  ACTIVITY_STREAM: 'ACTIVITY_STREAM',
  EXPORT_AND_SYNC: 'EXPORT_AND_SYNC',
  NO_WORKSPACE: 'NO_WORKSPACE',
  GUIDE: 'GUIDE',
};

export const SUB_PAGE = {
  /** PROFILE_SETTINGS */
  PROFILE_INTEGRATIONS: 'PROFILE_INTEGRATIONS',

  /** WORKSPACE_SETTINGS */
  WORKSPACE_ABOUT: 'WORKSPACE_ABOUT',
  /** WORKSPACE_SETTINGS */
  WORKSPACE_USAGE: 'WORKSPACE_USAGE',
  /** WORKSPACE_SETTINGS */
  WORKSPACE_USERS: 'WORKSPACE_USERS',

  /** WORKSPACE_SETTINGS */
  WORKSPACE_BILLING: 'WORKSPACE_BILLING',
  /** WORKSPACE_SETTINGS_WORKSPACE_BILLING */
  BILLING_OVERVIEW: 'BILLING_OVERVIEW',
  /** WORKSPACE_SETTINGS_WORKSPACE_BILLING */
  BILLING_INFORMATION: 'BILLING_INFORMATION',
  /** WORKSPACE_SETTINGS_WORKSPACE_BILLING */
  BILLING_HISTORY: 'BILLING_HISTORY',

  /** WORKSPACE_SETTINGS */
  WORKSPACE_PRICING: 'WORKSPACE_PRICING',
  REWARDS: 'REWARDS',

  /**  SETTINGS_USAGE */
  SETTINGS_USAGE: 'SETTINGS_USAGE',

  /** SETTINGS_WORKSPACE */
  SETTINGS_WORKSPACE: `SETTINGS_WORKSPACE`,
};

// A journey is a goal (usually tied to KPIs)
export const JOURNEY = {
  FLOW_CREATE: 'FLOW_CREATE',
  FLOW_DUPLICATE: 'FLOW_DUPLICATE',
  FLOW_EDIT: 'FLOW_EDIT',
  WORKFLOW_DESIGNER: 'WORKFLOW_DESIGNER',
  AUTH: 'AUTH',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  DELETE_WORKSPACE: 'DELETE_WORKSPACE',
  SSO: 'SSO',
};

// Smaller scoped actions which MUST fit within a journey
// or a sub / root page
export const MODULE = {
  AUTH: 'AUTH',
  CHOOSE_PROJECT: 'CHOOSE_PROJECT',
  INVITE_MEMBER: 'INVITE_MEMBER',
  ADD_BLOCK: 'ADD_BLOCK', // blocks of a workflow
  WORKBLOCKS: 'WORKBLOCKS', // blocks of a flow
  GUIDE: 'GUIDE',
  TOOL_SELECTION: 'TOOL_SELECTION',
  FLOW_DIRECTION: 'FLOW_DIRECTION',
  RULES: 'RULES',
  MAPPINGS: 'MAPPINGS',
  OPTIONS: 'OPTIONS',
  PERMISSIONS: 'PERMISSIONS',
  PAYMENT: 'PAYMENT',
  SURVEY: 'SURVEY',
  CHANGE_CREDIT_CARD: 'CHANGE_CREDIT_CARD',
};

export const TRACK = 'TRACK';
export const TRACK_ANON = 'TRACK_ANON';

export const AUTH_SETUP = {
  AUTH_START: 'AUTH_START',
  AUTH_SUBMIT: 'AUTH_SUBMIT',
  AUTH_ENTER_URL_SUBMIT: 'AUTH_ENTER-URL_SUBMIT',
  AUTH_BLOCKED: 'AUTH_BLOCKED',
  AUTH_ONBOARDING_SUBMIT: 'AUTH_ONBOARDING_SUBMIT',
};

export const SETTINGS_MENU_EVENTS = {
  ACTION_NAME: 'SETTINGS_ACTION',
  ACTIONS: {
    CONNECTOR_SECTION: 'clicked on connectors',
    REPORTS_SECTION: 'clicked on reports',
    WORKSPACE_SECTION: 'clicked workspace',
    USAGE_SECTION: 'clicked usage',
    PEOPLE_SECTION: 'clicked people',
    REWARDS_SECTION: 'clicked earn rewards',
    CLICK_REWARDS_FORM_SUBMIT: 'clicked on submit to refer contacts and earn rewards',
    PRICING_SECTION: 'clicked pricing',
    BILLING_SECTION: 'clicked billing',
    ITEMS_IN_SYNC_SECTION: 'clicked items in sync',
    SWITCH_ORGANIZATION: 'clicked on switch organization',
    CURRENT_ORGANIZATION: 'clicked on current organization',
    DIFFERENT_ORGANIZATION: 'switched to a different organization',
  },
};

export const BANNER_EVENTS = {
  START: 'BANNER_START',
  ACTION: 'BANNER_ACTION',
  ACTIONS: {
    UPGRADE_PLAN: 'clicked on upgrade plan',
    KB_ARTICLE: 'clicked on KB Article',
  },
  TYPES: {
    OVER_PLAN_LIMIT: 'Over plan limit',
    AIRTABLE_MIGRATION: 'Legacy Airtable migration',
  },
};

const CONCEPT_NAMES = {
  BILLING: 'BILLING',
  CONNECTORS: 'CONNECTORS',
  PEOPLE: 'PEOPLE',
  USAGE: 'USAGE',
  USER_MENU: 'USER-MENU',
  WORKSPACE: 'WORKSPACE',
};

export const BILLING_EVENTS = {
  ACTION_NAME: `SETTINGS_${CONCEPT_NAMES.BILLING}_ACTION`,
  CONFIG_MIRROR: `${CONCEPT_NAMES.BILLING}_CONFIG-MIRROR_ACTION`,
  USER_UPDATED_CREDIT_CARD: 'USER_UPDATED_CREDIT_CARD',
  USER_UPDATED_CREDIT_CARD_BLOCKED: 'USER_UPDATED_CREDIT_CARD_BLOCKED',
  ACTIONS: {
    CLICK_CANCEL_SUBSCRIPTION: 'clicked on cancel subscription',
    SELECT_CANCEL_SUBSCRIPTION: 'submitted cancel account on pause or cancel modal',
    ON_SEND_RECEIPT: 'Turned Send me a copy of my receipt ON',
    OFF_SEND_RECEIPT: 'Turned Send me a copy of my receipt OFF',
    REDEEM_COUPON: 'redeemed coupon',
  },
};

export const CONNECTORS_EVENTS = {
  ACTION_NAME: `SETTINGS_${CONCEPT_NAMES.CONNECTORS}_ACTION`,
  ACTIONS: {
    ADD_CONNECTOR: 'clicked add new connector',
    REMOVE_CONNECTOR: 'disconnected connector',
  },
};

export const PEOPLE_EVENTS = {
  ACTION_NAME: `SETTINGS_${CONCEPT_NAMES.PEOPLE}_ACTION`,
  ACTIONS: {
    ADD_ONE_USER: 'clicked on add 1 active user (previously removed)',
    ADD_NEW_USER: 'invited new collaborator',
    INVITE_MEMBER: 'clicked invite existing Unito user',
    UPDATE_WORKSPACE_NAME: 'updated workspace name',
    REMOVE_ONE_USER: 'confirmed remove 1 active user',
    MERGE_UNITO_IDENTITIES: 'merged collaborators',
    EXCLUDED_ACTIVE_USER: 'excluded an active user',
    UNMERGE_UNITO_IDENTITIES: 'unmerged collaborators',
    MERGE_ATTEMPT_SAME_TOOL: 'tried to map collaborators with the same tool',
    SWITCH_ROLE_TO_ADMIN: 'changed user role to admin',
    SWITCH_ROLE_TO_MEMBER: 'changed user role to member',
  },
};

export const USAGE_EVENTS = {
  ACTION_NAME: `SETTINGS_${CONCEPT_NAMES.USAGE}_ACTION`,
  ACTIONS: {
    ACTIVATE_MIRROR: 'clicked activate mirror',
    CHOOSE_PLAN: 'clicked on plans',
    MANAGE_FEATURE: 'clicked on manage',
    CREATE_SYNC: 'clicked on create a sync',
    ADD_TO_TRELLO: 'clicked on add to trello',
    SEE_ALL_CHANGES: 'clicked on see all changes',
    SEE_DETAILS_ITEMS_IN_SYNC: 'clicked on details for items in sync',
    SEE_DETAILS_ACTIVE_USERS: 'clicked on details for active users',
    SEE_FLOW_LIST: 'clicked on go to flow list',
    SEE_MIRROR_LIST: 'clicked on go to mirror list',
  },
};

export const WORKSPACE_EVENTS = {
  ACTIONS: {
    SAVED_CHANGES: 'clicked save changes',
    DELETE_ACCOUNT: 'clicked delete my account',
  },
};

export const USER_DASHBOARD_EVENTS = {
  ACTION_NAME: 'DASHBOARD_ACTION',
  ACTIONS: {
    ADD_SYNC: 'clicked on "Add sync" button',
    ARROW: 'clicked on arrow button',
    ITEM_SYNC: 'clicked on "Sync" item in dropdown menu',
    USEFUL_LINK: 'clicked on useful link article',
    ACCESS_TRELLO: 'clicked on access trello',
    DUPLICATE_LINK: 'clicked duplicate',
    PLAN_LABEL: 'clicked on plan label',
    LOGO: 'clicked on logo',
    WEB_APP: 'clicked on link to web app',
  },
};

export const HEADER_BAR_EVENTS = {
  ACTION_NAME: 'HEADER-BAR_ACTION',
  ACTIONS: {
    PLAN_LABEL: 'clicked on plan label',
    DIAGNOSTICS: 'clicked on diagnostic',
    LAUNCHPAD: 'clicked on launchpad',
  },
};

export const USER_MENU_EVENTS = {
  ACTION_NAME: `${CONCEPT_NAMES.USER_MENU}_ACTION`,
  SWITCH_ORGANIZATION_ACTION: `${CONCEPT_NAMES.USER_MENU}_SWITCH-ORGANIZATION_ACTION`,
  ACTIONS: {
    VIEW: 'Viewed Menu',
    UPGRADE_NOW: 'EXCEEDED USER LIMIT: Clicked on Upgrade now',
    SEE_DETAILS: 'Clicked on See details',
    BILLING: 'Clicked on Billing',
    WORKSPACE: 'Clicked on Workspace',
    PRICING: 'Clicked on Pricing',
    LOG_OUT: 'Clicked on Log out',
    SWITCH_ORGANIZATION: 'Clicked on Switch organization',
    BACK_ORGANIZATION: 'Clicked on Back',
    CURRENT_ORGANIZATION: 'Clicked on current organization',
    DIFFERENT_ORGANIZATION: 'Switched to a different organization',
  },
};

export const USER_DROPDOWN_EVENTS = {
  USER_CLICKED_CANCEL_SUBSCRIPTION: 'USER_CLICKED_CANCEL_SUBSCRIPTION',
  USER_CLICKED_CHANGE_CARD: 'USER_CLICKED_CHANGE_CARD',
  USER_CLICKED_FAILED_PAYMENT_BANNER: 'USER_CLICKED_FAILED_PAYMENT_BANNER',
  USER_CLICKED_BLOCKED_AUTO_SYNC_BANNER: 'USER_CLICKED_BLOCKED_AUTO_SYNC_BANNER',
  USER_CLICKED_BILLING_HEADER_MENU_UPGRADE_NOW: 'USER_CLICKED_HEADER_MENU_UPGRADE_NOW',
  USER_CLICKED_BILLING_GAUGE_UPGRADE_NOW: 'USER_CLICKED_HEADER_GAUGE_UPGRADE_NOW',
  USER_CLICKED_BILLING_GAUGE_SEE_DETAILS: 'USER_CLICKED_HEADER_GAUGE_SEE_DETAILS',
  USER_CLICKED_BILLING_EXPERIMENT_1: 'USER_CLICKED_UPGRADE_BANNER',
  USER_CLICKED_UPGRADE_NOW_ADD_SYNC_BLOCKED: 'USER_CLICKED_UPGRADE_NOW_ADD_SYNC_BLOCKED',
  USER_CLICKED_UPGRADE_NOW_ADD_EDIT_SYNC_BLOCKED: 'USER_CLICKED_UPGRADE_NOW_ADD_EDIT_SYNC_BLOCKED',
  USER_CLICKED_BACK_SYNC_BLOCKED: 'USER_CLICKED_BACK_TO_DASHBOARD_SYNC_BLOCKED',
  USER_SAW_ADD_SYNC_BLOCKED: 'USER_SAW_ADD_SYNC_BLOCKED',
  USER_SAW_ADD_EDIT_SYNC_BLOCKED: 'USER_SAW_ADD_EDIT_SYNC_BLOCKED',
  USER_SAW_BILLING_EXPERIMENT_1: 'USER_SAW_UPGRADE_BANNER',
  USER_SAW_FAILED_PAYMENT_BANNER: 'USER_SAW_FAILED_PAYMENT_BANNER',
  USER_SAW_BLOCKED_AUTOSYNC: 'USER_SAW_BLOCKED_AUTOSYNC',
};

export const USER_PRICING_EVENTS = {
  USER_CLICKED_BILLING_INTERVAL: 'USER_CLICKED_BILLING_INTERVAL',
  USER_CLICKED_SELECT_PLAN: 'USER_CLICKED_SELECT_PLAN',
  PLAN_CHANGE: 'PLAN_CHANGE',
};

export const FUNNELS = {
  EDIT_SYNC: 'EDIT-SYNC',
  NEW_SYNC: 'NEW-SYNC',
  WORKFLOW: 'WORKFLOW',
  ORGANIZATION_REMOVE_MEMBER: 'REMOVE-USER',
  WORKFLOW_PLAYGROUND: 'WORKFLOW-PLAYGROUND',
  DASHBOARD: 'DASHBOARD',
  HEADER: 'HEADER',
  ITEM_DETAILS: 'ITEM_DETAILS',
  ACTIVITY_LOG: 'ACTIVITY_LOG',
  SIDE_PANEL: 'SIDE_PANEL',
};

export const WORKFLOW_FLOW_DUPLICATE = {
  FLOW_START: 'DUPLICATE-FLOW_START',
  FLOW_ACTION: 'DUPLICATE-FLOW_ACTION',
  FLOW_SUBMIT: 'DUPLICATE-FLOW_SUBMIT',
  ACTIONS: {
    NEW_FLOW: 'clicked on create a new flow',
    DUPLICATE_FLOW: 'clicked on apply existing settings',
    SELECTED_FLOW: 'selected an existing flow',
  },
};

export const LAZY_RESYNC = {
  START: 'EDIT-SYNC_LAZY-RESYNC_START',
  SUBMIT: 'EDIT-SYNC_LAZY-RESYNC_SUBMIT',
  ACTION: 'EDIT-SYNC_LAZY-RESYNC_ACTION',
};

export const ACTIVITY_LOG = {
  ACTIONS: {
    PASTE_URL: 'entered url to search work item',
    HELP_URL: 'clicked need help finding work item',
    WORK_ITEM_FOUND: 'work item found',
    NO_ITEM_FOUND: 'no recent sync activity for that work item',
    ERROR_SEARCHING: 'error trying to get work item information',
    OPEN_DIAGNOSTICS: 'clicked on open diagnostic',
    MORE_INFO: 'clicked on more info for a work item',
    CONTACT_SUPPORT: 'clicked contact support',
    CLICKED_ARTICLE: 'clicked on an article',
    CLICKED_OPEN_ITEM: 'clicked on open to access the work item',
    CLICKED_LOGO: 'clicked on logo',
    CLICKED_DROPDOWN: 'clicked on the drop down',
    CLICKED_LINK_ITEM: 'clicked on link to the item',
  },
};

export const FLOW_BUILDER_SIDE_PANEL = {
  ACTIONS: {
    CLICKED_ARTICLE: 'clicked on an article',
    CONTACT_SUPPORT: 'clicked contact support',
    NEED_HELP: 'clicked on need some help',
  },
};

export const AUTHENTICATION = {
  SIGNUP: {
    START: 'SIGNUP_START',
    ACTION: 'SIGNUP_ACTION',
    SUBMIT: 'SIGNUP_SUBMIT',
    BLOCKED: 'SIGNUP_BLOCKED',
  },
  LOGIN: {
    START: 'LOGIN_START',
    ACTION: 'LOGIN_ACTION',
    SUBMIT: 'LOGIN_SUBMIT',
    BLOCKED: 'LOGIN_BLOCKED',
  },
  ACTIONS: {
    CLICKED_UNITO_GUIDE: 'clicked Unito guide',
    CLICKED_GET_HELP: 'clicked Get help',
    CLICKED_TERMS_OF_SERVICE: 'clicked Terms of service',
    CLICKED_PRIVACY_POLICY: 'clicked Privacy policy',
    PRESELECT_TOOL: 'preselected tool based on intent',
    SELECT_TOOL: 'selected tool',
    RESET_PASSWORD: 'entered email to reset password',
    CHANGE_PASSWORD: 'password changed',
  },
};

export const DIAGNOSTIC = {
  ACTION: 'DIAGNOSTIC_ACTION',
};

export const VIRALITY = {
  VIRALITY: 'VIRALITY',
  EXPERIMENTS: {
    ADD_ANOTHER_COLLABORATOR: 'clicked add another collaborator',
    INVITE_USERS: 'invite users',
  },
};

export const CONNECTORS = {
  ACTION_NAME: 'CONNECTORS_ACTION',
  ACTIONS: {
    UPCOMING_INTEGRATIONS: 'clicked on vote for upcoming integrations',
  },
};

export const ADD_CONNECTOR_START = 'ADD_CONNECTOR_START';
export const ADD_CONNECTOR_ACTION = 'ADD_CONNECTOR_ACTION';
export const ADD_CONNECTOR_SUBMIT = 'ADD_CONNECTOR_SUBMIT';

export const ADD_BLOCK_START = 'ADD-BLOCK_START';
export const ADD_BLOCK_ACTION = 'ADD-BLOCK_ACTION';
export const ADD_BLOCK_SUBMIT = 'ADD-BLOCK_SUBMIT';
export const ADD_BLOCK_BLOCKED = 'ADD-BLOCK_BLOCKED';

export const TROUBLESHOOT_MESSAGE_ACTION = 'TROUBLESHOOT-MESSAGE_ACTION';
