import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUsageByOrganizationId, getUsageErrorByOrganizationId, isUsageLoadingByOrganizationId } from 'reducers';
import { organizationActions } from '~/actions';

export const useGetOrganizationUsage = (organizationId) => {
  const dispatch = useDispatch();

  const isUsageLoading = useSelector((state) => isUsageLoadingByOrganizationId(state, organizationId));
  const usage = useSelector((state) => getUsageByOrganizationId(state, organizationId));
  const usageError = useSelector((state) => getUsageErrorByOrganizationId(state, organizationId));
  const isUsageEmpty = usage?.isEmpty();
  const isUsageErrorEmpty = !usageError || usageError?.isEmpty();
  const shouldFetchUsage = !isUsageLoading && isUsageEmpty && isUsageErrorEmpty;
  useEffect(() => {
    const fetchUsage = async () => {
      if (shouldFetchUsage) {
        try {
          await dispatch(organizationActions.getUsage(organizationId));
        } catch (error) {
          // absorb because error is handled by the middleware/redux
        }
      }
    };
    fetchUsage();
  }, [dispatch, shouldFetchUsage, organizationId]);
  return {
    usage,
    isUsageLoading,
    usageError,
  };
};
