import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { getLinkById } from 'reducers';

import { errorTemplates } from '../components/SyncStatusForm/templates';

export const ANOMALY_LEVEL = {
  ERROR: 'error',
  WARNING: 'warning',
};

export const PAGES = {
  GUIDE: 'guide',
  CONNECT_TOOLS: 'tool-selection',
  RULES: 'rules',
  MAPPED_FIELDS: 'mappings',
};

export const ANOMALIES = {
  MISSING_FIELD: 'MissingField',
  MISSING_REQUIRED_FIELD: 'MissingRequiredField',
  MISSING_TIMESTAMP_FIELD: 'MissingTimestampField',
  READONLY_FIELD: 'ReadonlyField',
  PROJECT_INACCESSIBLE: 'ProjectInaccessible',
  INVALID_ACCESS_TOKEN: 'InvalidAccessToken',
  TOOL_INACCESSIBLE: 'ToolInaccessible',
  SUB_TASK_DISABLED: 'SubTaskDisabled',
  SUB_TASK_INACCESSIBLE: 'SubTaskInaccessible',
  TASK_INACCESSIBLE: 'TaskInaccessible',
  TOOL_TIMEOUT: 'ToolTimeout',
  TOOL_SLOW: 'ToolSlow',
  PROJECT_NOT_FOUND: 'ProjectNotFound',
  TASK_IN_WRONG_CONTAINER: 'TaskInWrongContainer',
  EMPTY_REQUIRED_FIELD: 'EmptyRequiredField',
  MISSING_MAPPED_FIELD: 'MissingMappedField',
  DUPLICATE_ITEM_COUNT: 'DuplicateItemCount',
  MAPPED_FIELD_VALUE_NOT_ACCEPTED: 'MappedFieldValueNotAccepted',
  INCOMPATIBLE_FILTER_TYPE: 'IncompatibleFilterType',
};

export const ANOMALIES_BY_PAGE = {
  [PAGES.CONNECT_TOOLS]: [
    ANOMALIES.PROJECT_INACCESSIBLE,
    ANOMALIES.INVALID_ACCESS_TOKEN,
    ANOMALIES.TOOL_TIMEOUT,
    ANOMALIES.TOOL_SLOW,
    ANOMALIES.PROJECT_NOT_FOUND,
    ANOMALIES.TASK_INACCESSIBLE,
    ANOMALIES.TOOL_INACCESSIBLE,
    ANOMALIES.TASK_IN_WRONG_CONTAINER,
  ],
  [PAGES.RULES]: [ANOMALIES.MISSING_FIELD, ANOMALIES.INCOMPATIBLE_FILTER_TYPE],
  [PAGES.MAPPED_FIELDS]: [
    ANOMALIES.MISSING_FIELD,
    ANOMALIES.SUB_TASK_DISABLED,
    ANOMALIES.SUB_TASK_INACCESSIBLE,
    ANOMALIES.READONLY_FIELD,
    ANOMALIES.MISSING_MAPPED_FIELD,
    ANOMALIES.EMPTY_REQUIRED_FIELD,
    ANOMALIES.MAPPED_FIELD_VALUE_NOT_ACCEPTED,
  ],
  [PAGES.GUIDE]: [ANOMALIES.DUPLICATE_ITEM_COUNT, ANOMALIES.MISSING_REQUIRED_FIELD, ANOMALIES.MISSING_TIMESTAMP_FIELD],
};

export function useGetAnomalies(linkId) {
  const currentLink = useSelector((state) => getLinkById(state, linkId));

  return currentLink.getIn(['syncStatus', 'anomaliesById'], Map());
}

export function useGetAnomaliesByLevel(linkId) {
  const anomalies = useGetAnomalies(linkId);
  const errors = anomalies.filter((anomaly) => {
    const categoryName = anomaly.get('categoryName');
    const template = errorTemplates[categoryName] || {};

    return template.level === ANOMALY_LEVEL.ERROR;
  });

  const warnings = anomalies.filter((anomaly) => {
    const categoryName = anomaly.get('categoryName');
    const template = errorTemplates[categoryName] || {};

    return template.level === ANOMALY_LEVEL.WARNING;
  });

  return [errors, warnings];
}

export function useGetAnomaliesByPage(linkId, page = PAGES.GUIDE) {
  const [errors, warnings] = useGetAnomaliesByLevel(linkId);
  const errorsByPage = errors.filter((anomaly) => ANOMALIES_BY_PAGE[page].includes(anomaly.get('categoryName')));
  const warningsByPage = warnings.filter((anomaly) => ANOMALIES_BY_PAGE[page].includes(anomaly.get('categoryName')));

  return [errorsByPage, warningsByPage];
}

export function useHasAnomaliesOnPage(linkId, page) {
  const [errorsByPage, warningsByPage] = useGetAnomaliesByPage(linkId, page);
  const hasSyncStatusErrors = !errorsByPage.isEmpty();
  const hasSyncStatusWarnings = !warningsByPage.isEmpty();

  return [hasSyncStatusErrors, hasSyncStatusWarnings];
}

export function useGetLabelAndStatusFromAnomalies(linkId, page) {
  const [hasSyncStatusErrors, hasSyncStatusWarnings] = useHasAnomaliesOnPage(linkId, page);
  const errorStatus = hasSyncStatusErrors ? 'error' : null;
  const warningStatus = hasSyncStatusWarnings ? 'review' : null;
  const labelFromAnomalies = errorStatus ? 'Review' : `Review ${page}`;
  const statusFromAnomalies = errorStatus ?? warningStatus;

  return [labelFromAnomalies, statusFromAnomalies];
}
