import PropTypes from 'prop-types';
import React from 'react';
import copy from 'copy-to-clipboard';

export const CopyToClipboard = ({ children, as = 'strong' }) => {
  const Element = as;
  return (
    <Element onClick={() => copy(children)} style={{ cursor: 'copy' }}>
      {children}
    </Element>
  );
};

CopyToClipboard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  as: PropTypes.oneOf(['span', 'div', 'strong', 'pre']),
};
