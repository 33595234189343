import React from 'react';
import PropTypes from 'prop-types';

import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import { Button } from '~/components/Button/Button';
import { Title } from '~/components/Title/Title';
import { Accordion, AccordionItem } from '~/components/Accordion/Accordion';
import { Href } from '~/components/Href/Href';

export const MirrorPowerUpPromotionalState = ({ trackEvent, organizationId }) => (
  <div>
    <div className="row">
      <div className="col-md-12">
        <Title style={{ marginBottom: '2rem' }} type="h2">
          Keep cards on different boards up to date.
        </Title>
      </div>
    </div>
    <div className="row">
      <div className="col-md-7">
        <Button
          type="href"
          btnStyle="dark"
          href="https://trello.com/power-ups/5c75708a72eecc4565ecacf5/enable"
          style={{ marginBottom: '2rem' }}
          onClick={() => trackEvent(trackingTypes.USAGE_EVENTS.ACTIONS.ADD_TO_TRELLO)}
        >
          Add to Trello
        </Button>
        <Accordion defaultItem={0}>
          <AccordionItem title="What is Mirror?">
            Mirror is a Trello Power-Up developed by Unito that allows cards to exist on several boards simultaneously.
            Any change made to one version of the card will be applied to all corresponding cards automatically.
          </AccordionItem>
          <AccordionItem title="How does it differ from other Unito Power-Ups?">
            <p>
              Unito's main functionality allows you to build workflows that span work, tools and teams. You can sync
              different lists and boards together in Trello for example. You could also sync a Trello board to a Jira or
              Asana project for a better cross-team collaboration.
            </p>
          </AccordionItem>
          <AccordionItem title="Is it included in my account?">
            Yes! <Href to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/pricing`}>Every plan</Href>{' '}
            includes the ability to create a number of Mirrors.
          </AccordionItem>
        </Accordion>
      </div>
      <div className="col-md-5">
        <iframe
          src="https://www.youtube.com/embed/RI3IX9Rfoys"
          frameBorder="0"
          allowFullScreen
          title="video"
          style={{ height: '12rem', width: '22rem', borderRadius: '0.25rem' }}
        />
      </div>
    </div>
  </div>
);

MirrorPowerUpPromotionalState.propTypes = {
  trackEvent: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
};
