import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Typography, tokens } from '@unitoio/mosaic';

const Number = styled(Typography)`
  height: ${tokens.spacing.s6};
  width: ${tokens.spacing.s6};
  margin: 0;
  border-radius: ${tokens.spacing.s3};
  border: 1px solid ${tokens.colors.content.neutral.n10};
  background: ${tokens.colors.global.primary.light};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GuideStepTitle = ({ title, number, locked = false }) => (
  <>
    <Number color={locked ? tokens.colors.content.neutral.n20 : tokens.colors.content.neutral.n40} variant="body1">
      {number}
    </Number>
    <Box p={[0, 0, 0, tokens.spacing.s3]}>
      <Typography
        color={locked ? tokens.colors.content.neutral.n20 : tokens.colors.content.neutral.n40}
        variant="body1"
      >
        {title}
      </Typography>
    </Box>
  </>
);

GuideStepTitle.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  locked: PropTypes.bool,
};
