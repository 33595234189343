import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Bold, NewButton, NewModal, NewTypography, Space, tokens } from '@unitoio/mosaic';

import AutomappingAnimation from './images/illustration-automapping.png';

const CustomSpace = styled(Space)`
  padding-bottom: ${tokens.spacing.s4};
`;
const AutomappingImage = styled.img`
  width: 80%;
  align-self: center;
`;
const ImageWrapper = styled(Space)`
  text-align: center;
`;
export function ChooseMappingStrategyModal({
  isOpen,
  onClose,
  generateFieldAssociations,
  hasCompleteFieldAssociations,
}) {
  const { setValue } = useFormContext();

  return (
    <NewModal
      open={isOpen}
      onCancel={onClose}
      footer={[
        <NewButton
          key="submit"
          onClick={() => {
            setValue('associations', [], { shouldDirty: false });
            onClose();
          }}
        >
          {hasCompleteFieldAssociations ? 'Clear mappings' : 'Map manually'}
        </NewButton>,
        <NewButton
          key="submit"
          type="primary"
          onClick={() => {
            generateFieldAssociations();
            onClose();
          }}
        >
          Map automatically
        </NewButton>,
      ]}
      cancelText="Map manually"
      okText="Map automatically"
      width={600}
    >
      <CustomSpace direction="vertical" size="large">
        <NewTypography.Title level={4}>Map fields automatically or manually?</NewTypography.Title>
        <ImageWrapper>
          <AutomappingImage src={AutomappingAnimation} alt="gif representing the auto-mapping feature" />
        </ImageWrapper>
        <NewTypography.Text>
          Unito can <Bold>automatically map fields</Bold> based on their name and type (recommended), or you can map
          them manually if you only want to sync few specific fields.
        </NewTypography.Text>
      </CustomSpace>
    </NewModal>
  );
}

ChooseMappingStrategyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  generateFieldAssociations: PropTypes.func.isRequired,
  hasCompleteFieldAssociations: PropTypes.bool.isRequired,
};
