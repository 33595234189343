import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import styled from 'styled-components';

import { Box, ProviderIcon, tokens } from '@unitoio/mosaic';

const Item = styled.span`
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.$bold ? tokens.fontWeight.fw7 : tokens.fontWeight.fw4)};
`;

export const ProviderItem = ({ provider, size = 'default', bold = false }) => (
  <Item $bold={bold} data-testid="provider-item">
    <ProviderIcon name={provider.get('name')} size={size} />
    <Box m={[0, 0, 0, tokens.spacing.s4]}>{provider.get('displayName')}</Box>
  </Item>
);

ProviderItem.propTypes = {
  bold: PropTypes.bool,
  provider: PropTypes.instanceOf(Map).isRequired,
  size: PropTypes.oneOf(['small', 'default', 'large']),
};
