import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color } from 'theme';

const Tr = styled.tr`
  opacity: ${(props) => props.$isDisabled && 0.65};
  &:hover td {
    background-color: ${color.brand.hintBlue};
  }
`;

export const TableRow = ({
  children,
  className = null,
  dataTestId,
  isDisabled = false,
  onMouseOver = () => null,
  onMouseOut = () => null,
}) => (
  <Tr
    className={className}
    onMouseOver={onMouseOver}
    onFocus={onMouseOver}
    onMouseOut={onMouseOut}
    onBlur={onMouseOut}
    $isDisabled={isDisabled}
    data-testid={dataTestId}
  >
    {children}
  </Tr>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  dataTestId: PropTypes.string,
};
