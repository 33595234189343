import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Icon, Tooltip, message, Spinner } from '@unitoio/mosaic';

import { providerIdentityActions } from '~/actions';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { DropdownItem } from '~/components/Dropdown/Dropdown';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import {
  getIsProviderIdentitiesLoading,
  getLinkById,
  getLinkProviderNameBySide,
  getProviderCapabilitiesV3,
  getUserId,
} from '~/reducers';

const DuplicateDropdownItemComponent = ({ link, linkId, capabilitiesV3A, capabilitiesV3B }) => {
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector((state) => getUserId(state));

  const isProviderIdentitiesLoading = useSelector((state) => getIsProviderIdentitiesLoading(state));
  const [canUserDuplicateFlow, setCanUserDuplicateFlow] = useState(true);

  const isDuplicableSync =
    !link.get('isSuspended') && link.get('kind') === linkTypes.KIND.MIRROR_SYNC && !link.get('restricted', false);

  const isMergeBasedFlow =
    capabilitiesV3A.getIn(['item', 'canMerge'], false) || capabilitiesV3B.getIn(['item', 'canMerge'], false);

  if (!isDuplicableSync) {
    return null;
  }

  const tooltipContent = isMergeBasedFlow
    ? 'Duplicating flows with contact based tools is not supported yet.'
    : "You'll need access to the accounts used in this flow in order to duplicate it.";

  const duplicatePath = routes.getDuplicateFlowBuilderRoute(linkId, 'tool-selection');

  const handleOnClickDuplication = async () => {
    const { providerIdentities: allProviderIdentities } = await dispatch(
      providerIdentityActions.getProviderIdentities(),
    );

    const identityIds = allProviderIdentities.filter((pi) => pi.users.includes(userId)).map((pi) => pi._id);

    const syncProviderIdentityA = link.getIn(['A', 'providerIdentity', '_id'], null);
    const syncProviderIdentityB = link.getIn(['B', 'providerIdentity', '_id'], null);
    const canDuplicate = identityIds.includes(syncProviderIdentityA) && identityIds.includes(syncProviderIdentityB);

    if (canDuplicate) {
      history.push(duplicatePath);
      trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTION_NAME, {
        action_name: trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.DUPLICATE_LINK,
      });
    } else {
      message.info({
        content: "You'll need access to the accounts used in this flow in order to duplicate it",
        duration: 5,
      });
      setCanUserDuplicateFlow(false);
    }
  };

  return (
    <DropdownItem disabled={!canUserDuplicateFlow || isMergeBasedFlow} onClick={handleOnClickDuplication}>
      <Tooltip content={tooltipContent} as="div">
        {isProviderIdentitiesLoading ? (
          <Spinner />
        ) : (
          <>
            <Icon name="clone" fixedWidth /> Duplicate
          </>
        )}
      </Tooltip>
    </DropdownItem>
  );
};

const mapStateToProps = (state, ownProps) => {
  const link = getLinkById(state, ownProps.linkId);

  const providerNameA = getLinkProviderNameBySide(state, { containerSide: 'A', linkId: ownProps.linkId });
  const providerNameB = getLinkProviderNameBySide(state, { containerSide: 'B', linkId: ownProps.linkId });

  const capabilitiesV3A = getProviderCapabilitiesV3(state, {
    providerName: providerNameA,
    itemType: link.getIn(['A', 'itemType']),
  });
  const capabilitiesV3B = getProviderCapabilitiesV3(state, {
    providerName: providerNameB,
    itemType: link.getIn(['B', 'itemType']),
  });

  return {
    link,
    capabilitiesV3A,
    capabilitiesV3B,
  };
};

DuplicateDropdownItemComponent.propTypes = {
  link: PropTypes.instanceOf(Map).isRequired,
  linkId: PropTypes.string.isRequired,
  capabilitiesV3A: PropTypes.instanceOf(Map).isRequired,
  capabilitiesV3B: PropTypes.instanceOf(Map).isRequired,
};

export const DuplicateDropdownItem = connect(mapStateToProps)(DuplicateDropdownItemComponent);
