import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { Box, Select, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import * as fieldTypes from '~/consts/fields';
import * as featureTypes from '~/consts/features';
import * as pcdFilterOperatorTypes from '~/consts/pcdFilterOperator';
import { formatDate } from '~/utils/formatDate';
import { PlanFeature } from '~/components/PlanFeature/PlanFeature';

import { ItemFieldsSelect } from './ItemFieldsSelect';
import { useGetDefaultTestModeDate } from '../pages/Rules/RulesSection/ClassicIntegrationRules/utils';

const statuses = [
  { label: 'Open', value: 'open' },
  { label: 'Open or archived', value: 'all' },
];

const { FEATURES } = featureTypes;

const StretchedBox = styled(Box)`
  width: 100%;
  gap: ${tokens.spacing.s3};
`;
export function RulesRowSpecialField({
  name,
  data = null,
  isLocked = false,
  containerId = null,
  containerSide,
  providerIdentityId = null,
  providerName = null,
  fieldDetails = {},
  itemType = null,
}) {
  const { setValue, register } = useFormContext();
  const { fieldId, value, kind, type } = data;
  const defaultTestModeDate = useGetDefaultTestModeDate(data.value);
  const testModeDate = fieldId === fieldTypes.EARLIEST_CREATED_AT ? defaultTestModeDate : null;
  return (
    <>
      {fieldId === fieldTypes.EARLIEST_CREATED_AT && (
        <StretchedBox p={[tokens.spacing.s0, tokens.spacing.s3]} flexDirection="row" alignItems="flex-start">
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              Creation date
            </Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              is on or after
            </Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              {formatDate(testModeDate)}
            </Box>
          </Typography>
        </StretchedBox>
      )}

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`${name}.kind`)} defaultValue={kind} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`${name}.type`)} defaultValue={type} />

      {data.fieldId === fieldTypes.INCLUDE_CLOSED_TASKS && (
        <StretchedBox flexDirection="row" alignItems="flex-start">
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              Status
            </Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              is
            </Box>
          </Typography>
          <PlanFeature name={FEATURES.CLOSED_TASKS}>
            {(canSyncClosedTasks) => (
              <Box m={[0, tokens.spacing.s2]}>
                <Select
                  name={`${name}.value`}
                  value={value}
                  readOnly={isLocked}
                  placeholder="Select a status"
                  options={statuses.map((status) => ({
                    ...status,
                    disabled: status.value === 'all' && !canSyncClosedTasks,
                  }))}
                  onChange={(newValue) => {
                    setValue(`${name}.value`, newValue, { shouldDirty: true });
                  }}
                />
              </Box>
            )}
          </PlanFeature>
        </StretchedBox>
      )}

      {data.fieldId === fieldTypes.SUBFOLDERS && (
        <StretchedBox p={[tokens.spacing.s0, tokens.spacing.s3]} flexDirection="row" alignItems="flex-start">
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              Subfolders
            </Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              are
            </Box>
          </Typography>
          <Box m={[0, tokens.spacing.s2]}>
            <Select
              name={`${name}.value`}
              value={value}
              options={[
                { value: 'included', label: 'Included' },
                { value: 'excluded', label: 'Excluded' },
              ]}
              onChange={(newValue) => {
                setValue(`${name}.value`, newValue, { shouldDirty: true });
              }}
            />
          </Box>
        </StretchedBox>
      )}

      {data.fieldId === fieldTypes.DEEP_FILTER_ITEM && (
        <StretchedBox p={[tokens.spacing.s0, tokens.spacing.s3]} flexDirection="row" alignItems="flex-start">
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              Associated object
            </Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              is
            </Box>
          </Typography>

          <Box m={[0, tokens.spacing.s2]}>
            <ItemFieldsSelect
              value={value}
              name={`${name}.value`}
              itemType={itemType}
              searchable={false}
              containerSide={containerSide}
              isFilterField
              providerIdentityId={providerIdentityId}
              providerName={providerName}
              containerId={containerId}
              isOptionDisabledHandler={({ id }) => ({ disabled: fieldDetails.values.includes(id) })}
              onChange={(newValue) => {
                setValue(`${name}.value`, newValue, { shouldDirty: true });
              }}
            />
          </Box>
        </StretchedBox>
      )}
      {(fieldId === fieldTypes.PAST_DATE || fieldId === fieldTypes.FUTURE_DATE) && (
        <StretchedBox p={[tokens.spacing.s0, tokens.spacing.s3]} flexDirection="row" alignItems="flex-start">
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              Date and time
            </Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >{`is in the ${fieldId === fieldTypes.PAST_DATE ? 'past' : 'upcoming'}`}</Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              {data.value}
            </Box>
          </Typography>
          <Typography variant={TypographyVariants.BODY2}>
            <Box
              backgroundColor={tokens.colors.background.neutral.grey}
              p={[tokens.spacing.s2, tokens.spacing.s3]}
              borderRadius={0.4}
            >
              Months
            </Box>
          </Typography>
        </StretchedBox>
      )}
    </>
  );
}

RulesRowSpecialField.propTypes = {
  name: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  containerSide: PropTypes.oneOf(['A', 'B']),
  providerName: PropTypes.string,
  providerIdentityId: PropTypes.string,
  containerId: PropTypes.string,
  // FIXME: PCDv3 - Remove fieldDetails as we don't need to differenciate when displaying if the field is a filter, set default value or component from mapping
  fieldDetails: PropTypes.shape({
    existingOperators: PropTypes.arrayOf(PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator))),
    canBeDenied: PropTypes.bool,
    canBeAllowed: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }),
  data: PropTypes.shape({
    operator: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)),
    fieldId: PropTypes.string,
    parentFieldId: PropTypes.string,
    // TODO PCDv3 - aug 28
    kind: PropTypes.oneOf([...Object.values(fieldTypes.KINDS), 'options']),
    type: PropTypes.string,
    id: PropTypes.string,
    _id: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])),
    ]),
  }),
  itemType: PropTypes.string,
};
