import { Box, tokens, Typography } from '@unitoio/mosaic';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getOrganizationPlanProfile, isOnFreeTrial, isOrganizationTrialExpired } from 'reducers';

import { PlanFeatureList } from './PlanFeatureList';

export const CurrentPlanDetail = () => {
  const { organizationId } = useParams();
  const currentPlan = useSelector((state) => getOrganizationPlanProfile(state));
  const isTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const isTrialExpired = useSelector((state) => isOrganizationTrialExpired(state, organizationId));

  return (
    <>
      <Box m={[0, 0, tokens.spacing.s5]}>
        <Typography variant={Typography.variants.H2}>
          Included in your {isTrialing || isTrialExpired ? 'trial' : 'plan'}
        </Typography>
      </Box>
      <PlanFeatureList icon="check" plan={currentPlan} />
    </>
  );
};
