import React, { Component } from 'react';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import { SyncDirectionIcon } from '~/components/SyncDirectionIcon/SyncDirectionIcon';

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 60px;
  background-color: ${tokens.colors.global.primary.light};
  border: 1px solid ${tokens.colors.content.neutral.n10};
  border-radius: ${tokens.spacing.s2};
  margin-bottom: ${tokens.spacing.s4};
  padding: 6px 12px;

  .dropdown-menu {
    border-top: 0;
    margin-right: -13px;
    margin-top: 18px;
  }
`;

const IconPlaceholder = styled.div`
  background-color: ${tokens.colors.content.neutral.n10};
  border-radius: ${tokens.spacing.s2};
  height: 20px;
  width: 20px;
  margin-top: ${tokens.spacing.s1};
  margin-left: ${tokens.spacing.s3};
  margin-right: ${tokens.spacing.s3};
`;

const LinkNameWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const LinkNamePlaceholder = styled.div`
  background-color: ${tokens.colors.content.neutral.n10};
  border-radius: ${tokens.spacing.s1};
  height: ${tokens.spacing.s4};
  margin-left: ${tokens.spacing.s4};
  width: ${(props) => props.$width};
`;

const TogglePlaceholder = styled.div`
  background-color: ${tokens.colors.content.neutral.n10};
  border-radius: 26px;
  height: 26px;
  width: 52px;
  margin-left: ${tokens.spacing.s3};
  margin-right: ${tokens.spacing.s3};
`;

const DropdownWrapper = styled.div`
  padding-left: 14px;
  padding-right: 14px;
`;

export class LinkItemLoading extends Component {
  state = {
    linkNamePlaceholderWidth: `${40 + Math.floor(Math.random() * 250)}px`,
  };

  render() {
    const { linkNamePlaceholderWidth } = this.state;

    return (
      <Item className="link-item">
        <IconPlaceholder />

        <SyncDirectionIcon color={tokens.colors.content.neutral.n10} readOnlyA={false} readOnlyB={false} />

        <IconPlaceholder />

        <LinkNameWrapper>
          <LinkNamePlaceholder $width={linkNamePlaceholderWidth} />
        </LinkNameWrapper>

        <TogglePlaceholder />

        <DropdownWrapper>
          <Icon
            name="chevron-down"
            color={tokens.colors.content.neutral.n10}
            kind={Icon.KINDS.SOLID}
            title="open dropdown"
          />
        </DropdownWrapper>
      </Item>
    );
  }
}
