import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Tooltip } from '@unitoio/mosaic';

import { color as themeColor } from 'theme';
import { CheckboxText } from '../CheckboxText/CheckboxText';

export class CheckboxField extends Component {
  static propTypes = {
    color: PropTypes.string,
    label: PropTypes.node.isRequired,
    input: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
    meta: PropTypes.shape({ error: PropTypes.string, asyncError: PropTypes.string, touched: PropTypes.bool })
      .isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    color: undefined,
    onChange: () => null,
  };

  handleOneChange = async (e) => {
    const { input, onChange } = this.props;
    onChange && onChange(e);
    await input.onChange(e);
  };

  render() {
    const { color, label, input, meta, ...rest } = this.props;
    const error = (meta.error || meta.asyncError) && meta.touched;

    return (
      <CheckboxText
        {...rest}
        {...input}
        onChange={this.handleOneChange}
        checked={!!input.value}
        color={error ? themeColor.brand.error : color}
      >
        {label}
        {error && <Tooltip forceShow={!!error} content={meta.error} />}
      </CheckboxText>
    );
  }
}
