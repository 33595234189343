import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

export const useContainerLinkTrackEvent = () => {
  const trackEvent = useTrackEvent();

  return (selectedToolName) =>
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on link to go to their container',
      selected_tool_name: selectedToolName,
    });
};
