import { useSelector } from 'react-redux';

import { getCapabilitiesForItem } from 'reducers';

export function useIsSideReadOnly(providerId, containerId, itemType) {
  const capabilities = useSelector((state) => getCapabilitiesForItem(state, providerId, containerId, itemType));

  // early return since we use this hook in drafts, where provider/container/itemType could be undefined
  if (capabilities.isEmpty()) {
    return false;
  }

  return !capabilities.get('canCreate', false);
}
