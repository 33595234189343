import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export function TextAreaInput({ className, helpText, input, label, meta = {}, placeholder, rows = 6, subLabel }) {
  const classNames = classnames('form-control', className);

  return (
    <div className="form-group">
      {label && <label htmlFor={input.name}>{label}</label>}
      {subLabel && <p>{subLabel}</p>}
      <textarea
        {...input} // eslint-disable-line
        className={classNames}
        rows={rows}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && (
        <div className="has-error">
          <div className="help-block">{meta.error}</div>
        </div>
      )}
      {helpText && <div className="help-block">{helpText}</div>}
    </div>
  );
}

TextAreaInput.propTypes = {
  className: PropTypes.string,
  helpText: PropTypes.node,
  input: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  label: PropTypes.node,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  subLabel: PropTypes.string,
};
