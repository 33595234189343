import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { tokens, Box, Typography, TypographyVariants, Segmented, notification } from '@unitoio/mosaic';

import { ErrorBoundary } from '@unitoio/sherlock';

import * as appActions from '~/actions/app';
import * as billingActions from '~/actions/billing';
import * as inviteActions from '~/actions/invites';
import * as appTypes from '~/consts/app';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import * as billingTypes from '~/consts/billing';
import {
  customerHasPaymentSource,
  getOrganizationPlanProfile,
  getOrganizationById,
  getPlans,
  getFeatureFlagValue,
  isOnFreeTrial,
  isOrganizationAccountPaying,
  getMaxUsageFeatureToDisplay,
} from 'reducers';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { Href } from '~/components/Href/Href';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { Loading } from '~/components/Loading/Loading';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';

import { useGetOrganizationUsage } from '~/hooks/useGetOrganizationUsage';
import { BillingOverviewErrorState } from '~/containers/billing/BillingContainer/BillingOverview/BillingOverviewErrorState';
import { PlanDetails, ConversionFeedbackModal } from './components';

const PlansWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14.5rem, 1fr));
`;

const IntervalContent = styled.div`
  text-align: center;
  margin-top: ${tokens.spacing.s4};
`;

function PricingContainer({ match, history }) {
  const { productName, organizationId } = match.params;
  const currentPlan = useSelector((state) => getOrganizationPlanProfile(state, organizationId));
  const currentPlanId = currentPlan.get('id');
  const currentPlanInterval = currentPlan.get('interval');
  const isPayingAccount = useSelector((state) => isOrganizationAccountPaying(state, organizationId));
  const defaultPlanInterval =
    isPayingAccount && currentPlanInterval === billingTypes.PLAN_INTERVALS.MONTH
      ? billingTypes.PLAN_INTERVALS.MONTH
      : billingTypes.PLAN_INTERVALS.YEAR;

  const [isLoading, setIsLoading] = useState(true);
  const [isSourceUpdating, setIsSourceUpdating] = useState(false);
  const [showPostConversionFeedbackModal, setShowPostConversionFeedbackModal] = useState(false);
  const [interval, setInterval] = useState(defaultPlanInterval);

  const trackEvent = useTrackEvent({}, false);
  const dispatch = useDispatch();

  const organization = useSelector((state) => getOrganizationById(state, organizationId));
  const orgCustomerId = organization.get('stripeCustomerId');

  const hasPaymentSource = useSelector((state) => customerHasPaymentSource(state, orgCustomerId));
  const plans = useSelector((state) => getPlans(state));
  const isOrgTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const variant = useSelector((state) => getFeatureFlagValue(state, 'plans-pricing-variant'));
  const yearlySavedPercentage = variant >= '20200915' ? 20 : 25;

  const valueMetricToShow = useSelector((state) => getMaxUsageFeatureToDisplay(state, organizationId));

  useEffect(() => {
    dispatch(appActions.setProductNameContext(appTypes.mapUrlParamToProductName[productName]));
  }, [productName, dispatch]);

  const { isUsageLoading, usageError } = useGetOrganizationUsage(organizationId);

  useEffect(() => {
    async function fetchPlanResources() {
      try {
        await Promise.all([
          dispatch(billingActions.getPlanRejectionReasons(organizationId, currentPlanId)),
          dispatch(billingActions.getOrganization(organizationId)),
        ]);
      } finally {
        setIsLoading(false);
      }
    }

    fetchPlanResources();
  }, [currentPlanId, orgCustomerId, organizationId, dispatch]);

  const handleChangeInterval = (newInterval) => {
    trackEvent(trackingTypes.USER_PRICING_EVENTS.USER_CLICKED_BILLING_INTERVAL, { interval: newInterval });
    setInterval(newInterval);
  };

  const handleRedirectToWorkspace = () => {
    dispatch(inviteActions.showInviteCoworkersModal());
    history.replace({ pathname: `${routes.ABSOLUTE_PATHS.BILLING}/${organizationId}/billing` });
  };

  const onChangeSubscription = async (planId, stripeToken) => {
    setIsSourceUpdating(true);
    try {
      const updatedToken = hasPaymentSource ? undefined : stripeToken;
      await updateSubscription(planId, updatedToken);
      if (isOrgTrialing) {
        setShowPostConversionFeedbackModal(true);
      } else {
        handleRedirectToWorkspace();
      }
    } finally {
      setIsSourceUpdating(false);
    }
  };

  const updateSubscription = async (planId, stripeToken) => {
    const response = await dispatch(billingActions.updateSubscription(organizationId, planId, stripeToken));
    notification.success({
      message: 'Subscription updated',
      description: 'Your billing information has been updated successfully and will be included in your next invoice.',
      placement: 'topRight',
    });
    return response;
  };

  const renderPlanDetailsByTier = (tierPlans, tier) => (
    <PlanDetails
      key={tier}
      hasPaymentSource={hasPaymentSource}
      interval={interval}
      currentPlan={currentPlan}
      nbPlans={tierPlans.size}
      onSelect={onChangeSubscription}
      plans={tierPlans}
      planTier={tier}
      valueMetricToShow={valueMetricToShow}
      orgCustomerId={orgCustomerId}
      isPayingAccount={isPayingAccount}
    />
  );

  const renderPlans = (allPlans) => {
    const possibleTiers = [
      billingTypes.PLAN_TYPES.PERSONAL,
      billingTypes.PLAN_TYPES.TEAM,
      billingTypes.PLAN_TYPES.COMPANY,
      billingTypes.PLAN_TYPES.BASIC,
      billingTypes.PLAN_TYPES.PRO,
      billingTypes.PLAN_TYPES.BUSINESS,
      billingTypes.PLAN_TYPES.SPREADSHEET,
    ];

    const selectedIntervalPlans = allPlans.filter((plan) => plan.get('interval') === interval);
    return possibleTiers.reduce((acc, tier) => {
      const tierPlans = selectedIntervalPlans.filter((plan) => plan.get('id').includes(tier));

      if (tierPlans.isEmpty()) {
        return acc;
      }

      return [...acc, renderPlanDetailsByTier(tierPlans, tier)];
    }, []);
  };

  if (isSourceUpdating) {
    return <Loading />;
  }

  return (
    <div className="billing-container">
      <Title type="h3">Pricing</Title>
      {usageError ? (
        <BillingOverviewErrorState />
      ) : (
        <>
          <IntervalContent>
            <Box
              m={[tokens.spacing.s5, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}
              p={[tokens.spacing.s0]}
              justifyContent="center"
            >
              <Segmented
                value={interval}
                onChange={handleChangeInterval}
                optionType="button"
                options={[
                  { label: 'Monthly', value: billingTypes.PLAN_INTERVALS.MONTH },
                  {
                    label: `Yearly (${yearlySavedPercentage}% off)`,
                    value: billingTypes.PLAN_INTERVALS.YEAR,
                  },
                ]}
              />
            </Box>
          </IntervalContent>

          <Section>
            {isLoading || isUsageLoading ? (
              <InlineLoading />
            ) : (
              <Box m={[tokens.spacing.s5, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
                <Typography variant={TypographyVariants.BODY2}>
                  All prices are in USD. Yearly plans are paid upfront, cover you for 12 months and are non-refundable.
                </Typography>

                <PlansWrapper>{renderPlans(plans)}</PlansWrapper>

                <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s5, tokens.spacing.s3]}>
                  <Href href="https://unito.io/pricing/">Explore plans in details on unito.io/pricing</Href>
                </Box>
              </Box>
            )}
          </Section>
          {showPostConversionFeedbackModal && (
            <TrackingFunnel contextName={trackingTypes.MODULE.SURVEY}>
              <ConversionFeedbackModal
                isOpen={showPostConversionFeedbackModal}
                onClose={() => setShowPostConversionFeedbackModal(false)}
                onSubmit={handleRedirectToWorkspace}
              />
            </TrackingFunnel>
          )}
        </>
      )}
    </div>
  );
}
function PricingContainerWithErrorBoundary(props) {
  const { reportException } = useLogger();

  return (
    <ErrorBoundary
      FallbackComponent={BillingOverviewErrorState}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, { ...errMessageContext, componentStack })
      }
    >
      <PricingContainer {...props} />
    </ErrorBoundary>
  );
}

function PricingContainerWithTrackingFunnel(props) {
  return (
    <TrackingFunnel contextName={trackingTypes.SUB_PAGE.WORKSPACE_PRICING}>
      <PricingContainerWithErrorBoundary {...props} />
    </TrackingFunnel>
  );
}

PricingContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export { PricingContainerWithTrackingFunnel as PricingContainer };
