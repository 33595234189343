import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { Select } from '@unitoio/mosaic';

const MaxWidthSelect = styled(Select)`
  max-width: 100%;
  min-width: ${(props) => (props.$fullWidth ? 'auto' : '0')};
`;

export const SelectField = ({
  onChange: onChangeField = () => null,
  placeholder,
  options,
  name,
  value,
  fullWidth = false,
  ...rest
}) => {
  const {
    field: { onChange: formOnChange, value: formValue },
  } = useController({ name, defaultValue: value ?? null });

  return (
    <MaxWidthSelect
      onChange={(changedValue, option) => {
        onChangeField(changedValue, option);
        return formOnChange(changedValue);
      }}
      placeholder={placeholder}
      value={value ?? formValue}
      options={options}
      $fullWidth={fullWidth}
      showTruncatedTitlesInTooltip
      {...rest}
    />
  );
};

SelectField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool),
      ]),
      searchableLabel: PropTypes.string,
      icon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['provider', 'icon', 'avatar']).isRequired,
        image: PropTypes.string,
      }),
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.bool,
            PropTypes.arrayOf(PropTypes.bool),
          ]).isRequired,
          searchableLabel: PropTypes.string,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
};
