import PropTypes from 'prop-types';
import React from 'react';

import { NewTag, Box, Typography, tokens, IconWrapper } from '@unitoio/mosaic';

export const GuideStepTitleWithTime = ({ title, time, icon }) => (
  <>
    <IconWrapper icon={icon} size="xl" backgroundColor={tokens.colors.background.message.info} />
    <Box p={[0, 0, 0, tokens.spacing.s4]}>
      <Box as={Typography} variant="h4" m={[0]}>
        {title}
      </Box>
      <NewTag bordered={false}>About {time} min</NewTag>
    </Box>
  </>
);

GuideStepTitleWithTime.propTypes = {
  title: PropTypes.node.isRequired,
  time: PropTypes.number.isRequired,
  icon: PropTypes.node.isRequired,
};
