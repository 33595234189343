export const GET_METRICS_REQUEST = 'GET_METRICS_REQUEST';
export const GET_METRICS_FAILURE = 'GET_METRICS_FAILURE';
export const GET_METRICS_SUCCESS = 'GET_METRICS_SUCCESS';

export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST';
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';

export const GET_ORGANIZATION_BY_ID_REQUEST = 'GET_ORGANIZATION_BY_ID_REQUEST';
export const GET_ORGANIZATION_BY_ID_FAILURE = 'GET_ORGANIZATION_BY_ID_FAILURE';
export const GET_ORGANIZATION_BY_ID_SUCCESS = 'GET_ORGANIZATION_BY_ID_SUCCESS';

export const GET_COLLABORATORS_REQUEST = 'GET_COLLABORATORS_REQUEST';
export const GET_COLLABORATORS_FAILURE = 'GET_COLLABORATORS_FAILURE';
export const GET_COLLABORATORS_SUCCESS = 'GET_COLLABORATORS_SUCCESS';

export const GET_USAGE_REQUEST = 'GET_USAGE_REQUEST';
export const GET_USAGE_SUCCESS = 'GET_USAGE_SUCCESS';
export const GET_USAGE_FAILURE = 'GET_USAGE_FAILURE';

export const GET_ITEMS_USAGE_REQUEST = 'GET_ITEMS_USAGE_REQUEST';
export const GET_ITEMS_USAGE_SUCCESS = 'GET_ITEMS_USAGE_SUCCESS';
export const GET_ITEMS_USAGE_FAILURE = 'GET_ITEMS_USAGE_FAILURE';

export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST';
export const GET_MEMBERS_FAILURE = 'GET_MEMBERS_FAILURE';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';

export const GET_COWORKERS_REQUEST = 'GET_COWORKERS_REQUEST';
export const GET_COWORKERS_FAILURE = 'GET_COWORKERS_FAILURE';
export const GET_COWORKERS_SUCCESS = 'GET_COWORKERS_SUCCESS';

export const GET_REPORT_URL_REQUEST = 'GET_REPORT_URL_REQUEST';
export const GET_REPORT_URL_FAILURE = 'GET_REPORT_URL_FAILURE';
export const GET_REPORT_URL_SUCCESS = 'GET_REPORT_URL_SUCCESS';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';

export const PATCH_COLLABORATOR_REQUEST = 'PATCH_COLLABORATOR_REQUEST';
export const PATCH_COLLABORATOR_SUCCESS = 'PATCH_COLLABORATOR_SUCCESS';
export const PATCH_COLLABORATOR_FAILURE = 'PATCH_COLLABORATOR_FAILURE';

export const GET_SYNC_ACTIVITY_REQUEST = 'GET_SYNC_ACTIVITY_REQUEST';
export const GET_SYNC_ACTIVITY_SUCCESS = 'GET_SYNC_ACTIVITY_SUCCESS';
export const GET_SYNC_ACTIVITY_FAILURE = 'GET_SYNC_ACTIVITY_FAILURE';

export const DELETE_WORKSPACE_REQUEST = 'DELETE_WORKSPACE_REQUEST';
export const DELETE_WORKSPACE_SUCCESS = 'DELETE_WORKSPACE_SUCCESS';
export const DELETE_WORKSPACE_FAILURE = 'DELETE_WORKSPACE_FAILURE';

export const STATUSES = {
  CANCELED: 'canceled',
  CHURNED: 'churned',
  DELINQUENT: 'delinquent',
  EXPIRED: 'expired',
  PAUSED: 'paused',
  PAYING: 'paying',
  TRIAL: 'trialing',
  TRIAL_EXPIRED: 'trial-expired',
  RESOLD: 'resold',
};

// Stripe IDs of some specific plans
export const PLANS = {
  TRIAL: 'trial',
  MIRROR_TRIAL: 'mirror-trial',
  CUSTOM_INVOICED: 'custom-invoiced',
  FREE_WITH_WRIKE: 'reseller-wrike',
  PAUSED: 'paused',
};

// Key contained in the id of some specific plans
export const PLAN_KEYS = {
  ENTERPRISE: 'enterprise_', // enterprise_base and enterprise_volume
};

export const VIP_PLAN = 'UNITO-EMP';

export const EDIT_ORG_NAME_FORM = 'EDIT_ORG_NAME_FORM';
export const EDIT_ORG_BILLING_EMAIL_FORM = 'EDIT_ORG_BILLING_EMAIL_FORM';
export const EDIT_ORG_SEND_EMAIL_RECEIPT_FORM = 'EDIT_ORG_SEND_EMAIL_RECEIPT_FORM';

export const PEOPLE_TYPES = {
  MEMBER: 'member',
  COWORKER: 'coworker',
};

export const PATCH_MEMBER_REQUEST = 'PATCH_MEMBER_REQUEST';
export const PATCH_MEMBER_SUCCESS = 'PATCH_MEMBER_SUCCESS';
export const PATCH_MEMBER_FAILURE = 'PATCH_MEMBER_FAILURE';

export const GET_ORGANIZATION_ROLES_REQUEST = 'GET_ORGANIZATION_ROLES_REQUEST';
export const GET_ORGANIZATION_ROLES_SUCCESS = 'GET_ORGANIZATION_ROLES_SUCCESS';
export const GET_ORGANIZATION_ROLES_FAILURE = 'GET_ORGANIZATION_ROLES_FAILURE';

export const ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
};

export const GET_FLAGS_REQUEST = 'GET_FLAGS_REQUEST';
export const GET_FLAGS_SUCCESS = 'GET_FLAGS_SUCCESS';
export const GET_FLAGS_FAILURE = 'GET_FLAGS_FAILURE';

export const UPDATE_FLAGS_LOCALLY = 'UPDATE_FLAGS_LOCALLY';

export const LD_ANONYMOUS_ORGANIZATION_ID = 'ANONYMOUS';

export const REMOVE_ORGANIZATION_MEMBER_REQUEST = 'REMOVE_ORGANIZATION_MEMBER_REQUEST';
export const REMOVE_ORGANIZATION_MEMBER_SUCCESS = 'REMOVE_ORGANIZATION_MEMBER_SUCCESS';
export const REMOVE_ORGANIZATION_MEMBER_FAILURE = 'REMOVE_ORGANIZATION_MEMBER_FAILURE';

export const IDENTIFY_ORGANIZATION_REQUEST = 'IDENTIFY_ORGANIZATION_REQUEST';
export const IDENTIFY_ORGANIZATION_SUCCESS = 'IDENTIFY_ORGANIZATION_SUCCESS';
export const IDENTIFY_ORGANIZATION_FAILURE = 'IDENTIFY_ORGANIZATION_FAILURE';

export const DAILY_ACTIVITY_TYPE = {
  LAST_REFRESHED_AT: 'lastRefreshedAt',
  COUNT_ITEMS_KEPT_IN_SYNC: 'countItemsInSync',
  COUNT_CHANGES_SYNCED_30D: 'countChangesSynced30d',
  COUNT_ITEMS_PER_LINK: 'countItemsPerLink',
  COUNT_DISTINCT_ITEMS_KEPT_IN_SYNC: 'countDistinctItemsInSync',
};
