import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Map } from 'immutable';

import { color } from 'theme';
import { getCustomFields, getContainerPlugins, getProviderVisibleFields } from 'reducers';
import { Button } from '~/components/Button/Button';
import { Card } from '~/components/Card/Card';
import { Href } from '~/components/Href/Href';
import { Title } from '~/components/Title/Title';
import * as linkActions from '~/actions/links';

const Params = styled.input`
  background: none;
  border: none;
  border-bottom: 2px solid #2ec16b;
  margin-top: 10px;
  padding: 2px 5px;
  width: 100%;
`;

const basicFunctions = [
  { label: 'Columns', name: 'getFieldValues', fieldContext: { field: 'column', kind: 'pcdTypedField' } },
  { label: 'Container by Id', name: 'getContainerById' },
  { label: 'Custom Fields', name: 'getCustomFields' },
  {
    label: 'CF values',
    name: 'getFieldValues',
    fieldContext: (sanitizedArg) => ({ field: sanitizedArg, kind: 'custom' }),
    argCount: 1,
  },
  { label: 'Labels', name: 'getFieldValues', fieldContext: { field: 'labels', kind: 'pcdTypedField' } },
  { label: 'Statuses', name: 'getFieldValues', fieldContext: { field: 'status', kind: 'pcdTypedField' } },
  { label: 'Task Types', name: 'getFieldValues', fieldContext: { field: 'taskType', kind: 'pcdTypedField' } },
  { label: 'Users', name: 'getUsers' },
  { label: 'Task Ids', name: 'getTaskIds', includeFilteredOutItems: true },
  { label: 'Filtered in Task Ids', name: 'getTaskIds', includeFilteredOutItems: false },
  { label: 'Tasks', name: 'getTasks', includeFilteredOutItems: true },
  { label: 'Filtered in Tasks', name: 'getTasks', includeFilteredOutItems: false },
  { label: 'Subtasks', name: 'getSubtasks' },
  { label: 'Subtasks by task Id', name: 'getSubtasks', argCount: 1 },
  { label: 'Task by Id', name: 'getTask', argCount: 1 },
  { label: 'Task by URL', name: 'getTaskByUrl', argCount: 1 },
  { label: 'Task History', name: 'getTaskEvents', argCount: 1 },
  { label: 'Force Sync Task by Id', name: 'flagItemAndSync', argCount: 1 },
  { label: 'Get Org Mirror cards Count', name: 'getOrganizationMirrorCardsCount' },
];

export function ConnectorInfo({ sync }) {
  const dispatch = useDispatch();

  const [Aarg, setAarg] = useState('');
  const [Barg, setBarg] = useState('');
  function getArgBySide(side) {
    return side === 'A' ? Aarg : Barg;
  }
  function setArgBySide(side, newState) {
    if (side === 'A') {
      setAarg(newState);
    } else {
      setBarg(newState);
    }
  }

  const [resyncFieldsA, setResyncFieldsA] = useState([]);
  const [resyncFieldsB, setResyncFieldsB] = useState([]);
  function getResyncFieldsBySide(side) {
    return side === 'A' ? resyncFieldsA : resyncFieldsB;
  }
  function setResyncFieldsBySide(side, newState) {
    if (side === 'A') {
      setResyncFieldsA(newState);
    } else {
      setResyncFieldsB(newState);
    }
  }

  const customFields = useSelector((state) =>
    Map({
      A: getCustomFields(state, {
        containerSide: 'A',
        providerName: sync.getIn(['A', 'providerName']),
        itemType: sync.getIn(['A', 'itemType']),
      }),
      B: getCustomFields(state, {
        containerSide: 'B',
        providerName: sync.getIn(['B', 'providerName']),
        itemType: sync.getIn(['B', 'itemType']),
      }),
    }),
  );

  const pcdFields = useSelector((state) =>
    Map({
      A: getProviderVisibleFields(
        state,
        sync.getIn(['A', 'providerIdentity', 'providerId']),
        sync.getIn(['A', 'itemType']),
        sync.getIn(['A', 'container', 'id']),
      ),
      B: getProviderVisibleFields(
        state,
        sync.getIn(['B', 'providerIdentity', 'providerId']),
        sync.getIn(['B', 'itemType']),
        sync.getIn(['B', 'container', 'id']),
      ),
    }),
  );

  const plugins = useSelector((state) =>
    Map({
      A: getContainerPlugins(state, sync.getIn(['A', 'container', 'id'])),
      B: getContainerPlugins(state, sync.getIn(['B', 'container', 'id'])),
    }),
  );

  function resyncLink(forcedFields, forcedSide) {
    dispatch(linkActions.syncLink(sync.get('_id'), true, forcedFields, forcedSide));
  }

  const callConnectorFn =
    (side, { name, includeFilteredOutItems, fieldContext, argCount }, arg) =>
    () => {
      // Convenience: strip starting/trailing double-quotes that you get when you copy from the JSONView
      const sanitizedArg = arg.startsWith('"') && arg.endsWith('"') ? arg.slice(1, -1).trim() : arg.trim();
      if (argCount && !sanitizedArg) {
        return;
      }

      dispatch(
        linkActions.callConnectorFn(
          sync.get('_id'),
          side,
          name,
          typeof fieldContext === 'function' ? fieldContext(sanitizedArg) : fieldContext,
          includeFilteredOutItems,
          sanitizedArg,
        ),
      );
    };

  return (
    <div className="row">
      {['A', 'B'].map((side) => (
        <div key={side} className="col-xs-6">
          <Card borderless color={color.dark.quiet} padding="1em">
            <Title type="h3">Connector infos</Title>
            <p>
              <strong>
                {side} - {sync.getIn([side, 'providerName'])}
                {' - '}
                {sync.getIn([side, 'container', 'url']) ? (
                  <Href target="_blank" href={sync.getIn([side, 'container', 'url'])}>
                    {sync.getIn([side, 'container', 'id'])}
                  </Href>
                ) : (
                  sync.getIn([side, 'container', 'id'])
                )}
              </strong>
              <br />
              providerIdentityId: {sync.getIn([side, 'providerIdentity', '_id'])} (
              {sync.getIn([side, 'providerIdentity', 'profileDisplayName']) ||
                sync.getIn([side, 'providerIdentity', 'profileUsername'])}
              )
              {sync.getIn([side, 'providerName']) === 'trello' && plugins?.get(side).size > 0 && (
                <span>
                  <br />⚠ Power-ups:{' '}
                  {plugins
                    .get(side)
                    .map((plugin) => (
                      <Href
                        target="_blank"
                        key={plugin.get('id')}
                        href={`https://trello.com/power-ups/${plugin.get('id')}`}
                      >
                        {plugin.get('name') || plugin.get('id')}
                      </Href>
                    ))
                    .reduce((prev, curr) => [prev, ', ', curr])}
                </span>
              )}
            </p>

            <Title type="h3">Fetch from Connector</Title>
            <div className="btn-toolbar">
              {basicFunctions.map((func) => (
                <Button
                  style={{ marginBottom: '5px' }}
                  key={func.label}
                  type="button"
                  size="xs"
                  reverse
                  onClick={callConnectorFn(side, func, getArgBySide(side))}
                >
                  {func.label}
                </Button>
              ))}
              <Params
                type="text"
                style={{ marginBottom: '10px' }}
                placeholder="Call parameters (if necessary)"
                onChange={(e) => {
                  setArgBySide(side, e.target.value);
                }}
              />
            </div>

            <Title type="h3">Force resync fields</Title>
            <div className="btn-toolbar">
              {pcdFields
                .get(side, Map())
                .map((_field, key) => (
                  <Button
                    style={{ marginBottom: '5px' }}
                    key={key}
                    type="button"
                    size="xs"
                    reverse
                    btnStyle="dark"
                    onClick={() => {
                      const resyncFields = getResyncFieldsBySide(side);
                      setResyncFieldsBySide(
                        side,
                        resyncFields.concat([
                          {
                            id: key,
                            kind: 'native',
                          },
                        ]),
                      );
                    }}
                  >
                    {key}
                  </Button>
                ))
                .toArray()}
              {customFields
                .get(side, Map())
                .map((field) => (
                  <Button
                    style={{ marginBottom: '5px' }}
                    key={field.get('id')}
                    type="button"
                    size="xs"
                    reverse
                    btnStyle="secondary"
                    onClick={() => {
                      const resyncFields = getResyncFieldsBySide(side);
                      setResyncFieldsBySide(
                        side,
                        resyncFields.concat([
                          {
                            id: field.get('id'),
                            kind: 'custom',
                          },
                        ]),
                      );
                    }}
                  >
                    {`${field.get('name')} (${field.get('id')})`}
                  </Button>
                ))
                .toArray()}
            </div>

            <div className="btn-toolbar">
              <div>
                We will resync these fields:{' '}
                {getResyncFieldsBySide(side)
                  .map((f) => f.id)
                  .join(', ')}
              </div>
            </div>

            <div className="btn-toolbar">
              <Button
                size="md"
                reverse
                disabled={getResyncFieldsBySide(side).length === 0}
                onClick={() => {
                  resyncLink(getResyncFieldsBySide(side), side);
                  setResyncFieldsBySide(side, []);
                }}
              >
                Start resync
              </Button>

              <Button
                size="md"
                reverse
                disabled={getResyncFieldsBySide(side).length === 0}
                onClick={() => {
                  setResyncFieldsBySide(side, []);
                }}
              >
                Clear fields
              </Button>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}

ConnectorInfo.propTypes = {
  sync: PropTypes.instanceOf(Map).isRequired,
};
