import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { tokens } from '@unitoio/mosaic';

const Box = styled.div`
  background-color: ${(props) => (props.$checked ? props.$color : props.$contrastColor)};
  border: 2px solid ${(props) => (props.$checked ? props.$color : tokens.colors.content.neutral.n30)};
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin: 2px;
  position: relative;
  vertical-align: middle;
  width: 18px;
`;

const Checkmark = styled.span`
  border: solid ${(props) => props.color};
  border-width: 0 2px 2px 0;
  height: 12px;
  left: 5px;
  position: absolute;
  transform: rotate(45deg);
  width: 5px;
`;

const Line = styled.span`
  border: solid ${(props) => props.color};
  border-width: 0 0px 2px 0;
  height: 6px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 8px;
`;

const checkTypes = {
  CHECKMARK: 'checkmark',
  LINE: 'line',
};

export class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    color: PropTypes.string,
    contrastColor: PropTypes.string,
    onChange: PropTypes.func,
    checkType: PropTypes.oneOf(Object.values(checkTypes)),
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    checkType: checkTypes.CHECKMARK,
    contrastColor: tokens.colors.global.primary.light,
    color: tokens.colors.content.primary.default,
    onChange: () => null,
    dataTestId: undefined,
  };

  getCheckType = () => {
    const { checkType } = this.props;
    if (checkType === checkTypes.CHECKMARK) {
      return Checkmark;
    }

    return Line;
  };

  render() {
    const { checked, color, contrastColor, onChange, dataTestId, ...rest } = this.props;
    const Check = this.getCheckType();

    return (
      <Box
        $checked={checked}
        $color={color}
        $contrastColor={contrastColor}
        onClick={() => onChange(checked)}
        data-test={rest['data-test']}
        title="checkbox"
      >
        {checked && <Check data-testid={dataTestId} color={contrastColor} />}
      </Box>
    );
  }
}
