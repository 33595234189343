import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Icon, tokens, Typography } from '@unitoio/mosaic';

const Circle = styled(Box)`
  min-width: ${tokens.spacing.s7};
  height: ${tokens.spacing.s7};
  border-radius: 50%;
`;

export const StepDot = ({ stepId, activeStep = 1, hasErrors = false, shouldColorDot = false }) => {
  const circleBackgroundColor = () => {
    if (shouldColorDot && !hasErrors) {
      return tokens.colors.content.branding.unito;
    }

    if (shouldColorDot && hasErrors) {
      return tokens.colors.content.destructive.default;
    }

    return tokens.colors.global.primary.light;
  };

  const circleBorderColor = () => {
    if (shouldColorDot && !hasErrors) {
      return tokens.colors.content.branding.unito;
    }
    if (shouldColorDot && hasErrors) {
      return tokens.colors.content.destructive.default;
    }
    return tokens.colors.content.neutral.n10;
  };

  const contentColor = () => {
    if (shouldColorDot) {
      return tokens.colors.global.primary.light;
    }
    return tokens.colors.global.primary.dark;
  };

  const circleContent = () => {
    if (!hasErrors) {
      if (activeStep > stepId) {
        return <Icon name="check" />;
      }
      return stepId;
    }
    return <Icon name="exclamation-triangle" kind="solid" size="lg" />;
  };

  return (
    <Circle
      backgroundColor={circleBackgroundColor()}
      color={contentColor()}
      borderColor={circleBorderColor()}
      justifyContent="center"
      alignItems="center"
    >
      <Typography color={contentColor()}>{circleContent()}</Typography>
    </Circle>
  );
};

StepDot.propTypes = {
  stepId: PropTypes.number.isRequired,
  activeStep: PropTypes.number,
  hasErrors: PropTypes.bool,
  shouldColorDot: PropTypes.bool,
};
