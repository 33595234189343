export const GET_UNITO_IDENTITIES_REQUEST = 'GET_UNITO_IDENTITIES_REQUEST';
export const GET_UNITO_IDENTITIES_SUCCESS = 'GET_UNITO_IDENTITIES_SUCCESS';
export const GET_UNITO_IDENTITIES_FAILURE = 'GET_UNITO_IDENTITIES_FAILURE';
export const UNMERGE_UNITO_IDENTITIES_REQUEST = 'UNMERGE_UNITO_IDENTITIES_REQUEST';
export const UNMERGE_UNITO_IDENTITIES_SUCCESS = 'UNMERGE_UNITO_IDENTITIES_SUCCESS';
export const UNMERGE_UNITO_IDENTITIES_FAILURE = 'UNMERGE_UNITO_IDENTITIES_FAILURE';
export const MERGE_UNITO_IDENTITIES_REQUEST = 'MERGE_UNITO_IDENTITIES_REQUEST';
export const MERGE_UNITO_IDENTITIES_SUCCESS = 'MERGE_UNITO_IDENTITIES_SUCCESS';
export const MERGE_UNITO_IDENTITIES_FAILURE = 'MERGE_UNITO_IDENTITIES_FAILURE';
export const STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  EXCLUDED: 'excluded',
};
