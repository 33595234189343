import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useParams, Switch } from 'react-router-dom';
import { useWatch } from 'react-hook-form';

import * as linkTypes from '~/consts/link';
import { useQueryParams } from '~/hooks/useQueryParams';

import { MappedFields } from '../pages/MappedFields';
import { AutopopulateContext } from './Autopopulate/tracking/AutopopulateContext';
import { Autopopulate } from './Autopopulate/Autopopulate';

export function MappedFieldsRouter(props) {
  const { match } = props;
  const { linkId } = useParams();
  const { showChooseMappingStrategy } = useQueryParams();
  const fieldAssociations = useWatch({ name: 'associations' });
  const linkState = useWatch({ name: 'state' });
  /**
   * `showChooseMappingStrategy === undefined` instead of `!showChooseMappingStrategy` because:
   * - showChooseMappingStrategy is undefined when: we haven't validated yet whether or not the Flow can be autopopulated -> We want to redirect to the autopopulate page
   * - showChooseMappingStrategy is true: when the Flow failed the validation -> We want to redirect to the choose method page
   * - showChooseMappingStrategy is false when: autopopulate has been triggered -> Whether it failed or succeeded we want to redirect to the mapping page
   */
  const mappingStrategyNotSet = showChooseMappingStrategy === undefined;
  const isDraftOrDuplicate = [linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.DUPLICATE].includes(linkState);
  const associationsAreEmpty = fieldAssociations.length === 0;
  const shouldShowAutopopulate = isDraftOrDuplicate && mappingStrategyNotSet && associationsAreEmpty;

  return (
    <Switch>
      <Route
        path={`${match.url}/auto-populate`}
        render={() => (
          <AutopopulateContext>
            <Autopopulate linkId={linkId} backToMappingUrl={match.url} />
          </AutopopulateContext>
        )}
      />
      <Route
        render={() => {
          if (shouldShowAutopopulate) {
            return <Redirect to={`${match.url}/auto-populate`} />;
          }
          return <MappedFields {...props} />;
        }}
      />
    </Switch>
  );
}

MappedFieldsRouter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};
