import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import { tokens } from '@unitoio/mosaic';

import { NoWrapHeader, TableCell } from '../components';

const Date = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const Cell = ({ value: epochDate }) => {
  const parsedValue = moment(epochDate);
  const datePart = parsedValue.format('DD MMM');
  const timePart = parsedValue.format('h:mm:ss A');
  return (
    <TableCell>
      <div className="no-wrap">
        <Date>{datePart}</Date> | {timePart}
      </div>
    </TableCell>
  );
};

Cell.propTypes = {
  value: PropTypes.number.isRequired,
};

export const timestampColumn = {
  header: () => <NoWrapHeader>Timestamp</NoWrapHeader>,
  accessor: 'changeTime',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
