import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Icon, tokens } from '@unitoio/mosaic';

export class SyncDirectionIcon extends Component {
  static propTypes = {
    color: PropTypes.string,
    multisync: PropTypes.bool,
    readOnlyA: PropTypes.oneOf([true, false, null]),
    readOnlyB: PropTypes.oneOf([true, false, null]),
    size: PropTypes.string,
  };

  static defaultProps = {
    color: undefined,
    multisync: false,
    size: undefined,
    readOnlyA: undefined,
    readOnlyB: undefined,
  };

  getIconName = () => {
    const { readOnlyA, readOnlyB, multisync } = this.props;

    if (multisync || (typeof readOnlyA !== 'boolean' && typeof readOnlyB !== 'boolean')) {
      return 'plus';
    }

    if (readOnlyA) {
      return 'long-arrow-right';
    }

    if (readOnlyB) {
      return 'long-arrow-left';
    }

    return 'exchange';
  };

  render() {
    const { color, size } = this.props;

    return <Icon color={color || tokens.colors.content.neutral.n40} size={size} name={this.getIconName()} />;
  }
}
