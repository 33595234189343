import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router';

import { Box, Icon, tokens, Typography } from '@unitoio/mosaic';

import { getSyncActivityStatus } from '~/utils/getSyncActivityStatus';
import * as linkTypes from '~/consts/link';
import { useGetSyncStatusActivityAndHealth } from '~/hooks/useGetSyncStatusActivityAndHealth';

import { useGetAnomaliesByLevel } from '../hooks/useGetAnomalies';

const BoldTypo = styled(Typography)`
  font-weight: ${tokens.fontWeight.fw7};
  margin-bottom: 1rem;
`;

const DynamicBox = styled(Box)`
  background-color: ${(props) => props.$backgroundColor};
`;

const TitleWrapper = styled(Box)`
  margin-left: 0.5rem;
`;

const activityStatuses = {
  [linkTypes.LINK_ACTIVITY_STATUS.DEGRADED]: {
    iconColor: tokens.colors.content.destructive.default,
    title: 'Unable to sync',
    description: 'There are issues to investigate preventing your flow’s ability to sync.',
    backgroundColor: tokens.colors.background.message.destructive,
    iconName: 'times-circle',
    kind: 'solid',
  },
  [linkTypes.LINK_ACTIVITY_STATUS.HEALTHY]: {
    iconColor: tokens.colors.content.positive.default,
    description: 'Your flow is healthy and information is syncing.',
    backgroundColor: tokens.colors.background.message.positive,
    iconName: 'check-circle',
    title: 'Healthy',
    kind: 'solid',
  },
  [linkTypes.LINK_ACTIVITY_STATUS.INACTIVE]: {
    backgroundColor: tokens.colors.background.message.info,
    iconColor: tokens.colors.content.secondary.default,
    description: 'No recent activity or changes synced in the past 3 months.',
    iconName: 'clock',
    title: 'No recent activity',
    kind: 'regular',
  },
  [linkTypes.LINK_ACTIVITY_STATUS.INITIALIZING]: {
    backgroundColor: tokens.colors.background.message.info,
    iconColor: tokens.colors.content.secondary.default,
    description: 'This flow is currently syncing information for the first time. This might take a while.',
    iconName: 'rotate',
    title: 'Initial sync in progress',
    kind: 'regular',
    spin: true,
  },
  [linkTypes.LINK_ACTIVITY_STATUS.SYNCING]: {
    backgroundColor: tokens.colors.background.message.info,
    iconColor: tokens.colors.content.secondary.default,
    description: 'Your flow is currently syncing or Unito is looking for changes.',
    iconName: 'rotate',
    title: 'Syncing',
    kind: 'regular',
    spin: true,
  },
  [linkTypes.LINK_ACTIVITY_STATUS.TRIGGERED]: {
    backgroundColor: tokens.colors.background.message.info,
    iconColor: tokens.colors.content.secondary.default,
    description: 'Your flow is currently syncing.',
    iconName: 'rotate',
    title: 'Syncing',
    kind: 'regular',
    spin: true,
  },
  [linkTypes.LINK_ACTIVITY_STATUS.WARNING]: {
    iconColor: tokens.colors.content.warning.default,
    title: 'Review suggested',
    description: 'A review is recommended, but your flow is still syncing.',
    backgroundColor: tokens.colors.background.message.warning,
    iconName: 'exclamation-triangle',
    kind: 'solid',
  },
  [linkTypes.LINK_ACTIVITY_STATUS.LOADING]: {
    backgroundColor: tokens.colors.background.message.info,
    description: 'Just a moment while we load the status of this flow.',
    iconColor: tokens.colors.content.secondary.default,
    iconName: 'spinner-third',
    kind: 'solid',
    spin: true,
    title: 'Loading',
  },
};

const SYNC_STATUS_LOADING_GRACE_PERIOD = 2000;

function getSyncStatusActivityBlock({
  anomalyOrHealthLevel = 'unavailable',
  backgroundColor,
  description,
  iconColor,
  iconName,
  kind,
  title,
  spin = false,
}) {
  return (
    <DynamicBox
      key={anomalyOrHealthLevel}
      borderRadius={1}
      $backgroundColor={backgroundColor}
      m={[tokens.spacing.s6, 0, 0]}
    >
      <Box p={[tokens.spacing.s5]}>
        <BoldTypo variant="body1">
          <Icon kind={kind} color={iconColor} name={iconName} spin={spin} />{' '}
          <TitleWrapper as="span">{title}</TitleWrapper>
        </BoldTypo>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </DynamicBox>
  );
}

export const SyncStatus = () => {
  const {
    params: { linkId },
  } = useRouteMatch();
  const [errors, warnings] = useGetAnomaliesByLevel(linkId);
  const [activity, health, linkState] = useGetSyncStatusActivityAndHealth(linkId);
  const [firstRenderTimestamp] = useState(Date.now());
  const now = Date.now();
  const hasErrors = !errors.isEmpty();
  const hasWarnings = !warnings.isEmpty();
  const hasActivity = !!activity;

  const activityStatus = getSyncActivityStatus(linkState, activity, health);

  if (!hasActivity && (hasErrors || hasWarnings)) {
    const anomalyMessagesToShow = [];

    if (hasErrors) {
      anomalyMessagesToShow.push(linkTypes.LINK_ACTIVITY_STATUS.DEGRADED);
    }

    if (hasWarnings) {
      anomalyMessagesToShow.push(linkTypes.LINK_ACTIVITY_STATUS.WARNING);
    }

    return anomalyMessagesToShow.map((level) => {
      const activityInformation = activityStatuses[level];

      return getSyncStatusActivityBlock({
        ...activityInformation,
        anomalyOrHealthLevel: level,
      });
    });
  }

  if (!activityStatus && now - firstRenderTimestamp < SYNC_STATUS_LOADING_GRACE_PERIOD) {
    return getSyncStatusActivityBlock(activityStatuses[linkTypes.LINK_ACTIVITY_STATUS.LOADING]);
  }

  const {
    backgroundColor = tokens.colors.background.message.info,
    description = 'There is an issue that is preventing us from loading the status of this flow.',
    iconColor = tokens.colors.content.secondary.default,
    iconName = 'times',
    kind = 'solid',
    spin = false,
    title = 'Status not available',
  } = activityStatuses[activityStatus] || {};

  return getSyncStatusActivityBlock({
    anomalyOrHealthLevel: activityStatus,
    backgroundColor,
    description,
    iconColor,
    iconName,
    kind,
    spin,
    title,
  });
};
