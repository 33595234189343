import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import * as formTypes from '~/consts/form';
import * as formUtils from '~/utils/forms';
import { PasswordInput } from '~/components/PasswordInput/PasswordInput';
import { Button } from '~/components/Button/Button';

const PasswordChecks = styled.ul`
  margin: 2rem;
`;

const Visiblity = styled.div`
  max-height: ${(props) => (props.$isVisible ? '400px' : 0)};
  overflow: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
  visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
  transition:
    max-height 0.7s ease-out,
    visibility 0.4s 0.2s ease-out;
`;

export const ResetChangeFields = ({ resetPassword, confirmPassword, disableSubmit = false }) => {
  const isSubmitBtnDisabled = () => {
    const passwordEmpty = formUtils.isEmpty(resetPassword.input.value);
    const confirmPasswordEmpty = formUtils.isEmpty(confirmPassword.input.value);
    const passwordInvalid = !!resetPassword.meta.error;
    const confirmPasswordInvalid = !!confirmPassword.meta.error;

    return disableSubmit || passwordEmpty || confirmPasswordEmpty || passwordInvalid || confirmPasswordInvalid;
  };

  const inputMaxLength = 60;

  return (
    <>
      <PasswordInput {...resetPassword} label="New password" maxLength={inputMaxLength} errorOnBlur />
      <PasswordInput {...confirmPassword} label="Confirm new password" maxLength={inputMaxLength} errorOnBlur />
      <Visiblity $isVisible={resetPassword.input.value}>
        <PasswordChecks className="fa-ul">
          {formUtils.passwordPolicies.map((policy, index) => (
            <li key={index}>
              {policy.validationFn(resetPassword.input.value) ? (
                <Icon
                  name="check"
                  kind={Icon.KINDS.SOLID}
                  color={tokens.colors.content.branding.unito}
                  title="valid"
                  listItem
                />
              ) : (
                <Icon
                  name="exclamation-triangle"
                  kind={Icon.KINDS.SOLID}
                  color={tokens.colors.content.warning.default}
                  title="invalid"
                  listItem
                />
              )}
              {policy.label}
            </li>
          ))}
        </PasswordChecks>
      </Visiblity>
      <Button block disabled={isSubmitBtnDisabled()} type="submit" size="lg">
        Change my password
      </Button>
    </>
  );
};

ResetChangeFields.propTypes = {
  resetPassword: PropTypes.shape(formTypes.fieldPropTypes).isRequired,
  confirmPassword: PropTypes.shape(formTypes.fieldPropTypes).isRequired,
  disableSubmit: PropTypes.bool,
};
