import styled from 'styled-components';

export const TableCell = styled.div`
  .no-wrap {
    white-space: nowrap;
  }

  .wide {
    width: 12.5rem;
  }

  .ellipsized {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .capitalized {
    text-transform: capitalize;
  }
`;
