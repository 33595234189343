import styled from 'styled-components';
import { borderRadius, color, fontWeight } from 'theme';

const LEFT = 'left';
const RIGHT = 'right';

export const InputGroup = styled.div`
  .input-group-addon {
    background-color: ${color.light.primary};
    border-${(props) => (props.$iconPosition === RIGHT ? LEFT : RIGHT)}: 0;
  }

  .input-group-addon-focus {
    border-color: ${color.brand.dark};
    box-shadow: none;
  }

  .form-control {
    border-${(props) => props.$iconPosition || LEFT}: 0;
  }
`;

export const Input = styled.input`
  border-radius: ${borderRadius.default};

  &:focus {
    border-color: ${color.brand.dark};
    box-shadow: none;
  }

  &&&::placeholder {
    color: #aaa;
    font-weight: ${fontWeight.regular};
  }
`;
