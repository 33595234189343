import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { tokens } from '@unitoio/mosaic';

import { ContainerIcon } from '~/components/ContainerIcon/ContainerIcon';
import { SyncDirectionIcon } from '~/components/SyncDirectionIcon/SyncDirectionIcon';
import { Box } from '~/components/Box/Box';

export function LinkContainerIcons({
  containerA = Map(),
  containerB = Map(),
  providerA = Map(),
  providerB = Map(),
  readOnlyA = false,
  readOnlyB = false,
}) {
  return (
    <Fragment>
      <Box $m={[0, 0.5, 0, 0]}>
        <ContainerIcon container={containerA} provider={providerA} tooltipPlacement="top" />
      </Box>

      <SyncDirectionIcon color={tokens.colors.content.secondary.default} readOnlyA={readOnlyA} readOnlyB={readOnlyB} />

      <Box $m={[0, 0, 0, 0.5]}>
        <ContainerIcon container={containerB} provider={providerB} tooltipPlacement="top" />
      </Box>
    </Fragment>
  );
}

LinkContainerIcons.propTypes = {
  containerA: PropTypes.instanceOf(Map),
  containerB: PropTypes.instanceOf(Map),
  providerA: PropTypes.instanceOf(Map),
  providerB: PropTypes.instanceOf(Map),
  readOnlyA: PropTypes.bool,
  readOnlyB: PropTypes.bool,
};
