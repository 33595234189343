import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';

import { useQueryParams } from '~/hooks/useQueryParams';

import { AuthGheOAuthAppStep1 } from './AuthGheOAuthAppStep1';
import { AuthGheOAuthAppStep2 } from './AuthGheOAuthAppStep2';

export function AuthGheInstructions({ children, match, onCancel, onSubmit, trackEvent }) {
  const [newUI, setNewUi] = useState(true);
  const { windowOpenerId } = useQueryParams();
  const switchUI = () => setNewUi(!newUI);

  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/step1?windowOpenerId=${windowOpenerId}`} />
      <Route
        exact
        path={`${match.path}/step1`}
        render={(routeProps) => (
          <AuthGheOAuthAppStep1
            {...routeProps}
            newUI={newUI}
            onCancel={onCancel}
            switchUI={switchUI}
            trackEvent={trackEvent}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/step2`}
        render={(routeProps) => (
          <AuthGheOAuthAppStep2
            {...routeProps}
            newUI={newUI}
            onCancel={onCancel}
            onSubmit={onSubmit}
            switchUI={switchUI}
            trackEvent={trackEvent}
          >
            {children}
          </AuthGheOAuthAppStep2>
        )}
      />
    </Switch>
  );
}

AuthGheInstructions.propTypes = {
  children: PropTypes.node,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
