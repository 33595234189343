import { useSyncExternalStore, useCallback, useEffect } from 'react';

import { useLogger } from './useLogger';

// Taken from: https://usehooks.com/uselocalstorage
// Implementation: https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js#L592-L652

function dispatchStorageEvent(key, newValue) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

const setLocalStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, JSON.stringify(null, null, 2));
};

const getLocalStorageItem = (key) => window.localStorage.getItem(key);

function useLocalStorageSubscribe(callback) {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
}

export function useLocalStorage(key, initialValue) {
  const { reportWarning } = useLogger();
  const getSnapshot = () => getLocalStorageItem(key);

  const value = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot);

  const setValue = useCallback(
    (newValue) => {
      if (newValue === undefined || newValue === null) {
        try {
          removeLocalStorageItem(key);
        } catch (error) {
          reportWarning(`An error occured while removing item ${key} from localStorage`, { error });
        }
      } else {
        try {
          setLocalStorageItem(key, newValue);
        } catch (error) {
          reportWarning(`An error occured while setting item ${key} and value ${newValue} in localStorage`, { error });
        }
      }
    },
    [key, reportWarning],
  );

  useEffect(() => {
    if (getLocalStorageItem(key) === null && typeof initialValue !== 'undefined') {
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [value ? JSON.parse(value) : initialValue, setValue];
}
