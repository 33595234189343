import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Bold, Link, Typography } from '@unitoio/mosaic';

import * as providerIdentityActions from '~/actions/providerIdentity';
import * as routes from '~/consts/routes';
import { getUserProviderIdentities } from 'reducers';
import { Box } from '~/components/Box/Box';

import { trackableHoC } from '../../containers/TrackableHoC/TrackableHoC';
import { ProviderIdentitiesSelect } from './ProviderIdentitiesSelect';

function useFetchAllowedProviderIdentityIdsForContainer({
  containerId,
  containerType,
  itemType,
  isEdit,
  providerIdentityId,
}) {
  const dispatch = useDispatch();
  const [allowedProviderIdentityIds, setAllowedProviderIdentityIds] = useState(List());

  useEffect(() => {
    async function fetchAllowedProviderIdentities() {
      if (!containerId || !containerType) {
        return;
      }
      const { providerIdentityIds } = await dispatch(
        providerIdentityActions.fetchAllowedProviderIdentities(
          providerIdentityId,
          containerId,
          containerType,
          itemType,
        ),
      );
      setAllowedProviderIdentityIds(List(providerIdentityIds));
    }

    fetchAllowedProviderIdentities();
  }, [containerId, containerType, itemType, dispatch, isEdit, providerIdentityId]);

  return allowedProviderIdentityIds;
}

function ToolAndAccountSelectComponent({
  containerId,
  containerType,
  isDuplicate = false,
  isEdit = false,
  isSideLocked = false,
  onChangeProviderIdentity = () => null,
  onOpenProviderIdentitiesSelect = () => null,
  providerId,
  providerIdentityId,
  disabledFields = { input: { value: [] }, meta: {} },
  itemType,
}) {
  const userProviderIdentities = useSelector((state) => getUserProviderIdentities(state, false)); // false because we don't want providers that are "onlyAuthenticate" (githubappuser)
  const allowedProviderIdentityIdsForContainer = useFetchAllowedProviderIdentityIdsForContainer({
    containerId,
    containerType,
    itemType,
    isEdit,
    providerIdentityId: providerIdentityId.input.value,
  });

  const providerIdentities = isEdit
    ? userProviderIdentities.filter((pi) => pi.get('providerId') === providerId.input.value)
    : userProviderIdentities;

  function handleOnChangeProviderIdentity(selectedProviderIdentityId, maybeProviderIdentity) {
    // when selecting a providerIdentity after auth, the PI is not in the list yet, so we have to send the full providerIdentity.
    // when selecting a PI that's already auth, no need. see ./ProviderIdentitiesSelect handleOnAddNewPI vs handleOnChange.
    const providerIdentity = providerIdentities.get(selectedProviderIdentityId) || Map(maybeProviderIdentity);
    const selectedProviderId = providerIdentity.get('providerId');

    providerId.input.onChange(selectedProviderId);
    providerIdentityId.input.onChange(selectedProviderIdentityId);
    onChangeProviderIdentity && onChangeProviderIdentity(providerIdentity);
  }

  return (
    <Box $m={[0, 0, 1.5, 0]}>
      <ProviderIdentitiesSelect
        allowedProviderIdentityIds={allowedProviderIdentityIdsForContainer}
        disabled={isDuplicate || disabledFields.input.value.includes('providerIdentityId')}
        isEdit={isEdit}
        isSideLocked={isSideLocked}
        onChangeProviderIdentity={onChangeProviderIdentity}
        onOpenProviderIdentitiesSelect={onOpenProviderIdentitiesSelect}
        providerId={providerId}
        providerIdentities={providerIdentities}
        providerIdentityId={{
          ...providerIdentityId,
          input: {
            ...providerIdentityId.input,
            onChange: handleOnChangeProviderIdentity,
          },
        }}
      />
      <Box $p={[1.5, 0, 0, 0]}>
        <Typography>
          <Bold>Note:</Bold> Certain tools are currently unavailable via the workflow designer. To sync items from those
          tools,
          <Link as={ReactRouterLink} to={`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_ADD}`}>
            create a new flow
          </Link>
          and select them there.
        </Typography>
      </Box>
    </Box>
  );
}

ToolAndAccountSelectComponent.propTypes = {
  containerId: PropTypes.string,
  containerType: PropTypes.string,
  itemType: PropTypes.string,
  isDuplicate: PropTypes.bool,
  isEdit: PropTypes.bool,
  isSideLocked: PropTypes.bool,
  onChangeProviderIdentity: PropTypes.func,
  onOpenProviderIdentitiesSelect: PropTypes.func,
  providerIdentityId: PropTypes.shape({
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
      onChange: PropTypes.func.isRequired,
    }),
    meta: PropTypes.shape({
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
      asyncError: PropTypes.string,
    }),
  }).isRequired,
  providerId: PropTypes.shape({
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string,
    }),
    meta: PropTypes.shape({
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
      asyncError: PropTypes.string,
    }),
  }).isRequired,
  disabledFields: PropTypes.shape({
    input: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    meta: PropTypes.shape({
      error: PropTypes.string,
      submitFailed: PropTypes.bool,
      asyncError: PropTypes.string,
    }),
  }),
};

export const ToolAndAccountSelect = trackableHoC(ToolAndAccountSelectComponent);
