import { Map, fromJS } from 'immutable';
import { actionTypes } from 'redux-form';

import * as fieldTypes from '~/consts/fields';
import { normalizeEntitiesById } from '~/utils/normalizeEntitiesById';

const { CUSTOM_FIELD, PCD_FIELD, PCD_TYPED_FIELD, PCD_TYPED_ITEM_FIELD } = fieldTypes.KINDS;

export const initialState = Map({
  [CUSTOM_FIELD]: Map(),
  [PCD_FIELD]: Map(),
  [PCD_TYPED_FIELD]: Map(),
  capabilitiesFieldTypes: Map(),
  isAutoMapping: false,
  isLoaded: Map({
    A: Map(),
    B: Map(),
  }),
  isLoading: Map({
    A: Map(),
    B: Map(),
  }),
  loadingFieldValues: Map({
    A: Map(),
    B: Map(),
  }),
  fieldPermissions: Map(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fieldTypes.GET_FIELD_VALUES_REQUEST: {
      const { containerSide, fieldId } = action.meta;
      return state.setIn(['loadingFieldValues', containerSide, fieldId], true);
    }

    case fieldTypes.GET_FIELD_VALUES_FAILURE: {
      const { containerSide, fieldId } = action.meta;

      return state.deleteIn(['loadingFieldValues', containerSide, fieldId]);
    }

    case fieldTypes.GET_CUSTOM_FIELDS_FAILURE: {
      const {
        meta: { containerSide },
      } = action;

      return state.setIn(['isLoading', containerSide, CUSTOM_FIELD], false);
    }

    case fieldTypes.GET_CUSTOM_FIELDS_REQUEST: {
      const {
        meta: { containerSide },
      } = action;

      return state.setIn(['isLoading', containerSide, CUSTOM_FIELD], true);
    }

    case fieldTypes.GET_CUSTOM_FIELDS_SUCCESS: {
      const {
        payload,
        meta: { containerSide },
      } = action;

      const customFields = normalizeEntitiesById(fromJS(payload.customFields));

      return state
        .setIn([CUSTOM_FIELD, containerSide], customFields)
        .setIn(['isLoading', containerSide, CUSTOM_FIELD], false)
        .setIn(['isLoaded', containerSide, CUSTOM_FIELD], true);
    }

    case fieldTypes.GET_FIELD_VALUES_SUCCESS: {
      const { containerSide, fieldId, kind } = action.meta;
      const fieldValues = fromJS(action.payload.fieldValues);
      const entitiesById = normalizeEntitiesById(fieldValues);

      let path = [kind, containerSide, fieldId];

      if (kind === CUSTOM_FIELD) {
        path = [...path, 'values'];
      }

      return state.mergeIn(path, entitiesById).deleteIn(['loadingFieldValues', containerSide, fieldId]);
    }

    case fieldTypes.GET_FIELD_VALUE_SUCCESS: {
      const { containerSide, fieldId, kind } = action.meta;
      const fieldValue = fromJS(action.payload.fieldValue);
      if (!fieldValue) {
        return state.deleteIn(['loadingFieldValues', containerSide, fieldId]);
      }

      let path = [kind, containerSide, fieldId];

      if (kind === CUSTOM_FIELD) {
        path = [...path, 'values'];
      }

      return state
        .setIn([...path, fieldValue.get('id')], fieldValue)
        .deleteIn(['loadingFieldValues', containerSide, fieldId]);
    }

    case fieldTypes.AUTOMAP_FIELD_VALUES_REQUEST: {
      return state.setIn(['isAutoMapping'], true);
    }

    case fieldTypes.AUTOMAP_FIELD_VALUES_SUCCESS: {
      const {
        meta: { fieldAssociation },
        payload,
      } = action;
      const fieldIdA = fieldAssociation.getIn(['A', 'field']);
      const fieldIdB = fieldAssociation.getIn(['B', 'field']);
      const kindA = fieldAssociation.getIn(['A', 'kind']);
      const kindB = fieldAssociation.getIn(['B', 'kind']);
      let pathA = [kindA, 'A', fieldIdA];
      let pathB = [kindB, 'B', fieldIdB];
      if (kindA === CUSTOM_FIELD) {
        pathA = [...pathA, 'values'];
      }
      if (kindB === CUSTOM_FIELD) {
        pathB = [...pathB, 'values'];
      }
      const valuesA = normalizeEntitiesById(fromJS(payload.A.values));
      const valuesB = normalizeEntitiesById(fromJS(payload.B.values));

      return state.setIn(pathA, valuesA).setIn(pathB, valuesB).setIn(['isAutoMapping'], false);
    }

    case fieldTypes.AUTOMAP_FIELD_VALUES_FAILURE: {
      return state.setIn(['isAutoMapping'], false);
    }

    case fieldTypes.RESET_STORE: {
      const capabilitiesFieldTypes = state.get('capabilitiesFieldTypes');
      return state.clear().merge(initialState).set('capabilitiesFieldTypes', capabilitiesFieldTypes);
    }

    case actionTypes.DESTROY: {
      const forms = action.meta.form || [];
      const capabilitiesFieldTypes = state.get('capabilitiesFieldTypes');
      if (forms.includes('syncForm') || forms.includes('multisyncForm')) {
        return state.clear().merge(initialState).set('capabilitiesFieldTypes', capabilitiesFieldTypes);
      }
      return state;
    }

    case fieldTypes.GET_CAPABILITIES_FIELD_TYPES_SUCCESS: {
      const { payload } = action;
      return state.set('capabilitiesFieldTypes', fromJS(payload.capabilitiesFieldTypes));
    }

    default: {
      return state;
    }
  }
};

export const isLoadedUsers = (state) => {
  const isLoadedA = !!state.getIn([PCD_TYPED_FIELD, 'A', 'users']);
  const isLoadedB = !!state.getIn([PCD_TYPED_FIELD, 'B', 'users']);

  return isLoadedA && isLoadedB;
};

export const getCustomFields = (state, containerSide) => state.getIn([CUSTOM_FIELD, containerSide], Map());

const getCustomFieldValuesById = (state, customFieldId, containerSide) => {
  const customFields = getCustomFields(state, containerSide);
  return customFields.getIn([customFieldId, 'values'], Map());
};

const getPcdFieldValuesByName = (state, pcdName, containerSide) =>
  state.getIn([PCD_FIELD, containerSide, pcdName], Map());

const getTypedPcdFieldValuesByName = (state, pcdName, containerSide) =>
  state.getIn([PCD_TYPED_FIELD, containerSide, pcdName], Map());

const getTypedItemPcdFieldValuesByName = (state, pcdName, containerSide) =>
  state.getIn([PCD_TYPED_ITEM_FIELD, containerSide, pcdName], Map());

export const getFieldValues = (state, kind, fieldId, containerSide) => {
  if (kind === PCD_FIELD) {
    return getPcdFieldValuesByName(state, fieldId, containerSide);
  }

  if (kind === PCD_TYPED_FIELD) {
    return getTypedPcdFieldValuesByName(state, fieldId, containerSide);
  }

  if (kind === PCD_TYPED_ITEM_FIELD) {
    return getTypedItemPcdFieldValuesByName(state, fieldId, containerSide);
  }

  return getCustomFieldValuesById(state, fieldId, containerSide);
};

export const getFieldById = (state, kind, fieldId, containerSide) => {
  if (kind === PCD_FIELD) {
    return state.getIn([PCD_FIELD, containerSide], Map());
  }

  if (kind === PCD_TYPED_FIELD) {
    return state.getIn([PCD_TYPED_FIELD, containerSide], Map());
  }

  if (kind === PCD_TYPED_ITEM_FIELD) {
    return state.getIn([PCD_TYPED_ITEM_FIELD, containerSide], Map());
  }

  const customFields = getCustomFields(state, containerSide);
  return customFields.get(fieldId, Map());
};

export const areCustomFieldsLoaded = (state, { containerSide }) =>
  state.getIn(['isLoaded', containerSide, CUSTOM_FIELD], false);

export const isLoadingFieldValue = (state, containerSide, fieldId) =>
  !!state.getIn(['loadingFieldValues', containerSide, fieldId]);

export const isLoadingFieldValues = (state) =>
  !state.getIn(['loadingFieldValues', 'A'], Map()).isEmpty() ||
  !state.getIn(['loadingFieldValues', 'B'], Map()).isEmpty();

export const getIsCustomFieldsLoading = (state) =>
  state.getIn(['isLoading', 'A', CUSTOM_FIELD], false) || state.getIn(['isLoading', 'B', CUSTOM_FIELD], false);

export const getIsAutoMapping = (state) => state.get('isAutoMapping');

export const getCapabilitiesFieldTypes = (state) => state.get('capabilitiesFieldTypes', Map());
