export const EXTRA_STEP_TYPE = {
  ADD_ON: {
    defaultName: 'Add-on',
    lowerCaseName: 'add-on',
  },
  ADD_IN: {
    defaultName: 'Add-in',
    lowerCaseName: 'add-in',
  },
};

const providerExtraStepMap = {
  googlesheets: EXTRA_STEP_TYPE.ADD_ON,
  excel: EXTRA_STEP_TYPE.ADD_IN,
};

export function useGetProviderExtraStep(providerName) {
  const extraStepType = providerExtraStepMap[providerName];

  return extraStepType || null;
}
