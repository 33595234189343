import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useGetItemTypes } from './useGetItemTypes';
import { getNativeFields } from '../../../reducers';
import { useGetProviderNames } from './useGetProviderNames';
import { useGetContainers } from './useGetContainers';

/**
 * This hook is used to get the fields of the providers (modern or classic) for a given link.
 *
 * @param {string} linkId - The ID of the link for which to get the provider fields.
 * @returns {Array} An array containing the fields of provider A and provider B.
 */
export const useGetProvidersFields = () => {
  const [providerNameA, providerNameB] = useGetProviderNames();

  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [containerA, containerB] = useGetContainers();

  const providerFieldsA = useSelector((state) =>
    getNativeFields(state, {
      providerName: providerNameA,
      containerId: containerA?.get('id'),
      itemType: itemTypeA,
    }),
  );

  const providerFieldsB = useSelector((state) =>
    getNativeFields(state, {
      providerName: providerNameB,
      containerId: containerB?.get('id'),
      itemType: itemTypeB,
    }),
  );

  return [providerFieldsA, providerFieldsB];
};

useGetProvidersFields.propTypes = {
  linkId: PropTypes.string.isRequired,
};
