import { Map } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Badge, LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import * as containerActions from '~/actions/containers';
import { FlowBuilderErrorContext } from '~/contexts';
import { getProviderCapabilitiesV3 } from 'reducers';
import { capitalize } from '~/utils/capitalize';
import { ContainerAlerts } from '~/containers/ContainerAlerts/ContainerAlerts';
import { BoxedAlert } from '~/components/BoxedAlert/BoxedAlert';
import { SelectField } from '~/components/SelectField/SelectField';

import { useFetchContainers } from '../../containers/FlowBuilder/hooks/useFetchContainers';
import { useProviderItemSupportsMerging } from '../../containers/FlowBuilder/hooks/useProviderItemSupportsMerging';
import { SelectorLabel } from './SelectorLabel';
import { Placeholder } from './Placeholder';
import * as formUtils from '../../containers/FlowBuilder/utils/form';

const BadgeSmallText = styled(Badge)`
  font-size: ${tokens.fontSize.f7};
  line-height: ${tokens.spacing.s4};
`;

export const MergeBasedContainerSelect = ({
  readOnly = false,
  valuesToDisable = [],
  side,
  provider,
  onChange = () => null,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const {
    watch,
    formState: { errors },
    register,
    setError,
    clearErrors,
  } = useFormContext();
  const selectedProviderName = watch(`${side}.providerName`);
  const selectedContainerId = watch(`${side}.containerId`);
  const providerIdentityId = watch(`${side}.providerIdentityId`);
  const selectedItemType = watch(`${side}.itemType`);
  const pageName = useContext(FlowBuilderErrorContext);

  const capabilitiesV3 = useSelector((state) =>
    getProviderCapabilitiesV3(state, {
      providerIdentityId,
      providerName: selectedProviderName,
      itemType: selectedItemType,
    }),
  );

  const itemSupportsMerge = useProviderItemSupportsMerging(selectedProviderName, selectedItemType);
  const itemTypeContainers = capabilitiesV3.get('containers');
  const fallbackContainerType = itemTypeContainers.first().getIn(['names', 'native']);
  const selectedContainerType = watch(`${side}.containerType`) || fallbackContainerType;
  const isSearchable = capabilitiesV3.getIn(['containers', selectedContainerType, 'searchable', 'isSearchable']);

  const [loadedState, containers] = useFetchContainers({
    containerId: selectedContainerId,
    containerType: selectedContainerType,
    providerIdentityId,
    isSearchable,
    searchValue: null,
    side,
    itemType: selectedItemType,
  });

  const rawContainerTerm = capabilitiesV3.getIn(['containers', selectedContainerType, 'names', 'singular'], 'project');
  const containerTerm = capitalize(rawContainerTerm);

  // if we have an actual container error (not auth related), we can display it here.
  const containerError = errors[pageName]?.[side]?.containerId?.error;

  // for contact based provider items, we implicitly set the itemType and containerTypes to fallback values for now.
  const providerDisplayName = provider.get('displayName');
  // filter out any containerId which might have already been selected on the other side (but apply logic only for one side)
  const filteredContainers =
    side === 'B' ? containers.filter(({ id }) => !valuesToDisable.some((disabledId) => disabledId === id)) : containers;
  let autoSelectedContainer;
  let autoSelectedContainerId;
  if (containers.length <= 1) {
    [autoSelectedContainer] = filteredContainers;
    autoSelectedContainerId = autoSelectedContainer?.id;
  }

  // logic here is kept to support item type and container type changes
  useEffect(() => {
    if (
      itemSupportsMerge &&
      selectedContainerType &&
      !selectedContainerId &&
      autoSelectedContainerId &&
      loadedState === formUtils.loadingStates.LOADED &&
      !readOnly
    ) {
      onChange(autoSelectedContainerId, selectedContainerType);
    }
  }, [
    onChange,
    itemSupportsMerge,
    selectedContainerId,
    selectedContainerType,
    autoSelectedContainerId,
    loadedState,
    readOnly,
  ]);

  const itemsTerm = capabilitiesV3.getIn(['item', 'names', 'plural']);
  const containerOptions = useMemo(
    () =>
      filteredContainers.map((container) => ({
        label: container.displayName,
        value: container.id,
      })),
    [filteredContainers],
  );

  async function validate(containerId, containerType) {
    if (!containerId || !containerType) {
      return;
    }

    try {
      const { container } = await dispatch(
        containerActions.getContainerById({
          providerIdentityId,
          containerId,
          containerType,
          itemType: selectedItemType,
          options: {
            displayError: false,
          },
        }),
      );
      if (container.errors?.length) {
        setError(`${pageName}.${side}.containerId`, { type: 'manual', error: container.errors[0] });
        return;
      }
    } catch (error) {
      setError(`${pageName}.${side}.containerId`, { type: 'manual', error: error?.message || 'An error occurred' });
    }
    clearErrors(`${pageName}.${side}.containerId`);
  }

  if (isLoading) {
    return <LoadingPlaceholder width={tokens.spacing.s9} height={tokens.spacing.s6} borderRadius={tokens.spacing.s4} />;
  }

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`${side}.containerId`)} defaultValue={autoSelectedContainerId} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`${side}.containerType`)} defaultValue={selectedContainerType} />
      <SelectorLabel withMargin label={`in the ${containerTerm}`}>
        {autoSelectedContainerId ? (
          <BadgeSmallText
            tooltipText={`This ${rawContainerTerm} contains ${itemsTerm} that were manually added or were added via an integration to ${providerDisplayName}`}
          >
            {capitalize(autoSelectedContainer.displayName)}
          </BadgeSmallText>
        ) : (
          <SelectField
            value={selectedContainerId}
            name={`${side}.containerId`}
            readOnly={readOnly}
            noResultsText={`No ${containerTerm} found`}
            searchPlaceholder={formUtils.getSearchContainerPlaceholdertext(capabilitiesV3, selectedContainerType)}
            optionsLoading={loadedState === formUtils.loadingStates.LOADING}
            isLoading={
              selectedContainerId &&
              loadedState !== formUtils.loadingStates.LOADED &&
              loadedState !== formUtils.loadingStates.ERROR
            }
            isError={!!containerError}
            searchable
            placeholder={Placeholder({ text: `Connect ${containerTerm}` })}
            onChange={async (value) => {
              await validate(value, selectedContainerType);
              onChange(value, selectedContainerType);
            }}
            options={containerOptions}
          />
        )}
      </SelectorLabel>
      {!autoSelectedContainerId && !filteredContainers.length && containers.length === 1 && (
        <BoxedAlert
          message={`The ${itemsTerm} ${rawContainerTerm} selected above has already been added to this flow. Please choose a different account to continue.`}
          level="info"
        />
      )}
      {containerError && <BoxedAlert message={containerError} />}
      <ContainerAlerts severity="warning" providerIdentityId={providerIdentityId} containerId={selectedContainerId} />
      <ContainerAlerts severity="error" providerIdentityId={providerIdentityId} containerId={autoSelectedContainerId} />
    </>
  );
};

MergeBasedContainerSelect.propTypes = {
  provider: PropTypes.instanceOf(Map).isRequired,
  side: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  valuesToDisable: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
};
