import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map, List } from 'immutable';

import * as trackingTypes from '~/consts/tracking';
import { getPlanTier } from '~/utils/getPlanTier';

import { Box, Modal, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';

export class BlockSelectPlanModal extends Component {
  static propTypes = {
    newPlan: PropTypes.instanceOf(Map).isRequired,
    onRequestClose: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    rejectionReasons: PropTypes.instanceOf(List).isRequired,
  };

  componentDidMount() {
    const { newPlan, trackEvent, rejectionReasons } = this.props;

    trackEvent(`${trackingTypes.SUB_PAGE.WORKSPACE_PRICING}_${trackingTypes.BLOCKED}`, {
      plan_selected: newPlan.get('id'),
      plan_tier_selected: getPlanTier(newPlan.get('id')),
      reason: rejectionReasons.toJS(),
    });
  }

  render() {
    const { newPlan, onRequestClose, rejectionReasons } = this.props;

    return (
      <Modal
        cancelLabel="Close"
        isOpen
        onRequestClose={onRequestClose}
        title={
          <span>
            Sorry, you can't pick the <em>{newPlan.get('nickname') || newPlan.get('name')}</em> plan
          </span>
        }
        onConfirm={onRequestClose}
      >
        <div>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s5, tokens.spacing.s0]}>
            <Typography variant={TypographyVariants.BODY1}>
              You're using Unito beyond the limits of that plan. Here's how: <br />
            </Typography>
            <ul>
              {rejectionReasons
                .map((reason, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${reason}-${index}`}>
                    <Typography variant={TypographyVariants.BODY1}>{reason}</Typography>
                  </li>
                ))
                .toArray()}
            </ul>
          </Box>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s5, tokens.spacing.s0]}>
            <Typography variant={TypographyVariants.BODY1}>
              Please choose a plan better suited for your usage or reconfigure your flows to address the list above.
              Have questions about usage or pricing?
            </Typography>
          </Box>
          <ChatBubble type="default">Get in touch with our team</ChatBubble>
        </div>
      </Modal>
    );
  }
}
