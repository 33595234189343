import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex, Button, Layout, NewTypography as Typography, tokens, Result } from '@unitoio/mosaic';
import ErrorEmptyResult from '~/images/ErrorEmptyResult.svg';

import { UnitoLogo } from '~/components/UnitoLogo/UnitoLogo';
import * as routes from '~/consts/routes';
import { getSearchParams } from '~/utils/getSearchParams';

const EmptyErrorImage = <img alt="Auth expired" src={ErrorEmptyResult} />;

const TERMS_BY_TYPE = {
  login: 'login',
  signup: 'signup',
};

const TITLES_BY_TYPE = {
  login: 'The login session has expired',
  signup: 'The signup session has expired',
};

const REDIRECT_URL_BY_TYPE = {
  login: routes.ABSOLUTE_PATHS.LOGIN,
  signup: routes.ABSOLUTE_PATHS.SIGNUP,
};

export const AuthExpired = () => {
  const history = useHistory();
  const searchParams = getSearchParams(history.location.search);
  const { type } = searchParams;
  const term = TERMS_BY_TYPE[type] || TERMS_BY_TYPE.login;
  const title = TITLES_BY_TYPE[type] || TITLES_BY_TYPE.login;
  const redirectUrl = REDIRECT_URL_BY_TYPE[type] || REDIRECT_URL_BY_TYPE.login;
  return (
    <Layout style={{ backgroundColor: tokens.colors.global.primary.light, height: '100vh' }}>
      <Flex vertical>
        <Flex
          align="center"
          justify="flex-start"
          style={{ height: '70px', padding: `${tokens.spacing.s4} 0 0 ${tokens.spacing.s4}` }}
        >
          <Typography.Link href={`/#${redirectUrl}`}>
            <UnitoLogo kind="horizontal" color="color" width="132px" />
          </Typography.Link>
        </Flex>
        <Flex align="center" justify="center" vertical style={{ marginTop: 158 }}>
          <Result
            extra={
              <Button type="primary" onClick={() => history.replace(redirectUrl)}>
                Back to {term}
              </Button>
            }
            icon={EmptyErrorImage}
            title={<Typography.Title level={3}>{title}</Typography.Title>}
            subTitle={<Typography.Text type="secondary">Please, go back to the page and try again</Typography.Text>}
          />
        </Flex>
      </Flex>
    </Layout>
  );
};
