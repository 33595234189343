export const pcdFilterOperator = {
  EQ: 'EQUAL',
  NEQ: 'NOT_EQUAL',
  GT: 'GREATER_THAN',
  LT: 'LESS_THAN',
  LTE: 'LESS_OR_THAN',
  GTE: 'GREATER_OR_THAN',
  IN: 'IN',
  NIN: 'NOT_IN',
  MATCHES_REGEX: 'MATCHES_REGEX',
  NOT_MATCHES_REGEX: 'NOT_MATCHES_REGEX',
  STARTS_WITH: 'STARTS_WITH',
  ENDS_WITH: 'ENDS_WITH',
  EXISTS: 'EXISTS',
  NOT_EXISTS: 'NOT_EXISTS',
  // Array operators
  ALL: 'ALL',
  NOT_ALL: 'NOT_ALL', // equivalent to ANY of the reverse condition
  ANY: 'ANY',
  NOT_ANY: 'NOT_ANY', // equivalent to ALL of the reverse condition
};
