import * as fieldTypes from '~/consts/fields';

export function getDefautValuesToSetBySide(pcdFields) {
  // We only do defaults on field types that aren't string or date for now, to exclude unsupported defaults on fields
  // like title that may be required on create
  return pcdFields.filter(
    (pcdField) =>
      pcdField.get('requiredOnCreate') && [fieldTypes.TYPES.ENUM, fieldTypes.TYPES.USER].includes(pcdField.get('type')),
  );
}
