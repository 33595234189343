import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color as themeColor } from 'theme';
import * as workflowTypes from '~/consts/workflows';

const PORT_CSS_VALUES = {
  [workflowTypes.PORT_POSITIONS.TOP]: {
    $height: 0.5,
    $hoveredHeight: 1.5,
    $hoveredWidth: 3,
    $left: '50%',
    $padding: '0.5rem 0',
    $top: '-2px',
    $width: 2,
  },
  [workflowTypes.PORT_POSITIONS.LEFT]: {
    $height: 2,
    $hoveredHeight: 3,
    $hoveredWidth: 1.5,
    $left: '-2px',
    $padding: '0 0.5rem',
    $top: '50%',
    $width: 0.5,
  },
  [workflowTypes.PORT_POSITIONS.BOTTOM]: {
    $bottom: '-2px',
    $height: 0.5,
    $hoveredHeight: 1.5,
    $hoveredWidth: 3,
    $left: '50%',
    $padding: '0.5rem 0',
    $width: 2,
  },
  [workflowTypes.PORT_POSITIONS.RIGHT]: {
    $height: 2,
    $hoveredHeight: 3,
    $hoveredWidth: 1.5,
    $padding: '0 0.5rem',
    $right: '-2px',
    $top: '50%',
    $width: 0.5,
  },
};

const PORT_MARGINS = {
  [workflowTypes.PORT_POSITIONS.TOP]: {
    $marginLeft: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.TOP].width / 2}rem`,
    $hoveredMarginLeft: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.TOP].hoveredWidth / 2}rem`,
  },
  [workflowTypes.PORT_POSITIONS.LEFT]: {
    $marginTop: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.LEFT].height / 2}rem`,
    $hoveredMarginTop: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.LEFT].hoveredHeight / 2}rem`,
  },
  [workflowTypes.PORT_POSITIONS.BOTTOM]: {
    $marginLeft: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.BOTTOM].width / 2}rem`,
    $hoveredMarginLeft: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.BOTTOM].hoveredWidth / 2}rem`,
  },
  [workflowTypes.PORT_POSITIONS.RIGHT]: {
    $marginTop: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.RIGHT].height / 2}rem`,
    $hoveredMarginTop: `-${PORT_CSS_VALUES[workflowTypes.PORT_POSITIONS.RIGHT].hoveredHeight / 2}rem`,
  },
};

const Port = styled.div.attrs((props) => PORT_CSS_VALUES[props.$position])`
  align-items: center;
  background: ${(props) => (props.$disabled ? themeColor.content.secondary.disabled : props.$color)};
  border-radius: 0.25rem;
  color: ${themeColor.content.neutral.white};
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  line-height: 1;
  text-indent: ${(props) => (props.$isHovered ? 'initial' : '-9999px')};
  height: ${(props) => (props.$isHovered ? props.$hoveredHeight : props.$height)}rem;
  width: ${(props) => (props.$isHovered ? props.$hoveredWidth : props.$width)}rem;
  visibility: ${(props) => (props.$disabled ? 'hidden' : 'visible')};
`;

const PortHitbox = styled.div.attrs((props) => ({
  ...PORT_MARGINS[props.$position],
  ...PORT_CSS_VALUES[props.$position],
}))`
  bottom: ${(props) => props.$bottom};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  left: ${(props) => props.$left};
  padding: ${(props) => (props.$isHovered ? 0 : props.$padding)};
  margin-left: ${(props) => (props.$isHovered ? props.$hoveredMarginLeft : props.$marginLeft)};
  margin-top: ${(props) => (props.$isHovered ? props.$hoveredMarginTop : props.$marginTop)};
  min-height: ${(props) => props.$height}rem;
  min-width: ${(props) => props.$width}rem;
  box-sizing: border-box;
  position: absolute;
  right: ${(props) => props.$right};
  top: ${(props) => props.$top};
  transition: padding 0.125s cubic-bezier(0.4, 0, 1, 1);
  visibility: ${(props) => (props.$disabled ? 'hidden' : 'visible')};
`;

const WorkBlockPortComponent = ({
  disabled = false,
  position,
  className,
  onClick,
  color = themeColor.content.primary.default,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  function handleClick(e) {
    e.stopPropagation();

    if (disabled) {
      return null;
    }
    return onClick(position);
  }

  return (
    <PortHitbox
      $position={position}
      className={className}
      $disabled={disabled}
      onClick={handleClick} // eslint-disable-line react/jsx-no-bind
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
      $isHovered={isHovered}
    >
      <Port $position={position} $color={color} $disabled={disabled} $isHovered={isHovered}>
        +
      </Port>
    </PortHitbox>
  );
};

Port.propTypes = {
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

PortHitbox.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string.isRequired,
};

WorkBlockPortComponent.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  position: PropTypes.string.isRequired,
};

export const WorkBlockPort = styled((props) => <WorkBlockPortComponent {...props} />)``;
