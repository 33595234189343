import * as activityLogsTypes from '~/consts/activityLogs';
import * as routes from '~/consts/routes';

// eslint-disable-next-line import/prefer-default-export
export const fetchActivityLogs = (
  organizationId,
  page,
  pageSize,
  filter,
  sortBy,
  sinceInMs,
  shouldClearResults,
  overwrite = true, // When true, replace existing activityLogs state with the response.
) => ({
  types: [
    activityLogsTypes.FETCH_ACTIVITY_LOGS_REQUEST,
    activityLogsTypes.FETCH_ACTIVITY_LOGS_SUCCESS,
    activityLogsTypes.FETCH_ACTIVITY_LOGS_FAILURE,
  ],
  method: routes.METHODS.GET,
  meta: {
    shouldClearResults,
    overwrite,
  },
  displayError: false,
  url: routes.API_PATHS.GET_ACTIVITY_LOGS(organizationId, page, pageSize, filter, sortBy, sinceInMs),
});
