export function getSearchParams(search = '') {
  if (!search) {
    return {};
  }

  const searchString = search.startsWith('?') ? search.slice(1) : search;
  const queryParams = new URLSearchParams(searchString);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent#Decoding_query_parameters_from_a_URL
  const decodeQueryParam = (value) => {
    let decodedValue;
    try {
      decodedValue = JSON.parse(decodeURIComponent(value.replace(/\+/g, ' ')));
    } catch {
      decodedValue = value;
    }

    return decodedValue;
  };

  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = decodeQueryParam(value);
  });

  return queryParamsObject;
}
