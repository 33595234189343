import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { tokens, ProviderIcon, Icon, Typography, Tooltip, Space, Box } from '@unitoio/mosaic';

import { Href } from '~/components/Href/Href';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { getSelectedOrganizationId } from '~/reducers';
import { useContainerLinkTrackEvent } from '../../hooks/useContainerLinkTrackEvent';
import { GuideItemsInSyncCount } from './GuideItemsInSyncCount';

const DIRECTIONS = {
  both: 'exchange',
  A: 'long-arrow-left',
  B: 'long-arrow-right',
};

const LastSyncTypo = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

export const GuideEditHeader = () => {
  const [linkName, syncDirection, providerNameA, providerNameB, lastSyncRequest, creatorName] = useWatch({
    name: ['name', 'syncDirection', 'A.providerName', 'B.providerName', 'lastSyncRequest', 'userFullName'],
  });
  const [containerA, containerB] = useGetContainers();
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const containerLinkTrackEvent = useContainerLinkTrackEvent();

  return (
    <Box m={[tokens.spacing.s6, 0]}>
      <Space align="center" direction="horizontal" size="middle">
        <Tooltip content={`Open ${containerA.get('displayName')} in a new tab`} placement="right">
          <Href onClick={() => containerLinkTrackEvent(providerNameA)} href={containerA.get('url')}>
            <ProviderIcon size="large" name={providerNameA} />
          </Href>
        </Tooltip>
        <Icon name={DIRECTIONS[syncDirection]} />
        <Tooltip content={`Open ${containerB.get('displayName')} in a new tab`} placement="right">
          <Href onClick={() => containerLinkTrackEvent(providerNameB)} href={containerB.get('url')}>
            <ProviderIcon size="large" name={providerNameB} />
          </Href>
        </Tooltip>
        <Space direction="vertical" size="small">
          <Typography variant="h3">{linkName}</Typography>
          <LastSyncTypo variant="body2">
            <Icon name="clock" /> Last sync: {lastSyncRequest ? moment(lastSyncRequest).fromNow() : 'Never'}
            {creatorName ? `  |  Created by ${creatorName}` : ''}
            <GuideItemsInSyncCount organizationId={organizationId} />
          </LastSyncTypo>
        </Space>
      </Space>
    </Box>
  );
};
