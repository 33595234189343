import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import * as formUtils from '~/utils/forms';
import * as formTypes from '~/consts/form';
import { Input, InputGroup } from '~/components/Input/Input';

const ErrorMessage = styled.div`
  color: ${tokens.colors.content.destructive.default};
  visibility: ${(props) => props.$visibility};
`;

const POSITION_LEFT = 'left';
const POSITION_RIGHT = 'right';

export class TextInput extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    helpText: PropTypes.node,
    iconPosition: PropTypes.oneOf([POSITION_LEFT, POSITION_RIGHT]),
    iconProps: PropTypes.shape({ name: PropTypes.string, color: PropTypes.string }),
    input: PropTypes.shape(formTypes.fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(formTypes.fieldMetaPropTypes).isRequired,
    inputClassName: PropTypes.string,
    label: PropTypes.node,
    maxLength: PropTypes.number,
    subLabel: PropTypes.node,
    errorOnBlur: PropTypes.bool,
  };

  static defaultProps = {
    defaultValue: undefined,
    helpText: undefined,
    iconPosition: POSITION_LEFT,
    iconProps: undefined,
    inputClassName: undefined,
    label: undefined,
    maxLength: undefined,
    subLabel: undefined,
    errorOnBlur: false,
  };

  componentDidMount() {
    const { defaultValue, input } = this.props;
    if (defaultValue && formUtils.isEmpty(input.value)) {
      input.onChange(defaultValue);
    }
  }

  render() {
    const {
      defaultValue,
      helpText,
      input,
      inputClassName,
      label,
      maxLength,
      meta,
      iconProps,
      iconPosition,
      subLabel,
      errorOnBlur,
      ...rest
    } = this.props;
    const error = meta.error || meta.asyncError;
    const classNames = classnames('form-group', { 'has-error': meta.touched && error });
    const showError = errorOnBlur ? meta.touched : true;
    const isErrorVisible = ![undefined, null].includes(error);

    const inputField = iconProps ? (
      <InputGroup className="input-group" iconPosition={iconPosition}>
        {iconPosition === POSITION_LEFT && (
          <span className="input-group-addon">
            <Icon {...iconProps} />
          </span>
        )}
        <Input
          {...rest}
          {...input}
          id={input.name}
          className={classnames('form-control', inputClassName)}
          maxLength={maxLength}
        />
        {iconPosition === POSITION_RIGHT && (
          <span className="input-group-addon">
            <Icon {...iconProps} />
          </span>
        )}
      </InputGroup>
    ) : (
      <Input
        {...rest}
        {...input}
        id={input.name}
        className={classnames('form-control', inputClassName)}
        maxLength={maxLength}
      />
    );

    return (
      <div className={classNames}>
        {label && <label htmlFor={input.name}>{label}</label>}
        {subLabel && <p>{subLabel}</p>}
        {inputField}
        {helpText && <div className="help-block">{helpText}</div>}
        {showError && isErrorVisible && (
          <ErrorMessage $visibility={isErrorVisible ? 'visible' : 'hidden'}>{`${
            isErrorVisible ? error : 'blank'
          }`}</ErrorMessage>
        )}
      </div>
    );
  }
}
