import React from 'react';
import PropTypes from 'prop-types';

import { useQueryParams } from '~/hooks/useQueryParams';
import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';
import { CopyToClipboard } from '~/components/CopyToClipboard/CopyToClipboard';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import jiraAppLink2 from './jira-app-link-2.jpg';
import oldJiraAppLink2 from './old-jira-app-link-2.png';

export function AuthJiraAppLinkStep2({ history, newUI, onCancel, switchUI, trackEvent }) {
  const { windowOpenerId } = useQueryParams();
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (Application Link - Step 2 of 3)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>Fill out the form on the next screen with the values below.</p>
        <AuthImageWrapper className="clearfix">
          <img
            src={newUI ? jiraAppLink2 : oldJiraAppLink2}
            alt="Jira Application Link Step - Step 2"
            width="557"
            height="100%"
          />
          <Button noPadding size="xs" pullRight btnStyle="link" onClick={switchUI}>
            Not your Jira version? Switch instructions for {newUI ? 'v6 or v7' : 'v8'}
          </Button>
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            In the Link Applications modal window, complete these fields and leave the others blank:
            <ul>
              <li>
                Application Name: <CopyToClipboard>Unito</CopyToClipboard>
              </li>
              <li>
                Application Type: <CopyToClipboard>Generic Application</CopyToClipboard>
              </li>
              <li>
                Create incoming link: <strong>checked</strong>
              </li>
            </ul>
          </li>
          <li>
            Click <strong>Continue</strong>
          </li>
        </AuthOrderedList>
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            onClick={() => trackEvent('application link onboarding 2 (of 3)')}
            to={`step3?windowOpenerId=${windowOpenerId}`}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraAppLinkStep2.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  newUI: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  switchUI: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
