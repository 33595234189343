import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS, Map } from 'immutable';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Flex, tokens } from '@unitoio/mosaic';

import * as organizationActions from '~/actions/organizations';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useWindowEvent } from '~/hooks/useWindowEvent';
import { Box } from '~/components/Box/Box';
import { Button } from '~/components/Button/Button';
import { Href } from '~/components/Href/Href';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { LinkItemHoverActions } from '~/components/LinkItem/LinkItemHoverActions';

const LinkList = styled(Flex)`
  overflow-y: auto;
  max-height: 25rem;
`;

const ButtonsBar = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const WorkspaceMemberRemoveModal = ({ match, closeModal, member }) => {
  const {
    params: { organizationId },
  } = match;
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState(null);
  const flowQuantity = { flow_qty: links?.size || 0 };
  const trackEvent = useTrackEvent(flowQuantity);
  const dispatch = useDispatch();
  const { reportWarning } = useLogger();
  const memberId = member.get('_id');
  const memberEmail = member.getIn(['user', 'email']);

  const removeMemberDryRun = useCallback(async () => {
    setIsLoading(true);
    const response = await dispatch(organizationActions.removeMember(organizationId, memberId, true));
    const { validations: { flows = [] } = {} } = response;
    setLinks(fromJS(flows));
    setIsLoading(false);
    return flows.length;
  }, [dispatch, organizationId, memberId]);

  const removeMember = useCallback(async () => {
    trackEvent(trackingTypes.SUBMIT);
    setIsLoading(true);
    try {
      await dispatch(organizationActions.removeMember(organizationId, memberId, false));
    } catch (error) {
      reportWarning(error.message, { identifier: 'removeMember WorkspaceMemberRemoveModal' });
    } finally {
      closeModal();
    }
  }, [trackEvent, dispatch, organizationId, memberId, closeModal, reportWarning]);

  function handleMessage(event) {
    const { origin } = new URL(window.location);
    if (
      (process.env.REACT_APP_ENV !== 'development' && event.origin !== origin) ||
      event.data.type !== linkTypes.POST_MESSAGE_TYPES.LEFT_EDIT_SYNC
    ) {
      return;
    }

    removeMemberDryRun();
  }

  useWindowEvent('message', handleMessage);
  useEffect(() => {
    async function initialFetch() {
      const updatedFlowQuantity = await removeMemberDryRun();
      trackEvent(trackingTypes.START, { flow_qty: updatedFlowQuantity });
    }
    initialFetch();
  }, [removeMemberDryRun, trackEvent]);

  return (
    <Fragment>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!links ? (
        <InlineLoading />
      ) : links.isEmpty() ? (
        <Box $m={[0, 0, 'auto']}>
          <p>
            You are about to remove <strong>{memberEmail}</strong> from your workspace.
          </p>
          <p>
            They will no longer have access to this workspace, its workflows, flows, blocks of work, or shared accounts.
          </p>
          <p>
            Unconnected blocks of work using <strong>{memberEmail}</strong> will automatically be removed from your
            workflows.
          </p>
          <p>
            <strong>Don’t forget to remove this user from your tools.</strong>
          </p>
        </Box>
      ) : (
        <Fragment>
          <Box $m={[0, 0, 1]} as="p">
            To remove <strong>{memberEmail}</strong>, you’ll need to{' '}
            <Href
              onClick={() =>
                trackEvent(trackingTypes.ACTION, {
                  action_name: 'clicked on KB article',
                })
              }
              href="https://guide.unito.io/how-do-you-change-the-tool-account-used-to-sync"
            >
              change the account
            </Href>{' '}
            used in the following flows or delete these flows permanently if they are no longer relevant (they might be
            included in a workflow).
          </Box>
          <LinkList $m={[0, 0, 'auto']} vertical gap={tokens.spacing.s3}>
            {links
              .map((link) => (
                <LinkItemHoverActions
                  sync={link}
                  onDeleteSync={removeMemberDryRun}
                  optionalTrackingProps={flowQuantity}
                />
              ))
              .toArray()}
          </LinkList>
        </Fragment>
      )}
      <ButtonsBar $m={[3, 0, 0, 0]}>
        <Button btnStyle="link" onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={!links?.isEmpty() || isLoading} btnStyle="error" onClick={removeMember}>
          Remove user
        </Button>
      </ButtonsBar>
    </Fragment>
  );
};

WorkspaceMemberRemoveModal.propTypes = {
  member: PropTypes.instanceOf(Map).isRequired,
  closeModal: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
