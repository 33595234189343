import React from 'react';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import { UnitoLogo } from '~/components/UnitoLogo/UnitoLogo';

const FullPage = styled.div`
  background: ${tokens.colors.content.secondary.default};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -80px;
  color: ${tokens.colors.global.primary.light};
  text-align: center;
`;

const LogoWrapper = styled.div`
  margin-bottom: 20px;
`;

export function Loading() {
  return (
    <FullPage>
      <Content>
        <LogoWrapper>
          <UnitoLogo color="transparent" />
        </LogoWrapper>
        <Icon name="spinner" kind={Icon.KINDS.SOLID} size="4x" title="loading" pulse />
      </Content>
    </FullPage>
  );
}
