import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import * as fieldActions from '~/actions/fields';
import { useLogger } from '~/hooks/useLogger';

import { LOADING_STATUSES } from '../consts/flowBuilder';

import { useGetField } from './useGetField';

/**
 * The selected field value needs to be fetched on the initial loading, as field values don't get automatically fetched on connectors that require a typeahead
 * This allows to display the selected value on load
 */
export function useFetchSelectedSearchableFieldValue({
  containerId,
  containerSide,
  containerType,
  fieldId,
  parentFieldId,
  kind,
  name,
  providerIdentityId,
  setStatus,
  status,
  multiSelect,
  fieldValues,
  customFieldValues,
  itemType,
}) {
  const dispatch = useDispatch();
  const { watch } = useFormContext();
  const { reportException } = useLogger();
  const field = useGetField({ providerIdentityId, fieldId, itemType, containerId });
  const fieldIsSearchable = field.get('searchable', false);
  let selectedFieldValueIds = watch(name, []);

  if (!Array.isArray(selectedFieldValueIds)) {
    selectedFieldValueIds = [selectedFieldValueIds];
  }

  const shouldFetchValue =
    status === LOADING_STATUSES.INITIAL &&
    fieldIsSearchable &&
    selectedFieldValueIds.some((id) => !fieldValues.has(id)) &&
    selectedFieldValueIds.some((id) => !customFieldValues.has(id));

  useEffect(() => {
    async function fetchFieldValue(payload) {
      try {
        setStatus(LOADING_STATUSES.LOADING);
        await dispatch(fieldActions.fetchFieldValue(payload));
        setStatus(LOADING_STATUSES.LOADED);
      } catch (err) {
        reportException(err);
        setStatus(LOADING_STATUSES.ERROR);
      }
    }

    if (!selectedFieldValueIds.length) {
      return;
    }

    // field is searchable, we need to fetch each field value
    selectedFieldValueIds
      .filter((id) => !fieldValues.has(id) && !customFieldValues.has(id))
      .forEach((selectedFieldValueId) => {
        if (selectedFieldValueId && shouldFetchValue) {
          fetchFieldValue({
            containerId,
            fieldId,
            parentField: parentFieldId,
            fieldValueId: selectedFieldValueId,
            kind,
            providerIdentityId,
            containerSide,
            itemType,
          });
        }
      });
  }, [
    providerIdentityId,
    containerId,
    containerSide,
    containerType,
    dispatch,
    fieldId,
    parentFieldId,
    fieldIsSearchable,
    kind,
    shouldFetchValue,
    selectedFieldValueIds,
    setStatus,
    multiSelect,
    fieldValues,
    customFieldValues,
    itemType,
    reportException,
  ]);
}
