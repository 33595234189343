import React from 'react';
import PropTypes from 'prop-types';

import * as linkTypes from '~/consts/link';

import { RulesRow } from '../../../../components/RulesRow';

export const SetDefaultFieldValueFromFilterRows = ({
  otherContainerSection,
  otherItemType,
  otherContainerType,
  otherContainerSide,
  linkState,
  prefix,
}) =>
  otherContainerSection.fields.map((data, index) => (
    <RulesRow
      prefix={prefix}
      iconName="trash"
      providerName={otherContainerSection.providerName}
      providerIdentityId={otherContainerSection.providerIdentityId}
      containerId={otherContainerSection.containerId}
      itemType={otherItemType}
      containerType={otherContainerType}
      containerSide={otherContainerSide}
      key={data._id}
      name={`${otherContainerSide}.filters.${index}`}
      data={data}
      fieldDetails={otherContainerSection.groupedFields[data.fieldId]}
      isAction
      linkState={linkState}
      iconOnClick={() => {
        otherContainerSection.remove(index);
      }}
    />
  ));

const fieldShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

const filterValueType = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.bool.isRequired,
]);

const filterShape = PropTypes.shape({
  fieldId: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([filterValueType, PropTypes.arrayOf(filterValueType)]).isRequired,
});

SetDefaultFieldValueFromFilterRows.propTypes = {
  otherContainerSection: PropTypes.shape({
    providerName: PropTypes.string.isRequired,
    providerIdentityId: PropTypes.string.isRequired,
    containerId: PropTypes.string.isRequired,
    containerType: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(filterShape).isRequired,
    groupedFields: PropTypes.shape({
      [PropTypes.string]: fieldShape,
    }).isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  otherItemType: PropTypes.string.isRequired,
  prefix: PropTypes.node,
  otherContainerType: PropTypes.string.isRequired,
  otherContainerSide: PropTypes.string.isRequired,
  linkState: PropTypes.oneOf(Object.values(linkTypes.LINK_STATES)).isRequired,
};
