import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

const COLOR_SCHEMES = {
  dark: {
    background: tokens.colors.content.neutral.n10,
    border: tokens.colors.content.neutral.n10,
    color: tokens.colors.content.neutral.n40,
  },
  placeholder: {
    background: tokens.colors.global.primary.light,
    border: tokens.colors.content.info.default,
    color: tokens.colors.content.info.default,
  },
};

const StyledAvatar = styled.span`
  align-items: center;
  background: ${(props) => COLOR_SCHEMES[props.$colorScheme].background};
  border-radius: 50%;
  border: 1px solid ${(props) => COLOR_SCHEMES[props.$colorScheme].border};
  color: ${(props) => COLOR_SCHEMES[props.$colorScheme].color};
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  border-radius: 50%;
`;

const StyledIcon = styled(Icon)`
  vertical-align: sub;
`;

const BORDER_WIDTH_TOTAL = 2;

export class Avatar extends Component {
  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    colorScheme: PropTypes.oneOf(Object.keys(COLOR_SCHEMES)),
    size: PropTypes.number,
    src: PropTypes.string,
    iconName: PropTypes.string,
  };

  static defaultProps = {
    alt: '',
    className: undefined,
    colorScheme: 'dark',
    size: 26,
    src: undefined,
    iconName: 'user',
  };

  state = {
    imageLoadError: false,
  };

  onImageErrorLoad = () => {
    this.setState({
      imageLoadError: true,
    });
  };

  render() {
    const { alt, className, colorScheme, size, src, iconName } = this.props;
    const { imageLoadError } = this.state;

    const style = {
      width: size + BORDER_WIDTH_TOTAL,
      height: size + BORDER_WIDTH_TOTAL,
    };

    return (
      <StyledAvatar className={classnames('avatar', className)} style={style} $colorScheme={colorScheme}>
        {!imageLoadError && src ? (
          <Img src={src} width={size} height={size} alt={alt} onError={this.onImageErrorLoad} />
        ) : (
          <StyledIcon
            name={iconName}
            color={COLOR_SCHEMES[colorScheme].color}
            kind={Icon.KINDS.SOLID}
            title={iconName}
          />
        )}
      </StyledAvatar>
    );
  }
}
