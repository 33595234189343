import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from 'theme';
import './Title.scss';

const H1 = styled.h1`
  font-size: ${fontSize.h1};
  font-weight: ${fontWeight.regular};
  letter-spacing: 0.00735em;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  text-align: ${(props) => props.$align};
`;

const H2 = styled.h2`
  font-size: ${fontSize.h2};
  font-weight: ${fontWeight.regular};
  letter-spacing: normal;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  text-align: ${(props) => props.$align};
`;

const H3 = styled.h3`
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.medium};
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  text-align: ${(props) => props.$align};
`;

const H4 = styled.h4`
  font-size: ${fontSize.h4};
  font-weight: ${fontWeight.medium};
  margin-bottom: 0.25rem;
  line-height: 1.6rem;
  text-align: ${(props) => props.$align};
`;

const H5 = styled.h5`
  font-size: ${fontSize.small};
  margin-bottom: 0rem;
  text-align: ${(props) => props.$align};
`;

const Subtitle1 = styled.h6`
  color: ${color.dark.hint};
  font-size: ${fontSize.h4};
  font-weight: ${fontWeight.regular};
  line-height: 1.75rem;
  letter-spacing: 0.00937em;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  text-align: ${(props) => props.$align};
`;

const Subtitle2 = styled.h6`
  color: ${color.dark.hint};
  font-size: ${fontSize.h4};
  font-weight: ${fontWeight.regular};
  line-height: 1.75rem;
  letter-spacing: 0.00937em;
  margin-bottom: 0.5rem;
  text-align: ${(props) => props.$align};
`;

const Subtitle3 = styled.h6`
  color: ${color.dark.hint};
  font-size: ${fontSize.subheading};
  font-weight: ${fontWeight.regular};
  line-height: 1.75rem;
  letter-spacing: 0.00937em;
  margin-bottom: 0.5rem;
  text-align: ${(props) => props.$align};
`;

const elements = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  subtitle1: Subtitle1,
  subtitle2: Subtitle2,
  subtitle3: Subtitle3,
};

const aligns = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export function Title({ children, className, inverse = false, type = 'h1', uppercase = false, align, ...rest }) {
  const Element = elements[type];
  const classNames = classnames('title', type, className, {
    'title--uppercase': uppercase,
    'title--inverse': inverse,
  });

  return (
    <Element className={classNames} $align={align} {...rest}>
      {children}
    </Element>
  );
}

Title.propTypes = {
  align: PropTypes.oneOf(Object.values(aligns)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inverse: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(elements)),
  uppercase: PropTypes.bool,
};
