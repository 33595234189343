import * as organizationTypes from '~/consts/organizations';
import * as routes from '~/consts/routes';
import { getSelectedOrganizationId, isUserSiteAdmin } from 'reducers';
import { isMongooseModelId } from '~/utils/isMongooseModelId';

export const getOrganizations = () => ({
  types: [
    organizationTypes.GET_ORGANIZATIONS_REQUEST,
    organizationTypes.GET_ORGANIZATIONS_SUCCESS,
    organizationTypes.GET_ORGANIZATIONS_FAILURE,
  ],
  url: routes.API_PATHS.GET_ORGANIZATIONS,
});

export const getOrganizationById = (orgId) => (dispatch, getState) => {
  const state = getState();
  const organizationId = orgId || getSelectedOrganizationId(state);
  return dispatch({
    types: [
      organizationTypes.GET_ORGANIZATION_BY_ID_REQUEST,
      organizationTypes.GET_ORGANIZATION_BY_ID_SUCCESS,
      organizationTypes.GET_ORGANIZATION_BY_ID_FAILURE,
    ],
    url: routes.API_PATHS.GET_ORGANIZATION_BY_ID(organizationId),
  });
};

export const getCollaborators = (orgId) => (dispatch, getState) => {
  const state = getState();
  const organizationId = orgId || getSelectedOrganizationId(state);

  return dispatch({
    types: [
      organizationTypes.GET_COLLABORATORS_REQUEST,
      organizationTypes.GET_COLLABORATORS_SUCCESS,
      organizationTypes.GET_COLLABORATORS_FAILURE,
    ],
    url: routes.API_PATHS.GET_COLLABORATORS(organizationId),
  });
};

export const getMetrics = (organizationId, options = {}) => ({
  ...options,
  types: [
    organizationTypes.GET_METRICS_REQUEST,
    organizationTypes.GET_METRICS_SUCCESS,
    organizationTypes.GET_METRICS_FAILURE,
  ],
  meta: { organizationId },
  url: routes.API_PATHS.GET_ORGANIZATION_METRICS(organizationId),
});

export const getUsage = (orgId) => (dispatch, getState) => {
  const organizationId = orgId || getSelectedOrganizationId(getState());
  return dispatch({
    types: [
      organizationTypes.GET_USAGE_REQUEST,
      organizationTypes.GET_USAGE_SUCCESS,
      organizationTypes.GET_USAGE_FAILURE,
    ],
    meta: { organizationId },
    method: routes.METHODS.POST,
    url: routes.API_PATHS.GET_ORGANIZATION_USAGE(organizationId),
    displayError: false,
  });
};

// items kept in sync usage were extracted in their own usage call to prevent
// failing the main /usage call if snowflake is down for some reason
export const getItemsUsage = (orgId) => (dispatch, getState) => {
  const organizationId = orgId || getSelectedOrganizationId(getState());
  return dispatch({
    types: [
      organizationTypes.GET_ITEMS_USAGE_REQUEST,
      organizationTypes.GET_ITEMS_USAGE_SUCCESS,
      organizationTypes.GET_ITEMS_USAGE_FAILURE,
    ],
    meta: { organizationId },
    method: routes.METHODS.POST,
    url: routes.API_PATHS.GET_ORGANIZATION_ITEMS_USAGE(organizationId),
  });
};

export const getSyncActivity =
  (orgId, options = {}) =>
  (dispatch, getState) => {
    const organizationId = orgId || getSelectedOrganizationId(getState());
    return dispatch({
      ...options,
      types: [
        organizationTypes.GET_SYNC_ACTIVITY_REQUEST,
        organizationTypes.GET_SYNC_ACTIVITY_SUCCESS,
        organizationTypes.GET_SYNC_ACTIVITY_FAILURE,
      ],
      meta: { organizationId },
      method: routes.METHODS.POST,
      url: routes.API_PATHS.GET_ORGANIZATION_SYNC_ACTIVITY(organizationId),
    });
  };

export const getMembers = (organizationId) => ({
  types: [
    organizationTypes.GET_MEMBERS_REQUEST,
    organizationTypes.GET_MEMBERS_SUCCESS,
    organizationTypes.GET_MEMBERS_FAILURE,
  ],
  meta: { organizationId },
  url: routes.API_PATHS.GET_ORGANIZATION_MEMBERS(organizationId),
  displayError: false,
});

export const getCoworkers = (organizationId) => ({
  types: [
    organizationTypes.GET_COWORKERS_REQUEST,
    organizationTypes.GET_COWORKERS_SUCCESS,
    organizationTypes.GET_COWORKERS_FAILURE,
  ],
  url: routes.API_PATHS.GET_ORGANIZATION_COWORKERS(organizationId),
  meta: { organizationId },
});

export const getReportUrl = (organizationId, reportId) => ({
  types: [
    organizationTypes.GET_REPORT_URL_REQUEST,
    organizationTypes.GET_REPORT_URL_SUCCESS,
    organizationTypes.GET_REPORT_URL_FAILURE,
  ],
  url: routes.API_PATHS.GET_ORGANIZATION_REPORT_URL(organizationId, reportId),
  meta: { organizationId, reportId },
});

export const patchOrganization = (organizationId, payload) => ({
  types: [
    organizationTypes.UPDATE_ORGANIZATION_REQUEST,
    organizationTypes.UPDATE_ORGANIZATION_SUCCESS,
    organizationTypes.UPDATE_ORGANIZATION_FAILURE,
  ],
  url: routes.API_PATHS.UPDATE_ORGANIZATION(organizationId),
  method: routes.METHODS.PATCH,
  meta: { organizationId },
  payload,
});

export const deleteWorkspace = (organizationId) => ({
  types: [
    organizationTypes.DELETE_WORKSPACE_REQUEST,
    organizationTypes.DELETE_WORKSPACE_SUCCESS,
    organizationTypes.DELETE_WORKSPACE_FAILURE,
  ],
  url: routes.API_PATHS.DELETE_WORKSPACE(organizationId),
  method: routes.METHODS.DELETE,
  displayError: false,
});

export const patchCollaborator = (organizationId, payload) => ({
  types: [
    organizationTypes.PATCH_COLLABORATOR_REQUEST,
    organizationTypes.PATCH_COLLABORATOR_SUCCESS,
    organizationTypes.PATCH_COLLABORATOR_FAILURE,
  ],
  method: routes.METHODS.PATCH,
  payload,
  url: routes.API_PATHS.PATCH_COLLABORATOR(organizationId), // eslint-disable-line
  meta: { organizationId, ...payload },
});

export const includeCollaborator = (organizationId, collaboratorId) => (dispatch) =>
  dispatch(patchCollaborator(organizationId, { collaboratorId, status: 'included' }));

export const excludeCollaborator = (organizationId, collaboratorId) => (dispatch) =>
  dispatch(patchCollaborator(organizationId, { collaboratorId, status: 'excluded' }));

export const updateOrganizationMemberRole = (organizationId, memberId, roleId) => ({
  types: [
    organizationTypes.PATCH_MEMBER_REQUEST,
    organizationTypes.PATCH_MEMBER_SUCCESS,
    organizationTypes.PATCH_MEMBER_FAILURE,
  ],
  method: routes.METHODS.PATCH,
  payload: { roleId },
  url: routes.API_PATHS.UPDATE_MEMBER_ROLE(organizationId, memberId),
});

export const getOrganizationRoles = (organizationId) => ({
  types: [
    organizationTypes.GET_ORGANIZATION_ROLES_REQUEST,
    organizationTypes.GET_ORGANIZATION_ROLES_SUCCESS,
    organizationTypes.GET_ORGANIZATION_ROLES_FAILURE,
  ],
  url: routes.API_PATHS.GET_ORGANIZATION_ROLES(organizationId),
  meta: { organizationId },
});

export const getFlags = (orgId) => (dispatch, getState) => {
  const organizationId = isMongooseModelId(orgId)
    ? orgId
    : false || getSelectedOrganizationId(getState()) || organizationTypes.LD_ANONYMOUS_ORGANIZATION_ID;

  return dispatch({
    types: [
      organizationTypes.GET_FLAGS_REQUEST,
      organizationTypes.GET_FLAGS_SUCCESS,
      organizationTypes.GET_FLAGS_FAILURE,
    ],
    meta: {
      organizationId,
    },
    url: routes.API_PATHS.GET_FLAGS(organizationId),
  });
};

export const updateFlagsLocally = (orgId, flagData) => ({
  type: organizationTypes.UPDATE_FLAGS_LOCALLY,
  payload: {
    orgId,
    flagData,
  },
});

export const removeMember = (organizationId, memberId, dryRun) => ({
  method: routes.METHODS.DELETE,
  types: [
    organizationTypes.REMOVE_ORGANIZATION_MEMBER_REQUEST,
    organizationTypes.REMOVE_ORGANIZATION_MEMBER_SUCCESS,
    organizationTypes.REMOVE_ORGANIZATION_MEMBER_FAILURE,
  ],
  meta: {
    organizationId,
    dryRun,
    memberId,
  },
  url: routes.API_PATHS.REMOVE_ORGANIZATION_MEMBER(organizationId, memberId, dryRun),
});

/**
 * Useful for refreshing analytics / segment / bouncer with this organization's
 * most up to date attributes.
 * */
export const identify = (organizationId) => ({
  method: routes.METHODS.PUT,
  types: [
    organizationTypes.IDENTIFY_ORGANIZATION_REQUEST,
    organizationTypes.IDENTIFY_ORGANIZATION_SUCCESS,
    organizationTypes.IDENTIFY_ORGANIZATION_FAILURE,
  ],
  meta: {
    organizationId,
  },
  shouldCallApi: isUserSiteAdmin, // Restricted to SiteAdmins for now.
  url: routes.API_PATHS.IDENTIFY(organizationId),
});
