import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Map } from 'immutable';

import { Modal, Select, Typography, TypographyVariants, message, notification, tokens } from '@unitoio/mosaic';

import * as billingActions from '~/actions/billing';
import * as organizationActions from '~/actions/organizations';
import * as trackingTypes from '~/consts/tracking';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { color as themeColor, fontWeight } from 'theme';
import { RadioButton } from '~/components/RadioButton/RadioButton';
import { RadioButtonGroup } from '~/components/RadioButtonGroup/RadioButtonGroup';

const StyledTextArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  border-color: ${tokens.colors.content.neutral.n20};
  border-radius: ${tokens.spacing.s2};
  margin: ${tokens.spacing.s3} ${tokens.spacing.s0};
`;

const Red = styled.span`
  color: ${themeColor.brand.red};
  font-weight: ${fontWeight.medium};
`;

const StyledSelect = styled(Select)`
  width: max-content;
  margin: ${tokens.spacing.s3} ${tokens.spacing.s0};
`;

const Section = styled.section`
  margin-bottom: ${tokens.spacing.s4};

  &:last-child {
    margin-bottom: ${tokens.spacing.s0};
  }
`;

const USER_CHOICES = {
  PAUSE: 'PAUSE',
  CANCEL: 'CANCEL',
};

const getCancellationOptions = () => [
  { value: 'cost', label: 'Too expensive for value' },
  { value: 'too_difficult', label: 'Too complicated/time-consuming to use' },
  { value: 'alternative_solution', label: 'Found an alternative solution' },
  { value: 'champion_leaving', label: 'Admin left the company' },
  { value: 'missing_functionality', label: 'Missing functionality' },
  { value: 'dropped_tool', label: 'No longer using one of the tools' },
  { value: 'project_ended', label: 'Project ended' },
  { value: 'just_testing', label: 'I was just testing' },
  { value: 'no_longer_needed', label: 'My workflow changed, so I no longer need Unito' },
  { value: 'migration', label: 'Was only using Unito for a migration' },
  { value: 'other', label: 'I have different feedback' },
];

const getPauseOptions = () => [
  { value: 'cost', label: 'Too expensive for value' },
  { value: 'too_difficult', label: 'Too complicated/time-consuming to use' },
  { value: 'champion_leaving', label: 'Admin left the company' },
  { value: 'missing_functionality', label: 'Missing functionality' },
  { value: 'dropped_tool', label: 'No longer using one of the tools' },
  { value: 'project_ended', label: 'Project ended' },
  { value: 'migration', label: 'Was only using Unito for a migration' },
  { value: 'other', label: 'I have different feedback' },
  { value: 'missing_integration', label: 'Missing integration' },
];

const FEEDBACK_LABEL = {
  [USER_CHOICES.CANCEL]: 'Why are you canceling your subscription?*',
  [USER_CHOICES.PAUSE]: 'Why are you pausing your subscription?*',
};

const QUALITATIVE_FEEDBACK_LABEL = {
  [USER_CHOICES.CANCEL]: 'What could we be doing better?*',
  [USER_CHOICES.PAUSE]: 'Is there anything you think we could improve before you get back?*',
};

async function cancelSubscription(dispatch, reportWarning, { organizationId, feedback, qualitativeFeedback }) {
  try {
    await dispatch(billingActions.cancelSubscription(organizationId, feedback, qualitativeFeedback));

    dispatch(organizationActions.getFlags(organizationId));
    message.info({
      content: 'Subscription canceled',
    });
  } catch (err) {
    reportWarning(err, { identifier: 'CancelOrPauseModal cancelSubscription failedToCancelSubscription' });
    message.error({
      content: 'Something went wrong',
    });
  }
}

async function pauseSubscription(dispatch, reportWarning, { organizationId, feedback, qualitativeFeedBack }) {
  try {
    const response = await dispatch(billingActions.pauseSubscription(organizationId, feedback, qualitativeFeedBack));
    notification.info({
      message: 'Subscription paused',
      description: `Your Unito subscription has been paused. You will still have access until ${moment(
        response?.organization?.validUntil,
      ).format('MMM DD, YYYY')}`,
      placement: 'topRight',
    });
  } catch (err) {
    reportWarning(err, { identifier: 'CancelOrPauseModal pauseSubscription failedToPauseSubscription' });
    message.error({
      content: 'Something went wrong',
    });
  }
}

export function CancelOrPauseModal({ isOpen, onClose, organization }) {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent({}, false);
  const { reportWarning } = useLogger();
  const [userChoice, setUserChoice] = useState(USER_CHOICES.PAUSE);
  const [feedback, setFeedback] = useState('');
  const [qualitativeFeedback, setQualitativeFeeback] = useState('');

  const organizationId = organization.get('id');

  const confirmSelection = async () => {
    trackEvent(trackingTypes.BILLING_EVENTS.ACTION_NAME, {
      action_name: trackingTypes.BILLING_EVENTS.ACTIONS.SELECT_CANCEL_SUBSCRIPTION,
    });
    if (userChoice === USER_CHOICES.PAUSE) {
      await pauseSubscription(dispatch, reportWarning, { organizationId, feedback, qualitativeFeedback });
    } else if (userChoice === USER_CHOICES.CANCEL) {
      await cancelSubscription(dispatch, reportWarning, { organizationId, feedback, qualitativeFeedback });
    }

    handleOnClose();
  };

  const onChangeQualitativeFeedback = (event) => {
    const value = event.target.value && event.target.value.trim();
    setQualitativeFeeback(value);
  };

  const handleOnClose = () => {
    setFeedback('');
    setQualitativeFeeback('');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleOnClose}
      size={Modal.sizes.LG}
      title="We're sorry to see you go!"
      confirmLabel={userChoice === USER_CHOICES.PAUSE ? 'Pause my subscription' : 'Cancel my subscription'}
      isConfirmActionDestructive
      onConfirm={confirmSelection}
      isConfirmButtonDisabled={!feedback || !qualitativeFeedback}
      onCancel={handleOnClose}
    >
      <Section>
        <Typography variant={TypographyVariants.BODY1}>
          If you'd like, Unito offers the option to <strong>pause your subscription</strong> and allow you to keep
          everything you've set up until you are ready to come back.
        </Typography>
        <br />
        <Typography variant={TypographyVariants.BODY1}>
          Whether you choose to pause or cancel, your flows will remain active until the end of your billing period on{' '}
          {moment(organization.get('validUntil')).format('MMM DD, YYYY')}.
        </Typography>
      </Section>

      <Section>
        <RadioButtonGroup
          id="cancellationType"
          name="cancellationType"
          value={userChoice}
          onChange={setUserChoice}
          color={tokens.colors.content.primary.default}
        >
          <RadioButton
            color={tokens.colors.content.primary.default}
            value={USER_CHOICES.PAUSE}
            label={
              <Typography variant={TypographyVariants.BODY1}>
                <strong>Pause your subscription</strong>
              </Typography>
            }
            subLabel={
              <Typography color={tokens.colors.content.neutral.n30} variant={TypographyVariants.BODY1}>
                Save your workflows until you come back for $12/year.
              </Typography>
            }
          />
          <RadioButton
            color={tokens.colors.content.primary.default}
            value={USER_CHOICES.CANCEL}
            label={
              <Typography variant={TypographyVariants.BODY1}>
                <strong>Cancel your subscription</strong> <Red>(This cannot be undone)</Red>
              </Typography>
            }
            subLabel={
              <Typography color={tokens.colors.content.neutral.n30} variant={TypographyVariants.BODY1}>
                Delete all your account information, flows and workflows
              </Typography>
            }
          />
        </RadioButtonGroup>
      </Section>

      <Section>
        <Typography variant={TypographyVariants.BODY1}>{FEEDBACK_LABEL[userChoice]}</Typography>
        <StyledSelect
          options={userChoice === USER_CHOICES.CANCEL ? getCancellationOptions() : getPauseOptions()}
          onChange={(event) => setFeedback(event)}
          value={feedback}
          size="sm"
          placeholder={userChoice === USER_CHOICES.CANCEL ? 'Select a cancellation reason' : 'Select a pause reason'}
        />
      </Section>

      <Section>
        <Typography variant={TypographyVariants.BODY1}>{QUALITATIVE_FEEDBACK_LABEL[userChoice]}</Typography>
        <StyledTextArea cols={72} rows={4} maxLength={500} onChange={onChangeQualitativeFeedback} />
      </Section>

      <Section>
        <Typography variant={TypographyVariants.BODY1}>* Indicates a required field</Typography>
      </Section>
    </Modal>
  );
}

CancelOrPauseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.instanceOf(Map).isRequired,
};
