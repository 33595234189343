import styled from 'styled-components';

import { color } from 'theme';

const activeStyles = `
  &&& {
    border-bottom: 4px solid ${color.brand.secondary};
    margin-bottom: -4px;
  }
`;

export const UnderlinedNavItem = styled.li`
  a {
    color: ${color.dark.primary};
    outline: none;
  }

  &&& {
    margin-bottom: 0;

    a:active,
    a:focus,
    a:hover {
      background: none;
      border-color: transparent;
    }
  }

  display: inline-block;
  ${(props) => props.$isActive && activeStyles}
`;
