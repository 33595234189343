import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';

import { getActiveLinkByProviderContainers } from 'reducers';
import * as linkTypes from '~/consts/link';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { useGetContainerTypes } from '~/containers/FlowBuilder/hooks/useGetContainerTypes';

export function useIsAlreadyActiveFlow(linkId) {
  const [containerA, containerB] = useGetContainers();
  const containerAId = containerA?.get('id');
  const containerBId = containerB?.get('id');
  const containerADisplayName = containerA?.get('displayName');
  const containerBDisplayName = containerB?.get('displayName');
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [containerTypeA, containerTypeB] = useGetContainerTypes();
  const [linkState, providerContainerIdA, providerContainerIdB] = useWatch({
    name: ['state', 'A.providerContainerId', 'B.providerContainerId'],
  });
  const linkWithSameContainers = useSelector((state) =>
    getActiveLinkByProviderContainers(
      state,
      {
        providerContainerId: providerContainerIdA,
        containerId: containerAId,
        itemType: itemTypeA,
        containerType: containerTypeA,
      },
      {
        providerContainerId: providerContainerIdB,
        containerId: containerBId,
        itemType: itemTypeB,
        containerType: containerTypeB,
      },
      [linkId],
    ),
  );

  const hasAnotherLinkWithSameContainers = !!linkWithSameContainers;

  const isAlreadyActiveSync =
    linkState === linkTypes.LINK_STATES.DRAFT && hasAnotherLinkWithSameContainers && containerAId && containerBId;

  return {
    isAlreadyActiveSync: !!isAlreadyActiveSync,
    linkWithSameContainers,
    containerADisplayName,
    containerBDisplayName,
  };
}
