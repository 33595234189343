import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dock } from 'react-dock';
import styled from 'styled-components';

import { Button, Box, Icon, Typography, tokens } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';

import { SecondaryInfo } from './SecondaryInfo';
import { ProviderIconTooltipUrl } from './ProviderIconTooltipUrl';

const EllipsisContainer = styled(Box)`
  min-width: 0;
`;

export function Card({
  dockContent,
  href,
  iconDataA,
  iconDataB,
  iconName,
  primaryTitle,
  providerNameA,
  providerNameB,
  secondaryTitle,
  isElevated = false,
  trackEvent,
}) {
  const [isDockOpen, openDock] = useState(false);
  return (
    <>
      {dockContent && (
        <Dock position="right" isVisible={isDockOpen} resizing>
          <Box p={[tokens.spacing.s6]}>
            <Box alignItems="center" justifyContent="space-between">
              <Typography variant="h3">{primaryTitle}</Typography>
              <Button startIcon="times" variant="subtle" onClick={() => openDock(false)} />
            </Box>
            <Box m={[tokens.spacing.s4, 0, 0]}>{dockContent}</Box>
          </Box>
        </Dock>
      )}
      <Box
        elevation={isElevated && 'small'}
        alignItems="center"
        p={[tokens.spacing.s4]}
        borderColor={isElevated ? tokens.colors.content.neutral.n40 : tokens.colors.content.neutral.n10}
        borderRadius={1}
        borderSize={isElevated && 2}
        m={[tokens.spacing.s4, 0]}
        data-testid="ItemBlock"
        onClick={dockContent ? () => openDock(!isDockOpen) : undefined}
      >
        <Box alignItems="center">
          <ProviderIconTooltipUrl
            providerName={providerNameA}
            url={iconDataA?.url}
            tooltipContent={iconDataA?.tooltipContent}
            trackEvent={trackEvent}
          />
          {providerNameB && (
            <>
              <Box p={[0, tokens.spacing.s3]}>
                <Icon name={iconName} />
              </Box>
              <ProviderIconTooltipUrl
                providerName={providerNameB}
                url={iconDataB?.url}
                tooltipContent={iconDataB?.tooltipContent}
              />
            </>
          )}
        </Box>
        <EllipsisContainer p={[0, tokens.spacing.s4]}>
          <Typography variant="body1" as="div" textOverflow="ellipsis">
            {primaryTitle}
          </Typography>
          <SecondaryInfo variant="body2" as="div">
            {secondaryTitle}
          </SecondaryInfo>
        </EllipsisContainer>
        <Box m={[0, 0, 0, 'auto']}>
          {href && (
            <Button
              variant="subtle"
              startIcon="external-link"
              href={href}
              target="_blank"
              onClick={() =>
                trackEvent &&
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.CLICKED_OPEN_ITEM,
                })
              }
            >
              Open
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

Card.propTypes = {
  href: PropTypes.string,
  iconName: PropTypes.string,
  primaryTitle: PropTypes.node.isRequired,
  providerNameA: PropTypes.string.isRequired,
  providerNameB: PropTypes.string,
  secondaryTitle: PropTypes.string.isRequired,
  iconDataA: PropTypes.shape({ url: PropTypes.string, tooltipContent: PropTypes.string }),
  iconDataB: PropTypes.shape({ url: PropTypes.string, tooltipContent: PropTypes.string }),
  dockContent: PropTypes.node,
  isElevated: PropTypes.bool,
  trackEvent: PropTypes.func,
};
