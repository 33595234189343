export function expandPermissions(permissionsByOrgId) {
  const PERMISSIONS_LETTER_TO_ACTION = {
    c: 'create',
    r: 'read',
    u: 'update',
    d: 'delete',
  };

  return Object.entries(permissionsByOrgId).reduce((expandedPermissionsByOrgId, [orgId, permissions]) => {
    const expandedPermissions = Object.entries(permissions).reduce((acc, [subject, actionString]) => {
      const permissionsForSubject = [...actionString].map((actionLetter) => ({
        subject,
        action: PERMISSIONS_LETTER_TO_ACTION[actionLetter],
      }));
      return acc.concat(...permissionsForSubject);
    }, []);
    return { ...expandedPermissionsByOrgId, [orgId]: expandedPermissions };
  }, {});
}
