import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Typography, tokens } from '@unitoio/mosaic';

// the SelectorLabel component is being used in two different contexts that have
// two different styling: Flow Builder and One-Click. STYLE_TYPE 'regular' refers
// to the Flow Builder and 'wide' to the One-Click.
export const WORK_ITEM_SELECTOR_VARIANT = {
  /** Displays a "natural language" label inline with the user input */
  NATURAL_LANGUAGE: 'naturalLanguage',
  /** Displays a simple label with a block user input */
  LABEL_INPUT_BLOCK: 'labelInputBlock',
};

const BoldTypo = styled(Typography)`
  font-weight: ${tokens.fontWeight.fw7};
  white-space: nowrap;
`;

const MaxWidthInput = styled(Box)`
  min-width: 0;
`;

const InlineDiv = styled.div`
  display: inline-flex;
  max-width: 100%;
  div {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const WideWrapper = styled(Box)`
  display: block;
`;

const WideInlineDiv = styled.div`
  div {
    &:not(:last-child) {
      margin-right: 8px;
      padding-top: ${tokens.spacing.s3};
      padding-bottom: ${tokens.spacing.s3};
    }
  }
`;

export const SelectorLabel = ({
  label,
  children,
  withMargin = false,
  workItemSelectorVariant = WORK_ITEM_SELECTOR_VARIANT.NATURAL_LANGUAGE,
}) => {
  if (workItemSelectorVariant === WORK_ITEM_SELECTOR_VARIANT.LABEL_INPUT_BLOCK) {
    return (
      <WideWrapper alignItems="center">
        <Box>
          <BoldTypo as={Box} m={[0, 0, tokens.spacing.s3, 0]} variant="body">
            {label}
          </BoldTypo>
          <WideInlineDiv>{children}</WideInlineDiv>
        </Box>
      </WideWrapper>
    );
  }

  return (
    <Box alignItems="center" m={[withMargin ? tokens.spacing.s4 : 0, 0, 0, 0]}>
      <BoldTypo as={Box} m={[0]} variant="body">
        {label}
      </BoldTypo>
      <MaxWidthInput m={[0, 0, 0, tokens.spacing.s3]}>
        <InlineDiv>{children}</InlineDiv>
      </MaxWidthInput>
    </Box>
  );
};

SelectorLabel.propTypes = {
  withMargin: PropTypes.bool,
  workItemSelectorVariant: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node,
};
