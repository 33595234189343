import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext, useWatch } from 'react-hook-form';

import { tokens, Collapse, NewTypography as Typography } from '@unitoio/mosaic';

import { otherSide as getOtherSide } from '~/utils/otherSide';
import { FeatureFlag } from '~/components/FeatureFlag/FeatureFlag';
import * as featureTypes from '~/consts/features';
import { FeatureFlagVariant } from '~/components/FeatureFlag/FeatureFlagVariant';
import { StartDefaultValues } from '~/containers/FlowBuilder/pages/ModernRules/DefaultValues/StartDefaultValues';
import { useSelector } from 'react-redux';
import { isUserSiteAdmin } from '~/reducers';
import { StopSyncingLabel } from './StopSyncing/StopSyncingLabel';
import { StartSyncing } from './StartSyncing/StartSyncing';
import { StopSyncing } from './StopSyncing/StopSyncing';
import * as formUtils from '../../utils/form';
import { ModernRulesSideHeader } from './ModernRulesSideHeader';
import { useGetProviderTerms } from '../../hooks/useGetProviderTerms';
import { useGetSides } from '../Rules/hooks';
import { OnFilterOutRule } from '../Rules/RulesSection/OnFilterOutRule/OnFilterOutRule';
import { LegacyDefaultValues } from './DefaultValues/LegacyDefaultValues';

const OverrideCollapse = styled(Collapse)`
  background: ${tokens.colors.global.primary.light};

  .mimics-collapse-header {
    align-items: center !important;
  }
`;

const RuleContainer = styled.div`
  margin-bottom: ${tokens.spacing.s4};
  padding: ${tokens.spacing.s4};
  border: 1px solid ${tokens.colors.content.neutral.n10};
  border-radius: ${tokens.spacing.s3};
`;

const SectionContainer = styled.div`
  padding: ${tokens.spacing.s4};
  border-bottom: 1px solid ${tokens.colors.content.neutral.n10};
`;

export const ModernRuleSide = ({ side, loadingState, initializeFieldsLoadingState }) => {
  const { register } = useFormContext();
  const [direction, isSideInitialized] = useWatch({
    name: ['syncDirection', `${side}.filtersInitializedAt`],
  });
  const isOneWayAndSameSide = direction === side;

  const otherSide = getOtherSide(side);
  const { [side]: currentSide, [otherSide]: currentOtherSide } = useGetSides();
  const itemTerms = useGetProviderTerms(currentSide.providerName, currentSide.itemType);
  const itemTermsOtherSide = useGetProviderTerms(currentOtherSide.providerName, currentOtherSide.itemType);

  const isSiteAdmin = useSelector((state) => isUserSiteAdmin(state));

  const items = [
    {
      label: <StopSyncingLabel side={side} />,
      children: <StopSyncing loadingState={loadingState} side={side} />,
      key: 'stopSyncing',
    },
  ];

  return (
    <RuleContainer>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="hidden" {...register(`${side}.filtersInitializedAt`)} defaultValue={isSideInitialized} />

      <ModernRulesSideHeader side={side} />
      {!isOneWayAndSameSide ? (
        <>
          <SectionContainer>
            <StartSyncing loadingState={loadingState} side={side} />
          </SectionContainer>
          <SectionContainer>
            <FeatureFlag name={featureTypes.FEATURES.MODERN_ACTIONS}>
              <FeatureFlagVariant value={true}>
                <StartDefaultValues side={side} loadingState={loadingState} />
              </FeatureFlagVariant>
              <FeatureFlagVariant value={false}>
                <LegacyDefaultValues side={side} loadingState={initializeFieldsLoadingState} />
              </FeatureFlagVariant>
            </FeatureFlag>
          </SectionContainer>
        </>
      ) : (
        <SectionContainer>
          <Typography.Text>
            The flow direction selected to one-way prevents {itemTerms?.itemName?.plural ?? currentSide.itemType} to be
            synced as {itemTermsOtherSide?.itemName?.plural ?? currentOtherSide.itemType} in{' '}
            {itemTermsOtherSide.providerDisplayName}
          </Typography.Text>
        </SectionContainer>
      )}
      <OverrideCollapse
        expandIconPosition="end"
        items={items}
        bordered={false}
        // only site admins can collapse the section for now
        collapsible={isSiteAdmin ? null : 'disabled'}
      />

      <FeatureFlag name={featureTypes.FEATURES.DELETE_PRESERVE_OUT_OF_SYNC_ITEM_FILTER}>
        <FeatureFlagVariant value={true}>
          <OnFilterOutRule withSeparator rowIntroduction="THEN" side={side} />
        </FeatureFlagVariant>
      </FeatureFlag>
    </RuleContainer>
  );
};

ModernRuleSide.propTypes = {
  side: PropTypes.oneOf(['A', 'B']),
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
  initializeFieldsLoadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
