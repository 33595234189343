import React from 'react';
import { useSelector } from 'react-redux';

import { getCapabilitiesForItem } from 'reducers';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { useGetProviderNames } from '../../hooks/useGetProviderNames';
import { useGetProviders } from '../../hooks/useGetProviders';

export const PageHeaderTitle = () => {
  const [itemTypeA] = useGetItemTypes();
  const [containerA] = useGetContainers();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const [providerA, providerB] = useGetProviders(providerNameA, providerNameB);

  const providerDisplayNameA = providerA.get('displayName') || providerNameA;
  const providerDisplayNameB = providerB.get('displayName') || providerNameB;

  const isSameProvider = providerNameA === providerNameB;
  const sameSideCapabilitiesForItem = useSelector((state) =>
    getCapabilitiesForItem(state, providerA.get('_id'), containerA.get('id'), itemTypeA),
  );
  const sameSidePluralItemType = sameSideCapabilitiesForItem.getIn(['names', 'plural'])?.toLowerCase();

  return (
    <>
      Map fields between {providerDisplayNameA}{' '}
      {isSameProvider ? sameSidePluralItemType : `and ${providerDisplayNameB}`}
    </>
  );
};
