import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import { WorkBlockModel } from './WorkBlockModel';
import { WorkBlockWidget } from './WorkBlockWidget';

export class WorkBlockFactory extends AbstractReactFactory {
  constructor() {
    super('WorkBlock');
  }

  // eslint-disable-next-line class-methods-use-this
  generateModel = () => new WorkBlockModel();

  generateReactWidget(event) {
    return <WorkBlockWidget engine={this.engine} node={event.model} event={event} />;
  }
}
