import * as linkTypes from '~/consts/link';

export function getSyncActivityStatus(linkState, activity, health, isSuspended = false) {
  const isLinkStateDraft = linkState === linkTypes.LINK_STATES.DRAFT;
  const isLinkDegraded = linkTypes.DEGRADED_LINK_STATES.includes(linkState);

  if (isLinkStateDraft) {
    return linkTypes.LINK_STATES.DRAFT;
  }

  if (isSuspended) {
    return linkTypes.LINK_ACTIVITY_STATUS.SUSPENDED;
  }

  if (isLinkDegraded) {
    return linkTypes.LINK_ACTIVITY_STATUS.DEGRADED;
  }

  return activity || health;
}
