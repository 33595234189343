import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Bold, Box, Flex, tokens, Tooltip, ProviderIcon, Collapsible, Typography, Icon } from '@unitoio/mosaic';

import { getCountItemsPerItemTypePerContainer, getFeatureFlagValue } from 'reducers';

import { ActiveContainerLabel } from './ActiveContainerLabel';
import { ContainerItemTypeCount } from './ContainerItemTypeCount';

function getItemActivity(container, isCalculatingItems) {
  const count = container.get('itemsCount');

  if (count > 0) {
    return (
      <Typography variant={Typography.variants.BODY2}>
        <Bold>{Math.round(count)} </Bold> {count > 1 ? 'items' : 'item'} in sync
      </Typography>
    );
  }

  // if we have activity logs that match the current container and the
  // count is 0 we must be calculating the IIS
  if (isCalculatingItems) {
    return (
      <Tooltip placement="top" content="Items count updates every 2-4 hours">
        Calculating items in sync
      </Tooltip>
    );
  }

  return 'No items activity';
}

function getItemTypeForActiveContainer(countPerItemTypePerContainerEachDay, containerId) {
  const today = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const allContainersToday = countPerItemTypePerContainerEachDay.get(today);

  if (!allContainersToday) {
    return Map();
  }

  return allContainersToday.get(containerId, Map()).filter((v) => v > 0);
}

export function ActiveContainers({ container, isCalculatingItems }) {
  const { organizationId } = useParams();
  const hasItemsKeptInSyncItemType = useSelector((state) => getFeatureFlagValue(state, 'items-kept-in-sync-item-type'));

  const countPerItemTypePerContainerEachDay = useSelector((state) =>
    getCountItemsPerItemTypePerContainer(state, organizationId),
  );

  const typesInContainer = getItemTypeForActiveContainer(countPerItemTypePerContainerEachDay, container.get('_id'));

  return (
    <Box
      p={[tokens.spacing.s4]}
      borderRadius={tokens.spacing.s4}
      borderSize={1}
      m={[tokens.spacing.s3, tokens.spacing.s0, tokens.spacing.s2]}
      key={container.get('_id')}
      flexDirection={Box.flexDirection.ROW}
    >
      {typesInContainer.size > 1 && hasItemsKeptInSyncItemType ? (
        <Collapsible
          expandIconKind={Icon.KINDS.SOLID}
          headerOpensOnClick
          header={
            <Flex align="center" gap="large">
              <Flex align="center">
                <ProviderIcon size="default" name={container.getIn(['provider', 'name'])} />
              </Flex>
              <Flex align="center" flex={1}>
                <ActiveContainerLabel name={container.get('name')} url={container.getIn(['container', 'url'])} />
              </Flex>
              <Flex align="flex-end" style={{ marginRight: tokens.spacing.s4 }}>
                {getItemActivity(container, isCalculatingItems)}
              </Flex>
            </Flex>
          }
        >
          <Flex style={{ marginRight: '2.5rem' }} flex={1} justify="flex-end">
            <Flex align="flex-end" vertical>
              {typesInContainer
                .map((count, itemType) => (
                  <div key={container.getIn(['container', 'id'])}>
                    {ContainerItemTypeCount({
                      providerName: container.getIn(['provider', 'name']),
                      itemType,
                      count,
                    })}
                  </div>
                ))
                .toArray()}
            </Flex>
          </Flex>
        </Collapsible>
      ) : (
        <Flex align="center" gap="large">
          <Flex align="center">
            <ProviderIcon size="default" name={container.getIn(['provider', 'name'])} />
          </Flex>
          <Flex align="center" flex={1}>
            <ActiveContainerLabel name={container.get('name')} url={container.getIn(['container', 'url'])} />
          </Flex>
          <Flex align="flex-end" style={{ marginRight: tokens.spacing.s4 }}>
            {getItemActivity(container, isCalculatingItems)}
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

ActiveContainers.propTypes = {
  container: PropTypes.instanceOf(Map).isRequired,
  isCalculatingItems: PropTypes.bool.isRequired,
};
