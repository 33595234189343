import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import { getProviderByProviderIdentityId, getProviderVisibleFields, getProviderSupportedFields } from 'reducers';

export function useGetField({ providerIdentityId, fieldId, itemType, containerId, includeHidden }) {
  const provider = useSelector((state) => getProviderByProviderIdentityId(state, providerIdentityId));
  const fields = useSelector((state) => {
    if (includeHidden) {
      return getProviderSupportedFields(state, provider.get('_id'), itemType, containerId);
    }
    return getProviderVisibleFields(state, provider.get('_id'), itemType, containerId);
  });
  return fields.get(fieldId, Map());
}
