import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';

import { Icon, tokens } from '@unitoio/mosaic';

import { color, fontSize, fontWeight } from 'theme';
import { Href } from '~/components/Href/Href';

const NavbarHeaderItem = styled.li`
  color: ${color.dark.primary};
  font-weight: ${fontWeight.medium};
  ${(props) => !props.$hasSubmenu && 'padding: .5rem 1.5rem'};
  position: relative;
  z-index: 1;

  & a:hover {
    color: ${color.dark.primary};
  }
`;

const Subtitle = styled.div`
  color: ${color.dark.secondary};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.regular};
`;

const Submenu = styled.div`
  font-weight: ${fontWeight.regular};
  left: calc(100% + 1px);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const SidebarMenuContainer = styled.div`
  display: flex;
`;

const SidebarWorkspaceNameContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ChevronContainer = styled.div`
  align-self: center;
  margin-left: ${tokens.spacing.s3};
`;

export const NavbarHeader = onClickOutside(
  class NavbarHeader extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
      submenu: PropTypes.node,
      subtitle: PropTypes.node,
      onClick: PropTypes.func,
    };

    static defaultProps = {
      submenu: undefined,
      subtitle: undefined,
      onClick: () => null,
    };

    state = {
      isSubmenuOpen: false,
    };

    handleClickOutside = () => {
      this.setState({ isSubmenuOpen: false });
    };

    handleClick = () => {
      const { onClick, submenu } = this.props;
      onClick();
      submenu && this.setState((state) => ({ isSubmenuOpen: !state.isSubmenuOpen }));
    };

    render() {
      const { children, subtitle, submenu, ...rest } = this.props;
      const { isSubmenuOpen } = this.state;

      return (
        <NavbarHeaderItem $hasSubmenu={!!submenu} {...rest}>
          {!submenu ? (
            <SidebarWorkspaceNameContainer>{children}</SidebarWorkspaceNameContainer>
          ) : (
            <Href className="media" onClick={this.handleClick}>
              <SidebarMenuContainer>
                <SidebarWorkspaceNameContainer>
                  {children}
                  {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </SidebarWorkspaceNameContainer>
                {children && (
                  <ChevronContainer>
                    <div className="media-object">
                      <Icon size="sm" name="chevron-right" kind={Icon.KINDS.SOLID} />
                    </div>
                  </ChevronContainer>
                )}
              </SidebarMenuContainer>
            </Href>
          )}
          {isSubmenuOpen && <Submenu>{submenu}</Submenu>}
        </NavbarHeaderItem>
      );
    }
  },
);
