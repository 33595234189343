import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const TableCellWrapper = styled.th`
  text-align: ${(props) => props.align};
  padding: ${(props) => props.$padding || '0 20px'};
`;

export const TableCellHead = ({ align = 'left', cellSize, children, padding }) => (
  <TableCellWrapper align={align} colSpan={cellSize} $padding={padding}>
    {children}
  </TableCellWrapper>
);

TableCellHead.propTypes = {
  align: PropTypes.string,
  cellSize: PropTypes.number,
  children: PropTypes.node,
  padding: PropTypes.string,
};
