import * as featureTypes from '~/consts/features';

export function makeLimitMessageTemplate(feature = '', templateVariables = {}) {
  const templateString = featureTypes.LIMIT_MESSAGES_PER_FEATURE[feature];
  const regex = /\${[A-Za-z]+}/;
  const matchTemplateString = regex.test(templateString);
  if (!matchTemplateString) {
    return templateString;
  }

  const keys = Object.keys(templateVariables);
  const values = Object.values(templateVariables);

  try {
    const templateFunction = new Function(...keys, `return \`${templateString}\`;`); // eslint-disable-line
    return templateFunction(...values);
  } catch (error) {
    return templateString;
  }
}
