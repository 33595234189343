import PropTypes from 'prop-types';
import React from 'react';

import * as unitoLogos from './index';

export function UnitoLogo({ kind = 'horizontal', color = 'color', height = 'auto', width = 'auto' }) {
  const src = unitoLogos[`logo_${color}_${kind}`];
  return <img alt="Unito logo" className="unito-logo" src={src} width={width} height={height} />;
}

UnitoLogo.propTypes = {
  kind: PropTypes.oneOf(['vertical', 'horizontal', 'mark']),
  color: PropTypes.oneOf(['transparent', 'color', 'green']),
  height: PropTypes.string,
  width: PropTypes.string,
};
