import { Flex, NewSelect, NewTypography, ProviderIcon, tokens } from '@unitoio/mosaic';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetSides } from '../pages/Rules/hooks';
import { useGetContainers } from '../hooks/useGetContainers';

export const ContainerNameWithProviderIcon = ({ side }) => {
  const { [side]: currentSide } = useGetSides();
  const [containerA, containerB] = useGetContainers();
  const container = side === 'A' ? containerA : containerB;

  return (
    <NewSelect
      variant="readOnly"
      labelInValue
      labelRender={() => (
        <Flex align="center" gap={tokens.spacing.s3}>
          <ProviderIcon name={currentSide.providerName} size="small" />
          <NewTypography>{container.get('displayName')}</NewTypography>
        </Flex>
      )}
      value={{ value: container.get('displayName'), label: '' }}
    />
  );
};

ContainerNameWithProviderIcon.propTypes = {
  side: PropTypes.oneOf(['A', 'B']).isRequired,
};
