import React from 'react';
import PropTypes from 'prop-types';

import * as routes from '~/consts/routes';
import { Href } from '~/components/Href/Href';

import { TableCell } from '../components';

function Cell({ value }) {
  return (
    <TableCell>
      <div className="wide">
        <Href target="_blank" href={`#${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${value.id}`}>
          {value.name}
        </Href>
      </div>
    </TableCell>
  );
}

Cell.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};
export const flowColumn = {
  header: 'Flow',
  accessor: 'link',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
