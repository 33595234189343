import React from 'react';
import PropTypes from 'prop-types';

import { tokens, Icon, Flex, NewTypography, NewTooltip } from '@unitoio/mosaic';
import { useSetStopSyncing } from './StopSyncing';

export const StopSyncingLabel = ({ icon, side }) => {
  const [stopSyncingValues] = useSetStopSyncing(side);
  const stopSyncingValuesTotal = stopSyncingValues?.$or?.length ? `(${stopSyncingValues?.$or?.length})` : '';
  return (
    <Flex
      align="center"
      gap={tokens.spacing.s3}
      style={{ padding: `${tokens.spacing.s3} 0`, borderColor: tokens.colors.content.neutral.n10 }}
    >
      {icon && <Icon name={icon} />}{' '}
      <NewTypography.Text type="secondary">Stop syncing conditions {stopSyncingValuesTotal}</NewTypography.Text>
      {/* During the A/B test, users won't have access to the stop syncing rule so we need to inform them */}
      <NewTooltip
        title={
          <>
            This feature is currently in beta and is currently disabled. The stop syncing conditions are automatically
            set as the opposite of your start syncing conditions.
          </>
        }
      >
        <Icon name="info-circle" size="sm" kind={Icon.KINDS.SOLID} />
      </NewTooltip>
    </Flex>
  );
};

StopSyncingLabel.propTypes = {
  icon: PropTypes.string,
  side: PropTypes.oneOf(['A', 'B']),
};
