import PropTypes from 'prop-types';
import React from 'react';

import { Alert, AlertLevels, Box, tokens, Typography } from '@unitoio/mosaic';

import MappingHelpImg from '../images/MappingHelpImg.svg';

const helpBlockByProviderName = {
  googlesheets: {
    alertLevel: AlertLevels.INFO,
    helpImg: MappingHelpImg,
    helpImgAlt: 'Google Sheets mapping help img',
    helpText: (
      <span>
        When using Google Sheets, make sure the <b>first row contains distinct and descriptive names</b> and are{' '}
        <b>inserted between the UnitoID and Last Modified columns</b> that you previously added. Any unnamed columns
        will not be visible or mappable above.{' '}
        <a href="https://guide.unito.io/how-to-add-unito-to-your-google-sheet" rel="noreferrer" target="_blank">
          Learn more about the add-on
        </a>
        .
      </span>
    ),
  },
  mailchimp: {
    alertLevel: AlertLevels.INFO,
    helpImg: MappingHelpImg,
    helpImgAlt: 'Mailchimp mapping help img',
    helpText: (
      <span>
        In order to properly sync addresses from a Mailchimp audience, you'll need to map the{' '}
        <b>Street, City, State/Province/Region</b> and <b>Postal/Zip</b> fields. If you notice information missing in{' '}
        those fields after a sync, <b>make sure they aren't empty</b> in the tool you've connected with Mailchimp.{' '}
        <a href="https://guide.unito.io/a-primer-on-unitos-mailchimp-integration" rel="noreferrer" target="_blank">
          Learn more about syncing Mailchimp
        </a>
        .
      </span>
    ),
  },
  pipedrive: {
    alertLevel: AlertLevels.INFO,
    helpImg: MappingHelpImg,
    helpImgAlt: 'Pipedrive mapping help img',
    helpText: (
      <span>
        You can map Pipedrive's marketing status field, but once a contact's status is <b>Unsubscribed</b> or{' '}
        <b>Bounced</b>, it will no longer be editable.{' '}
        <a href="https://guide.unito.io/a-primer-on-unitos-pipedrive-integration" rel="noreferrer" target="_blank">
          Learn more about syncing Pipedrive
        </a>
        .
      </span>
    ),
  },
};

export const ProviderSpecificMappingHelp = ({ providerName }) => {
  const helpBlock = helpBlockByProviderName[providerName];

  if (!helpBlock) {
    return null;
  }

  const { alertLevel, helpImg, helpImgAlt, helpText } = helpBlock;

  return (
    <Alert borderRadius={tokens.spacing.s4} level={alertLevel} showBorder={false} showIcon={false}>
      <Box alignItems="center" flexDirection="row">
        {helpImg && (
          <Box m={[0, tokens.spacing.s5, 0, 0]}>
            <img src={helpImg} alt={helpImgAlt} data-testid={`${providerName}MappingHelpText`} />
          </Box>
        )}
        <Typography variant="body1">{helpText}</Typography>
      </Box>
    </Alert>
  );
};

ProviderSpecificMappingHelp.propTypes = {
  providerName: PropTypes.string.isRequired,
};
