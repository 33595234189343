import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as linkActions from '~/actions/links';
import * as linkTypes from '~/consts/link';
import * as appTypes from '~/consts/app';
import {
  isInProviderEmbed,
  getSignupIntentProduct,
  getSignupIntentBySide,
  hasPositiveCountLinksByKind,
  isUserSiteAdmin,
} from 'reducers';

export const useIsMirrorPowerUpShown = ({ selectedOrganizationId }) => {
  const dispatch = useDispatch();

  const isEmbedTrello = useSelector((state) => isInProviderEmbed(state, appTypes.EMBED.TRELLO));
  const isSiteAdmin = useSelector((state) => isUserSiteAdmin(state));

  const isSignupIntentTrello = useSelector((state) => getSignupIntentProduct(state)) === linkTypes.KIND.TASK_SYNC;

  const hasUserTrelloSignupIntent = useSelector(
    (state) =>
      getSignupIntentBySide(state, { containerSide: 'A' }) === 'Trello' ||
      getSignupIntentBySide(state, { containerSide: 'B' }) === 'Trello',
  );
  const isTaskSyncLinks = useSelector((state) => hasPositiveCountLinksByKind(state));

  // keep this async to avoid blocking the render
  useEffect(() => {
    dispatch(linkActions.getLinksCountLinks({ kinds: [linkTypes.KIND.TASK_SYNC] }));
  }, [dispatch, selectedOrganizationId, isEmbedTrello]);

  return isEmbedTrello || isSiteAdmin || isSignupIntentTrello || hasUserTrelloSignupIntent || isTaskSyncLinks;
};
