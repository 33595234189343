import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { useGetCanAutopopulate, VALIDATE_AUTOPOPULATE_STATUSES } from './hooks';
import { AutopopulateContent } from './AutopopulateContent';
import { AutopopulateLoading } from './AutopopulateLoading';

export function Autopopulate({ linkId, backToMappingUrl }) {
  const [isFetchingCanAutopopulate, { canAutopopulate, side }, hasCanAutopopulateFailed] =
    useGetCanAutopopulate(linkId);

  if (isFetchingCanAutopopulate) {
    return <AutopopulateLoading />;
  }

  if (hasCanAutopopulateFailed || canAutopopulate === VALIDATE_AUTOPOPULATE_STATUSES.CANT_AUTOPOPULATE) {
    return <Redirect to={`${backToMappingUrl}?showChooseMappingStrategy=true`} />;
  }

  return (
    <AutopopulateContent
      side={side}
      linkId={linkId}
      backToMappingUrl={`${backToMappingUrl}?showChooseMappingStrategy=false&sideAutopopulated=${side}`}
    />
  );
}

Autopopulate.propTypes = {
  linkId: PropTypes.string.isRequired,
  backToMappingUrl: PropTypes.string.isRequired,
};
