import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Map } from 'immutable';

import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getPlanTier } from '~/utils/getPlanTier';

import { Modal, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import styled from 'styled-components';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

export function ConfirmChangePlanModal({ currentPlan, newPlan, onRequestClose, onSubmit }) {
  const newAmount = newPlan.get('amount');
  const currentAmount = currentPlan.get('amount');

  const trackEvent = useTrackEvent({}, false);

  useEffect(() => {
    trackEvent(`${trackingTypes.USER_PRICING_EVENTS.PLAN_CHANGE}_${trackingTypes.START}`, {
      plan_selected: newPlan.get('id'),
      plan_tier_selected: getPlanTier(newPlan.get('id')),
    });
  }, [trackEvent, newPlan]);

  return (
    <Modal
      cancelLabel="Close"
      isOpen
      onCancel={onRequestClose}
      onConfirm={onSubmit}
      onRequestClose={onRequestClose}
      title="Confirm plan change"
    >
      <Typography variant={TypographyVariants.BODY1}>
        You will be {currentAmount < newAmount ? 'upgraded' : 'downgraded'} to the{' '}
        <Bold>{newPlan.get('nickname') || newPlan.get('name')}</Bold> plan.
        <br />
        You will be charged immediately and see the price change on your next invoice.
      </Typography>
    </Modal>
  );
}

ConfirmChangePlanModal.propTypes = {
  currentPlan: PropTypes.instanceOf(Map).isRequired,
  newPlan: PropTypes.instanceOf(Map).isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
