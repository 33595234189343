import * as organizationTypes from '~/consts/organizations';

/**
 *
 * @param {*} organization
 * @returns if org is valid
 */
export const isOrganizationValid = (organization) => {
  if (
    organization?.get('status') &&
    ![
      organizationTypes.STATUSES.TRIAL_EXPIRED,
      organizationTypes.STATUSES.CHURNED,
      organizationTypes.STATUSES.EXPIRED,
    ].includes(organization.get('status'))
  ) {
    return true;
  }

  return false;
};
