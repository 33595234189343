import React from 'react';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import { Box } from '~/components/Box/Box';

const AddNewPIWrapper = styled(Box)`
  color: ${tokens.colors.content.info.default};
`;

export function AddProviderIdentityOption() {
  return (
    <div className="media">
      <div className="media-left">
        <AddNewPIWrapper $m={[0, 0.125]} className="media-object">
          <Icon name="square-plus" size="2x" />
        </AddNewPIWrapper>
      </div>
      <div className="media-body">Connect a new account</div>
    </div>
  );
}
