import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useQueryParams } from '~/hooks/useQueryParams';
import { getProviderByName, getProviderIdentityByProfileId } from 'reducers';

/**
 * Custom hook that retrieves query parameters to populate the form data
 * - It handles the query params for the one click report (GSheet plugin) and handles retrieving an existing
 *   provider identity based on the profileId and providerId
 * - It memoizes the query params to avoid unnecessary re-renders
 */
export function useGetQueryParams() {
  const {
    // Query Params for one click report don't have the side in the name, so renaming them to avoid confusing
    // But we should definitely rename the query params in the future
    providerNameA,
    providerNameB,
    // The following params are used by Trello embed to pre-select the A side in connect tools
    itemType: itemTypeA,
    containerType: containerTypeA,
    containerId: containerIdA,
    profileId: profileIdA,
  } = useQueryParams();

  const provider = useSelector((state) => getProviderByName(state, providerNameA));
  const providerIdentityA = useSelector((state) =>
    getProviderIdentityByProfileId(state, profileIdA, provider?.get('_id')),
  );
  let providerIdentityIdA;
  if (providerIdentityA) {
    providerIdentityIdA = providerIdentityA?.get('_id');
  }

  const queryParams = useMemo(
    () => ({
      A: {
        providerName: providerNameA,
        containerId: containerIdA,
        itemType: itemTypeA,
        containerType: containerTypeA,
        providerIdentityId: providerIdentityIdA,
      },
      B: { providerName: providerNameB },
    }),
    [containerIdA, providerNameA, providerNameB, containerTypeA, itemTypeA, providerIdentityIdA],
  );

  return queryParams;
}
