import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';

import { notification } from '@unitoio/mosaic';

import { SelectResyncTypeModal } from './SelectResyncTypeModal';

import { useGetLeaveFlowBuilderRoute } from '../hooks/useGetLeaveFlowBuilderRoute';
import * as formUtils from '../utils/form';

export const SaveChangesModal = ({ isDraft, hasChanged, loadingState }) => {
  const history = useHistory();
  const leaveFlowBuilderRoute = useGetLeaveFlowBuilderRoute();
  const { handleSubmit } = useFormContext();

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [hasConfirmedNavigation, setHasConfirmedNavigation] = useState(showSaveModal);

  // Close the modal when unmounting the component
  useEffect(
    () => () => {
      setShowSaveModal(false);
    },
    [],
  );

  const handleOnConfirm = async () => {
    try {
      await handleSubmit();
      setShowSaveModal(false);
      setHasConfirmedNavigation(false);
    } catch (e) {
      notification.error({
        message: 'Flow settings were not saved',
        description:
          'There was an issue when trying to save your flow. If this error occurs again, contact our team for support.',
        duration: 7,
        placement: 'top',
      });
    }
  };

  const handleOnCancel = () => {
    setShowSaveModal(false);
    history.push(leaveFlowBuilderRoute);
  };

  const handleOnRequestClose = () => {
    setShowSaveModal(false);
    setHasConfirmedNavigation(false);
  };

  return (
    <>
      <Prompt
        when={!hasConfirmedNavigation}
        message={(location) => {
          const isStayingInFlowBuilder = location.pathname.includes('flow-builder');
          // Navigation is considered "confirmed" once the user saw the modal, which is why it is set when the modal opens.
          // It is necessary because otherwise the modal would close and then re-open again when the user clicks on either
          // "Discard changes" or "Save & sync" because clicking those redirects the user.
          const shouldAllowNavigation = hasConfirmedNavigation || isStayingInFlowBuilder || !hasChanged || isDraft;

          if (shouldAllowNavigation) {
            return true;
          }

          setShowSaveModal(true);
          return false;
        }}
      />

      <SelectResyncTypeModal
        onCancel={handleOnCancel}
        onConfirm={handleOnConfirm}
        onOpen={useCallback(() => setHasConfirmedNavigation(true), [setHasConfirmedNavigation])}
        onRequestClose={handleOnRequestClose}
        isOpen={showSaveModal}
        isSaving={loadingState === formUtils.loadingStates.SAVING}
      />
    </>
  );
};

SaveChangesModal.propTypes = {
  isDraft: PropTypes.bool.isRequired,
  hasChanged: PropTypes.bool.isRequired,
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
