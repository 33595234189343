import styled from 'styled-components';

import { color } from 'theme';

export const AuthImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 0.5rem 0;

  img {
    border: 1px solid ${color.dark.hint};
    border-radius: 0.75rem;
    max-width: 100%;
    margin-bottom: 0.25rem;
  }
`;
