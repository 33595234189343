import React from 'react';
import PropTypes from 'prop-types';

import { ExportAndSyncErrorContext } from '~/contexts';

export function ExportAndSyncErrorFunnel({ children, pageName }) {
  return <ExportAndSyncErrorContext.Provider value={pageName}>{children}</ExportAndSyncErrorContext.Provider>;
}

ExportAndSyncErrorFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string.isRequired,
};
