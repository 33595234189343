import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

export const TestModeRuleTooltip = ({ providerTerms }) => {
  const { getValues } = useFormContext();
  const earliestCreatedAt = getValues('earliestCreatedAt');
  const createdAt = getValues('createdAt');
  const earliestCreatedAtDate = new Date(earliestCreatedAt);
  const createdAtDate = new Date(createdAt);
  const shouldUseRecentTooltip = earliestCreatedAtDate < createdAtDate;
  if (shouldUseRecentTooltip) {
    return (
      <>
        Test your flow safely by only syncing recently created {providerTerms}. If you intend to sync all{' '}
        {providerTerms}, remove this rule.
      </>
    );
  }
  return (
    <>
      Test your flow safely by only syncing {providerTerms} created after the flow is launched. If you intend to sync
      preexisting {providerTerms}, remove this rule.
    </>
  );
};

TestModeRuleTooltip.propTypes = {
  providerTerms: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};
