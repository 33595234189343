import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@unitoio/mosaic';

import { NoWrapHeader, TableCell } from '../components';

function Header() {
  return <NoWrapHeader>Updated Fields</NoWrapHeader>;
}

function Cell({ value: fields }) {
  const fieldsStr = fields.sort().join(', ');
  return (
    <TableCell>
      <Tooltip as="div" content={fieldsStr}>
        <div className="wide ellipsized capitalized">
          <span>{fieldsStr}</span>
        </div>
      </Tooltip>
    </TableCell>
  );
}

Cell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const updatedFieldsColumn = {
  header: Header,
  accessor: 'fields',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
