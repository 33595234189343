import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';

import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import * as featureTypes from '~/consts/features';

import { useShouldDisplayAddOnConfigurationStep } from '~/containers/FlowBuilder/hooks/useShouldDisplayAddOnConfigurationStep';
import { GuideStep } from '~/containers/FlowBuilder/components/GuideStep/GuideStep';
import { FlowBuilderErrorFunnel } from '~/containers/FlowBuilder/components/FlowBuilderErrorFunnel';
import { GuideStepWorkItems } from '~/containers/FlowBuilder/components/GuideStep/GuideStepWorkItems';
import { GuideStepFlowDirection } from '~/containers/FlowBuilder/components/GuideStep/GuideStepFlowDirection';
import { GuideStepRules } from '~/containers/FlowBuilder/components/GuideStep/GuideStepRules';
import { GuideStepMapping } from '~/containers/FlowBuilder/components/GuideStep/GuideStepMapping';
import { FeatureFlag } from '~/components/FeatureFlag/FeatureFlag';
import { FeatureFlagVariant } from '~/components/FeatureFlag/FeatureFlagVariant';
import { GuideStepAddOnConfiguration } from '~/containers/FlowBuilder/components/GuideStep/GuideStepAddOnConfiguration';
import { GuideStepReview } from '~/containers/FlowBuilder/components/GuideStep/GuideStepReview';

export const GuideSteps = ({ isLoading, linkState, linkKind }) => {
  const { linkId } = useParams();
  const isDraftFlow = !linkId || linkState === linkTypes.LINK_STATES.DRAFT;
  const isLiteGuide = linkTypes.GUIDE_VARIANTS[linkKind] === linkTypes.GUIDE_VARIANT_TYPE.LITE;
  const shouldDisplayAddonConfiguration = useShouldDisplayAddOnConfigurationStep();

  if (isLoading) {
    return (
      <>
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
      </>
    );
  }

  return (
    <>
      <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.TOOL_SELECTION}>
        <GuideStepWorkItems />
      </FlowBuilderErrorFunnel>

      {(!isLiteGuide || !isDraftFlow) && (
        <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.FLOW_DIRECTION}>
          <GuideStepFlowDirection linkState={linkState} />
        </FlowBuilderErrorFunnel>
      )}

      {!isLiteGuide && (
        <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.RULES}>
          <GuideStepRules linkState={linkState} />
        </FlowBuilderErrorFunnel>
      )}

      <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.MAPPINGS}>
        <GuideStepMapping linkState={linkState} />
      </FlowBuilderErrorFunnel>

      <FeatureFlag name={featureTypes.FEATURES.ADD_ON_IMPROVEMENTS}>
        <FeatureFlagVariant value={true}>
          {shouldDisplayAddonConfiguration && isDraftFlow && (
            <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.ADD_ON}>
              <GuideStepAddOnConfiguration />
            </FlowBuilderErrorFunnel>
          )}
        </FeatureFlagVariant>
      </FeatureFlag>

      {isDraftFlow && <GuideStepReview linkState={linkState} />}
    </>
  );
};

GuideSteps.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  linkState: PropTypes.string.isRequired,
  linkKind: PropTypes.string.isRequired,
};
