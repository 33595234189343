import React from 'react';
import PropTypes from 'prop-types';

import { Box, ProviderIcon } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import { Href } from '~/components/Href/Href';

export function ProviderIconTooltipUrl({ providerName, tooltipContent, url, trackEvent }) {
  const providerIcon = (
    <Box
      onClick={() =>
        trackEvent &&
        trackEvent(trackingTypes.ACTION, {
          action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.CLICKED_LOGO,
        })
      }
    >
      <ProviderIcon name={providerName} size="large" tooltipText={tooltipContent} />
    </Box>
  );

  if (url) {
    return (
      <Href target="_blank" href={url}>
        {providerIcon}
      </Href>
    );
  }

  return providerIcon;
}

ProviderIconTooltipUrl.propTypes = {
  providerName: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string,
  url: PropTypes.string,
  trackEvent: PropTypes.func,
};
