import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import * as appTypes from '~/consts/app';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import {
  getCollaboratorsStatsByOrgId,
  getCurrentPlan,
  getEmbedName,
  getSelectedOrganizationId,
  getOrganizationSubscription,
  isOrganizationDelinquent,
  isOnMirrorEmbedContext,
  getIsAuthenticated,
} from 'reducers';
import { RoutedNavTabs } from '~/components/NavTabs/RoutedNavTabs';
import { Header, RightHeader } from '~/components/Header/Header';
import { Href } from '~/components/Href/Href';

import { RightHeaderContent } from './RightHeaderContent';

const StyledRedBanner = styled.div`
  background-color: ${tokens.colors.background.message.warning};
  color: ${tokens.colors.global.primary};
  text-align: center;

  a,
  a:active,
  a:visited,
  a:hover {
    color: ${tokens.colors.global.primary};
    text-decoration: underline;
  }
`;

function RedBanner({ children, eventOnMount }) {
  const trackEvent = useTrackEvent();
  useEffect(() => {
    trackEvent(eventOnMount);
  }, [eventOnMount, trackEvent]);
  return <StyledRedBanner>{children}</StyledRedBanner>;
}

RedBanner.propTypes = {
  children: PropTypes.node.isRequired,
  eventOnMount: PropTypes.string.isRequired,
};

export function HeaderContainer() {
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const currentSubscription = useSelector((state) => getOrganizationSubscription(state, selectedOrganizationId));

  const collaboratorsStats = useSelector((state) => getCollaboratorsStatsByOrgId(state, selectedOrganizationId));
  const currentPlan = useSelector((state) => getCurrentPlan(state, currentSubscription));
  const isDelinquent = useSelector((state) => isOrganizationDelinquent(state, selectedOrganizationId));
  const isMirrorEmbedContext = useSelector((state) => isOnMirrorEmbedContext(state));
  const embedName = useSelector((state) => getEmbedName(state));
  const isAuthenticated = useSelector((state) => getIsAuthenticated(state));
  const trackEvent = useTrackEvent();

  function getTabRoutes() {
    const tabRoutes = [
      {
        path: routes.ABSOLUTE_PATHS.DASHBOARD,
        activeOnPaths: [
          routes.ABSOLUTE_PATHS.TASK_SYNC,
          routes.ABSOLUTE_PATHS.PROJECT_SYNC,
          routes.ABSOLUTE_PATHS.WORKFLOWS,
        ],
        tab: 'Dashboard',
        exact: true,
      },
    ];

    if (!embedName) {
      tabRoutes.push({
        path: routes.ABSOLUTE_PATHS.ACTIVITY_LOGS,
        activeOnPaths: [routes.ABSOLUTE_PATHS.ACTIVITY_LOGS],
        tabKey: 'Activity Logs',
        onClick: () =>
          trackEvent(trackingTypes.HEADER_BAR_EVENTS.ACTION_NAME, {
            action_name: trackingTypes.HEADER_BAR_EVENTS.ACTIONS.DIAGNOSTICS,
          }),
        tab: <Fragment>Activity Stream</Fragment>,
        exact: true,
      });
    }

    return tabRoutes;
  }

  function getDefaultAppHeader() {
    return (
      <Header>
        {isAuthenticated && (
          <RoutedNavTabs
            className="multisync-container__navigation"
            isJustified={false}
            routes={getTabRoutes()}
            tabStyle="underline"
          />
        )}
        <RightHeader trackEvent={trackEvent}>
          <RightHeaderContent />
        </RightHeader>
      </Header>
    );
  }

  const numberOfCollaborators = collaboratorsStats.get('numCollaborators', 0);
  const planMaxUsers = Number(currentPlan.getIn(['featuresById', 'MAX_USERS', 'limit']));
  const productNameRedirect = isMirrorEmbedContext
    ? appTypes.PRODUCT_NAMES_PARAM.TASK_SYNC
    : appTypes.PRODUCT_NAMES_PARAM.PROJECT_SYNC;

  return (
    <>
      {getDefaultAppHeader()}
      {isDelinquent && (
        <RedBanner eventOnMount={trackingTypes.USER_DROPDOWN_EVENTS.USER_SAW_FAILED_PAYMENT_BANNER}>
          <div className="container">
            We had trouble charging your credit card. Please update your billing information to continue using Unito.{' '}
            <Href
              to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/billing/${productNameRedirect}/information`}
              onClick={() => trackEvent(trackingTypes.USER_DROPDOWN_EVENTS.USER_CLICKED_FAILED_PAYMENT_BANNER)}
            >
              Update billing info
            </Href>
          </div>
        </RedBanner>
      )}

      {!isDelinquent && numberOfCollaborators > planMaxUsers && (
        <RedBanner eventOnMount={trackingTypes.USER_DROPDOWN_EVENTS.USER_SAW_BILLING_EXPERIMENT_1}>
          <div className="container">
            <Icon name="lock" kind={Icon.KINDS.SOLID} /> Oops! You’ve hit your plan limit of active users. Unlock your
            team’s potential!{' '}
            <Href
              to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/billing`}
              onClick={() => trackEvent(trackingTypes.USER_DROPDOWN_EVENTS.USER_CLICKED_BILLING_EXPERIMENT_1)}
            >
              Upgrade now
            </Href>
          </div>
        </RedBanner>
      )}
    </>
  );
}
