import React from 'react';

import { DefaultLinkFactory } from '@projectstorm/react-diagrams';
import { EdgeModel } from './EdgeModel';
import { EdgeWidget } from './EdgeWidget';

export class EdgeLineFactory extends DefaultLinkFactory {
  constructor() {
    super('advanced');
  }

  // eslint-disable-next-line class-methods-use-this
  generateModel = () => new EdgeModel();

  // eslint-disable-next-line class-methods-use-this
  generateLinkSegment(model, selected, path) {
    return (
      <g>
        <EdgeWidget model={model} path={path} />
      </g>
    );
  }
}
