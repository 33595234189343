import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Map } from 'immutable';

import { ScriptArgItem } from './ScriptArgItem';

export const ScriptArgsList = ({ scriptDefinition }) => (
  <div>
    {scriptDefinition.get('kwargs').map((kwarg) => (
      <Field key={kwarg.get('name')} name={kwarg.get('name')} component={ScriptArgItem} props={{ kwarg }} />
    ))}
  </div>
);

ScriptArgsList.propTypes = {
  scriptDefinition: PropTypes.instanceOf(Map).isRequired,
};
