import PropTypes from 'prop-types';

// FIXME Use the prop-types definitions exported from redux-form
// once this warning bug is fixed https://github.com/redux-form/redux-form/issues/2535
// here the fix is to mark onDragStart and onDrop as not required

const { any, bool, func, shape, string } = PropTypes;

export const fieldInputPropTypes = {
  checked: bool,
  name: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onDragStart: func,
  onDrop: func,
  onFocus: func.isRequired,
  value: any,
};

export const fieldMetaPropTypes = {
  active: bool.isRequired,
  asyncValidating: bool.isRequired,
  autofilled: bool.isRequired,
  dirty: bool.isRequired,
  dispatch: func.isRequired,
  error: any,
  form: string.isRequired,
  invalid: bool.isRequired,
  pristine: bool.isRequired,
  submitting: bool.isRequired,
  submitFailed: bool.isRequired,
  touched: bool.isRequired,
  valid: bool.isRequired,
  visited: bool.isRequired,
  warning: string,
};

export const fieldPropTypes = {
  input: shape(fieldInputPropTypes).isRequired,
  meta: shape(fieldMetaPropTypes).isRequired,
};
