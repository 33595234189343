import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../AuthTitleWrapper';
import { AuthImageWrapper } from '../AuthImageWrapper';
import gheOAuthApp2 from './ghe-oauth-application-2.png';

export function AuthGheOAuthAppStep2({ children: instructionFields, history, onCancel, onSubmit }) {
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (OAuth Application - Step 2 of 2)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          In the screen Applications / Unito, copy the <strong>Client ID</strong> and <strong>Client Secret</strong>{' '}
          values and paste them below
        </p>
        <AuthImageWrapper>
          <img src={gheOAuthApp2} alt="GitHub Enterprise OAuth Application Step - Step 2" />
        </AuthImageWrapper>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button btnStyle="dark" onClick={onSubmit} type="href" pullRight>
            Authorize
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthGheOAuthAppStep2.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
