import { useIsSideReadOnly } from './useIsSideReadOnly';

export function useAreBothSidesReadOnly({
  itemTypeA,
  itemTypeB,
  providerIdA,
  providerIdB,
  containerIdA,
  containerIdB,
}) {
  const isASideReadOnly = useIsSideReadOnly(providerIdA, containerIdA, itemTypeA);
  const isBSideReadOnly = useIsSideReadOnly(providerIdB, containerIdB, itemTypeB);

  // early return since we use this hook in drafts, where provider/container/itemType could be undefined
  if (!providerIdA || !providerIdB || !itemTypeA || !itemTypeB || !containerIdA || !containerIdB) {
    return false;
  }
  return isASideReadOnly && isBSideReadOnly;
}
