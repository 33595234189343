import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { Box, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { SelectorLabel, WORK_ITEM_SELECTOR_VARIANT } from '../../../components/WorkItemSelector/SelectorLabel';

export const NoContainerSelect = ({ label }) => {
  const { watch } = useFormContext();

  const selectedProviderIdentityIdA = watch('A.providerIdentityId');
  const selectedProviderIdentityIdB = watch('B.providerIdentityId');
  const selectedContainerIdA = watch('A.containerId');

  const isSelectionCompleted = !!selectedProviderIdentityIdA && !!selectedProviderIdentityIdB && !!selectedContainerIdA;

  return (
    <>
      <SelectorLabel workItemSelectorVariant={WORK_ITEM_SELECTOR_VARIANT.LABEL_INPUT_BLOCK} withMargin label={label} />
      <Box
        p={[tokens.spacing.s3, tokens.spacing.s4]}
        backgroundColor={
          isSelectionCompleted ? tokens.colors.background.message.info : tokens.colors.background.neutral.grey
        }
        borderColor={isSelectionCompleted && tokens.colors.strokes.message.info}
        borderRadius={tokens.spacing.s2}
      >
        <Typography
          color={isSelectionCompleted ? tokens.colors.content.info.default : tokens.colors.content.secondary.default}
          variant={TypographyVariants.BODY2}
        >
          A new Google Sheet will be created in your Google Drive.
        </Typography>
      </Box>
    </>
  );
};

NoContainerSelect.propTypes = {
  label: PropTypes.string.isRequired,
};
