import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Box, Col, Grid, Row, Typography, tokens } from '@unitoio/mosaic';

import { CreateSyncButton } from '~/containers/SyncsTabNav/CreateSyncButton';
import * as routes from '~/consts/routes';

import poster from './poster.png';

const Video = styled.video`
  height: 100%;
  width: 100%;
`;

export const WorkItemStatusNotSynced = ({ isAuthenticated }) => (
  <Grid>
    <Row align="center" justify="between">
      <Col xl={5} lg={5}>
        <Box m={[0, 0, tokens.spacing.s4]}>
          <Typography variant="h3">This task is not part of any flows yet.</Typography>
        </Box>
        <Box m={[0, 0, tokens.spacing.s5]}>
          <Typography variant="h1">Connect your apps and automate your workflows in minutes</Typography>
        </Box>
        <Box m={[0, 0, tokens.spacing.s4]}>
          <Typography variant="body">Go beyond Zapier, with deep, bidirectional flows.</Typography>
        </Box>
        {isAuthenticated ? (
          <CreateSyncButton />
        ) : (
          <>
            <Button variant="primary" href={`/#${routes.ABSOLUTE_PATHS.SIGNUP}`}>
              Try it free
            </Button>
            <Box m={[0, tokens.spacing.s4]} as="span">
              <Button variant="outlined" href="https://unito.io/features" target="_blank">
                Discover our features
              </Button>
            </Box>
          </>
        )}
      </Col>
      <Col xl={7} lg={7}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <Video controls preload="none" playsInline autoplay poster={poster}>
          <source
            src="https://unito.io/wp-content/uploads/2021/07/HomepageVid_NewFlowBuilder-comp.webm"
            type="video/webm"
          />
          <source
            src="https://unito.io/wp-content/uploads/2021/07/HomepageVid_NewFlowBuilder-comp.mp4"
            type="video/mp4"
          />
        </Video>
      </Col>
    </Row>
  </Grid>
);

WorkItemStatusNotSynced.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};
