import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { WorkBlockAdd } from '~/components/WorkBlock/WorkBlockAdd';

const BLOCK_COUNT_GRID = 5;

const BlockPlaceholder = styled.div`
  height: ${(props) => props.$height}px;
  width: ${(props) => props.$width}px;
  position: absolute;
  > div {
    > div {
      transform: scale(${(props) => props.$scale});
    }
  }
`;

export const WorkflowWorkBlockAdd = React.memo(({ scale, quadrilleSize, point }) => {
  const sideLength = quadrilleSize * BLOCK_COUNT_GRID;
  return (
    <BlockPlaceholder
      $width={sideLength}
      $height={sideLength}
      $scale={scale}
      style={{
        top: `${point.y}px`,
        left: `${point.x}px`,
      }}
    >
      <WorkBlockAdd scale={scale} />
    </BlockPlaceholder>
  );
}, isSameWorkBlock);

WorkflowWorkBlockAdd.propTypes = {
  scale: PropTypes.number.isRequired,
  quadrilleSize: PropTypes.number.isRequired,
  point: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
};

function isSameWorkBlock(prevProps, nextProps) {
  return (
    prevProps.point.x === nextProps.point.x &&
    prevProps.point.y === nextProps.point.y &&
    prevProps.scale === nextProps.scale &&
    prevProps.quadrilleSize === nextProps.quadrilleSize
  );
}
