import { List } from 'immutable';

export const isMergeable = (a) => a && typeof a === 'object' && typeof a.mergeWith === 'function' && !List.isList(a);

// https://github.com/immutable-js/immutable-js/issues/1452#issuecomment-386162309

export const mergeDeepOverwriteLists = (a, b) => {
  if (!b) {
    return b;
  }

  return isMergeable(a) ? a.mergeWith(mergeDeepOverwriteLists, b) : b;
};
