import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Map } from 'immutable';
import { isMobile } from 'react-device-detect';
import { Button } from '@unitoio/mosaic';

import { fontSize, color, fontWeight } from 'theme';
import * as formTypes from '~/consts/form';
import { Divider } from '~/components/Divider/Divider';
import { Href } from '~/components/Href/Href';
import { Box } from '~/components/Box/Box';
import { SelectProviderField } from '~/containers/SelectProviderField/SelectProviderField';

const SectionTitle = styled.h3`
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.medium};
  margin-bottom: 2rem;
  text-align: center;
`;

const TermsOfService = styled.i`
  font-size: ${fontSize.small};
  color: ${color.dark.hint};
`;

export const SignupFields = ({ provider, providers, disableSubmit = false, trackSignupAction, onSubmit }) => {
  const onSubmitWithTool = () => {
    // we need to reset manually the input fields otherwise
    // in case of an error the submit will be blocked
    onSubmit();
  };

  return (
    <Fragment>
      <Box $m={[0, 1.5]}>
        {!isMobile && (
          <Fragment>
            <SectionTitle>Sign up with</SectionTitle>

            <SelectProviderField
              {...provider}
              id="signupProvider"
              providers={providers}
              track={trackSignupAction}
              onSubmit={onSubmitWithTool}
              disableSubmit={disableSubmit}
            />
            <Divider>OR</Divider>
          </Fragment>
        )}
        <SectionTitle>
          <Button variant="outlined" block onClick={() => onSubmit()}>
            Sign up with email and password
          </Button>
        </SectionTitle>
      </Box>

      <TermsOfService>
        By signing up, you agree to Unito’s{' '}
        <Href
          linkStyle="subtleLink"
          onClick={() => trackSignupAction('clicked Terms of service')}
          href="https://unito.io/terms"
        >
          Terms of service
        </Href>{' '}
        and{' '}
        <Href
          linkStyle="subtleLink"
          onClick={() => trackSignupAction('clicked Privacy policy')}
          href="https://unito.io/privacy"
        >
          Privacy policy.
        </Href>
      </TermsOfService>
    </Fragment>
  );
};

SignupFields.propTypes = {
  providers: PropTypes.instanceOf(Map).isRequired,
  provider: PropTypes.shape(formTypes.fieldPropTypes).isRequired,
  trackSignupAction: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
