import createEngine, { DefaultDiagramState } from '@projectstorm/react-diagrams';

import { WorkflowDiagramModel as WorkflowModel } from '../models/WorkflowDiagramModel';
import { WorkBlockFactory } from '../components/Nodes/WorkBlockFactory';
import { FlowNodeFactory } from '../components/Nodes/FlowFactory';
import { WorkBlockPortFactory } from '../components/Nodes/WorkBlockPortFactory';
import { FlowPortFactory } from '../components/Nodes/FlowPortFactory';
import { EdgeLineFactory } from '../components/Edges/EdgeFactory';

const MAX_NUMBER_BREAKS_PER_LINK = 0;

export function createWorkflowEngine(diagramRepresentation, workflowId, trackEvent, reportWarning = () => {}) {
  // https://github.com/projectstorm/react-diagrams/issues/271#ref-issue-361700027
  // prevent the backspace key to delete edges and nodes.
  const engine = createEngine({ registerDefaultDeleteItemsAction: false });

  // Create factories for our custom nodes
  engine.getLinkFactories().registerFactory(new EdgeLineFactory());
  engine.getNodeFactories().registerFactory(new WorkBlockFactory());
  engine.getNodeFactories().registerFactory(new FlowNodeFactory(reportWarning));
  engine.getPortFactories().registerFactory(new WorkBlockPortFactory());
  engine.getPortFactories().registerFactory(new FlowPortFactory());

  const model = new WorkflowModel({}, engine, diagramRepresentation, workflowId, trackEvent);

  // The MAX_NUMBER_BREAKS_PER_LINK is the number of breaks allowed on a link between two nodes.
  // Breaks create handles that allow the manipulation of the link. See: http://projectstorm.cloud/react-diagrams/?path=/story/simple-usage--simple-example
  // and click anywhere on the link between the node.
  engine.setMaxNumberPointsPerLink(MAX_NUMBER_BREAKS_PER_LINK);
  engine.setModel(model);

  const engineState = engine.getStateMachine().getCurrentState();
  if (engineState instanceof DefaultDiagramState) {
    engineState.dragNewLink.config.allowLooseLinks = false; // workaround to remove loose links;
    // Only known workaround to force out the option to select multiple nodes, which was crashing the library
    // this needs to be executed here because it gets repopulated by the engine depending on the state of the model, which is out of our control
    // TODO : send a message to the owner of the library to know if there's a better solution to fix this issue
    engine.getStateMachine().getCurrentState().childStates = [];
  }

  // Forces rerender of the placeholder block on zoom
  // This is needed because the library stops the propagation of mouse scroll events and the placeholder is outside the library
  const currentModel = engine.getModel();
  currentModel.registerWorkflowModelListeners();

  engine.zoomToFitAllNodes = () => {
    // by default a node is selected on load and a node also becomes selected when you move it, and selected influences `zoomToFitNodes`
    // we might use selected in the future so we shouldn't always override it to false
    // but we need to override it here because we want all of the nodes for this custom zoomToFit
    const selectedNodes = engine.model.getSelectedEntities();
    selectedNodes.forEach((node) => {
      node.setSelected(false);
    });
    engine.zoomToFitNodes(100);
  };

  return engine;
}
