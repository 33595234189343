import React from 'react';
import PropTypes from 'prop-types';

export const EdgeWidget = ({ model, color = '#152A4C', path }) => (
  <path fill="none" strokeWidth={model.getOptions().width} stroke={color} d={path} />
);

EdgeWidget.propTypes = {
  color: PropTypes.string,
  model: PropTypes.shape({
    getOptions: PropTypes.func.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};
