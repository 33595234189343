export function getPlanTier(planId) {
  // The logic here is aligned with Data Team's. It can be found in dbt --> macros/get_plan_tier.sql
  const planIdLowercase = planId.toLowerCase();
  if (planIdLowercase.includes('trial')) {
    return BillingPlanTier.TRIAL;
  }
  if (
    planIdLowercase.startsWith('basic-10-') ||
    planIdLowercase.startsWith('basic-10_') ||
    planIdLowercase.includes('starter') ||
    planIdLowercase.includes('personal')
  ) {
    return BillingPlanTier.PERSONAL;
  }
  if (
    planIdLowercase.includes('team') ||
    planIdLowercase.includes('pro5-') ||
    planIdLowercase.includes('pro10-') ||
    planIdLowercase.includes('pro15-') ||
    planIdLowercase.includes('pro15bis-') ||
    planIdLowercase.includes('pro20-') ||
    planIdLowercase.startsWith('basic-20') ||
    planIdLowercase.includes('-basic') ||
    planIdLowercase.includes('-spreadsheet2000')
  ) {
    return BillingPlanTier.TEAM;
  }
  if (
    planIdLowercase.includes('business') ||
    planIdLowercase.includes('company') ||
    planIdLowercase.includes('pro') ||
    planIdLowercase.startsWith('basic-50') ||
    planIdLowercase.startsWith('basic-100') ||
    planIdLowercase.includes('-spreadsheet')
  ) {
    return BillingPlanTier.COMPANY;
  }
  if (
    planIdLowercase.includes('custom-invoiced') ||
    planIdLowercase.includes('enterprise') ||
    planIdLowercase.includes('custom - see invoice')
  ) {
    return BillingPlanTier.ENTERPRISE;
  }
  if (planIdLowercase.includes('wrike')) return BillingPlanTier.FREE_WITH_WRIKE;
  if (planIdLowercase.includes('pause')) return BillingPlanTier.PAUSE;
  if (planIdLowercase.includes('migration')) return BillingPlanTier.MIGRATION;
  if (planIdLowercase.includes('unito')) return BillingPlanTier.UNITO_EMPLOYEE;
  return null;
}

export const BillingPlanTier = {
  TRIAL: 'Trial',
  PERSONAL: 'Personal',
  TEAM: 'Team',
  BUSINESS_PLUS: 'Business +',
  BUSINESS: 'Business',
  COMPANY_PLUS: 'Company +',
  COMPANY: 'Company',
  ENTERPRISE: 'Enterprise',
  FREE_WITH_WRIKE: 'Free with Wrike',
  PAUSE: 'Pause',
  MIGRATION: 'Migration',
  UNITO_EMPLOYEE: 'Unito Employee',
};
