import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import { Box } from '~/components/Box/Box';

const Message = styled.div`
  color: ${tokens.colors.content.neutral.n30};
  margin-top: ${tokens.spacing.s4};
`;

const sizeMap = {
  small: 'lg',
  medium: '2x',
  large: '3x',
};

export const InlineLoading = ({ children, as = 'div', size = 'large' }) => {
  const padding = as === 'div' ? [1.25] : [];

  return (
    <Box as={as} $p={padding} className="text-center">
      <Icon
        size={sizeMap[size]}
        color={tokens.colors.content.neutral.n30}
        name="spinner"
        kind={Icon.KINDS.SOLID}
        title="loading"
        pulse
      />
      {children && <Message>{children}</Message>}
    </Box>
  );
};

InlineLoading.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  as: PropTypes.oneOf(['span', 'div']),
};
