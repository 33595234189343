export const statuses = {
  INITIAL: 'initial',
  LOADED: 'loaded',
  STANDBY: 'standby',
  SUCCESS: 'success',
  SUCCESS_DFV: 'successDefaultFieldValue',
  SUCCESS_RULES: 'successDefaultRules',
  SUCCESS_DUPLICATE: 'successDuplicateRules',
  LOADING: 'loading',
  ERROR_CF: 'errorCustomFields',
  ERROR_DFV: 'errorDefaultFieldValue',
};
