const MONGO_ID_REGEX = /^[0-9a-fA-F]{24}$/;

export function isMongooseModelId(idOrDoc) {
  if (idOrDoc == null) return false;

  if (typeof idOrDoc === 'number') {
    return true;
  }

  if (typeof idOrDoc === 'string') {
    return idOrDoc.length === 12 || (idOrDoc.length === 24 && MONGO_ID_REGEX.test(idOrDoc));
  }

  return false;
}
