import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import { BoxedAlert } from '~/components/BoxedAlert/BoxedAlert';

export const ContainerErrors = ({ containerError, selectedProviderName }) => {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (containerError) {
      trackEvent(trackingTypes.EVENT_NAME.BLOCKED, {
        selected_tool_name: selectedProviderName,
        reason: containerError,
      });
    }
  }, [containerError, selectedProviderName, trackEvent]);

  if (!containerError) {
    return null;
  }

  return <BoxedAlert message={containerError} />;
};

ContainerErrors.propTypes = {
  containerError: PropTypes.string,
  selectedProviderName: PropTypes.string,
};
