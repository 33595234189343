import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { color } from 'theme';

const BarContainer = styled.div`
  margin-bottom: 0;
  margin-top: 8px;
  border-radius: 40px;

  .progress-bar {
    background-color: ${color.brand.secondary};
  }

  .progress-bar-danger {
    background-color: ${color.brand.lightRed};
  }
`;

const HorizontalBarContainer = styled(BarContainer)`
  height: 1rem;
  max-width: 20rem;
`;

const VerticalBarContainer = styled(BarContainer)`
  width: 1rem;
  max-height: 20rem;
`;

const Bar = styled.div`
  border-radius: 40px;
`;

const VerticalBar = styled(Bar)`
  width: 1rem;
  height: ${(props) => props.$progression}%;
`;

const HorizontalBar = styled(Bar)`
  height: 1rem;
  width: ${(props) => props.$progression}%;
`;

const BarWithTransition = styled(Bar)`
  transition: width 2s ease;
`;

const VerticalBarWithTransition = styled(BarWithTransition)`
  width: 1rem;
  height: ${(props) => (props.$isTransitioning ? props.$progression : 0)}%;
`;

const HorizontalBarWithTransition = styled(BarWithTransition)`
  height: 1rem;
  width: ${(props) => (props.$isTransitioning ? props.$progression : 0)}%;
`;

export class ProgressBar extends Component {
  static propTypes = {
    dangerZone: PropTypes.bool,
    progression: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['horizontal', 'vertical']),
    animateTransition: PropTypes.bool,
  };

  static defaultProps = {
    dangerZone: false,
    type: 'horizontal',
    animateTransition: false,
  };

  state = {
    isTransitioning: false,
  };

  componentDidMount() {
    const { animateTransition } = this.props;
    if (animateTransition) {
      this.delayTimer();
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timer);
  }

  delayTimer = () => {
    this.timer = setTimeout(() => {
      this.setState({ isTransitioning: true });
    }, 500);
  };

  mapTypeToBarComponent = () => {
    const { type, animateTransition } = this.props;
    const mapping = {
      vertical: [VerticalBarContainer, VerticalBar],
      horizontal: [HorizontalBarContainer, HorizontalBar],
    };

    const mappingWithTransition = {
      vertical: [VerticalBarContainer, VerticalBarWithTransition],
      horizontal: [HorizontalBarContainer, HorizontalBarWithTransition],
    };

    return animateTransition ? mappingWithTransition[type] : mapping[type];
  };

  render() {
    const { dangerZone, progression } = this.props;
    const { isTransitioning } = this.state;

    const [BarWrapper, BarComponent] = this.mapTypeToBarComponent();

    return (
      <BarWrapper className="progress">
        <BarComponent
          className={classnames('progress-bar', { 'progress-bar-danger': dangerZone })}
          role="progressbar"
          aria-valuenow={progression}
          aria-valuemin="0"
          aria-valuemax="100"
          $progression={progression}
          $isTransitioning={isTransitioning}
        >
          <span className="sr-only">{progression}% Complete</span>
        </BarComponent>
      </BarWrapper>
    );
  }
}
