import { useEffect } from 'react';

import * as trackingTypes from '~/consts/tracking';

import { useTrackEvent } from './useTrackEvent';

export function useTrackStartEvent() {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackingTypes.START);
  }, [trackEvent]);
}
