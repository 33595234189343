import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { tokens, Box, HorizontalSeparator, LoadingPlaceholder } from '@unitoio/mosaic';

import { otherSide } from '~/utils/otherSide';
import * as fieldTypes from '~/consts/fields';
import * as pcdFilterOperatorTypes from '~/consts/pcdFilterOperator';

import { RulesRow } from '../../../../components/RulesRow';
import { MissingItemFieldAssociation } from '../MissingItemFieldAssociation/MissingItemFieldAssociation';
import { FilterRows } from '../FilterRows/FilterRows';
import { AppendNewFilter } from '../AppendNewFilter/AppendNewFilter';
import { SetDefaultFieldValueFromFilterRows } from '../SetDefaultFieldValueFromFilterRows/SetDefaultFieldValueFromFilterRows';
import { AppendNewDefaultFieldValueFromFilter } from '../AppendNewDefaultFieldValueFromFilter/AppendNewDefaultFieldValueFromFilter';
import { ThenStatementRuleRow } from '../ThenStatementRuleRow/ThenStatementRuleRow';
import { KeepThemInSyncRow } from '../KeepThemInSyncRow/KeepThemInSyncRow';
import { displayCreatedDateFieldFilter, isDisabledOptionHandler, useGetAddNewRule } from './utils';

export const ClassicIntegrationRules = ({ isLoading, linkState, side, sides }) => {
  const otherContainerSide = otherSide(side);
  const { watch } = useFormContext();
  const otherContainerSection = sides[otherSide(side)];

  const deepFilterItemField = otherContainerSection.fields.find(
    (field) => field.fieldId === fieldTypes.DEEP_FILTER_ITEM,
  );
  const deepFilterItemFieldId = deepFilterItemField?.value;
  const needsItemFieldAssociation = !!(deepFilterItemFieldId && deepFilterItemFieldId !== 'all');
  const otherSideActionFields = otherContainerSection.actions.fields;
  const directionValue = watch('syncDirection');

  const addNewRule = useGetAddNewRule();

  if (isLoading) {
    return <LoadingPlaceholder borderRadius={tokens.spacing.s2} width="100%" height={tokens.spacing.s9} />;
  }

  return (
    <>
      <Box alignItems="center" flexDirection="column">
        <Box fullWidth>
          <FilterRows
            fields={sides[side].fields}
            remove={sides[side].remove}
            filtersContext={{
              side,
              itemType: sides[side].itemType,
              containerType: sides[side].containerType,
              groupedFields: sides[side].groupedFields,
              groupedDeepFields: sides[side].groupedDeepFields,
              deepFilters: sides[side].deepFilters,
              providerName: sides[side].providerName,
              providerIdentityId: sides[side].providerIdentityId,
              containerId: sides[side].containerId,
              otherContainerFieldArray: {
                fields: sides[otherContainerSide].fields,
                remove: sides[otherContainerSide].remove,
              },
            }}
            linkState={linkState}
            isDisabledOptionHandler={isDisabledOptionHandler}
            addNewRule={addNewRule}
          />
          <AppendNewFilter
            side={side}
            itemType={sides[side].itemType}
            containerType={sides[side].containerType}
            providerIdentityId={sides[side].providerIdentityId}
            providerName={sides[side].providerName}
            containerId={sides[side].containerId}
            addNewRule={addNewRule}
            fields={sides[side].fields}
            append={sides[side].append}
            groupedFields={sides[side].groupedFields}
            isDisabledOptionHandler={isDisabledOptionHandler}
            linkState={linkState}
            displayCreatedDateFieldFilter={displayCreatedDateFieldFilter}
          />
        </Box>
      </Box>

      <Box m={[tokens.spacing.s4, tokens.spacing.s0]}>
        <HorizontalSeparator />
      </Box>

      <Box alignItems="center" flexDirection="column">
        <ThenStatementRuleRow otherContainerSection={otherContainerSection} />

        <Box fullWidth>
          <KeepThemInSyncRow containerType={sides[side].containerType} itemType={sides[side].itemType} />

          {otherSideActionFields.map((data, index) => (
            <RulesRow
              isAction
              providerName={sides[otherContainerSide].providerName}
              providerIdentityId={sides[otherContainerSide].providerIdentityId}
              containerId={sides[otherContainerSide].containerId}
              itemType={sides[otherContainerSide].itemType}
              containerType={sides[otherContainerSide].containerType}
              containerSide={otherContainerSide}
              key={data._id}
              name={`${otherContainerSide}.actions.${index}`}
              fieldDetails={otherContainerSection.groupedActionFields[data.fieldId]}
              data={data}
              linkState={linkState}
            />
          ))}

          {directionValue !== fieldTypes.TARGET.BOTH ? (
            <>
              <SetDefaultFieldValueFromFilterRows
                filters={sides[side]}
                otherContainerSection={otherContainerSection}
                otherItemType={sides[otherContainerSide].itemType}
                otherContainerType={sides[otherContainerSide].containerType}
                otherContainerSide={otherContainerSide}
                linkState={linkState}
              />
              <AppendNewDefaultFieldValueFromFilter
                otherContainerSide={otherContainerSide}
                otherContainerSection={otherContainerSection}
                itemType={sides[side].itemType}
                containerType={sides[side].containerType}
                isDisabledOptionHandler={isDisabledOptionHandler}
                otherItemType={sides[otherContainerSide].itemType}
                addNewRule={addNewRule}
              />
            </>
          ) : null}
        </Box>

        {needsItemFieldAssociation && (
          <MissingItemFieldAssociation
            itemType={sides[side].itemType}
            containerType={sides[side].containerType}
            deepFilterItemFieldId={deepFilterItemFieldId}
            otherContainerSide={otherContainerSide}
            side={side}
            providerIdentityId={sides[side].providerIdentityId}
            filtersA={sides.A.fields}
            filtersB={sides.B.fields}
            providerName={sides[side].providerName}
            containerId={sides[side].containerId}
          />
        )}
      </Box>
    </>
  );
};

const filterValueType = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.bool.isRequired,
]);

const filterShape = PropTypes.shape({
  operator: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)),
  type: PropTypes.string,
  value: PropTypes.oneOfType([filterValueType, PropTypes.arrayOf(filterValueType)]),
  kind: PropTypes.string,
  fieldId: PropTypes.string,
  id: PropTypes.string,
  _id: PropTypes.string,
  isSetDefault: PropTypes.bool,
});

const groupedFieldShape = PropTypes.shape({
  canBeAllowed: PropTypes.bool.isRequired,
  canBeDenied: PropTypes.bool.isRequired,
  existingOperators: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)).isRequired,
  nbRulesForField: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(filterValueType),
});

ClassicIntegrationRules.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  linkState: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  sides: PropTypes.shape({
    A: PropTypes.shape({
      fields: PropTypes.arrayOf(filterShape).isRequired,
      groupedFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      append: PropTypes.func.isRequired,
      providerIdentityId: PropTypes.string.isRequired,
      providerName: PropTypes.string.isRequired,
      containerId: PropTypes.string.isRequired,
      containerType: PropTypes.string.isRequired,
      itemType: PropTypes.string.isRequired,
    }).isRequired,
    B: PropTypes.shape({
      fields: PropTypes.arrayOf(filterShape).isRequired,
      groupedFields: PropTypes.shape({
        [PropTypes.string]: groupedFieldShape,
      }).isRequired,
      append: PropTypes.func.isRequired,
      providerIdentityId: PropTypes.string.isRequired,
      providerName: PropTypes.string.isRequired,
      containerId: PropTypes.string.isRequired,
      containerType: PropTypes.string.isRequired,
      itemType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
