import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Map, Set } from 'immutable';
import styled from 'styled-components';

import { Icon, Modal, Toggle, tokens, Typography, TypographyVariants, Alert } from '@unitoio/mosaic';

import * as trackingActions from '~/actions/tracking';
import * as unitoIdentityActions from '~/actions/unitoIdentities';
import { getProviderIdentitiesOfUnitoIdentity } from 'reducers';
import * as trackingTypes from '~/consts/tracking';
import { Href } from '~/components/Href/Href';
import { Title } from '~/components/Title/Title';

import { Tools } from './Tools';

const FormSection = styled.div`
  margin-top: ${tokens.spacing.s4};
`;

const StyledTypography = styled(Typography)`
  display: inline-block;
`;

const StyledToggle = styled(Toggle)`
  display: inline-block;
  margin-right: ${tokens.spacing.s3};
`;

const RenderIncludeAsActiveUserSection = styled.div`
  margin-top: ${tokens.spacing.s3};
`;

const renderIncludeAsActiveUser = (props) => {
  const { input, disabled } = props;

  return (
    <Fragment>
      <RenderIncludeAsActiveUserSection>
        <StyledToggle
          value={!!input.value}
          disabled={!!disabled}
          onClick={() => {
            input.onChange(!input.value);
          }}
          id={input.name}
        />
        <StyledTypography variant={TypographyVariants.BODY1}>Include as an active user</StyledTypography>
      </RenderIncludeAsActiveUserSection>
      <RenderIncludeAsActiveUserSection>
        <Typography variant={TypographyVariants.BODY2}>
          When a user collaborates in a synced project, they{' '}
          <Href href="https://guide.unito.io/how-does-unitos-pricing-work">will automatically count</Href> as an active
          user. If they are not included, any items where they have collaborated will not sync.
        </Typography>
      </RenderIncludeAsActiveUserSection>
      {!input.value && (
        <RenderIncludeAsActiveUserSection>
          <Alert size="sm">
            <Typography variant={TypographyVariants.BODY2}>
              No comments or items assigned to this user will sync.
            </Typography>
          </Alert>
        </RenderIncludeAsActiveUserSection>
      )}
    </Fragment>
  );
};

renderIncludeAsActiveUser.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export function areIdentitiesMergeable(providerIdentities) {
  // Identities coming from IPS don't have providerInstanceId and therefore when we're trying to
  // compare providerInstanceIds of 2 modern identities it will always be the same and prevent merging.
  const providerInstanceIds = providerIdentities
    .map(
      (providerIdentity) =>
        providerIdentity.get('providerInstanceId') ??
        `${providerIdentity.get('providerName')}-${providerIdentity.get('domain')}`,
    )
    .toList();

  return providerInstanceIds.size === Set(providerInstanceIds).size;
}

const UnitoIdentityEditModalComponent = ({
  handleSubmit,
  mergeWithIds,
  isOpen,
  onCancel,
  onRequestClose,
  providerIdentities,
  submitting,
  unitoIdentity,
}) => {
  const isMergeable = areIdentitiesMergeable(providerIdentities);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMergeable) {
      dispatch(
        trackingActions.trackEvent(trackingTypes.PEOPLE_EVENTS.ACTION_NAME, {
          action_name: trackingTypes.PEOPLE_EVENTS.ACTIONS.MERGE_ATTEMPT_SAME_TOOL,
        }),
      );
    }
  }, [dispatch, isMergeable]);

  const getConfirmLabel = () => {
    if (isMergeable) {
      if (submitting) {
        return 'Saving...';
      }
      return 'Save';
    }
    return 'Ok, I understand';
  };

  return (
    <Modal
      displayCloseButton
      isOpen={isOpen}
      onCancel={onCancel}
      onRequestClose={onRequestClose}
      size="lg"
      title={isMergeable ? "User's details" : "Accounts with the same tool can't be merged yet"}
      confirmLabel={getConfirmLabel()}
      displayCancelButton={isMergeable}
      onConfirm={isMergeable ? handleSubmit : onRequestClose}
      isConfirmButtonDisabled={isMergeable ? submitting : false}
    >
      {isMergeable ? (
        <form onSubmit={handleSubmit}>
          <FormSection>
            <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n30}>
              Name
            </Typography>
            <Typography variant={TypographyVariants.BODY1}>
              {providerIdentities.getIn([unitoIdentity.getIn(['providerIdentities', 0]), 'profileDisplayName'])}
            </Typography>
          </FormSection>

          <FormSection>
            <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n30}>
              Tools and accounts
            </Typography>
            <Typography variant={TypographyVariants.BODY1}>
              <Icon name="refresh" kind={Icon.KINDS.SOLID} /> Last time has collaborated on a work item:{' '}
              {unitoIdentity.get('lastActivityAt') ? moment(unitoIdentity.get('lastActivityAt')).fromNow() : 'Never'}
            </Typography>

            <FieldArray
              name="providerIdentityIds"
              component={Tools}
              props={{ providerIdentities, isMerge: mergeWithIds.length > 1 }}
            />
          </FormSection>

          <FormSection>
            <Field
              name="isIncluded"
              component={renderIncludeAsActiveUser}
              label={<Title type="h4">Include as an active user</Title>}
            />
          </FormSection>
        </form>
      ) : (
        <Typography variant={TypographyVariants.BODY1}>
          Merging users within the same tool isn't available, but we're working on it! You can keep an eye on our{' '}
          <Href href="https://unito.io/blog/unito-product-updates/">Product Updates</Href> and{' '}
          <strong>subscribe to our newsletter</strong> to be the first to know when it goes live.
        </Typography>
      )}
    </Modal>
  );
};

UnitoIdentityEditModalComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired, // eslint-disable-line
  isOpen: PropTypes.bool.isRequired,
  mergeWithIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired, // eslint-disable-line
  onRequestClose: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  unitoIdentity: PropTypes.instanceOf(Map).isRequired,
  organizationId: PropTypes.string.isRequired, // eslint-disable-line
  providerIdentities: PropTypes.instanceOf(Map).isRequired,
};

const UnitoIdentityEditModalWithForm = reduxForm({
  form: 'updateUnitoIdentity',
  onSubmit: async (values, dispatch, { mergeWithIds, onConfirm, organizationId, unitoIdentity }) => {
    const providerIdentityIdsToUnmerge = unitoIdentity
      .get('providerIdentities')
      .filter((id) => !values.providerIdentityIds.includes(id))
      .toArray();

    let result;
    if (providerIdentityIdsToUnmerge.length > 0) {
      dispatch(
        trackingActions.trackEvent(trackingTypes.PEOPLE_EVENTS.ACTION_NAME, {
          action_name: trackingTypes.PEOPLE_EVENTS.ACTIONS.UNMERGE_UNITO_IDENTITIES,
          include_as_active_user: values.isIncluded,
          unmerged_qty: providerIdentityIdsToUnmerge.length,
        }),
      );

      result = await dispatch(
        unitoIdentityActions.unmergeUnitoIdentity(
          organizationId,
          unitoIdentity.get('_id'),
          providerIdentityIdsToUnmerge,
          !values.isIncluded,
        ),
      );
    } else if (mergeWithIds.length > 0) {
      dispatch(
        trackingActions.trackEvent(trackingTypes.PEOPLE_EVENTS.ACTION_NAME, {
          action_name: trackingTypes.PEOPLE_EVENTS.ACTIONS.MERGE_UNITO_IDENTITIES,
          include_as_active_user: values.isIncluded,
          merged_qty: mergeWithIds.length,
        }),
      );

      result = await dispatch(
        unitoIdentityActions.mergeUnitoIdentities(organizationId, mergeWithIds, !values.isIncluded),
      );
    } else {
      if (!values.isIncluded) {
        dispatch(
          trackingActions.trackEvent(trackingTypes.PEOPLE_EVENTS.ACTION_NAME, {
            action_name: trackingTypes.PEOPLE_EVENTS.ACTIONS.EXCLUDED_ACTIVE_USER,
          }),
        );
      }

      result = await dispatch(
        unitoIdentityActions.updateUnitoIdentity(organizationId, unitoIdentity.get('_id'), !values.isIncluded),
      );
    }

    dispatch(unitoIdentityActions.getUnitoIdentities(organizationId));
    onConfirm();
    return result;
  },
})(UnitoIdentityEditModalComponent);

const mapStateToProps = (state, { mergeWithIds, unitoIdentity }) => {
  const providerIdentities = getProviderIdentitiesOfUnitoIdentity(state, { mergeWithIds, unitoIdentity });
  return {
    providerIdentities,
    initialValues: {
      providerIdentityIds: providerIdentities.map((pId) => pId.get('_id')).toArray(),
      isIncluded: !unitoIdentity.get('isExcluded'),
    },
  };
};

export const UnitoIdentityEditModal = connect(mapStateToProps)(UnitoIdentityEditModalWithForm);
