/**
 * Redux websockets middleware to communicate with Kirby.
 *
 * The client receives messages from Kirby through a WebSocket connection.
 *
 * The workflow to receive messages is:
 *
 * 1. Establish a WebSocket connection to Kirby.
 * 2. Send messages to subscribe to ALL **topics** for one or many **pages**.
 * 3. Receive WebSocket messages for those pages, one per topic.
 *
 * That WebSocket connection can be in the states `CONNECTING`, `OPEN`, `CLOSING` or `CLOSED`.
 *
 * ## The current state of disconnects / reconnects
 *
 * - On a page load, if the client is not able to connect to Kirby, it will store pending
 *   messages, and try to reconnect to Kirby every 10s.
 *
 * - When Kirby kills a connection to the client, a hard reload is necessary to re-establish
 *   that connection, unless the client still have pending messages to sent to Kirby.
 *   In that case, the client tries to reconnect to Kirby every 10s.
 *
 *   That said, the client will re-establish the connection to Kirby, send its pending
 *   messages, but the previous subscriptions are NOT re-sent. Not great.
 */

import { v4 } from 'uuid';
import { logger } from '@unitoio/sherlock';
import * as websocketActions from '~/actions/websocket';
import * as websocketTypes from '~/consts/websocket';

export function websocketsMiddleware({ dispatch, createWebSocketConnection }) {
  const pendingMessages = [];

  // The correlation id is produced at the initialization of the middleware,
  // which means its value stay the same from the moment the user opens the
  // app until the user closes the app.
  const correlationId = v4();

  let socket = null;
  let authToken = null;
  let heartbeatTimeout = null;
  let reconnectTimeout = null;

  const sendMessage = (action, currentPage, payload) => {
    if (!socket || socket.readyState !== websocketTypes.WEBSOCKET_STATE.OPEN) {
      // The client wants to send a message to the server but the connection is not open.
      // When it happens, we queue the message...
      pendingMessages.unshift({ action, currentPage, payload });

      // ... and we try to reconnect to the server, unless the connection is in progress.
      if (socket.readyState !== websocketTypes.WEBSOCKET_STATE.CONNECTING && authToken) {
        clearTimeout(reconnectTimeout);

        reconnectTimeout = setTimeout(() => {
          dispatch(websocketActions.connect(authToken));
        }, websocketTypes.RECONNECT_TIMER);
      }

      return null;
    }

    if (!currentPage) {
      return socket.send(JSON.stringify(action));
    }

    return socket.send(
      JSON.stringify({
        ...payload,
        action,
        currentPage,
      }),
    );
  };

  const heartbeat = () => {
    if (!socket || socket.readyState !== websocketTypes.WEBSOCKET_STATE.OPEN) {
      return;
    }

    const payload = {
      action: 'heartbeat',
      currentPage: null,
      organizationId: null,
      userId: null,
      linkIds: null,
    };

    socket.send(JSON.stringify(payload));
    heartbeatTimeout = setTimeout(heartbeat, websocketTypes.HEARTBEAT_TIMER);
  };

  const onOpen = (event) => {
    if (socket.readyState !== websocketTypes.WEBSOCKET_STATE.OPEN) {
      return null;
    }

    socket.send(JSON.stringify(event));
    heartbeat();

    // When the WS connection is open, we process the pending messages first,
    // in the same order they were received.
    //
    // This approach of starting from the end of the array, splicing items, etc.
    // is taken to avoid this potential infinite loop:
    //
    // Dequeue the pending message =>
    // Try to send it =>
    // Oops, the socket is closed =>
    // Enqueue the pending message
    //
    for (let i = pendingMessages.length - 1; i >= 0; i -= 1) {
      const message = pendingMessages.splice(i, 1)[0];

      sendMessage(message.action, message.currentPage, message.payload);
    }

    return dispatch(websocketActions.connected());
  };

  const onClose = () => {
    clearTimeout(heartbeatTimeout);
    heartbeatTimeout = null;

    // When the WS connection closes but the client still want to send messages,
    // we try to reconnect to the WS server.
    if (pendingMessages.length && authToken) {
      clearTimeout(reconnectTimeout);

      reconnectTimeout = setTimeout(() => {
        dispatch(websocketActions.connect(authToken));
      }, websocketTypes.RECONNECT_TIMER);

      return null;
    }

    authToken = null;
    pendingMessages.length = 0;

    return dispatch(websocketActions.disconnected());
  };

  const onMessage = (event) => {
    const { data: dataEvent } = event;

    if (!dataEvent || typeof dataEvent !== 'string' || dataEvent.trim().toUpperCase() === 'OK') {
      return null;
    }

    let data;

    try {
      data = JSON.parse(dataEvent);
    } catch (e) {
      logger.error('Failed to parse error from Kirby', { correlation_id: correlationId }, dataEvent);
      return null;
    }

    if (data.message && ['Internal server error', 'heartbeat'].includes(data.message)) {
      return null;
    }

    if (!data.currentPage || !data.topic || !data.payload) {
      return null;
    }

    return dispatch(websocketActions.updateTopicByPage(data.currentPage, data.topic, data.payload));
  };

  // Error
  const onError = (event) => {
    const { data } = event;

    // This event is fired when the connection cannot be opened or is closed by the server.
    // When it happens, we won't have data associated to the event.
    if (!data || typeof data !== 'string' || data.trim().toUpperCase() === 'OK') {
      return null;
    }

    try {
      const payload = JSON.parse(data);
      logger.info('Error received from Kirby', { correlation_id: correlationId }, payload);
    } catch (e) {
      logger.error('Failed to parse error from Kirby', { correlation_id: correlationId }, data);
    }

    return null;
  };

  // the middleware part of this function
  return (next) => (action) => {
    switch (action.type) {
      case websocketTypes.WS_CONNECT: {
        if (!process.env.REACT_APP_UNITO_WEB_SOCKET_INVOKE_URL) {
          logger.warn('REACT_APP_UNITO_WEB_SOCKET_INVOKE_URL is not set, skipping Kirby', {
            correlation_id: correlationId,
          });

          return next(action);
        }

        if (socket !== null) {
          socket.close();
        }

        authToken = action.meta.authToken;

        // The initial REACT_APP_UNITO_WEB_SOCKET_INVOKE_URL has the format...
        //
        //   ws://<host>:<port>?authBearerToken=
        //
        // ... so we first add the authentication token...
        //
        //   ws://<host>:<port>?authBearerToken=foo
        //
        // ... and then we add the remaining query params:
        //
        //   ws://<host>:<port>?authBearerToken=foo&clientVersion=bar&correlationId=spam
        //
        const kirbyUrl = [
          process.env.REACT_APP_UNITO_WEB_SOCKET_INVOKE_URL,
          authToken,
          `&clientVersion=${process.env.REACT_APP_VERSION}`,
          `&correlationId=${correlationId}`,
        ].join('');

        // The snake case "correlation_id" is necessary for proper Datadog logging.
        logger.info('Connecting to Kirby', { correlation_id: correlationId });

        try {
          if (createWebSocketConnection) {
            socket = createWebSocketConnection(kirbyUrl);
          } else {
            socket = new WebSocket(kirbyUrl);
          }
        } catch (err) {
          logger.error('Could not connect to Kirby', { correlation_id: correlationId }, err);

          return next(action);
        }

        logger.info('Connected to Kirby', { correlation_id: correlationId });

        // websocket handlers
        socket.onmessage = onMessage;
        socket.onclose = onClose;
        socket.onopen = onOpen;
        socket.onerror = onError;

        return socket;
      }

      case websocketTypes.WS_DISCONNECT: {
        if (socket !== null) {
          socket.close();
        }

        socket = null;

        return socket;
      }

      case websocketTypes.WS_SUBSCRIBE: {
        const { meta, payload } = action;
        const { currentPage } = meta;

        sendMessage(websocketTypes.WS_MESSAGE_ACTIONS.SUBSCRIBE, currentPage, {
          ...payload,
          // Sent as uppercase to match topic casing in Kirby
          Anomalies: {
            limitPerCategory: 5,
          },
        });

        return socket;
      }

      default: {
        return next(action);
      }
    }
  };
}
