import { useWatch } from 'react-hook-form';
import { useGetIsLastAssociationCompleted } from '../../../hooks/useGetIsLastAssociationCompleted';

export function useCanPerformFieldMappingActions(associations) {
  const fieldAssociations = useWatch({ name: 'associations', defaultValue: associations });
  const isLastAssociationCompleted = useGetIsLastAssociationCompleted(fieldAssociations);

  if (fieldAssociations.length === 1) {
    return [false, isLastAssociationCompleted];
  }

  const canAddMapping = isLastAssociationCompleted || fieldAssociations.length === 0;
  const canRemoveAssociation = fieldAssociations.length > 1 && canAddMapping;
  return [canRemoveAssociation, canAddMapping];
}
