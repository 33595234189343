import React from 'react';
import { useParams } from 'react-router-dom';

import { NewAlert, NewAlertLevel, NewTypography } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';
import { useHasMissingMergeSettings } from '../../../hooks/useHasMissingMergeSettings';

export const MissingMergeSettingsAlert = () => {
  const { linkId } = useParams();

  const isMissingMergeSettings = useHasMissingMergeSettings();

  if (!isMissingMergeSettings) {
    return null;
  }

  return (
    <NewAlert
      level={NewAlertLevel.ERROR}
      message={
        <>
          Please identify a source of truth tool to prevent contact duplicates. Fill in the missing information in the
          <NewTypography.Link href={`/#${routes.ABSOLUTE_PATHS.EDIT_FLOW_BUILDER}/${linkId}/rules`}>
            {' '}
            Manage Duplicates section
          </NewTypography.Link>
          .
        </>
      }
    />
  );
};
