import PropTypes from 'prop-types';

export const STEPS = {
  GUIDE: 'guide',
  CONNECT: 'connect',
  FLOW_DIRECTION: 'flowDirection',
  RULES: 'rules',
  MAPPING: 'mapping',
  REVIEW: 'review',
  DONE: 'done',
};

export const LOADING_STATUSES = {
  INITIAL: 'initial',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

export const STEP_STATUSES = {
  WAITING: 'waiting',
  DONE: 'done',
};

export const GOOGLE_ADD_ON = {
  STAGING_LINK: 'https://workspace.google.com/marketplace/app/unito_for_google_sheets_staging/1017107714555',
  PRODUCTION_LINK: 'https://workspace.google.com/marketplace/app/unito_for_google_sheets/548272798607',
};

export const fieldAssociationPropTypes = PropTypes.shape({
  A: PropTypes.shape({
    field: PropTypes.string,
    kind: PropTypes.string,
    mappingCategory: PropTypes.string,
    hasMapping: PropTypes.bool,
    mapping: PropTypes.arrayOf(
      PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })) }),
    ),
  }),
  B: PropTypes.shape({
    field: PropTypes.string,
    kind: PropTypes.string,
    mappingCategory: PropTypes.string,
    hasMapping: PropTypes.bool,
    mapping: PropTypes.arrayOf(
      PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })) }),
    ),
  }),
  id: PropTypes.string.isRequired,
  target: PropTypes.oneOf(['A', 'B', 'both']),
});
