import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Cookies from 'js-cookie';

import { Wrapper as MosaicWrapper, API } from '@unitoio/mosaic';

import { App } from '~/containers/App/App';
import { AppError } from '~/containers/AppError/AppError';
import * as loggingActions from '~/actions/logging';
import * as trackingActions from '~/actions/tracking';
import { ScrollToTop } from '~/components/ScrollToTop/ScrollToTop';

// TODO transform Root into our top level ErrorBoundary with sherlock ?
class RootComponent extends Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    logException: PropTypes.func.isRequired,
    store: PropTypes.shape({}).isRequired,
    trackEvent: PropTypes.func.isRequired,
  };

  state = {
    showError: false,
    error: undefined,
    errorInfo: undefined,
  };

  static getDerivedStateFromError(error) {
    return {
      showError: true,
      error,
    };
  }

  // Root component now acting as global error boundary for the App.
  componentDidCatch(error, errorInfo) {
    const { logException, trackEvent } = this.props;

    this.setState({
      error,
      errorInfo,
    });

    if (!error.reportedInMiddleware) {
      trackEvent('Had error in console', {
        error: {
          ...error,
          identifier: error.identifier || 'UnknownIdentifier',
          stack: errorInfo.componentStackerrorInfo,
        },
      });

      if (error instanceof Error) {
        logException(error, { context: { errorInfo } });
      } else {
        logException(new Error([error.identifier, error.message].filter((m) => m).join(' - ')), {
          context: { errorInfo },
        });
      }
    }
  }

  onDismissError = () => {
    this.setState({
      showError: false,
      error: undefined,
      errorInfo: undefined,
    });
  };

  render() {
    const { history, store } = this.props;
    const { showError, error, errorInfo } = this.state;

    const token = Cookies.get(process.env.REACT_APP_UNITO_AUTH_COOKIE_NAME ?? 'token');
    const api = new API.ApiClient(
      new API.BearerTokenAuthenticator(token),
      API.ApiBaseUrl[process.env.REACT_APP_ENV ?? API.ApiBaseUrl.production],
    );

    const AppContent = !showError ? (
      <App />
    ) : (
      <AppError error={error} errorInfo={errorInfo} onDismissError={this.onDismissError} />
    );

    return (
      <MosaicWrapper api={api}>
        <Provider store={store}>
          <div className="root-container">
            <Router history={history}>
              <div>
                <ScrollToTop />
                {AppContent}
              </div>
            </Router>
          </div>
        </Provider>
      </MosaicWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  logException: (error, context) => dispatch(loggingActions.reportException(error, context)),
  trackEvent: (eventName, data) => dispatch(trackingActions.trackEvent(eventName, data)),
});

export const Root = connect(null, mapDispatchToProps)(RootComponent);
