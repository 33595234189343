import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../AuthTitleWrapper';
import { AuthImageWrapper } from '../AuthImageWrapper';
import gitlabOAuthApp2 from './gitlab-oauth-application-2.png';
import oldGitlabOAuthApp2 from './old-gitlab-oauth-application-2.png';

export function AuthGitlabOAuthAppStep2({ children: instructionFields, history, newUI, onCancel, onSubmit, switchUI }) {
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (OAuth Application - Step 2 of 2)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          In the screen Application: Unito, copy the <strong>Application Id</strong> and <strong>Secret</strong> values
          and paste them below
        </p>
        <AuthImageWrapper className="clearfix">
          <img
            src={newUI ? gitlabOAuthApp2 : oldGitlabOAuthApp2}
            alt="Gitlab OAuth Application Step - Step 2"
            width="555"
            height="100%"
          />
          <Button noPadding size="xs" pullRight btnStyle="link" onClick={switchUI}>
            Not your Gitlab version? Switch instructions for {newUI ? 'v11 or less' : 'v12'}
          </Button>
        </AuthImageWrapper>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button btnStyle="dark" onClick={onSubmit} type="href" pullRight>
            Authorize
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthGitlabOAuthAppStep2.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  newUI: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  switchUI: PropTypes.func.isRequired,
};
