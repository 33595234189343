import React from 'react';
import PropTypes from 'prop-types';

import { ProviderIcon, Space, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { useGetExtraStepCopyToUse } from '../../hooks/useGetExtraStepCopyToUse';

export const AddOnStepCompleted = ({ providerName, containerDisplayName, providerExtraStep }) => {
  const extraStepCopyToUse = useGetExtraStepCopyToUse(providerExtraStep);

  return (
    <Space align="center" direction="horizontal">
      <ProviderIcon name={providerName} />
      <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n40}>
        {containerDisplayName}: {extraStepCopyToUse?.defaultName} installed
      </Typography>
    </Space>
  );
};

AddOnStepCompleted.propTypes = {
  providerName: PropTypes.string.isRequired,
  containerDisplayName: PropTypes.string.isRequired,
  providerExtraStep: PropTypes.string.isRequired,
};
