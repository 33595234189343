import { NodeModel } from '@projectstorm/react-diagrams';
import { FlowPortModel } from './FlowPortModel';

export class FlowNodeModel extends NodeModel {
  constructor({ A, B, name, siblings, creating = false, ...options } = {}) {
    super({ creating, ...options, type: 'Flow' });
    this.A = A;
    this.B = B;
    this.name = name;
    this.siblings = siblings;
    this.hasMoved = false;
    this.creating = creating;
    const flowPort = this.addPort(new FlowPortModel(true, 'FlowPort'));
    flowPort.canLinkToPort(false); // blocks the user from linking from and to a flow directly

    this.registerListener({
      eventDidFire: (e) => {
        const { function: type } = e;
        const model = e?.entity.getParentCanvasModel();

        if (!model?.lastKnownPositions[this.getID()]) {
          return;
        }
        const { x: lastKnownX, y: lastKnownY } = !model?.lastKnownPositions[this.getID()];
        const { x: thisX, y: thisY } = this.getPosition();

        if (type === 'positionChanged' && !this.hasMoved && (thisX !== lastKnownX || thisY !== lastKnownY)) {
          this.setHasMoved(true);
        }
      },
    });
  }

  // TODO: To test out
  serialize() {
    return {
      ...super.serialize(),
      A: this.A,
      B: this.B,
      linkId: this.linkId,
      name: this.name,
      siblings: this.siblings,
    };
  }

  // TODO: To test out
  deserialize(event, engine) {
    super.deserialize(event, engine);
    const { A, B, name, siblings, linkId } = event.data;
    this.A = A;
    this.B = B;
    this.linkId = linkId;
    this.name = name;
    this.siblings = siblings;
    this.linkId = linkId;
  }

  setHasMoved(hasMoved) {
    this.hasMoved = hasMoved;
  }

  getHasMoved() {
    return this.hasMoved;
  }

  // https://projectstorm.gitbook.io/react-diagrams/customizing/ports#specifying-if-a-link-can-be-connected
  isAlreadyConnected(sourceNodeId, targetNodeId) {
    // ideally we should look in the linksByWorkflowId (or having an endpoint for that?) and to make sure these 2 containers are not already connected...
    return this.siblings.includes(sourceNodeId) && this.siblings.includes(targetNodeId);
  }

  getFlowName() {
    return this.name;
  }

  setFlowName(name) {
    this.name = name;
  }

  getSideA() {
    return this.A;
  }

  getSideB() {
    return this.B;
  }

  flipSides(name) {
    const A = { ...this.A };
    const B = { ...this.B };
    this.A = B;
    this.B = A;

    this.setFlowName(name);
    this.setSiblings(this.siblings.reverse());
  }

  setLinkId(linkId) {
    this.linkId = linkId;
  }

  getLinkId() {
    return this.linkId;
  }

  getSiblings() {
    return this.siblings;
  }

  setSiblings(siblings) {
    this.siblings = siblings;
  }

  getCreating() {
    return this.creating;
  }

  setCreating(creating) {
    this.creating = creating;
  }
}
