/**
 * @param {*} Array of tuples in the form of [theKey, theValue][]
 */

export function querifyData(tuples) {
  function sanitizeValue(val) {
    if (Array.isArray(val) || typeof val === 'object') {
      return JSON.stringify(val);
    }
    return val;
  }

  return tuples
    .filter(([, val]) => val !== null && val !== undefined)
    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(sanitizeValue(val))}`)
    .join('&');
}
