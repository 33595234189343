import { EventTypes } from 'redux-segment';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import moment from 'moment';

import { addDatadogAction } from '@unitoio/sherlock';

import * as organizationTypes from '~/consts/organizations';
import * as trackingTypes from '~/consts/tracking';
import * as loggingActions from '~/actions/logging';
import {
  getContextSource,
  getFlags,
  getIsAuthenticated,
  getOrganizationTraits,
  getProductNameAppContext,
  getSelectedOrganizationId,
  getSessionId,
  getToken,
} from 'reducers';
import { isBrowserDoNotTrackEnabled } from '~/utils/isBrowserDoNotTrackEnabled';

const getGALabel = (source, data, suffixGALabelKeys = []) => {
  let label = source;
  if (data.action_name) {
    label += `||${data.action_name}`;
  }

  return suffixGALabelKeys.reduce((newLabel, key) => {
    if (data[key] === undefined) {
      return newLabel;
    }

    return `${newLabel}||${data[key]}`;
  }, label);
};

const trackAnonymousEvent =
  (eventName, data = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const sessionId = getSessionId(state);
    const productName = getProductNameAppContext(state);
    const featureFlags = getFlags(state, organizationTypes.LD_ANONYMOUS_ORGANIZATION_ID);

    const source = getContextSource(state);
    // ⚠️ There is no Unito userId at this point. The event will rely on segment anonymousId
    // Label is a GoogleAnalytics special field that will be used by Martketing to generate custom goals.
    const { suffixGALabelKeys, ...properties } = data;
    const label = getGALabel(source, properties, suffixGALabelKeys);
    let _ga; // eslint-disable-line
    try {
      _ga = Cookies.get('_ga');
    } catch (err) {
      dispatch(
        loggingActions.reportException(err, { context: { functionName: 'trackAnonymousEvent Cookies.get _ga' } }),
      );
    }

    const meta = {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: eventName,
          properties: {
            ...properties,
            label,
            sessionId,
            context: {
              source,
              dotNotTrack: isBrowserDoNotTrackEnabled(),
              product_name: productName,
              feature_flags: featureFlags.toJS(),
              user: {
                _ga,
              },
            },
          },
        },
      },
    };

    addDatadogAction(eventName, meta);

    dispatch({
      type: trackingTypes.TRACK_ANON,
      payload: {},
      meta,
    });
  };

const trackUserEvent =
  (eventName, data = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const sessionId = getSessionId(state);
    const productName = getProductNameAppContext(state);
    const token = getToken(state);
    let decodedToken;

    try {
      decodedToken = jwtDecode(token);
    } catch (err) {
      dispatch(loggingActions.reportException(err, { context: { functionName: 'trackUserEvent decodeToken' } }));
    }

    if (!decodedToken) {
      return;
    }

    const {
      _id: userId,
      email,
      fullName,
      createdAt,
      signupIntentA,
      signupIntentB,
      signupIntentProduct,
      signupSource,
    } = decodedToken;
    const organizationId = getSelectedOrganizationId(state);
    const organization = getOrganizationTraits(state, organizationId);
    const source = getContextSource(state);

    // Label is a GoogleAnalytics special field that will be used by Martketing to generate custom goals.
    const { suffixGALabelKeys, ...properties } = data;
    const label = getGALabel(source, properties, suffixGALabelKeys);

    const meta = {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: eventName,
          properties: {
            ...properties,
            sessionId,
            label,
            context: {
              source,
              dotNotTrack: isBrowserDoNotTrackEnabled(),
              product_name: productName,
              user: {
                id: userId,
                created_at: createdAt,
                email,
                name: fullName,
                signup_intent: signupIntentA || signupIntentB,
                signup_intent_product_name: signupIntentProduct,
                signup_source: signupSource,
              },
              organization: {
                cancelation_feedback: organization.cancelationFeedback,
                canceled_at: organization.canceledAt,
                churned_at: organization.churnedAt,
                // Generate the cohort value
                cohort: moment(organization.createdAt).startOf('isoWeek').format('YYYYMMDD'),
                converted_at: organization.convertedAt,
                created_at: organization.createdAt,
                id: organization.id,
                feature_flags: getFlags(state, organization.id).toJS(),
                last_downgraded_at: organization.lastDowngradedAt,
                last_upgraded_at: organization.lastUpgradedAt,
                name: organization.name,
                plan: organization.planId,
                status: organization.status,
                stripe_customer_id: organization.stripeCustomerId,
                stripe_subscription_id: organization.stripeSubscriptionId,
                will_be_back: organization.willBeBack,
              },
            },
          },
        },
      },
    };

    addDatadogAction(eventName, meta);

    dispatch({
      type: trackingTypes.TRACK,
      payload: {},
      meta,
    });
  };

/**
 * Note: This function is mocked globally in tests
 * You can reference it in your expect statements using `trackingActions.trackEvent`
 * Example:
 *
 * ```
 * import * as trackingActions from '~/actions/tracking';
 *
 * //...
 * describe('doSomething', () => {
 *   it('does something', () => {
 *     doSomething();
 *     expect(trackingActions.trackEvent).lastCalledWith('ACTION', { data: 'data' });
 *   });
 * });
 * ```
 */
export const trackEvent =
  (eventName, data = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const isAuthenticated = getIsAuthenticated(state);
    if (isAuthenticated) {
      return dispatch(trackUserEvent(eventName, data));
    }
    return dispatch(trackAnonymousEvent(eventName, data));
  };

/* eslint-disable import/prefer-default-export */
