import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Link, tokens, Typography } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';

const Content = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

const MainBox = styled(Box)`
  background: ${tokens.colors.background.neutral.grey};
`;

export function KBArticle({
  title = '',
  content,
  linkText,
  articleURL,
  image,
  imageRadius = tokens.spacing.s3,
  providerName,
}) {
  const trackEvent = useTrackEvent();

  const handleOnClickKbArticle = () => {
    trackEvent(trackingTypes.ACTION, {
      selected_tool_name: providerName,
      action_name: 'clicked on an article',
      article_url: articleURL,
    });
  };

  return (
    <MainBox
      p={[tokens.spacing.s5]}
      m={[tokens.spacing.s6, 0, 0, 0]}
      alignItems="center"
      borderRadius={tokens.spacing.s4}
    >
      <Box>
        <Box as="img" alt={title} src={image} borderRadius={imageRadius} />
      </Box>
      <Box m={[0, 0, 0, tokens.spacing.s5]}>
        <Content variant="body2">
          {title && <Typography variant="h4">{title}</Typography>}
          {content}{' '}
          <Link onClick={handleOnClickKbArticle} isExternalLink href={articleURL}>
            {linkText}
          </Link>
        </Content>
      </Box>
    </MainBox>
  );
}

KBArticle.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  articleURL: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  imageRadius: PropTypes.string,
  providerName: PropTypes.string.isRequired,
};
