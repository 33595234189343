import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { Box, tokens, Typography, Icon } from '@unitoio/mosaic';
import * as featureTypes from '~/consts/features';
import * as fieldTypes from '~/consts/fields';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { useMandatoryDeepFiltersMissing } from '../../../../hooks/useMandatoryDeepFiltersMissing';
import { RulesRow } from '../../../../components/RulesRow';
import { PCDFieldsSelect, displayTypes } from '../../../../components/PCDFieldsSelect';
import { MandatoryDeepFiltersMissingAlert } from '../../../../components/MandatoryDeepFiltersMissingAlert';

const LOCKED_RULES = {
  [fieldTypes.PAST_DATE]:
    'This trigger is required to prevent issues with recurring events that have no end date form syncing indefinitely',
  [fieldTypes.FUTURE_DATE]:
    'This trigger is required to prevent issues with recurring events that have no end date form syncing indefinitely',
};

export const FilterRows = ({
  fields,
  remove,
  filtersContext: {
    side,
    itemType,
    containerType,
    groupedFields,
    groupedDeepFields,
    deepFilters,
    providerName,
    providerIdentityId,
    containerId,
    otherContainerFieldArray,
  },
  linkState,
  isDisabledOptionHandler,
  addNewRule,
}) => {
  const trackEvent = useTrackEvent();

  const { setValue } = useFormContext();
  const [missingDeepFiltersA, missingDeepFiltersB] = useMandatoryDeepFiltersMissing();

  const deepFilterItemField = fields.find((field) => field.fieldId === fieldTypes.DEEP_FILTER_ITEM);
  const deepFilterItemFieldId = deepFilterItemField?.value;
  const displayDeepFilters = deepFilterItemFieldId && deepFilterItemFieldId !== 'all';

  return fields.map((data, dataIndex) => (
    <Fragment key={data._id}>
      <RulesRow
        iconName="trash"
        providerName={providerName}
        providerIdentityId={providerIdentityId}
        containerId={containerId}
        itemType={itemType}
        containerType={containerType}
        containerSide={side}
        name={`${side}.filters.${dataIndex}`}
        data={data}
        fieldDetails={groupedFields[data.fieldId]}
        linkState={linkState}
        iconOnClick={() => {
          remove(dataIndex);
          if (data.fieldId === fieldTypes.EARLIEST_CREATED_AT) {
            trackEvent(trackingTypes.EVENT_NAME.ACTION, { action_name: 'removed test mode' });
            const createdAtIndex = otherContainerFieldArray.fields.findIndex(
              (field) => field.fieldId === fieldTypes.EARLIEST_CREATED_AT,
            );
            otherContainerFieldArray.remove(createdAtIndex);
            // reset testModeVariant to NONE to make sure the backend does not create it again by mistake.
            setValue('testModeVariant', featureTypes.TEST_MODE_VARIANT.NONE);
          } else {
            trackEvent(trackingTypes.EVENT_NAME.ACTION, { action_name: 'clicked on remove rule' });
          }
        }}
        isLocked={!!LOCKED_RULES[data.fieldId]}
        lockedMessage={LOCKED_RULES[data.fieldId] ?? ''}
      />

      {displayDeepFilters &&
        data.fieldId === fieldTypes.DEEP_FILTER_ITEM &&
        (side === 'A' ? missingDeepFiltersA : missingDeepFiltersB) && <MandatoryDeepFiltersMissingAlert side={side} />}

      {/** deep filters */}
      {displayDeepFilters &&
        data.fieldId === fieldTypes.DEEP_FILTER_ITEM &&
        deepFilters.fields.map((childData, childDataIndex) => (
          <RulesRow
            iconName="trash"
            providerName={providerName}
            providerIdentityId={providerIdentityId}
            containerId={containerId}
            itemType={itemType}
            containerType={containerType}
            containerSide={side}
            key={childData._id}
            name={`${side}.deepFilters.${childDataIndex}`}
            data={childData}
            fieldDetails={groupedDeepFields[childData.fieldId]}
            linkState={linkState}
            iconOnClick={() => {
              deepFilters.remove(childDataIndex);
            }}
          />
        ))}

      {data.fieldId === fieldTypes.DEEP_FILTER_ITEM && displayDeepFilters && (
        <RulesRow hoverable={false} key={data._id} itemType={itemType} containerType={containerType}>
          <Box m={[0, tokens.spacing.s3, 0, tokens.spacing.s6]}>
            <Typography variant="h4">
              <Box as="span" m={[0, 0.5, 0, 0]}>
                <Icon name="level-up" rotation={90} />
              </Box>
              AND
            </Typography>
          </Box>
          <PCDFieldsSelect
            isOptionDisabledHandler={(newFieldId) => isDisabledOptionHandler(newFieldId, groupedDeepFields)}
            containerSide={side}
            placeholder={`Add a new ${deepFilterItemFieldId} trigger`}
            searchPlaceholder="Search for a trigger"
            parentFieldId={deepFilterItemFieldId}
            displayType={displayTypes.DEEP_FIELDS_ONLY}
            providerIdentityId={providerIdentityId}
            providerName={providerName}
            containerId={containerId}
            itemType={itemType}
            onChange={(newFieldId, field) => {
              addNewRule({
                newFieldId,
                append: deepFilters.append,
                groupedFields: groupedDeepFields,
                parentFieldId: deepFilterItemFieldId,
                field,
              });
            }}
          />
        </RulesRow>
      )}
    </Fragment>
  ));
};

const fieldShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

const filterValueType = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.bool.isRequired,
]);

const filterShape = PropTypes.shape({
  fieldId: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([filterValueType, PropTypes.arrayOf(filterValueType)]).isRequired,
});

FilterRows.propTypes = {
  fields: PropTypes.arrayOf(filterShape).isRequired,
  filtersContext: PropTypes.shape({
    deepFilters: PropTypes.shape({
      fields: PropTypes.arrayOf(filterShape).isRequired,
      append: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
    }).isRequired,
    providerName: PropTypes.string.isRequired,
    providerIdentityId: PropTypes.string.isRequired,
    containerId: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
    containerType: PropTypes.string.isRequired,
    groupedFields: PropTypes.shape({
      [PropTypes.string]: fieldShape,
    }).isRequired,
    groupedDeepFields: PropTypes.shape({
      [PropTypes.string]: fieldShape,
    }).isRequired,
    otherContainerFieldArray: PropTypes.shape({
      fields: PropTypes.arrayOf(filterShape).isRequired,
      remove: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  linkState: PropTypes.string.isRequired,
  isDisabledOptionHandler: PropTypes.func.isRequired,
  addNewRule: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};
