import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import { Button } from '~/components/Button/Button';

const PositionIndicator = styled.span`
  color: ${tokens.colors.content.neutral.n30};
  font-size: ${tokens.fontSize.f6};
`;

export function ActivityLogsPaginator({ dataTableData }) {
  const { currentPage, pageSize, totalRecords, gotoPage } = dataTableData;
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage * pageSize + pageSize >= totalRecords;
  const currentPosition = totalRecords > 0 ? pageSize * currentPage + 1 : 0; // (+1 because this index starts at 1)
  const upToPosition = isLastPage ? totalRecords : currentPage * pageSize + pageSize;

  return (
    <>
      <PositionIndicator>{`${currentPosition}-${upToPosition} of ${totalRecords}`}</PositionIndicator>
      <Button btnStyle="subtleLink" disabled={isFirstPage} onClick={() => gotoPage(currentPage - 1)} name="last page">
        <Icon name="angle-left" size="lg" />
      </Button>
      <Button btnStyle="subtleLink" disabled={isLastPage} onClick={() => gotoPage(currentPage + 1)} name="next page">
        <Icon name="angle-right" size="lg" />
      </Button>
    </>
  );
}

ActivityLogsPaginator.propTypes = {
  dataTableData: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalRecords: PropTypes.number.isRequired,
    gotoPage: PropTypes.func.isRequired,
  }).isRequired,
};
