import * as fieldTypes from '~/consts/fields';

/**
 *  Search recursively the PCD to find the item that matches the item type. It searches through the fields attribute.
 * @param {object} item  -item from PCDItem. Definition can be found in Connectors under PCDFields
 * @param {string} itemType - The item type name (native) e.g. card, task..
 * @returns items
 */
export const findItemByItemType = (item, itemType) => {
  const currentItem = item.get('item') || item.get('itemType');

  // Handle case where the item item type is itself
  if (currentItem === 'self') {
    if (item.getIn(['names', 'native']) === itemType) {
      return item;
    }

    // Since the item is itself, can't go deeper into the object.
    return undefined;
  }

  if (currentItem.getIn(['names', 'native']) === itemType) {
    return currentItem;
  }

  const fields = currentItem.get('fields');

  if (!fields) {
    return undefined;
  }

  const subItem = fields.find(
    (v) => v.get('semantic') === fieldTypes.SUBTASKS && v.get('type') === fieldTypes.TYPES.ITEM,
  );

  if (!subItem) {
    return undefined;
  }

  if (subItem.getIn(['names', 'native']) === itemType) {
    return subItem;
  }

  return findItemByItemType(subItem, itemType);
};
