import React from 'react';
import PropTypes from 'prop-types';

import { NewButton, Result } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';
import ImageError from '~/images/ErrorEmptyResult.svg';

const customIcon = <img alt="error-image" src={ImageError} />;

export const ProfileSettingsError = ({ title, message }) => (
  <Result
    icon={customIcon}
    title={title}
    subTitle={message}
    extra={[
      <NewButton href={`/#${routes.ABSOLUTE_PATHS.DASHBOARD}`} type="primary">
        Back to dashboard
      </NewButton>,
      <NewButton onClick={() => window.location.reload()} type="default">
        Retry
      </NewButton>,
    ]}
  />
);

ProfileSettingsError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};
