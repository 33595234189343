import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, tokens } from '@unitoio/mosaic';

export const Placeholder = ({ text }) => (
  <Box justifyContent="center" alignItems="center">
    <Icon name="plus" title={text} kind={Icon.KINDS.SOLID} />
    <Box m={[0, 0, 0, tokens.spacing.s3]}>{text}</Box>
  </Box>
);

Placeholder.propTypes = {
  text: PropTypes.string.isRequired,
};
