import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Link, SidePanel, Typography, tokens } from '@unitoio/mosaic';

import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getLinkById } from 'reducers';

import { ResourceBlock } from '../../components/ResourceBlock';
import { SidePanelVideoIframe } from './SidePanelVideoIframe';

import ConnectTools1 from '../../images/side_panel/connect_tools/connect_tools1.svg';
import ConnectTools2 from '../../images/side_panel/connect_tools/connect_tools2.svg';
import ConnectTools3 from '../../images/side_panel/connect_tools/connect_tools3.svg';

const ConnectToolSidePanel = ({ isOpen, onClose, linkId }) => {
  const link = useSelector((state) => getLinkById(state, linkId));
  const trackEvent = useTrackEvent({
    selected_tool_names: `${link.getIn(['A', 'providerName'])},${link.getIn(['B', 'providerName'])}`,
  });

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
    }
  }, [isOpen, trackEvent]);

  return (
    <SidePanel onClose={onClose} isOpen={isOpen} backButtonText="Close">
      <Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <Typography variant={Typography.variants.H4}>Need some help selecting your tools?</Typography>
        </Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <SidePanelVideoIframe videoId="inx4MM2eb-E" title="Flow Creation Step 1/4 - Tool Selection" />
        </Box>
        <Box
          alignItems={Box.alignItems.CENTER}
          flexDirection={Box.flexDirection.COLUMN}
          m={[0, 0, tokens.spacing.s7, 0]}
        >
          <Box>
            <Typography variant={Typography.variants.BODY1}>
              Here’s where you can select the tools and projects you’d like to sync. When we refer to projects, that
              includes spreadsheets, calendars, folders or any other container for your work items. Start by selecting a
              tool and a project on each side.
            </Typography>
          </Box>
          <Box>
            <img alt="start choosing tool to connect" src={ConnectTools1} width="100%" height="100%" />
          </Box>
          <Box m={[0, 0, tokens.spacing.s3, 0]} fullWidth>
            <Typography variant={Typography.variants.BODY1}>
              If one of your tools includes multiple types of work items, such as Hubspot deals, tickets or tasks, you
              need to choose which type of work item to sync here.
            </Typography>
          </Box>
          <Box>
            <img alt="select work item type" src={ConnectTools2} width="100%" height="100%" />
          </Box>
          <Box m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              Some tools require you to have detailed permissions to connect to Unito. If that’s the case, you’ll
              typically need to be an admin or workspace owner for your tool. You can find out more about permissions
              <Link href="https://guide.unito.io/" target="_blank">
                in our guide.
              </Link>
            </Typography>
            <br />
            <Typography variant={Typography.variants.BODY1}>
              If you don't see your tool or the type of item you'd like to sync, you can submit a request in the tool
              dropdown menu.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s3, 0]}>
            <img alt="request new tool" src={ConnectTools3} width="100%" height="100%" />
          </Box>
          <Box>
            <Typography variant={Typography.variants.BODY1}>
              The tool accounts you connect will be the authors of any work items created by Unito. As you become more
              familiar with Unito, you may want to consider what we call a “service account,” which you can
              <Link href="https://guide.unito.io/how-to-create-a-bot-user-in-unito" target="_blank">
                learn about here.
              </Link>
            </Typography>
          </Box>
        </Box>
        <Box fullWidth>
          <ResourceBlock trackEvent={trackEvent} />
        </Box>
      </Box>
    </SidePanel>
  );
};

ConnectToolSidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkId: PropTypes.string.isRequired,
};

const ConnectToolSidePanelWithTracking = (props) => (
  <TrackingFunnel contextName={trackingTypes.FUNNELS.SIDE_PANEL}>
    <ConnectToolSidePanel {...props} />
  </TrackingFunnel>
);

export { ConnectToolSidePanelWithTracking as ConnectToolSidePanel };
