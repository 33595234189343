import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import { capitalize } from '~/utils/capitalize';
import { getProviderByName, getProviderVisibleItems } from 'reducers';
import { useFormContext } from 'react-hook-form';
import { SelectField } from '~/components/SelectField/SelectField';

import { Placeholder } from './Placeholder';
import { SelectorLabel } from './SelectorLabel';

export function ItemTypeSelect({ readOnly = false, side, onChange = () => null }) {
  const { watch } = useFormContext();
  const selectedProviderName = watch(`${side}.providerName`);
  const selectedWorkItem = watch(`${side}.itemType`);
  const provider = useSelector((state) => getProviderByName(state, selectedProviderName));
  const supportedItems = useSelector((state) => getProviderVisibleItems(state, provider.get('_id')));

  const itemTypeOptions = supportedItems
    .keySeq()
    .reduce(
      (acc, itemName) =>
        acc.push({
          label: capitalize(supportedItems.getIn([itemName, 'item', 'names', 'singular'], '')),
          value: supportedItems.getIn([itemName, 'item', 'names', 'native']),
        }),
      List(),
    )
    .toArray();

  useEffect(() => {
    if (!selectedWorkItem && itemTypeOptions.length === 1) {
      const [firstItem] = itemTypeOptions;
      onChange(firstItem.value);
    }
  }, [itemTypeOptions, onChange, selectedWorkItem]);

  return (
    <SelectorLabel withMargin label="of the type">
      <SelectField
        value={selectedWorkItem}
        onChange={onChange}
        placeholder={Placeholder({ text: 'Choose a work item' })}
        readOnly={readOnly || (selectedWorkItem && supportedItems.size === 1)}
        name={`${side}.itemType`}
        searchable
        options={itemTypeOptions}
      />
    </SelectorLabel>
  );
}
ItemTypeSelect.propTypes = {
  readOnly: PropTypes.bool,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  onChange: PropTypes.func,
};
