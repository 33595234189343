import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';

import { useQueryParams } from '~/hooks/useQueryParams';

import { GoogleInstructionsStep1 } from './AuthGoogleInstructionsStep1';
import { GoogleInstructionsStep2 } from './AuthGoogleInstructionsStep2';

export function GoogleInstructions({ match, onCancel, onSubmit }) {
  const { windowOpenerId } = useQueryParams();

  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/step1?windowOpenerId=${windowOpenerId}`} />
      <Route
        exact
        path={`${match.path}/step1`}
        render={(routeProps) => <GoogleInstructionsStep1 {...routeProps} onCancel={onCancel} onSubmit={onSubmit} />}
      />
      <Route
        exact
        path={`${match.path}/step2`}
        render={(routeProps) => <GoogleInstructionsStep2 {...routeProps} onCancel={onCancel} onSubmit={onSubmit} />}
      />
    </Switch>
  );
}

GoogleInstructions.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
