export const GET_CUSTOM_FIELDS_REQUEST = 'GET_CUSTOM_FIELDS_REQUEST';
export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS';
export const GET_CUSTOM_FIELDS_FAILURE = 'GET_CUSTOM_FIELDS_FAILURE';
export const GET_FIELD_VALUE_REQUEST = 'GET_FIELD_VALUE_REQUEST';
export const GET_FIELD_VALUE_SUCCESS = 'GET_FIELD_VALUE_SUCCESS';
export const GET_FIELD_VALUE_FAILURE = 'GET_FIELD_VALUE_FAILURE';
export const GET_FIELD_VALUES_REQUEST = 'GET_FIELD_VALUES_REQUEST';
export const GET_FIELD_VALUES_SUCCESS = 'GET_FIELD_VALUES_SUCCESS';
export const GET_FIELD_VALUES_FAILURE = 'GET_FIELD_VALUES_FAILURE';
export const AUTOMAP_FIELD_VALUES_REQUEST = 'AUTOMAP_FIELD_VALUES_REQUEST';
export const AUTOMAP_FIELD_VALUES_SUCCESS = 'AUTOMAP_FIELD_VALUES_SUCCESS';
export const AUTOMAP_FIELD_VALUES_FAILURE = 'AUTOMAP_FIELD_VALUES_FAILURE';
export const GENERATE_FIELD_ASSOCIATIONS_REQUEST = 'GENERATE_FIELD_ASSOCIATIONS_REQUEST';
export const GENERATE_FIELD_ASSOCIATIONS_SUCCESS = 'GENERATE_FIELD_ASSOCIATIONS_SUCCESS';
export const GENERATE_FIELD_ASSOCIATIONS_FAILURE = 'GENERATE_FIELD_ASSOCIATIONS_FAILURE';
export const GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_REQUEST =
  'GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_REQUEST';
export const GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_SUCCESS =
  'GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_SUCCESS';
export const GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_FAILURE =
  'GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_FAILURE';
export const GET_CAPABILITIES_FIELD_TYPES_REQUEST = 'GET_CAPABILITIES_FIELD_TYPES_REQUEST';
export const GET_CAPABILITIES_FIELD_TYPES_SUCCESS = 'GET_CAPABILITIES_FIELD_TYPES_SUCCESS';
export const GET_CAPABILITIES_FIELD_TYPES_FAILURE = 'GET_CAPABILITIES_FIELD_TYPES_FAILURE';
export const RESET_STORE = 'RESET_STORE';

export const KINDS = {
  PCD_FIELD: 'pcdField',
  PCD_COMMON: 'pcdCommon',
  PCD_TYPED_FIELD: 'pcdTypedField',
  PCD_TYPED_ITEM_FIELD: 'pcdTypedItemField',
  CUSTOM_FIELD: 'custom',
};

export const FIELD_VALUES_TYPE = {
  BOOLEAN: 'boolean',
  ID: 'id',
  USERS: 'users',
};

export const TARGET = {
  A: 'A',
  B: 'B',
  BOTH: 'both',
};

export const MULTIMAPPING = {
  GROUP_LIMIT: 2,
};

export const URL = 'url';

export const DESCRIPTION_FOOTER = 'linkBlock';

export const COLUMN = 'column';

export const STATUS = 'status';

export const EARLIEST_CREATED_AT = 'earliestCreatedAt';

export const INCLUDE_CLOSED_TASKS = 'includeClosedTasks';

export const CLOSED_TASKS = 'closedTasks';

export const SUBTASK = 'subtask';
export const SUBTASKS = 'subtasks';

export const ATTACHMENTS = 'attachments';

export const CHECKLISTS = 'checklists';

export const EMAIL_ADDRESSES = 'emailAddresses';

export const PHONES = 'phones';

export const COMMENTS = 'comments';

export const TITLE = 'title';

export const SUBFOLDERS = 'subfolders';

export const DEEP_FILTER_ITEM = 'DEEP_FILTER_ITEM';

export const PAST_DATE = 'pastDate';

export const FUTURE_DATE = 'futureDate';

export const SEMANTIC = {
  ITEM_NAME: 'itemName',
  WORKFLOW_STATUS: 'workflow.status',
  SUBTASKS: 'subtasks',
  COMMENTS: 'comments',
  COMMENTS_PUBLIC: 'comments.public',
  WORKFLOW: 'workflow.',
  DESCRIPTION: 'description',
  ATTACHMENTS: 'attachments',
  TASKS: 'task.s',
  CREATED_AT: 'createdAt',
};

export const TYPES = {
  USER: 'user',
  STRING: 'string',
  SELECT: 'select',
  ENUM: 'enum',
  BOOLEAN: 'boolean',
  ITEM: 'item',
  OPTIONS: 'options',
  DATE: 'date',
  DATETIME: 'datetime',
  ISO_DATE_STRING: 'ISODateString',
  RICH_TEXT: 'rich_text',
};

export const PLURALITY = {
  SINGULAR: 'singular',
  PLURAL: 'plural',
  DEFAULT: 'default',
};
