import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import * as formTypes from '~/consts/form';
import { TextInput } from '~/components/TextInput/TextInput';

export const ScriptArgItem = ({ input, meta, kwarg }) => {
  const label = kwarg.get('required') ? `${input.name}*` : input.name;

  return (
    <div>
      <TextInput input={input} meta={meta} label={label} />
    </div>
  );
};

ScriptArgItem.propTypes = {
  kwarg: PropTypes.instanceOf(Map).isRequired,
  input: PropTypes.shape(formTypes.fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(formTypes.fieldMetaPropTypes).isRequired,
};
