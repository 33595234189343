import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  getEmbedName,
  getIsCurrentPlanTaskSync,
  getSelectedOrganizationId,
  getUserId,
  isLoadedLinks,
  getSortedLinks,
} from 'reducers';
import * as appTypes from '~/consts/app';
import { SiteAdminContext } from '~/contexts';
import { SiteAdminSearch } from '~/components/SiteAdminSearch/SiteAdminSearch';
import { Box } from '~/components/Box/Box';

import { EmbedFooter } from './EmbedFooter';
import { ProjectSyncList } from './ProjectSyncList';
import { TaskSyncList } from './TaskSyncList';
import { useGetLinks } from './hooks/useGetLinks';

const Content = styled.div`
  min-height: calc(100vh - 16rem);
`;

export const SyncList = ({ match, trackDashboardAction }) => {
  const embedName = useSelector((state) => getEmbedName(state));
  const userId = useSelector((state) => getUserId(state));
  const isLoading = useSelector((state) => !isLoadedLinks(state));
  const linkList = useSelector((state) => getSortedLinks(state));
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const isCurrentPlanTaskSync = useSelector((state) => getIsCurrentPlanTaskSync(state));
  const { isSiteAdmin } = useContext(SiteAdminContext);

  const { page, setPage, pageSize, setPageSize, setSearch, setSiteAdminSearch, setKinds, reset, total } = useGetLinks();

  const defaultRouteBasedOnPlan = isCurrentPlanTaskSync
    ? appTypes.PRODUCT_NAMES_PARAM.TASK_SYNC
    : appTypes.PRODUCT_NAMES_PARAM.PROJECT_SYNC;

  const commonProps = {
    embedName,
    linkList,
    isLoading,
    isCurrentPlanTaskSync,
    selectedOrganizationId,
    trackDashboardAction,
  };

  return (
    <Content className="sync-list">
      <Box $m={[2, 0, 0, 0]}>
        {isSiteAdmin && !embedName && <SiteAdminSearch onChangeSiteAdminSearch={setSiteAdminSearch} onReset={reset} />}
        <Switch>
          <Redirect exact from={match.path} to={`${match.path}/${defaultRouteBasedOnPlan}`} />
          <Route
            exact
            path={`${match.path}/project-sync`}
            render={(props) => (
              <ProjectSyncList
                {...commonProps}
                {...props}
                pagination={{ page, pageSize, total }}
                onSetKinds={setKinds}
                onChangePage={({ page: newPage, pageSize: newPageSize }) => {
                  setPage(newPage);
                  setPageSize(newPageSize);
                }}
                onSearch={setSearch}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/task-sync`}
            render={(props) => (
              <TaskSyncList
                userId={userId}
                {...commonProps}
                {...props}
                setPageSize={setPageSize}
                onSetKinds={setKinds}
              />
            )}
          />
        </Switch>
      </Box>
      {embedName && <EmbedFooter />}
    </Content>
  );
};

SyncList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  trackDashboardAction: PropTypes.func.isRequired,
};
