import { useContext } from 'react';

import { TrackingFunnelContext } from '~/contexts';

/**
 * This hook return the TrackingFunnelContext from the console. This is used when we are using Sherlock Tracking Funnel
 * in the console and we want to add the parents and shared properties to the current funnel.
 * This is temporarily as we migrate console tracking funnel to sherlock
 * @returns {Object} An object containing the parents and shared properties of the current funnel
 */
export const useConsoleTrackingFunnelContext = () => {
  // Retrieving context from console and adding to the Sherlock funnel
  const { parents: ancestors, sharedProperties } = useContext(TrackingFunnelContext);

  // Removing the first element as it represent the current funnel
  // and reversing the order to have the first parent as the first element
  const parents = [...(ancestors || [])].slice(1).reverse();

  // Creating map of parents where the key is parent1, parent2, etc.
  const parentMap = parents.reduce((acc, value, index) => {
    acc[`parent${index + 1}`] = value;
    return acc;
  }, {});

  return {
    ...parentMap,
    ...sharedProperties,
  };
};
