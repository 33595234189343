import { useWatch } from 'react-hook-form';

import { useGetProviderNames } from '~/containers/FlowBuilder/hooks/useGetProviderNames';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { useSelector } from 'react-redux';
import { getAreProviderCapabilitiesForItemsLoaded, getProviderByName, getSupportedFieldsForItem } from '~/reducers';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { useGetContainerTypes } from '~/containers/FlowBuilder/hooks/useGetContainerTypes';
import { useIsFlowDuplicate } from '~/containers/FlowBuilder/hooks/useIsFlowDuplicate';
import {
  useGetCustomFields,
  useGetSides,
  useInitializeDefaultRules,
  useSetDefaultFieldValues,
} from '../pages/Rules/hooks';
import { statuses } from '../pages/Rules/consts';

export const useInitializeLegacyDefaultValues = ({ loadingState, shouldInitializeSpecialRules = true }) => {
  const linkState = useWatch({ name: 'state' });

  const sides = useGetSides();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const [itemTypeA, itemTypeB] = useGetItemTypes();

  useGetCustomFields({
    containerIdA: sides.A.containerId,
    containerIdB: sides.B.containerId,
    providerIdentityIdA: sides.A.providerIdentityId,
    providerIdentityIdB: sides.B.providerIdentityId,
    providerNameA,
    providerNameB,
    itemTypeA,
    itemTypeB,
  });
  const [containerA, containerB] = useGetContainers();
  const containerIdA = containerA.get('id');
  const containerIdB = containerB.get('id');
  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));
  const providerFieldsA = useSelector((state) =>
    getSupportedFieldsForItem(state, providerA.get('_id'), containerIdA, itemTypeA),
  );

  const providerFieldsB = useSelector((state) =>
    getSupportedFieldsForItem(state, providerB.get('_id'), containerIdB, itemTypeB),
  );

  const areProvidersCapabilitiesForItemsLoaded = useSelector((state) =>
    getAreProviderCapabilitiesForItemsLoaded(
      state,
      providerA.get('_id'),
      providerB.get('_id'),
      containerIdA,
      containerIdB,
      itemTypeA,
      itemTypeB,
    ),
  );

  const [containerTypeA, containerTypeB] = useGetContainerTypes();

  const isDuplicate = useIsFlowDuplicate();

  const defaultValuesLoadingStates = useSetDefaultFieldValues({
    formSides: sides,
    isDraft: linkState === 'draft',
    loadingState,
    providerFieldsA,
    providerFieldsB,
    areProvidersCapabilitiesForItemsLoaded,
    itemTypeA,
    itemTypeB,
    containerTypeA,
    containerTypeB,
  });

  const initializeFieldsLoadingState = useInitializeDefaultRules({
    sides,
    isDuplicatedLink: isDuplicate,
    isDraft: linkState === 'draft',
    areActionsLoaded:
      defaultValuesLoadingStates === statuses.SUCCESS_DFV || defaultValuesLoadingStates === statuses.ERROR_DFV,
    providerFieldsA,
    providerFieldsB,
    shouldInitializeSpecialRules,
  });

  return { initializeFieldsLoadingState };
};
