import PropTypes from 'prop-types';
import styled from 'styled-components';

export const ALLOWED_NON_NUMERICAL_MARGIN_VALUES = ['initial', 'auto'];

// TODO: Move to mimics
function getRemCssValue(value = []) {
  return value
    .map((val) => {
      if (ALLOWED_NON_NUMERICAL_MARGIN_VALUES.includes(val)) {
        return val;
      }

      return `${val}rem`;
    })
    .join(' ');
}

export const Box = styled.div`
  box-sizing: border-box;
  margin: ${(props) => getRemCssValue(props.$m)};
  padding: ${(props) => getRemCssValue(props.$p)};
`;

/*
 * Shorthands:
 * [top, right, bottom, left]
 * [top, right & left, bottom]
 * [top & bottom, right & left]
 * [all]
 */

Box.propTypes = {
  /*
   * An array with the multiplier to apply for the margin size, based on the base font size of 16px
   * The order of the items in the margin array are treated likes the CSS equivalent shorthand
   * Examples:
   * m={[0, 0.5]} => margin: 0rem 0.5rem;
   * m={[0, 0.5, 0, 1]} => margin: 0rem 0.5rem 0rem 1rem;
   * m={[1]} => margin: 1rem;
   */
  $m: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(ALLOWED_NON_NUMERICAL_MARGIN_VALUES)])),
  /*
   * An array with the multiplier to apply for the padding size, based on the base font size of 16px
   * The order of the items in the margin array are treated likes the CSS equivalent shorthand
   * Examples:
   * p={[0, 0.5]} => padding: 0rem 0.5rem;
   * p={[0, 0.5, 0, 1]} => padding: 0rem 0.5rem 0rem 1rem;
   * p={[1]} => padding: 1rem;
   */
  $p: PropTypes.arrayOf(PropTypes.number),
};
