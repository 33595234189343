import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';
import { CopyToClipboard } from '~/components/CopyToClipboard/CopyToClipboard';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import { AuthUnorderedList } from '../../AuthUnorderedList';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import { publicKey } from './publicKey';

import jiraAppLink3 from './jira-app-link-3.jpg';
import oldJiraAppLink3 from './old-jira-app-link-3.png';

export class AuthJiraAppLinkStep3 extends Component {
  render() {
    const { children: instructionFields, history, newUI, onCancel, switchUI } = this.props;

    const handleSubmit = () => {
      const { onSubmit, trackEvent } = this.props;
      trackEvent('application link onboarding 3 (of 3)');
      return onSubmit();
    };

    return (
      <div className="auth-instruction-step">
        <AuthTitleWrapper>
          <Title type="h2" align="center">
            (Application Link - Step 3 of 3)
          </Title>
        </AuthTitleWrapper>
        <Section>
          <p>Finally, configure the Application Link with the values below.</p>
          <AuthImageWrapper className="clearfix">
            <img
              src={newUI ? jiraAppLink3 : oldJiraAppLink3}
              alt="Jira Application Link Step - Step 3"
              width="555"
              height="100%"
            />
            <Button noPadding size="xs" pullRight btnStyle="link" onClick={switchUI}>
              Not your Jira version? Switch instructions for {newUI ? 'v6 or v7' : 'v8'}
            </Button>
          </AuthImageWrapper>
          <AuthOrderedList>
            <li>
              On the last screen, input the following:
              <AuthUnorderedList>
                <li>
                  Consumer Key: <CopyToClipboard>unito</CopyToClipboard> (all lower case)
                </li>
                <li>
                  Consumer Name: <CopyToClipboard>Unito</CopyToClipboard>
                </li>
                <li>
                  Enter the following Public Key:
                  <br />
                  <CopyToClipboard as="pre">{publicKey}</CopyToClipboard>
                </li>
              </AuthUnorderedList>
            </li>
            <li>
              Click <strong>Authorize</strong>
            </li>
          </AuthOrderedList>
          {instructionFields}
          <AuthButtonsWrapper className="clearfix">
            <Button btnStyle="dark" onClick={handleSubmit} type="href" pullRight>
              Authorize
            </Button>
            <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
              Back
            </Button>
            <Button reverse btnStyle="dark" onClick={onCancel}>
              Cancel
            </Button>
          </AuthButtonsWrapper>
        </Section>
      </div>
    );
  }
}

AuthJiraAppLinkStep3.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  newUI: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  switchUI: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
