export const CREATE_DRAFT = 'CREATE_DRAFT';
export const CREATE_DRAFT_FAILURE = `${CREATE_DRAFT}_FAILURE`;
export const CREATE_DRAFT_REQUEST = `${CREATE_DRAFT}_REQUEST`;
export const CREATE_DRAFT_SUCCESS = `${CREATE_DRAFT}_SUCCESS`;
export const EDIT_DRAFT_REQUEST = 'EDIT_DRAFT_REQUEST';
export const EDIT_DRAFT_SUCCESS = 'EDIT_DRAFT_SUCCESS';
export const EDIT_DRAFT_FAILURE = 'EDIT_DRAFT_FAILURE';
export const CREATE_ONE_CLICK = 'CREATE_ONE_CLICK';
export const CREATE_ONE_CLICK_FAILURE = `${CREATE_ONE_CLICK}_FAILURE`;
export const CREATE_ONE_CLICK_REQUEST = `${CREATE_ONE_CLICK}_REQUEST`;
export const CREATE_ONE_CLICK_SUCCESS = `${CREATE_ONE_CLICK}_SUCCESS`;
export const ACTIVATE_ONE_CLICK = 'ACTIVATE_ONE_CLICK';
export const ACTIVATE_ONE_CLICK_FAILURE = `${ACTIVATE_ONE_CLICK}_FAILURE`;
export const ACTIVATE_ONE_CLICK_REQUEST = `${ACTIVATE_ONE_CLICK}_REQUEST`;
export const ACTIVATE_ONE_CLICK_SUCCESS = `${ACTIVATE_ONE_CLICK}_SUCCESS`;
