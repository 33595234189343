import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { Icon } from '@unitoio/mosaic';

import { Box } from '~/components/Box/Box';
import { Button } from '~/components/Button/Button';
import { RoutedModal } from '~/components/Modal/RoutedModal';
import { Dropdown, DropdownEllipsisButton, DropdownItem } from '~/components/Dropdown/Dropdown';

import { WorkflowDuplicateFlowForm } from '../WorkflowDuplicateFlowForm';
import { WorkflowDiagramModel } from '../../models/WorkflowDiagramModel';
import { EditSyncModalContainer } from './EditSyncModalContainer';

const WORKFLOW_ACTION = 'ACTION';

const LINK_SELECTION = 'link-selection';

function isInDuplicateAddFlow(pathname) {
  return pathname.includes('/duplicate/') && !pathname.includes(`duplicate/${LINK_SELECTION}`);
}

export const FlowModal = ({ parentMatch, onCreateSync, updateWorkflow, workflowModel, trackEvent, engine }) => {
  const isLargeScreen = useMediaQuery(['(min-width: 1368px)', '(max-width: 1367px)'], [true, false], true);
  const { workflowId } = parentMatch.params;
  const { pathname } = useLocation();

  const duplicateLinkTitle = (
    <Switch>
      <Route
        path={`${parentMatch.path}/flow/:nodeId/link/duplicate/${LINK_SELECTION}`}
        render={(routeProps) => {
          const { match: childMatch } = routeProps;
          const { nodeId } = childMatch.params;
          return (
            <Box $m={[0, 0, 0, -1.5]}>
              <Button type="href" to={`${parentMatch.url}/flow/${nodeId}/link/duplicate`} btnStyle="subtleLink">
                <Icon name="chevron-left" kind={Icon.KINDS.SOLID} />
                <Box $m={[0, 0, 0, 1]} as="span">
                  Back to options
                </Box>
              </Button>
            </Box>
          );
        }}
      />
      <Route path={`${parentMatch.path}/flow/:nodeId/link/duplicate/:linkId`}>Duplicate flow</Route>
      <Route>
        <div className="text-center">Before setting up this flow!</div>
      </Route>
    </Switch>
  );

  const addFlowModalOptions = (
    <Switch>
      <Route
        render={({
          match: {
            params: { nodeId },
          },
        }) => (
          <Dropdown btnContent={DropdownEllipsisButton}>
            <DropdownItem
              onClick={() => {
                trackEvent(WORKFLOW_ACTION, { action_name: 'confirmed delete flow (tools not connected)' });
                workflowModel.deleteFlow(nodeId);
                updateWorkflow();
              }}
              role="button"
            >
              <Icon name="trash" kind={Icon.KINDS.SOLID} />
              Remove flow
            </DropdownItem>
          </Dropdown>
        )}
      />
    </Switch>
  );

  return (
    <Switch>
      <RoutedModal
        path={`${parentMatch.path}/flow/:nodeId/link/duplicate`}
        title={duplicateLinkTitle}
        onClose={updateWorkflow}
        size={isInDuplicateAddFlow(pathname) ? 'full' : 'medium'}
        modalOptions={isInDuplicateAddFlow(pathname) ? addFlowModalOptions : undefined}
        render={() => (
          <WorkflowDuplicateFlowForm
            parentMatch={parentMatch}
            updateWorkflow={updateWorkflow}
            workflowModel={workflowModel}
            engine={engine}
            onCreateFlow={onCreateSync}
            workflowId={workflowId}
          />
        )}
      />

      <RoutedModal
        closeOnClickOutside={false}
        onClose={updateWorkflow}
        path={`${parentMatch.path}/flow/:nodeId/link/add`}
        title="Add flow"
        modalOptions={addFlowModalOptions}
        render={(routeProps) => {
          const {
            match: {
              params: { nodeId },
            },
          } = routeProps;
          const node = workflowModel.getNode(nodeId);

          return node.getLinkId() && <Redirect to={`${parentMatch.url}/links/${node.getLinkId()}`} />;
        }}
      />
      <EditSyncModalContainer
        parentMatch={parentMatch}
        onSaved={updateWorkflow}
        trackEvent={trackEvent}
        workflowModel={workflowModel}
        isLargeScreen={isLargeScreen}
      />
    </Switch>
  );
};

FlowModal.propTypes = {
  onCreateSync: PropTypes.func.isRequired,
  parentMatch: PropTypes.shape({
    params: PropTypes.shape({
      workflowId: PropTypes.string,
    }),
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  updateWorkflow: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  engine: PropTypes.instanceOf(DiagramEngine).isRequired,
  workflowModel: PropTypes.instanceOf(WorkflowDiagramModel).isRequired,
};
