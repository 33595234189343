import React from 'react';
import styled from 'styled-components';

import { Box, LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import { AutopopulateContentLayout } from './AutopopulateContentLayout';

const AlignedLoadingPlaceholder = styled(LoadingPlaceholder)`
  align-self: center;
`;

const ContentBox = styled(Box)`
  row-gap: ${tokens.spacing.s2};
`;

export function AutopopulateLoading() {
  return (
    <AutopopulateContentLayout
      image={<AlignedLoadingPlaceholder width="22.5rem" height={tokens.spacing.s9} borderRadius={tokens.spacing.s3} />}
      title={<LoadingPlaceholder width="32.75rem" height={tokens.spacing.s6} borderRadius={tokens.spacing.s3} />}
    >
      <ContentBox flexDirection={Box.flexDirection.COLUMN} alignItems={Box.alignItems.CENTER}>
        <LoadingPlaceholder width="22.5rem" height={tokens.spacing.s4} borderRadius={tokens.spacing.s3} />
        <LoadingPlaceholder width="17rem" height={tokens.spacing.s4} borderRadius={tokens.spacing.s3} />
      </ContentBox>
    </AutopopulateContentLayout>
  );
}
