import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Box,
  Flex,
  HorizontalSeparator,
  Icon,
  NewTooltip,
  NewTypography,
  OnFilterOut,
  tokens,
  Typography,
  TypographyVariants,
} from '@unitoio/mosaic';

import { getProviderByName, getCapabilitiesForItem } from 'reducers';
import { otherSide } from '~/utils/otherSide';
import { useGetSides } from '../../hooks';

const IconTooltip = styled(Icon)`
  cursor: help;
`;

function useSetOnFilterOut(side) {
  const { setValue, watch } = useFormContext();
  const inputName = `${side}.onFilterOut`;
  const onFilterOutValue = watch(inputName);
  return [onFilterOutValue, (value) => setValue(inputName, value, { shouldDirty: true })];
}

export const OnFilterOutRule = ({ side, rowIntroduction = 'AND', withSeparator = false }) => {
  const oppositeSide = otherSide(side);
  const { [side]: containerSide, [oppositeSide]: otherContainerSide } = useGetSides();

  const { providerName: sideProviderName, containerId: sideContainerId, itemType: sideItemType } = containerSide;
  const {
    providerName: otherSideProviderName,
    containerId: otherSideContainerId,
    itemType: otherSideItemType,
    containerType: otherContainerType,
  } = otherContainerSide;

  const [filterOutValue, handleOnChange] = useSetOnFilterOut(oppositeSide);

  const provider = useSelector((state) => getProviderByName(state, sideProviderName));
  const otherProvider = useSelector((state) => getProviderByName(state, otherSideProviderName));

  const sideCapabilitiesForItem = useSelector((state) =>
    getCapabilitiesForItem(state, provider.get('_id'), sideContainerId, sideItemType),
  );

  const otherSideCapabilitiesForItem = useSelector((state) =>
    getCapabilitiesForItem(state, otherProvider.get('_id'), otherSideContainerId, otherSideItemType),
  );

  const itemTypeDisplayName = sideCapabilitiesForItem.getIn(['names', 'plural'])?.toLowerCase();
  const otherItemTypeDisplayName = otherSideCapabilitiesForItem.getIn(['names', 'plural'])?.toLowerCase();

  // For now, only show filter out on providers that have the delete functionality
  // but can't close to avoid deleting data on providers that are not GSheets-like in behavior.
  const shouldShowOnFilterOut =
    otherSideCapabilitiesForItem.get('canDelete', false) && otherSideCapabilitiesForItem.get('canClose') === false;

  if (!shouldShowOnFilterOut) {
    return null;
  }

  return (
    <>
      {withSeparator && <HorizontalSeparator style={{ margin: `${tokens.spacing.s4} 0` }} />}
      <Flex gap="small" align="center" style={{ padding: `${tokens.spacing.s3} ${tokens.spacing.s4}` }}>
        <Box m={[0, tokens.spacing.s2, 0, 0]}>
          <Typography variant="h4">{rowIntroduction}</Typography>
        </Box>
        <Typography variant={TypographyVariants.BODY2}>
          <Box
            backgroundColor={tokens.colors.background.neutral.grey}
            p={[tokens.spacing.s2, tokens.spacing.s3]}
            borderRadius={0.4}
          >
            when {itemTypeDisplayName} no longer comply with rules or become unavailable
          </Box>
        </Typography>
        <OnFilterOut
          itemTypeDisplayName={otherItemTypeDisplayName}
          providerDisplayName={otherProvider.get('displayName')}
          onChange={handleOnChange}
          value={filterOutValue ?? 'ignore'}
        />
        <NewTooltip
          placement="top"
          title={
            <Flex style={{ display: 'block' }} vertical>
              <NewTypography.Text strong style={{ color: tokens.colors.global.primary.light }}>
                Preserve inactive {otherItemTypeDisplayName}{' '}
              </NewTypography.Text>
              allow to keep all historical data ever synced in your {otherContainerType}. While{' '}
              <NewTypography.Text strong style={{ color: tokens.colors.global.primary.light }}>
                remove inactive {otherItemTypeDisplayName}
              </NewTypography.Text>{' '}
              ensure only the most up-to-date version of your data is visible.
            </Flex>
          }
        >
          <IconTooltip
            title={`preserve/remove ${otherItemTypeDisplayName}`}
            name="info-circle"
            kind={Icon.KINDS.SOLID}
            color={tokens.colors.content.info.default}
          />
        </NewTooltip>
      </Flex>
    </>
  );
};

OnFilterOutRule.propTypes = {
  rowIntroduction: PropTypes.oneOf(['AND', 'THEN']),
  withSeparator: PropTypes.bool,
  side: PropTypes.oneOf(['A', 'B']),
};
