import { EXTRA_STEP_TYPE } from './useGetProviderExtraStep';

export function useGetExtraStepCopyToUse(providerAExtraStep, providerBExtraStep) {
  /* Google Sheets and Excel differ in the terminology used for their extra steps (add-on vs add-in).
   * if both providers need an extra step, and one of them is an add-on, we'll use the add-on terminology.
   * if only one provider needs an extra step, we'll use the terminology specific to that provider.
   */

  const providerAExtraStepIsAddOn = providerAExtraStep?.defaultName === EXTRA_STEP_TYPE.ADD_ON;
  const providerBExtraStepIsAddOn = providerBExtraStep?.defaultName === EXTRA_STEP_TYPE.ADD_ON;

  if (providerAExtraStepIsAddOn || providerBExtraStepIsAddOn) {
    return EXTRA_STEP_TYPE.ADD_ON;
  }

  return providerAExtraStep || providerBExtraStep;
}
