import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  getUserProviderIdentities,
  getIsProviderIdentitiesLoading,
  getIsProviderIdentitiesLoaded,
  hasProviderIdentitiesError,
} from '~/reducers';
import { reportException } from '~/actions/logging';
import * as providerIdentityActions from '~/actions/providerIdentity';
import { RESOURCE_ERROR_TYPE } from '~/consts/errors';

// If you don't want to use this hook under a flowBuilder context, you can use the other hook under src/hooks/useGetAllProvidersIdentities instead
export const useGetAllProvidersIdentities = () => {
  const dispatch = useDispatch();
  const { setError, clearErrors, formState } = useFormContext();

  const isProviderIdentitiesLoading = useSelector((state) => getIsProviderIdentitiesLoading(state));
  const isProviderIdentitiesLoaded = useSelector((state) => getIsProviderIdentitiesLoaded(state));
  const hasError = useSelector((state) => hasProviderIdentitiesError(state));

  const allProviderIdentities = useSelector((state) => getUserProviderIdentities(state));

  const shouldFetchProviderIdentities = !isProviderIdentitiesLoading && !isProviderIdentitiesLoaded && !hasError;

  useEffect(() => {
    const fetchAllProviderIdentities = async () => {
      if (shouldFetchProviderIdentities) {
        const errorKey = `root.${RESOURCE_ERROR_TYPE.DUPLICATION_ERROR}`;
        const { errors } = formState;
        try {
          await dispatch(providerIdentityActions.getProviderIdentities());
          !!errors.errorKey && clearErrors(errorKey);
        } catch (err) {
          reportException(err, 'Failed to fetch provider identities');
          setError(errorKey, { type: err.code, message: err.message });
        }
      }
    };
    fetchAllProviderIdentities();
  }, [dispatch, shouldFetchProviderIdentities, formState, setError, clearErrors]);

  return {
    allProviderIdentities,
    isLoading: isProviderIdentitiesLoading,
    isLoaded: isProviderIdentitiesLoaded,
    hasError,
  };
};
