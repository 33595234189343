import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@unitoio/mosaic';

import * as fieldTypes from '~/consts/fields';
import { SelectField } from '~/components/SelectField/SelectField';
import { useGetPCDFieldsSelectorOptions } from '../hooks/useGetPCDFieldsSelectorOptions';

const statuses = {
  INITIAL: 'initial',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'errorCustomField',
};

export const displayTypes = {
  MAPPING: 'mapping',
  FILTERS_ONLY: 'filtersOnly',
  STRING_CUSTOM_FIELDS_ONLY: 'customFieldsOnly',
  DEEP_FIELDS_ONLY: 'deepFieldsOnly',
  REQUIRES_FIELD_VALUE: 'requiresFieldValue',
  MERGEABLE_FIELDS_ONLY: 'mergeFieldsOnly',
};

export const PCDFieldsSelect = ({
  labelPrefix,
  labelSuffix,
  additionalOptions = [],
  containerSide,
  containerId,
  formatLabel,
  displayType,
  isOptionDisabledHandler,
  name,
  noResultsText = 'No matching fields found',
  onChange = () => {},
  placeholder = '',
  providerName,
  readOnly = false,
  searchPlaceholder,
  searchable = true,
  value,
  disabled = false,
  parentFieldId,
  itemType,
  fullWidth = false,
}) => {
  const [isLoading, sortedOptions] = useGetPCDFieldsSelectorOptions(
    providerName,
    containerId,
    containerSide,
    itemType,
    displayTypes,
    displayType,
    parentFieldId,
    labelPrefix,
    labelSuffix,
    isOptionDisabledHandler,
    additionalOptions,
  );

  const SelectComponent = !name ? Select : SelectField;

  return (
    <SelectComponent
      name={name}
      fullWidth={fullWidth}
      label="Select a field"
      formatLabel={formatLabel}
      isLoading={isLoading}
      isWarning={isLoading === statuses.ERROR}
      placeholder={placeholder}
      noResultsText={sortedOptions.length > 0 ? noResultsText : 'No fields available'}
      onChange={onChange}
      options={sortedOptions}
      optionsLoading={isLoading}
      readOnly={readOnly}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
      value={value}
      disabled={disabled}
    />
  );
};

PCDFieldsSelect.propTypes = {
  containerSide: PropTypes.oneOf(['A', 'B']).isRequired,
  containerId: PropTypes.string.isRequired,
  formatLabel: PropTypes.func,
  displayType: PropTypes.oneOf(Object.values(displayTypes)).isRequired,
  parentFieldId: PropTypes.string,
  /** Function to determine whether an option should be disabled */
  isOptionDisabledHandler: PropTypes.func.isRequired,
  name: PropTypes.string,
  labelPrefix: PropTypes.string,
  labelSuffix: PropTypes.string,
  noResultsText: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  providerName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  disabled: PropTypes.bool,
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      disabledText: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      // TODO PCDv3 - aug 28
      kind: PropTypes.oneOf(Object.values(fieldTypes.KINDS).concat('options')),
    }),
  ),
  itemType: PropTypes.string.isRequired,
};
