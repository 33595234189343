import { useSelector } from 'react-redux';
import { getProviderByName } from 'reducers';

export function useProviderItemSupportsMerging(providerName, itemType) {
  const provider = useSelector((state) => getProviderByName(state, providerName));
  // early return since we use this hook in drafts, where providerName/itemType could be undefined
  if (!provider || provider.isEmpty()) {
    return false;
  }
  return provider.getIn(['capabilitiesV3', 'primaryItems', itemType, 'item', 'canMerge'], false);
}
