import { Map } from 'immutable';

export const groupByField = (fields, capabilities) =>
  fields.reduce((acc, field) => {
    const existingValues = acc[field.fieldId]?.values || [];
    let valuesToAdd = [];
    if (field?.value) {
      valuesToAdd = Array.isArray(field.value) ? field.value : [field.value];
    }

    const canBeAllowed = capabilities.get(field.fieldId, Map()).getIn(['filterable', 'allowList'], false);
    const canBeDenied = capabilities.get(field.fieldId, Map()).getIn(['filterable', 'denyList'], false);

    acc[field.fieldId] = acc[field.fieldId] || { nbRulesForField: 0, existingOperators: [] };
    acc[field.fieldId] = {
      nbRulesForField: acc[field.fieldId].nbRulesForField + 1,
      existingOperators: [...acc[field.fieldId].existingOperators, field.operator],
      values: [...existingValues, ...valuesToAdd],
      canBeDenied,
      canBeAllowed,
    };
    return acc;
  }, {});
