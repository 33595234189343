import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import jiraCloudApiToken2 from './jira-cloud-api-token-2.png';
import jiraServerApiToken2 from './jira-server-api-token-2.png';

export function AuthJiraApiTokenStep2({ children: instructionFields, history, onCancel, onSubmit }) {
  const { providerName } = useParams();
  const imgUrl = providerName === 'jiracloud' ? jiraCloudApiToken2 : jiraServerApiToken2;

  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (API Token - Step 2 of 2)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          From the dialog copy your new <strong>API token</strong> and paste it below
        </p>
        <AuthImageWrapper>
          <img src={imgUrl} alt="Jira API Token Step - Step 2" />
        </AuthImageWrapper>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button btnStyle="dark" onClick={onSubmit} type="href" pullRight>
            Authorize
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraApiTokenStep2.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
