import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';

import * as trackingTypes from '~/consts/tracking';
import { getPlanTier } from '~/utils/getPlanTier';

import { Box, Modal, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';

export class BlockIntervalChangePlanModal extends Component {
  static propTypes = {
    currentPlan: PropTypes.instanceOf(Map).isRequired,
    newPlan: PropTypes.instanceOf(Map).isRequired,
    onRequestClose: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { currentPlan, newPlan, trackEvent } = this.props;

    trackEvent(`${trackingTypes.USER_PRICING_EVENTS.PLAN_CHANGE}_${trackingTypes.BLOCKED}`, {
      current_plan: currentPlan.get('id'),
      plan_selected: newPlan.get('id'),
      plan_tier_selected: getPlanTier(newPlan.get('id')),
      reason: 'shorterInterval',
    });
  }

  render() {
    const { onRequestClose } = this.props;

    return (
      <Modal
        cancelLabel="Close"
        isOpen
        onRequestClose={onRequestClose}
        title="Plan change blocked"
        onConfirm={onRequestClose}
      >
        <div>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s5, tokens.spacing.s0]}>
            <Typography variant={TypographyVariants.BODY1}>
              You're already on a yearly plan, we've blocked you from changing to a monthly plan so you don't pay twice
              for the same month.
            </Typography>
          </Box>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s5, tokens.spacing.s0]}>
            <Typography variant={TypographyVariants.BODY1}>
              If you meant to do this, please contact our customer support and we'll help you out.
            </Typography>
          </Box>
          <ChatBubble type="default">Contact customer support</ChatBubble>
        </div>
      </Modal>
    );
  }
}
