import React from 'react';
import { reset } from 'redux-form';

import { notification, Space } from '@unitoio/mosaic';

import { getUserEmail } from 'reducers';
import * as appActions from '~/actions/app';
import * as organizationActions from '~/actions/organizations';
import * as inviteTypes from '~/consts/invites';
import * as routes from '~/consts/routes';
import { history } from '~/utils/history';

export const getInvites = (organizationId) => ({
  types: [
    inviteTypes.GET_ORGANIZATION_INVITES_REQUEST,
    inviteTypes.GET_ORGANIZATION_INVITES_SUCCESS,
    inviteTypes.GET_ORGANIZATION_INVITES_FAILURE,
  ],
  url: routes.API_PATHS.GET_ORGANIZATION_INVITES(organizationId), // eslint-disable-line
});

export const getInviteWorkspaceName = () => ({
  types: [
    inviteTypes.GET_INVITE_WORKSPACE_NAME_REQUEST,
    inviteTypes.GET_INVITE_WORKSPACE_NAME_SUCCESS,
    inviteTypes.GET_INVITE_WORKSPACE_NAME_FAILURE,
  ],
  url: routes.API_PATHS.GET_INVITE_WORKSPACE_NAME,
});

export const getUserPendingInvites = () => async (dispatch, getState) => {
  let response;
  try {
    response = await dispatch({
      types: [
        inviteTypes.GET_USER_PENDING_INVITES_REQUEST,
        inviteTypes.GET_USER_PENDING_INVITES_SUCCESS,
        inviteTypes.GET_USER_PENDING_INVITES_FAILURE,
      ],
      url: routes.API_PATHS.GET_USER_PENDING_INVITES(),
      displayError: false,
    });
  } catch (error) {
    if (error.name === 'UnauthorizedInviteLinkError') {
      const userEmail = getUserEmail(getState());
      notification.error({
        message: 'This invite is for someone else.',
        description: (
          <Space direction="vertical">
            <p>You are currently logged in with {userEmail}, but this invite was sent to a different email address.</p>
            <p>Please ask your workspace admin to invite {userEmail}</p>
          </Space>
        ),
        placement: 'topRight',
        duration: 0,
      });
    } else {
      throw error;
    }
  }

  return response;
};

export const sendInvite = (organizationId, email) => ({
  types: [
    inviteTypes.SEND_HUBSPOT_INVITE_REQUEST,
    inviteTypes.SEND_HUBSPOT_INVITE_SUCCESS,
    inviteTypes.SEND_HUBSPOT_INVITE_FAILURE,
  ],
  url: routes.API_PATHS.SEND_HUBSPOT_INVITE,
  method: routes.METHODS.POST,
  payload: { organizationId, email },
  displayError: false,
});

export const sendReferral = (organizationId, formPayload) => ({
  types: [
    inviteTypes.SEND_HUBSPOT_REFERRAL_REQUEST,
    inviteTypes.SEND_HUBSPOT_REFERRAL_SUCCESS,
    inviteTypes.SEND_HUBSPOT_REFERRAL_FAILURE,
  ],
  url: routes.API_PATHS.SEND_HUBSPOT_REFERRAL,
  method: routes.METHODS.POST,
  payload: { organizationId, ...formPayload },
});

const updateInvite =
  (inviteId, inviteState, displayError = true) =>
  async (dispatch) => {
    const response = await dispatch({
      types: [inviteTypes.UPDATE_INVITE_REQUEST, inviteTypes.UPDATE_INVITE_SUCCESS, inviteTypes.UPDATE_INVITE_FAILURE],
      method: routes.METHODS.PATCH,
      url: routes.API_PATHS.UPDATE_INVITE(inviteId), // eslint-disable-line
      payload: { state: inviteState },
      displayError,
    });

    dispatch(reset(inviteTypes.INVITE_BY_EMAIL_FORM));
    return response;
  };

export const acceptInvite = (inviteId) => async (dispatch) => {
  let response;
  try {
    response = await dispatch(updateInvite(inviteId, inviteTypes.STATES.ACCEPTED, false));
  } catch (error) {
    if (error.name === 'InviteCanceledError') {
      notification.error({
        message: 'We could not add you to the workspace',
        description: 'The invite has been canceled by the admin of the workspace.',
        duration: 5000,
        placement: 'top',
      });
    } else {
      throw error;
    }
  }

  await dispatch(getUserPendingInvites());
  await dispatch(organizationActions.getOrganizations());
  if (response) {
    dispatch(appActions.setSelectedOrganizationId(response.invite.organization.id));
  }
  history.push({ pathname: routes.ABSOLUTE_PATHS.DASHBOARD });
};

export const declineInvite = (inviteId) => (dispatch) => dispatch(updateInvite(inviteId, inviteTypes.STATES.DECLINED));

export const cancelInvite = (inviteId) => (dispatch) => dispatch(updateInvite(inviteId, inviteTypes.STATES.CANCELED));

export const resendInvite = (inviteId) => (dispatch) => dispatch(updateInvite(inviteId, inviteTypes.STATES.PENDING));

export const showInviteCoworkersModal = () => ({ type: inviteTypes.SHOW_INVITE_COWORKERS_MODAL });

export const hideInviteCoworkersModal = () => ({ type: inviteTypes.HIDE_INVITE_COWORKERS_MODAL });
