import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { NewButton } from '@unitoio/mosaic';
import * as errorTypes from '~/consts/errors';
import { useSelector } from 'react-redux';
import { capitalize } from '~/utils/capitalize';
import { getProviderByProviderIdentityId } from '~/reducers';

import { BoxedAlert } from '~/components/BoxedAlert/BoxedAlert';

export function SelectedProviderIdentityError({ onReconnect, containerType, providerIdentity, providerIdentityError }) {
  const { errorType } = providerIdentityError;

  const provider = useSelector((state) => getProviderByProviderIdentityId(state, providerIdentity?.get('_id')));

  if (errorType === errorTypes.API_ERROR_TYPES.AUTHENTICATION) {
    return (
      <BoxedAlert>
        Unito no longer has access to this account. Try to{' '}
        <NewButton type="link" onClick={onReconnect} style={{ padding: 0 }}>
          reconnect
        </NewButton>{' '}
        it, or select another account to restore access.
      </BoxedAlert>
    );
  }

  if (errorType === errorTypes.API_ERROR_TYPES.UNAUTHORIZED) {
    const providerName = provider.get('displayName');
    const fallbackDisplayName = providerName ? `${capitalize(providerName)} account` : 'This account';
    const profileEmailOrFallback = providerIdentity.getIn(['profileEmails', 0], fallbackDisplayName);
    // using nullish here in case profileDisplayName comes back as `null` since immutable considers null a valid value
    // and won't apply the second argument
    const accountDisplayName = providerIdentity.get('profileDisplayName') ?? profileEmailOrFallback;

    return (
      <BoxedAlert>
        {accountDisplayName} is not authorized to access this {containerType}, please review your{' '}
        {providerName ?? 'tool'} permissions or reach out to your {providerName ?? ''} administrator to resume syncing.
      </BoxedAlert>
    );
  }

  return <BoxedAlert>{providerIdentityError.error}</BoxedAlert>;
}

SelectedProviderIdentityError.propTypes = {
  onReconnect: PropTypes.func.isRequired,
  containerType: PropTypes.string.isRequired,
  providerIdentity: PropTypes.instanceOf(Map).isRequired,
  providerIdentityError: PropTypes.shape({
    error: PropTypes.string.isRequired,
    errorType: PropTypes.string,
    errorName: PropTypes.string.isRequired,
  }).isRequired,
};
