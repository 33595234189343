import React from 'react';

import { Box, LoadingPlaceholder, tokens } from '@unitoio/mosaic';

export const FieldMappingPlaceholder = () => (
  <div aria-label="Loading mapped values">
    <Box p={[tokens.spacing.s4, tokens.spacing.s7]}>
      <LoadingPlaceholder height={tokens.spacing.s7} width="100%" borderRadius={tokens.spacing.s3} />
    </Box>
    <Box flexDirection="row" p={[tokens.spacing.s4, tokens.spacing.s7]} fullWidth>
      <Box fullWidth>
        <LoadingPlaceholder height={tokens.spacing.s6} width="100%" borderRadius={tokens.spacing.s3} />
      </Box>
      <Box m={[0, tokens.spacing.s8]} />
      <Box fullWidth>
        <LoadingPlaceholder height={tokens.spacing.s6} width="100%" borderRadius={tokens.spacing.s3} />
      </Box>
    </Box>
    <Box flexDirection="row" p={[tokens.spacing.s4, tokens.spacing.s7]} fullWidth>
      <Box fullWidth>
        <LoadingPlaceholder height={tokens.spacing.s6} width="100%" borderRadius={tokens.spacing.s3} />
      </Box>
      <Box m={[0, tokens.spacing.s8]} />
      <Box fullWidth>
        <LoadingPlaceholder height={tokens.spacing.s6} width="100%" borderRadius={tokens.spacing.s3} />
      </Box>
    </Box>
  </div>
);
