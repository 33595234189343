import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Box,
  Button,
  Icon,
  Select,
  tokens,
  Typography,
  TypographyAlignments,
  TypographyVariants,
} from '@unitoio/mosaic';

import * as billingTypes from '~/consts/billing';
import * as featureTypes from '~/consts/features';
import { useLogger } from '~/hooks';
import { capitalize } from '~/utils/capitalize';
import { Card } from '~/components/Card/Card';
import { IconHoverTooltip } from '~/components/IconHoverTooltip/IconHoverTooltip';

import { SelectPlanButton } from '../SelectPlanButton/SelectPlanButton';
import './PlanDetails.scss';

const Item = styled(Card)`
  flex-basis: ${(props) => (props.$nbPlans % 2 === 0 ? '23%' : '31.33%')};
  box-shadow: 0;
  transition: box-shadow 0.4s;
  padding: ${tokens.spacing.s3};
  margin: ${tokens.spacing.s4} ${tokens.spacing.s2};
  border-color: ${(props) =>
    props.$shouldShowTopBanner ? tokens.colors.content.primary.default : tokens.colors.content.neutral.n10};
  &:first-child {
    margin-left: 0;
  }

  &:hover {
    box-shadow: 0 0 1rem ${tokens.colors.content.neutral.n30};
  }
`;

const Features = styled.ul`
  font-size: ${tokens.fontSize.f7};
  list-style: none;
  margin: ${tokens.spacing.s6} ${tokens.spacing.s0} ${tokens.spacing.s5};
  padding: ${tokens.spacing.s0} ${tokens.spacing.s3};
`;

const Feature = styled.li`
  text-align: left;
  margin-top: ${tokens.spacing.s4};
`;

const Tagline = styled(Typography)`
  margin-bottom: ${tokens.spacing.s6};
`;

const Price = styled(Typography)`
  font-size: 3.125rem;
  line-height: 1;
  font-weight: ${tokens.fontWeight.fw7};
  margin-left: -${tokens.spacing.s3};

  sup {
    font-size: 50%;
    font-weight: ${tokens.fontWeight.fw4};
    top: -0.75em;
  }
`;

const Name = styled(Typography)`
  line-height: 1rem;
  padding: 0.75rem ${tokens.spacing.s2};
  margin-top: ${(props) => (props.$shouldShowTopBanner ? tokens.spacing.s3 : tokens.spacing.s6)};
`;

const TopBanner = styled(Typography)`
  background-color: ${tokens.colors.content.primary.default};
  color: ${tokens.colors.global.primary.light};
  border-radius: ${tokens.spacing.s2};
`;

const BoldTypography = styled(Typography)`
  font-weight: ${tokens.fontWeight.fw7};
`;

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;

function getValueMetricLimit(plan, valueMetric = featureTypes.FEATURES.MAX_USERS) {
  return plan
    ?.get('features')
    ?.find((feature) => feature.get('id') === valueMetric)
    ?.get('limit');
}

export function PlanDetails(props) {
  const {
    interval = billingTypes.PLAN_INTERVALS.MONTH,
    currentPlan,
    nbPlans,
    plans,
    planTier,
    onSelect,
    hasPaymentSource,
    valueMetricToShow,
    orgCustomerId,
    isPayingAccount,
  } = props;
  const valueMetricId = valueMetricToShow?.get('id');
  const plansByInterval = plans.filter((plan) => plan.get('interval') === interval);
  const { reportWarning } = useLogger();

  const findVariantToDisplay = () => {
    const supportedVariants = plansByInterval.map((plan) => getValueMetricLimit(plan, valueMetricId)).valueSeq();
    if (!plans.has(currentPlan.get('id'))) {
      return supportedVariants.min();
    }

    const currentPlanVariant = plans.has(currentPlan.get('id')) && getValueMetricLimit(currentPlan, valueMetricId);

    // It is possible for an account to have unsupported plan. This will prevent the app from crashing and show the minimum value plan
    const isCurrentVariantSupported = supportedVariants.includes(currentPlanVariant);
    return isCurrentVariantSupported ? currentPlanVariant : supportedVariants.min();
  };

  const [valueMetricVariant, setValueMetricVariant] = useState(findVariantToDisplay());

  const getShownPlan = () => {
    const shownPlan = plansByInterval.find((plan) => getValueMetricLimit(plan, valueMetricId) === valueMetricVariant);

    // In the event of custom plans where there isn't necessarily an equivalent yearly or monthly that is being fetched.
    // This would default to the minimum value plan rather than crashing the app due to a plan not being found
    if (!shownPlan) {
      const minVariant = findVariantToDisplay();
      return plansByInterval.find((plan) => getValueMetricLimit(plan, valueMetricId) === minVariant);
    }

    return shownPlan;
  };

  const shownPlan = getShownPlan();

  if (!shownPlan) {
    reportWarning('Pricing Page error - Cannot display a TierPlan', {
      shownPlan: shownPlan?.toJS(),
      currentPlan: currentPlan?.toJS(),
      tierPlans: plans.toJS(),
    });
    return null;
  }

  const isCurrentPlan = shownPlan?.get('id') === currentPlan?.get('id');

  const getOptions = () =>
    plansByInterval
      .map((plan) => {
        const option = {
          label: getOptionLabel(plan),
          value: getValueMetricLimit(plan, valueMetricId),
          disabled: plan.get('id') === shownPlan.get('id'),
        };
        return option;
      })
      .sort((optionA, optionB) => (optionA?.value || 0) - (optionB?.value || 0))
      .toArray();

  const getOptionLabel = (plan) =>
    `${getValueMetricLimit(plan, valueMetricId)} ${valueMetricToShow.get('displayName')}`;

  const planAmount = shownPlan.get('amount', 0);
  const yearlySaved = shownPlan.getIn(['metadata', 'yearlySaved'], null);
  const tagLine = shownPlan.getIn(['metadata', 'tagLine']);
  const isYearInterval = interval === billingTypes.PLAN_INTERVALS.YEAR;
  const isYearlyPlanSelected = isYearInterval && yearlySaved;
  const shouldShowTopBanner =
    planTier === billingTypes.PLAN_TYPES.SPREADSHEET || planTier === billingTypes.PLAN_TYPES.COMPANY;

  return (
    <Item $shouldShowTopBanner={shouldShowTopBanner} $nbPlans={nbPlans} planTier={planTier}>
      {shouldShowTopBanner && (
        <TopBanner planTier={planTier} variant={TypographyVariants.BODY2} align={TypographyAlignments.CENTER}>
          {planTier === billingTypes.PLAN_TYPES.SPREADSHEET ? 'New plan' : 'Recommended'}
        </TopBanner>
      )}
      <Name
        $shouldShowTopBanner={shouldShowTopBanner}
        variant={TypographyVariants.H2}
        align={TypographyAlignments.CENTER}
      >
        {capitalize(planTier)}
      </Name>
      {tagLine && (
        <Tagline variant={TypographyVariants.BODY2} align={TypographyAlignments.CENTER}>
          {tagLine}
        </Tagline>
      )}

      <Price align={TypographyAlignments.CENTER}>
        <sup>$</sup>
        {isYearInterval ? Math.round(planAmount / 12 / 100) : planAmount / 100}
      </Price>

      <BoldTypography variant={TypographyVariants.BODY2} align={TypographyAlignments.CENTER}>
        per month, billed {isYearlyPlanSelected ? 'annually' : 'monthly'}
      </BoldTypography>

      <Box m={[tokens.spacing.s6, tokens.spacing.s5]}>
        <Select
          placeholder="Select a plan"
          onChange={(value) => setValueMetricVariant(value)}
          options={getOptions()}
          size="md"
          value={valueMetricVariant}
        />
      </Box>

      {isCurrentPlan && isPayingAccount ? (
        <ButtonContainer m={[tokens.spacing.s4, tokens.spacing.s3]}>
          <Button disabled>Current plan</Button>
        </ButtonContainer>
      ) : (
        <ButtonContainer m={[tokens.spacing.s4, tokens.spacing.s3]}>
          <SelectPlanButton
            plan={shownPlan}
            onSelect={onSelect}
            hasPaymentSource={hasPaymentSource}
            currentPlan={currentPlan}
            orgCustomerId={orgCustomerId}
          />
        </ButtonContainer>
      )}

      <Features>
        {shownPlan
          .get('features', List())
          .filter((feature) => feature.get('isVisible', false) && feature.get('limit') !== 0)
          .map((feature) => (
            <Feature key={feature.get('id')}>
              <Icon name="check-circle" kind={Icon.KINDS.SOLID} />
              <Box as="span" m={[0.25, 0.25, 0, 0.5]}>
                {feature.get('label')}
              </Box>
              <IconHoverTooltip icon={{ color: tokens.colors.content.info.default }} placement="top">
                {feature.get('helpText')}
              </IconHoverTooltip>
            </Feature>
          ))
          .toArray()}
      </Features>
    </Item>
  );
}

PlanDetails.propTypes = {
  interval: PropTypes.oneOf(Object.values(billingTypes.PLAN_INTERVALS)),
  currentPlan: PropTypes.instanceOf(Map).isRequired,
  nbPlans: PropTypes.number.isRequired,
  plans: PropTypes.instanceOf(Map).isRequired,
  planTier: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  valueMetricToShow: PropTypes.instanceOf(Map).isRequired,
  hasPaymentSource: PropTypes.bool.isRequired,
  orgCustomerId: PropTypes.string.isRequired,
  isPayingAccount: PropTypes.bool.isRequired,
};
