import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Grid, Row, Col, tokens } from '@unitoio/mosaic';

const NoPaddingGrid = styled(Grid)`
  padding: 0 !important;
  margin-bottom: ${tokens.spacing.s7};
`;

export const PageContainer = ({ children, sidebar: Sidebar }) => {
  // We want to hide the sidebar in the Flow Builder, only on draft mode
  const showSidebar = !!Sidebar;

  return (
    <NoPaddingGrid>
      <Row nogutter justify="center">
        <Col md={12} lg={9}>
          <Box p={[0, tokens.spacing.s4]}>{children}</Box>
        </Col>
        {showSidebar && (
          <Col lg={3} md={12}>
            <Box p={[0, tokens.spacing.s4]}>
              <Sidebar />
            </Box>
          </Col>
        )}
      </Row>
    </NoPaddingGrid>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};
