import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { Icon, NewTag, Toggle, tokens, Tooltip } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as featureTypes from '~/consts/features';
import { getFeatureFlagValue, getProviderByName, isSavingSync as getIsSavingSync } from 'reducers';
import { DiagnoseModal } from '~/containers/DiagnoseModal/DiagnoseModal';
import { SiteAdminContext } from '~/contexts';
import { Box } from '~/components/Box/Box';
import { Button } from '~/components/Button/Button';
import { TurnOffTestModeModal } from '~/components/TurnOffTestModeModal/TurnOffTestModeModal';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import * as linkActions from '~/actions/links';

import { LinkStatus } from './LinkStatus';
import { LinkContainerIcons } from './LinkContainerIcons';
import { LinkInformation } from './LinkInformation';
import { SyncActions } from './SyncActions';
import { LinkItemDeleteModal } from './LinkItemDeleteModal';

const Flex = styled.div`
  align-items: center;
  border-radius: ${tokens.spacing.s2};
  ${(props) => !props.$isWorkflow && `border: 1px solid ${tokens.colors.content.neutral.n10};`}

  display: flex;
  ${(props) => !props.$isWorkflow && `margin-bottom: ${tokens.spacing.s3};`}
  padding: .75rem ${tokens.spacing.s3};
  justify-content: space-between;
  padding-left: 2.5rem;

  &:hover {
    background-color: ${tokens.colors.background.message.info};
  }
`;

const LinkInfoItemWrapper = styled.div`
  flex-grow: 1;
  padding-left: ${tokens.spacing.s5};
`;

const LinkStatusWrapper = styled.div`
  margin-left: auto;
  padding-left: ${tokens.spacing.s5};
`;

const AlignItem = styled.div`
  align-items: center;
  display: flex;
`;

const ItemWrapper = styled.div`
  margin-left: ${tokens.spacing.s3};
  margin-right: ${tokens.spacing.s3};
`;

class LinkItemComponent extends Component {
  static propTypes = {
    isSavingSync: PropTypes.bool,
    onSetAutoSync: PropTypes.func.isRequired,
    onSetManualSync: PropTypes.func.isRequired,
    isWorkflow: PropTypes.bool,
    // used in mapStateToProps
    // eslint-disable-next-line react/no-unused-prop-types
    providerNameA: PropTypes.string,
    // used in mapStateToProps
    // eslint-disable-next-line react/no-unused-prop-types
    providerNameB: PropTypes.string,
    providerA: PropTypes.instanceOf(Map),
    providerB: PropTypes.instanceOf(Map),
    showActions: PropTypes.bool,
    sync: PropTypes.instanceOf(Map),
    turnOffTestMode: PropTypes.func.isRequired,
    itemTypeA: PropTypes.string,
    itemTypeB: PropTypes.string,
    containerIdA: PropTypes.string.isRequired,
    containerIdB: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isSavingSync: false,
    showActions: false,
    isWorkflow: false,
    sync: Map(),
    providerNameA: undefined,
    providerNameB: undefined,
    itemTypeA: undefined,
    itemTypeB: undefined,
    providerA: Map(),
    providerB: Map(),
  };

  static contextType = SiteAdminContext;

  state = {
    isSyncDeleteModalOpen: false,
    isTurnOffTestModeModalOpen: false,
    isDiagnosisModalOpen: false,
  };

  openDeleteModal = () => {
    this.setState({ isSyncDeleteModalOpen: true });
  };

  openTurnOffTestModeModal = () => {
    this.setState({ isTurnOffTestModeModalOpen: true });
  };

  closeDeleteModal = () => {
    this.setState({ isSyncDeleteModalOpen: false });
  };

  closeTurnOffTestModeModal = () => {
    this.setState({ isTurnOffTestModeModalOpen: false });
  };

  turnOffTestMode = () => {
    const { turnOffTestMode, sync } = this.props;
    turnOffTestMode(sync.get('_id'));
    this.closeTurnOffTestModeModal();
  };

  getTurnOffTestModeModal = () => {
    const { sync } = this.props;
    const { isTurnOffTestModeModalOpen } = this.state;

    return (
      <TurnOffTestModeModal
        isOpen={isTurnOffTestModeModalOpen}
        onSuccess={this.turnOffTestMode}
        onRequestClose={this.closeTurnOffTestModeModal}
        taskTerm={this.getTaskTerm()}
        linkId={sync.get('id')}
      />
    );
  };

  getTaskTerm = () => {
    const { providerA, providerB, itemTypeA, itemTypeB, containerIdA, containerIdB } = this.props;

    return (
      <ProviderTermsByName
        providerNameA={providerA.get('name')}
        providerNameB={providerB.get('name')}
        termKey="task"
        plurality="plural"
        pcdv3={!!itemTypeA && !!itemTypeB}
        itemTypeA={itemTypeA}
        itemTypeB={itemTypeB}
        fallbackTerm="items"
        containerIdA={containerIdA}
        containerIdB={containerIdB}
      />
    );
  };

  handleOnAutoSyncToggle = () => {
    const { onSetAutoSync, onSetManualSync, sync } = this.props;

    if (sync.get('isAutoSync')) {
      onSetManualSync(sync.get('_id'));
      return;
    }

    onSetAutoSync(sync.get('_id'));
  };

  shouldDisplayAutoSyncToggle = () => {
    const { sync } = this.props;
    const kind = sync.get('kind');
    const isAutoSync = sync.get('isAutoSync');
    const restricted = sync.get('restricted');

    // There are some instances where the auto sync of a task sync can be false, we should allow the user to turn it on
    if (kind === linkTypes.KIND.TASK_SYNC) {
      return !isAutoSync && !restricted;
    }

    return !restricted;
  };

  renderTag = () => {
    const { sync } = this.props;
    const linkKind = sync.get('kind');
    const tagLabel = {
      [linkTypes.KIND.REPORT_SYNC]: 'Export & Sync',
    };

    return (
      tagLabel[linkKind] && (
        <Box>
          <NewTag>{tagLabel[linkKind]}</NewTag>
        </Box>
      )
    );
  };

  render() {
    const { isSavingSync, providerA, providerB, showActions, sync, isWorkflow } = this.props;
    const { isSiteAdmin } = this.context;
    const { isDiagnosisModalOpen, isSyncDeleteModalOpen } = this.state;

    const isDraft = sync.get('state') === linkTypes.LINK_STATES.DRAFT;

    return (
      <Flex className="link-item" $isWorkflow={isWorkflow} data-testid={sync.get('id')}>
        {this.shouldDisplayAutoSyncToggle() ? (
          <ItemWrapper>
            {isDraft ? (
              <Toggle
                value={sync.get('isAutoSync')}
                disabled
                onClick={this.handleOnAutoSyncToggle}
                data-test="dashboard__btn--autosync"
              />
            ) : (
              <Tooltip
                content="Activating auto-sync will allow Unito to sync changes based on your plan speed.
              By deactivating auto-sync, changes will no longer sync automatically."
                as="div"
              >
                <Toggle
                  value={sync.get('isAutoSync')}
                  disabled={isSavingSync}
                  onClick={this.handleOnAutoSyncToggle}
                  data-test="dashboard__btn--autosync"
                />
              </Tooltip>
            )}
          </ItemWrapper>
        ) : (
          <Box $m={[0, 0, 0, 4.2]} />
        )}

        <Box $m={[0, 2, 0, 1.5]}>
          <LinkItemDeleteModal
            isOpen={isSyncDeleteModalOpen}
            sync={sync}
            onCancel={this.closeDeleteModal}
            onRequestClose={this.closeDeleteModal}
          />
          {this.getTurnOffTestModeModal()}
          {isDiagnosisModalOpen && (
            <DiagnoseModal isOpen sync={sync} onRequestClose={() => this.setState({ isDiagnosisModalOpen: false })} />
          )}
          <AlignItem>
            <LinkContainerIcons
              containerA={sync.getIn(['A', 'container'])}
              containerB={sync.getIn(['B', 'container'])}
              providerA={providerA}
              providerB={providerB}
              readOnlyA={sync.getIn(['syncSettings', 'A', 'readOnly'])}
              readOnlyB={sync.getIn(['syncSettings', 'B', 'readOnly'])}
            />
          </AlignItem>
        </Box>

        <LinkInfoItemWrapper>
          <LinkInformation
            linkId={sync.get('_id')}
            taskTermPlural={this.getTaskTerm()}
            editSyncUrl={routes.getEditFlowBuilderRoute(sync.get('_id'))}
          />
          {isSiteAdmin && (
            <div>
              <Button
                btnStyle="error"
                className="diagnose-button"
                onClick={() => this.setState({ isDiagnosisModalOpen: true })}
                reverse
                size="xs"
              >
                <Icon name="heartbeat" size="lg" kind={Icon.KINDS.SOLID} /> Diagnose
              </Button>
            </div>
          )}
        </LinkInfoItemWrapper>
        {this.renderTag()}
        <LinkStatusWrapper className="link-status-wrapper">
          <LinkStatus
            editSyncUrl={routes.getEditFlowBuilderRoute(sync.get('_id'))}
            syncId={sync.get('_id')}
            isSuspended={sync.get('isSuspended')}
          />
        </LinkStatusWrapper>

        {!sync.isEmpty() && (
          <SyncActions
            sync={sync}
            linkId={sync.get('_id')}
            onDeleteClick={this.openDeleteModal}
            showActions={showActions}
            turnOffTestMode={this.openTurnOffTestModeModal}
          />
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const providerA = getProviderByName(state, ownProps.providerNameA);
  const providerB = getProviderByName(state, ownProps.providerNameB);

  return {
    // failsafe to disable duplicate at will for EOC ticket:
    // https://app.asana.com/0/216018736835574/1202056316265936/f
    providerA,
    providerB,
    isContainerBuilderEnabled: getFeatureFlagValue(state, featureTypes.FEATURES.CONTAINER_BUILDER),
    isSavingSync: getIsSavingSync(state),
    itemTypeA: ownProps.sync.getIn(['A', 'itemType']),
    itemTypeB: ownProps.sync.getIn(['B', 'itemType']),
    containerIdA: ownProps.sync.getIn(['A', 'container', 'id']),
    containerIdB: ownProps.sync.getIn(['B', 'container', 'id']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  turnOffTestMode: async (syncId) => dispatch(linkActions.turnOffTestMode(syncId)),
  onSetAutoSync: async (syncId) => dispatch(linkActions.setAutoSyncLink(syncId)),
  onSetManualSync: async (syncId) => dispatch(linkActions.setManualSyncLink(syncId)),
});

export const LinkItem = connect(mapStateToProps, mapDispatchToProps)(LinkItemComponent);
