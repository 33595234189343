import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import { FlowPortModel } from './FlowPortModel';

export class FlowPortFactory extends AbstractReactFactory {
  constructor() {
    super('FlowPort');
  }

  // eslint-disable-next-line class-methods-use-this
  generateModel = () => new FlowPortModel();

  // eslint-disable-next-line class-methods-use-this
  generateReactWidget() {
    return null;
  }
}
