import * as linkTypes from '~/consts/link';

export const HEARTBEAT_TIMER = 14000;
export const RECONNECT_TIMER = 10000;

export const WS_SUBSCRIBE_PAGES = {
  SYNC_LIST: 'SyncList',
  SYNC_ACTIVITY: 'SyncActivity',
};

export const DISPATCH_TYPES_BY_PAGE = {
  [WS_SUBSCRIBE_PAGES.SYNC_LIST]: linkTypes.UPDATE_SYNC_LIST_STATUSES,
  [WS_SUBSCRIBE_PAGES.SYNC_ACTIVITY]: linkTypes.UPDATE_CURRENT_SYNC_STATUS,
};

export const STATUS_TYPES = {
  ACTIVITY: 'activity',
  ANOMALIES: 'anomalies',
  HEALTH: 'health',
};

export const TOPICS = {
  ANOMALIES: 'Anomalies',
  HEALTH: 'Health',
  SYNCS_EVENTS: 'SyncsEvents',
};

export const WS_MESSAGE_ACTIONS = {
  SUBSCRIBE: 'subscribe',
};

export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_CONNECT = 'WS_CONNECT';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_SUBSCRIBE = 'WS_SUBSCRIBE';

export const WEBSOCKET_STATE = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};
