import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Map } from 'immutable';

import { color, fontSize } from 'theme';
import * as formTypes from '~/consts/form';
import { delay } from '~/utils/delay';
import { Modal } from '~/components/Modal/Modal';
import { Box } from '~/components/Box/Box';
import { ProviderIconButton } from '~/components/ProviderIconButton/ProviderIconButton';
import { SelectProviders } from '~/containers/SelectProviders/SelectProviders';

const BoxCenter = styled(Box)`
  text-align: center;
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: ${color.dark.primary};
  font-size: ${fontSize.small};
  margin-bottom: 0.5rem;
  text-align: center;
`;

const FlexContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => (props.$nbProviders > 3 ? 4 : props.$nbProviders)}, 1fr);
`;

export function SelectProviderField({ meta, providers, input, onSubmit, disableSubmit }) {
  const [showAllProvidersModal, setShowAllProvidersModal] = useState(false);

  const onSelectProvider = (provider) => {
    input.onChange(provider.get('_id'));
    delay(() => onSubmit());
  };

  const renderProviders = (providersToRender) =>
    providersToRender
      .sortBy((provider) => provider.get('displayName'))
      .map((provider) => (
        <BoxCenter key={provider.get('_id')} m={[0.5, 0]}>
          <ProviderIconButton
            onClick={() => onSelectProvider(provider)}
            providerName={provider.get('name')}
            disabled={disableSubmit}
          />
          <p>{provider.get('displayName')}</p>
        </BoxCenter>
      ))
      .toArray();

  const error = (meta.error || meta.asyncError) && meta.touched;
  const featuredProviders = providers;

  return (
    <div>
      <FlexContainer $nbProviders={providers.size}>{renderProviders(featuredProviders)}</FlexContainer>
      {error && <ErrorMessage className="alert alert-danger">{meta.error}</ErrorMessage>}

      <Modal
        displayCloseButton
        isOpen={showAllProvidersModal}
        onRequestClose={() => setShowAllProvidersModal(false)}
        type="plainModal"
        size="lg"
      >
        <Box $m={[1, 0, 0, 0]}>
          <SelectProviders
            title="Connect with your favorite integration"
            providers={providers}
            onSelect={onSelectProvider}
            disableSubmit={disableSubmit}
          />
        </Box>
      </Modal>
    </div>
  );
}

SelectProviderField.propTypes = {
  input: PropTypes.shape(formTypes.fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(formTypes.fieldMetaPropTypes).isRequired,
  providers: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
};
