import React from 'react';

import { NewButton, Result } from '@unitoio/mosaic';

import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';

export function BillingOverviewErrorState() {
  const trackEvent = useTrackEvent();

  return (
    <Result
      status="500"
      title="Error loading your account usage"
      subTitle="We encountered an issue loading your account usage, please click retry to load it again, if this error persists, please contact support"
      extra={[
        <NewButton href={`/#${routes.ABSOLUTE_PATHS.DASHBOARD}`} type="default">
          Back to dashboard
        </NewButton>,
        <NewButton
          onClick={() => {
            trackEvent(trackingTypes.ACTION, {
              action_name: 'clicked on reload page (error displaying plan or usage info)',
            });
            window.location.reload();
          }}
          type="primary"
        >
          Retry
        </NewButton>,
      ]}
    />
  );
}
