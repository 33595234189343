import { useWatch } from 'react-hook-form';

import { useGetProviderExtraStep } from './useGetProviderExtraStep';

export const useShouldDisplayAddOnConfigurationStep = () => {
  const providerNameA = useWatch({ name: 'A.providerName' });
  const providerNameB = useWatch({ name: 'B.providerName' });
  const containerIdA = useWatch({ name: 'A.containerId' });
  const containerIdB = useWatch({ name: 'B.containerId' });
  const associations = useWatch({ name: 'associations' });

  const providerAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerBExtraStep = useGetProviderExtraStep(providerNameB);

  if (associations.length === 0) {
    return false;
  }

  /* If both providers need an extra step, we need to make sure both containers are selected
   * in order to display the add-on configuration step */
  if (providerAExtraStep && providerBExtraStep) {
    return !!(containerIdA && containerIdB);
  }

  if (providerAExtraStep) {
    return !!containerIdA;
  }

  if (providerBExtraStep) {
    return !!containerIdB;
  }

  return false;
};
