import { useWatch } from 'react-hook-form';

export function useGetSelectedToolNames() {
  const formData = useWatch();

  const providerNameA = formData?.A?.providerName?.toLowerCase();
  const providerNameB = formData?.B?.providerName?.toLowerCase();

  if (!providerNameA && !providerNameB) {
    return '';
  }

  if (!providerNameA) {
    return providerNameB;
  }

  if (!providerNameB) {
    return providerNameA;
  }

  return [providerNameA, providerNameB].sort().join(', ');
}
