import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLogger } from '~/hooks/useLogger';
import * as linkActions from '~/actions/links';

export const VALIDATE_AUTOPOPULATE_STATUSES = {
  HAS_NOT_VALIDATED: 'HAS_NOT_VALIDATED',
  CAN_AUTOPOPULATE: 'CAN_AUTOPULATE',
  CANT_AUTOPOPULATE: 'CANT_AUTOPOPULATE',
};

export function useGetCanAutopopulate(linkId) {
  const dispatch = useDispatch();
  const { reportWarning } = useLogger();
  const [canAutopopulate, setCanAutopopulate] = useState(VALIDATE_AUTOPOPULATE_STATUSES.HAS_NOT_VALIDATED);
  const [side, setSideToAutopopulate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrored, setHasErrored] = useState(false);

  useEffect(() => {
    async function validateLinkCanBeAutopopulated() {
      try {
        const { canAutopopulate: canAutopopulateResult, sideToAutopopulate } = await dispatch(
          linkActions.validateLinkCanBeAutopopulated(linkId),
        );
        setCanAutopopulate(
          canAutopopulateResult
            ? VALIDATE_AUTOPOPULATE_STATUSES.CAN_AUTOPOPULATE
            : VALIDATE_AUTOPOPULATE_STATUSES.CANT_AUTOPOPULATE,
        );
        setSideToAutopopulate(sideToAutopopulate);
      } catch (error) {
        setHasErrored(true);
        reportWarning(`Error while validating whether or not link ${linkId} could be autopopulated`, { error });
      } finally {
        setIsLoading(false);
      }
    }

    validateLinkCanBeAutopopulated();
  }, [linkId, dispatch, reportWarning]);

  return [isLoading, { canAutopopulate, side }, hasErrored];
}
